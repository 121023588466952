import React, { SyntheticEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useKeepVisibleX } from 'src/hooks/useKeepVisibleX';
import { ArrowLeftIcon, ArrowRightIcon, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import noop from 'lodash/noop';
import { useSelector } from 'react-redux';

import DefaultImage from 'src/shared/image/media/default_image_product.svg';
import { Key, useShortcut } from 'src/hooks/useShortcut';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

const Root = styled.div`
    margin-bottom: 30px;

    > .slider {
        display: flex;
        padding: 0px 45px;
    }
`;

const Thumbnails = styled.div`
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 10px 0;
    width: 100%;
`;

const TransparentButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    &:hover {
        cursor: pointer;
    }
`;

const MediaThumbnailWrapper = styled.div<{ highlighted: boolean; withText: boolean; colorBranding: string }>`
    display: flex;
    cursor: pointer;
    margin-left: ${(props): string => (props.withText ? '30px' : '20px')};
    ${(props): string => (!props.highlighted && !props.withText ? `opacity: 0.6` : '')};

    .refEntityImageLabel {
        color: ${(props): string => (props.highlighted ? props.colorBranding : theme.color.grey120)};
        font-weight: ${(props): string => (props.highlighted ? '700' : 'normal')};
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
    }

    &:hover,
    &:focus {
        .refEntityImageLabel {
            color: ${(props): string => props.colorBranding};
            font-weight: 700;
        }

        img {
            border: 1px solid ${(props): string => props.colorBranding};
        }
    }
`;

const MediaThumbnail = styled.img<{ highlighted: boolean; colorBranding: string }>`
    width: 44px;
    height: 44px;

    object-fit: contain;
    flex-shrink: 0;
    border: 1px solid ${(props): string => (props.highlighted ? props.colorBranding : theme.color.grey100)};
`;

const Header = styled.div`
    display: flex;
    align-items: baseline;
    border-top: 1px solid ${theme.color.grey60};
    padding: 30px 0px 20px 75px;
`;

const Title = styled.div`
    color: ${theme.color.grey140};
`;

const ResultCounter = styled.div<{ colorBranding: string }>`
    white-space: nowrap;
    color: ${({ colorBranding }): string => colorBranding};
    margin-left: 4px;
    font-size: 13px;
    line-height: 16px;
    text-transform: none;
`;

const ArrowButton = styled(TransparentButton)`
    > svg {
        height: 36px;
        width: 24px;
        path,
        g,
        polyline {
            stroke: ${theme.color.grey100};
        }
    }
`;

type CarouselProps = {
    title: string;
    medias: PreviewMedia[];
    selectedIndex: number;
    onSelection?: (index: number) => void; //__ optional callbacks
    initialIndex?: number;
};

const Carousel: React.FC<CarouselProps> = (props) => {
    const { title, medias, selectedIndex, onSelection = noop, initialIndex = 0 } = props;

    const intl = useIntl();
    const { containerRef, elementRef } = useKeepVisibleX<HTMLImageElement>();
    const [index, setIndex] = useState(initialIndex);

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const handleSetIndex = useCallback(
        (index: number) => {
            setIndex(index);
            onSelection(index);
        },
        [onSelection],
    );

    const setNext = useCallback(() => {
        const newIndex = index === medias.length - 1 ? 0 : index + 1;
        handleSetIndex(newIndex);
    }, [index, medias, handleSetIndex]);

    const setPrevious = useCallback(() => {
        const newIndex = index === 0 ? medias.length - 1 : index - 1;
        handleSetIndex(newIndex);
    }, [index, medias, handleSetIndex]);

    useShortcut(Key.ArrowLeft, setPrevious);
    useShortcut(Key.ArrowRight, setNext);

    return (
        <Root>
            <Header>
                <Title
                    dangerouslySetInnerHTML={{
                        __html:
                            title +
                            (medias &&
                            medias.some((media) => typeof media == 'object' && 'reference_entity' === medias[0].type)
                                ? ': '
                                : ''),
                    }}
                />
                {medias && medias.some((media) => typeof media == 'object') && (
                    <ResultCounter colorBranding={colorBranding}>
                        {'media_file' === medias[0].type &&
                            intl.formatMessage(
                                {
                                    defaultMessage: '{count, plural, =0 {# media} one {# media} other {# media}}',
                                    id: 'components.Carousel.72a9c0',
                                },
                                { count: medias.length },
                            )}
                        {'reference_entity' === medias[0].type &&
                            intl.formatMessage(
                                {
                                    defaultMessage: '{count, plural, =0 {# record} one {# record} other {# records}}',
                                    id: 'components.Carousel.52229b',
                                },
                                { count: medias.length },
                            )}
                    </ResultCounter>
                )}
            </Header>
            <div className='slider'>
                <ArrowButton
                    title={intl.formatMessage({ defaultMessage: 'Previous', id: 'components.Carousel.24935c' })}
                    onClick={setPrevious}
                >
                    <ArrowLeftIcon />
                </ArrowButton>
                <Thumbnails ref={containerRef}>
                    {medias.map((media, index) => {
                        return (
                            <MediaThumbnailWrapper
                                colorBranding={colorBranding}
                                key={index}
                                highlighted={selectedIndex === index}
                                withText={'reference_entity' === media.type}
                                onClick={(): void => handleSetIndex(index)}
                            >
                                <MediaThumbnail
                                    highlighted={selectedIndex === index}
                                    colorBranding={colorBranding}
                                    ref={selectedIndex === index ? elementRef : undefined}
                                    key={index}
                                    src={media.thumbnailUrl || DefaultImage}
                                    onError={(e: SyntheticEvent<HTMLImageElement, Event>): void => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = DefaultImage;
                                    }}
                                    title={media.filename}
                                />
                                {'reference_entity' === media.type && (
                                    <div className='refEntityImageLabel'>{media.filename}</div>
                                )}
                            </MediaThumbnailWrapper>
                        );
                    })}
                </Thumbnails>
                <ArrowButton
                    title={intl.formatMessage({ defaultMessage: 'Next', id: 'components.Carousel.f7e0dd' })}
                    onClick={setNext}
                >
                    <ArrowRightIcon />
                </ArrowButton>
            </div>
        </Root>
    );
};

export default Carousel;
