import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FormEvent, useState, useEffect, useCallback } from 'react';

import { actions } from 'src/components/Authentication/store';
import { useAppDispatch } from 'src/tools/globalStore';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import InputPassword from 'src/shared/input/InputPassword/InputPassword';
import { BadRequestError } from '../../../utils/apiFetch';
import PasswordPolicies from './PasswordPolicies';
import { useSignupTokenAndExistingAccountValidation } from '../hooks/useSignupTokenAndExistingAccountValidation';

const Root = styled.div<{ colorBranding: string }>`
    .sign-up-form {
        min-width: 300px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .welcoming-message {
        margin-top: 0;
        margin-bottom: 20px;
        max-width: 300px;
        color: ${theme.color.grey120};
        font-size: 15px;

        @media (max-width: 768px) {
            font-size: 18px;
        }
        > span {
            color: ${({ colorBranding }): string => colorBranding};
            font-weight: bold;
        }
    }
`;

type Params = {
    email?: string;
    tenantId: string;
    catalogCode: string;
    signupVerificationToken: string;
};

const SignUpForm: React.FC = () => {
    const [password, setPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const history = useHistory();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const params = useParams<Params>();
    const { email = '', signupVerificationToken } = params;

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const { data: signupControlResponse, isLoading } = useSignupTokenAndExistingAccountValidation(
        email,
        signupVerificationToken,
    );

    useEffect(() => {
        if (signupControlResponse?.isValidToken === true && signupControlResponse.existingEmailAccount) {
            history.push(`/login/${signupControlResponse.existingEmailAccount}`);
        }
    }, [history, signupControlResponse]);

    const onPasswordChange = useCallback((value: string) => {
        setPassword(value);
    }, []);

    const onSecondPasswordChange = useCallback((value: string) => {
        setSecondPassword(value);
    }, []);

    const onSubmit = useCallback(async () => {
        if (password !== secondPassword) {
            setErrorMessages([
                intl.formatMessage({
                    defaultMessage: 'The password does not match',
                    id: 'components.SignUpForm.28e90d',
                }),
            ]);
            return;
        }

        try {
            await dispatch(
                actions.createAnAccount({
                    email: email || '',
                    password,
                    secondPassword,
                    signupVerificationToken,
                }),
            );
            history.push(`/login/${email}`);
        } catch (e) {
            if (e instanceof BadRequestError && typeof e.data === 'object' && typeof Array.isArray(e.data.errors)) {
                setErrorMessages(Object.values(e.data.errors as string[]));
            } else {
                setErrorMessages([
                    intl.formatMessage({
                        defaultMessage: 'An error occurred while creating the account',
                        id: 'components.SignUpForm.bf8287',
                    }),
                ]);
            }
        }
    }, [password, secondPassword, intl, dispatch, email, signupVerificationToken, history]);

    if (!isLoading) {
        if (signupControlResponse?.isValidToken === false) {
            return (
                <Root colorBranding={colorBranding}>
                    <p className='welcoming-message'>
                        {intl.formatMessage({
                            defaultMessage:
                                'You are not authorized to create an account. Please contact the catalog owner for access.',
                            id: 'components.SignUpForm.3ca50e',
                        })}
                    </p>
                </Root>
            );
        }
    }

    return (
        <>
            {!isLoading && (
                <Root colorBranding={colorBranding}>
                    <p className='welcoming-message'>
                        {intl.formatMessage(
                            {
                                defaultMessage:
                                    "Welcome {email}! You've been invited to use Akeneo Shared Catalogs. Please, create your password in order to access the available product information.",
                                id: 'components.SignUpForm.11964e',
                            },
                            { email: <span key={params.email}>{params.email}</span> },
                        )}
                    </p>

                    <form
                        className='sign-up-form'
                        onSubmit={(e: FormEvent<HTMLFormElement>): void => e.preventDefault()}
                    >
                        <div>
                            <InputPassword
                                status={errorMessages?.length ? 'error' : undefined}
                                isPasswordVisible={isPasswordVisible}
                                makePasswordVisible={setPasswordVisible}
                                label={intl.formatMessage({
                                    defaultMessage: 'Password',
                                    id: 'components.SignUpForm.e6c83b',
                                })}
                                title={intl.formatMessage({
                                    defaultMessage: 'Please fill out this field.',
                                    id: 'components.SignUpForm.020cf2',
                                })}
                                onChange={onPasswordChange}
                                readOnly={false}
                            />
                            <InputPassword
                                status={errorMessages?.length ? 'error' : undefined}
                                statusMessage={errorMessages?.join('. ')}
                                isPasswordVisible={isPasswordVisible}
                                label={intl.formatMessage({
                                    defaultMessage: 'Confirm password',
                                    id: 'components.SignUpForm.f078a6',
                                })}
                                title={intl.formatMessage({
                                    defaultMessage: 'Please fill out this field.',
                                    id: 'components.SignUpForm.020cf2',
                                })}
                                onChange={onSecondPasswordChange}
                                readOnly={false}
                            />
                        </div>

                        <PasswordPolicies />

                        <Button
                            type='submit'
                            disabled={password === '' || secondPassword === ''}
                            onClick={onSubmit}
                        >
                            {intl.formatMessage({ defaultMessage: 'Create', id: 'components.SignUpForm.573cd8' })}
                        </Button>
                    </form>
                </Root>
            )}
        </>
    );
};

export default SignUpForm;
