/* eslint-disable no-console */
import { useInfiniteQuery } from '@tanstack/react-query';
import { map } from 'lodash';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../tools/globalStore';

import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import TenantCatalogContext from 'src/shared/tenantcatalog/TenantCatalogContext';

type Params = AssociationParams | GalleryParams | VariationParams | VariationModalParams;

type GalleryParams = {
    type: 'gallery' | 'list';
    columns: string[];
    variationMode: 'grouped' | 'ungrouped';
    search: Search;
    searchContexts: SearchContext[];
};

type AssociationParams = {
    type: 'association';
    columns: string[];
    identifiersAndParentCodes: {
        products: string[];
        product_models: string[];
    };
};

type VariationParams = {
    type: 'variation';
    columns: string[];
    grandparent: string;
};

type VariationModalParams = {
    type: 'variationModal';
    columns: string[];
    grandparent: string;
    search: Search;
    searchContexts: SearchContext[];
};

type Result = {
    status: 'pending' | 'error' | 'success';
    isFetching: boolean;
    error: Error | null;
    hasNextPage: boolean;
    fetchNextPage: () => Promise<void>;
    products: Product[] | undefined;
    count: number;
};

export const useInfiniteProducts = (params: Params): Result => {
    const { queryKey, payload } = getPayload(params);

    const { tenantId, catalogCode } = useContext(TenantCatalogContext);
    const locale = useSelector(contextLocaleSelectors.getContextLocale);
    const dispatch = useAppDispatch();

    const fetchProducts = async ({ pageParam = ['', '', ''] }) => {
        const response = await fetch(
            `${process.env.REACT_APP_API_WEB_PATH}/${tenantId}/${catalogCode}/products/${locale}`,
            {
                method: 'POST',
                body: JSON.stringify({
                    ...payload,
                    after: pageParam,
                }),
            },
        );

        return response.json();
    };

    const { data, error, fetchNextPage, hasNextPage, status, isSuccess, isFetching } = useInfiniteQuery({
        queryKey: queryKey,
        queryFn: fetchProducts,
        initialPageParam: ['', '', ''],
        getNextPageParam: (lastPage: ServerProductsResponse) => {
            const lastProduct = lastPage.products[lastPage.products.length - 1];
            if (50 > lastPage.products.length) {
                return undefined;
            }
            return [lastProduct?.grandparent ?? null, lastProduct?.parent ?? null, lastProduct.pimIdentifier ?? null];
        },
    });

    const count = data?.pages ? data.pages[data.pages.length - 1].count : 0;
    const countProductModels = data?.pages ? data.pages[data.pages.length - 1].product_models : 0;
    const countProductSelection = data?.pages ? data.pages[data.pages.length - 1].selected.count : 0;
    const countDisplayedProducts = data?.pages ? data.pages[data.pages.length - 1].product_displayed : 0;

    const products = data?.pages
        .map((pages) => pages.products)
        .reduce((products, currentPage) => products.concat(currentPage));

    useEffect(() => {
        if (isSuccess && 'variationModal' !== params.type) {
            dispatch({
                type: 'SEARCH_RESULT_INFOS',
                productsTotal: count,
                selectedCount: countProductSelection,
                productModelsTotal: countProductModels,
                productDisplayedTotal: countDisplayedProducts,
            });
        }
    }, [count, countDisplayedProducts, countProductModels, countProductSelection, dispatch, isSuccess, params.type]);

    return {
        status: status,
        isFetching: isFetching,
        error: error,
        hasNextPage: hasNextPage,
        fetchNextPage: async (): Promise<void> => {
            if (hasNextPage) {
                await fetchNextPage();
            }
        },
        products: products,
        count: count,
    };
};

const getPayload = (params: Params): { queryKey: string[]; payload: SearchPayload } => {
    const emptyPayload = {
        searchContexts: [],
        searchString: null,
        families: [],
        categories: [],
        excluded_categories: [],
        attributes: [],
        columns: params.columns,
        identifiersAndParentCodes: {
            products: [],
            product_models: [],
        },
        variationGrouped: false,
    };

    switch (params.type) {
        case 'association':
            return {
                queryKey: [
                    'products',
                    params.type,
                    JSON.stringify(params.identifiersAndParentCodes),
                    JSON.stringify(params.columns),
                ],
                payload: {
                    ...emptyPayload,
                    identifiersAndParentCodes: params.identifiersAndParentCodes,
                },
            };

        case 'variation':
            return {
                queryKey: ['products', params.type, JSON.stringify(params.grandparent), JSON.stringify(params.columns)],
                payload: {
                    ...emptyPayload,
                    identifiersAndParentCodes: {
                        products: [],
                        product_models: [params.grandparent],
                    },
                },
            };

        case 'variationModal': {
            const { search } = params;
            return {
                queryKey: ['products', params.type, JSON.stringify(params.grandparent), JSON.stringify(params.columns)],
                payload: {
                    ...emptyPayload,
                    searchString: search.queryString,
                    families: search.families,
                    categories: search.categories || [],
                    excluded_categories: search.excluded_categories || [],
                    attributes: serializeAttributes(search.attributes),
                    columns: params.columns,
                    identifiersAndParentCodes: {
                        products: [],
                        product_models: [params.grandparent],
                    },
                },
            };
        }

        case 'gallery':
        case 'list':
        default: {
            const { search } = params;
            return {
                queryKey: [
                    'products',
                    params.type,
                    JSON.stringify(params.variationMode),
                    JSON.stringify(params.search),
                    JSON.stringify(params.columns),
                ],
                payload: {
                    ...emptyPayload,
                    searchString: search.queryString,
                    families: search.families,
                    categories: search.categories || [],
                    excluded_categories: search.excluded_categories || [],
                    attributes: serializeAttributes(search.attributes),
                    columns: params.columns,
                    identifiersAndParentCodes: {
                        products: [],
                        product_models: [],
                    },
                    variationGrouped: params.variationMode === 'grouped',
                    searchContexts: params.searchContexts,
                },
            };
        }
    }
};

const serializeAttributes = (
    attributes?: Record<string, FilterAttributeSerialized>,
): FilterAttributeSerialized[] | null => {
    return attributes ? map(attributes, (filter) => filter) : null;
};
