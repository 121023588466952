import noop from 'lodash/noop';
import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useClickout from 'src/hooks/useClickout';
import ButtonClose from 'src/shared/button/ButtonClose';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

type Props = {
    open?: boolean;
    onOpen?: (isOpen: boolean) => void;
    isOverlay?: boolean;
    className?: string;
    children?: ReactNode;
    title?: ReactNode;
};

const Root = styled.div<{ colorBranding: string }>`
    pointer-events: none;
    z-index: 999;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;

    .drawer-pan {
        z-index: 10;
        pointer-events: auto;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: ${theme.color.white};
        min-width: 300px;
        padding: 12px 20px 20px 20px;
        transition: transform 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
        transform: translateX(-400px);
        display: flex;
        flex-direction: column;

        > .bt-close {
            position: absolute;
            z-index: 999;
            right: 20px;
            top: 22px;
        }

        .drawer-title {
            text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${({ colorBranding }): string => colorBranding};
            font-size: ${theme.fontSize.small};
            font-weight: normal;
            margin: 0 0 20px 0;
            border-bottom: 1px solid ${({ colorBranding }): string => colorBranding};
            height: 44px;
            line-height: 44px;
            flex: none;
        }

        .drawer-content {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            overflow: auto;
        }

        .searchbar {
            > input {
                padding-right: 25px;
                height: 16px;
            }
        }

        &.translate {
            transform: translateX(-620px);
        }

        &.no-translate {
            transform: translateX(0px);
        }
    }

    .overlay {
        visibility: hidden;
        opacity: 0;
        z-index: 1;
        background-color: rgba(103, 118, 138, 0.6);
        pointer-events: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: opacity 0.2s;
    }

    &.open {
        .overlay {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const Drawer: React.FC<Props> = (props: Props) => {
    const { open: propsOpen = false, onOpen = noop, isOverlay = true, className = '', title } = props;

    const hasBeenOpened = useRef(propsOpen);
    const [open, setOpen] = useState(propsOpen);

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    useEffect(() => {
        if (propsOpen) {
            hasBeenOpened.current = true;
        }
        setOpen(propsOpen);
    }, [propsOpen]);

    const handleClose = useCallback(
        (event: Event) => {
            if (!open) {
                return;
            }
            event.stopPropagation();
            event.preventDefault();
            onOpen(false);
            return false;
        },
        [open, onOpen],
    );

    const refElement = useRef(null);
    useClickout(refElement, handleClose);

    return (
        <Root
            colorBranding={colorBranding}
            className={'drawer' + (open ? ' open' : '') + ' ' + className}
        >
            <div
                className={'drawer-pan ' + (open ? 'no-translate' : 'translate')}
                ref={refElement}
                data-testid='drawer-pan'
            >
                {title && <h2 className='drawer-title'>{title}</h2>}
                <ButtonClose onClick={handleClose} />
                {hasBeenOpened.current ? (
                    <div
                        className='drawer-content'
                        data-testid='drawer-content'
                    >
                        {props.children}
                    </div>
                ) : null}
            </div>
            {isOverlay && <div className='overlay' />}
        </Root>
    );
};

export default Drawer;
