import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Switch } from 'react-router-dom';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';

import Route from 'src/security/Route';
import TenantCatalogContext from 'src/shared/tenantcatalog/TenantCatalogContext';
import { actions as brandingActions } from 'src/shared/branding/store';
import { useAppDispatch } from 'src/tools/globalStore';

import LoginForm from 'src/components/Authentication/components/LoginForm';
import ResetForm from 'src/components/Authentication/components/ResetForm';
import SignUpForm from 'src/components/Authentication/components/SignUpForm';
import NewPasswordForm from 'src/components/Authentication/components/NewPasswordForm';

import logoAkeneo from 'src/shared/branding/media/logo-akeneo.svg';
import Branding from 'src/shared/branding/BrandingLogo';
import { useIsUsingSso } from './hooks/useIsUsingSso';
import { apiPathForTenantAndCatalog } from '../../utils/apiFetch/apiPathForTenantAndCatalog';
import { deleteAllCookies } from '../../tools/deleteAllCookies';

const Root = styled.div<{ coverUrl: string }>`
    color: ${theme.color.grey120};
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 4fr 6fr;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    .right-wrapper {
        display: grid;
        grid-template-rows: 1fr max-content;
        padding: 50px;

        .form-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .logo {
                margin-bottom: 10px;
                display: flex;
                justify-content: center;

                img {
                    max-width: 250px;
                    max-height: 60px;
                }
            }
        }

        .powered {
            display: flex;
            justify-content: center;
            padding-bottom: 0.5em;
            font-size: 13px;
            align-items: flex-end;
            color: ${theme.color.grey140};

            img {
                max-height: 20px;
                margin-left: 0.5em;
            }
        }

        .baseline {
            color: ${theme.color.purple100};
            text-align: center;
        }
    }

    .left-wrapper {
        background: ${theme.color.grey20};

        @media (max-width: 768px) {
            display: none;
        }

        .illustration-login {
            background-image: url(${({ coverUrl }): string => coverUrl});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;
        }
    }
`;

const AuthenticationPage: React.FC = () => {
    const { tenantId, catalogCode } = useContext(TenantCatalogContext);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { data: isUsingSso, isLoading } = useIsUsingSso();

    useEffect(() => {
        void (async (): Promise<void> => {
            await dispatch(brandingActions.fetchBranding());
        })();
    }, [dispatch]);

    if (isLoading) {
        return null;
    }
    if (isUsingSso !== undefined && isUsingSso.isUsingSso) {
        deleteAllCookies();
        const path = `${apiPathForTenantAndCatalog}/auth0/login`;
        window.location.replace(path);
        return null;
    }

    return (
        <Root coverUrl={`${process.env.REACT_APP_API_WEB_PATH}/${tenantId}/${catalogCode}/branding/cover`}>
            <div className='right-wrapper'>
                <div className='form-wrapper'>
                    <div className='logo'>
                        <Branding />
                    </div>
                    <div>
                        <Switch>
                            <Route
                                privateRoute={false}
                                path={'/login/:email?'}
                                component={LoginForm}
                            />
                            <Route
                                privateRoute={false}
                                exact={true}
                                path={'/signup/:email/:signupVerificationToken?'}
                                component={SignUpForm}
                            />
                            <Route
                                privateRoute={false}
                                exact={true}
                                path={'/reset'}
                                component={ResetForm}
                            />
                            <Route
                                privateRoute={false}
                                exact={true}
                                path={'/reset/:email/:verificationToken?'}
                                component={NewPasswordForm}
                            />
                        </Switch>
                    </div>
                </div>

                <a href='https://www.akeneo.com/'>
                    <div className='powered'>
                        {intl.formatMessage({
                            defaultMessage: 'Powered by',
                            id: 'Authentication.AuthenticationPage.53c401',
                        })}
                        <img
                            src={logoAkeneo}
                            alt='logo Akeneo'
                        />
                    </div>
                    <div className='baseline'>
                        {intl.formatMessage({
                            defaultMessage: 'Unlocking Growth Through Product Experiences',
                            id: 'Authentication.AuthenticationPage.95a872',
                        })}
                    </div>
                </a>
            </div>

            <div className='left-wrapper'>
                <div className='illustration-login' />
            </div>
        </Root>
    );
};

export default AuthenticationPage;
