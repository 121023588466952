import httpClient from 'src/tools/HttpClient';
import { ThunkResult } from 'src/tools/globalStore';

const fetchBranding =
    (): ThunkResult<Promise<void>> =>
    async (dispatch): Promise<void> => {
        try {
            const { data }: { data: { color: string } } = await httpClient.get('/branding/color');
            dispatch(setColorBrandingAction(data.color));
        } catch (e) {
            dispatch(resetColorAction());
        }
    };

export const resetColorAction = (): ResetColorAction => ({ type: 'RESET_COLOR' });

export const setColorBrandingAction = (color: string): SetColorAction => ({ type: 'SET_COLOR', color: color });

export default { fetchBranding, resetColorAction, setColorBrandingAction };
