import { createContext } from 'react';

type ContextType = {
    tenantId: string;
    catalogCode: string;
};

const [tenantId, catalogCode] = window.location.pathname.substring(1).split('/');

const TenantCatalogContext = createContext<ContextType>({
    tenantId,
    catalogCode,
});

export default TenantCatalogContext;
