import { Button, Dropdown, SectionTitle, SwitcherButton, useBooleanState } from 'akeneo-design-system';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useAppDispatch } from 'src/tools/globalStore';
import { useSelector } from 'react-redux';

import { selectors as brandingSelectors } from 'src/shared/branding/store';

import SearchBar from 'src/shared/searchbar';

type Props = {
    search: string;
    numberOfResult: number;
    collapseAttributeGroup: { [key: string]: boolean };
    handleChangeSearch: (search: string) => void;
    attributeDisplayType: string;
    setAttributeDisplayType: (attributeDisplayType: string) => void;
    handleCollapseAll: (isCollasped: boolean) => void;
};

const Root = styled.div<{ colorBranding: string }>`
    .product-attributes {
        .switcher-value {
            color: ${({ colorBranding }): string => colorBranding};
        }

        .attributes-searchbar {
            flex: 1;

            .dsm-searchbar {
                border-bottom: none;
                margin-bottom: -5px;

                input,
                div,
                svg {
                    font-size: 15px;
                }
            }
        }

        span.result {
            color: ${({ colorBranding }): string => colorBranding};
        }

        .section-attributes {
            display: flex;
            justify-content: center;
        }
    }
`;

const AttributeTitle: React.FC<Props> = (props) => {
    const {
        search,
        numberOfResult,
        collapseAttributeGroup,
        handleChangeSearch,
        attributeDisplayType,
        setAttributeDisplayType,
        handleCollapseAll,
    } = props;

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const [isOpen, open, close] = useBooleanState(false);
    const handleAttributeType = useCallback(
        (attributeType: string) => {
            setAttributeDisplayType(attributeType);
            dispatch({ type: 'SET_ATTRIBUTE_DISPLAY', payload: attributeType });
            close();
        },
        [close, dispatch, setAttributeDisplayType],
    );

    return (
        <Root
            colorBranding={colorBranding}
            className='section-title'
        >
            <SectionTitle className='product-attributes'>
                <div className='attributes-searchbar'>
                    <SearchBar
                        onChange={handleChangeSearch}
                        inputValue={search}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Search an attribute or a value',
                            id: 'attributes.AttributeTitle.91dba7',
                        })}
                        debounceWait={250}
                    ></SearchBar>
                </div>

                <span className='result'>
                    {intl.formatMessage(
                        {
                            defaultMessage: '{count, plural, =0 {# results} one {# result} other {# results} }',
                            id: 'attributes.AttributeTitle.cc7369',
                        },
                        { count: numberOfResult },
                    )}
                </span>
                <SectionTitle.Separator />

                <Dropdown className='dropdown section-attributes display-attributes'>
                    <SwitcherButton
                        label={intl.formatMessage({
                            defaultMessage: 'Display',
                            id: 'attributes.AttributeTitle.eb8340',
                        })}
                        inline={true}
                        onClick={open}
                    >
                        <div className='switcher-value'>
                            {attributeDisplayType === 'all'
                                ? intl.formatMessage({
                                      defaultMessage: 'All attributes',
                                      id: 'attributes.AttributeTitle.0e55e1',
                                  })
                                : intl.formatMessage({
                                      defaultMessage: 'Active attributes',
                                      id: 'attributes.AttributeTitle.7716d2',
                                  })}
                        </div>
                    </SwitcherButton>
                    {isOpen && (
                        <Dropdown.Overlay
                            verticalPosition='down'
                            onClose={close}
                            fullWidth={true}
                        >
                            <Dropdown.Header>
                                <Dropdown.Title>
                                    {intl.formatMessage({
                                        defaultMessage: 'Display',
                                        id: 'attributes.AttributeTitle.eb8340',
                                    })}
                                </Dropdown.Title>
                            </Dropdown.Header>
                            <Dropdown.ItemCollection>
                                <Dropdown.Item
                                    onClick={(): void => handleAttributeType('all')}
                                    isActive={attributeDisplayType === 'all'}
                                    className={attributeDisplayType === 'all' ? 'active' : ''}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: 'All attributes',
                                        id: 'attributes.AttributeTitle.0e55e1',
                                    })}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={(): void => handleAttributeType('active')}
                                    isActive={attributeDisplayType !== 'all'}
                                    className={attributeDisplayType !== 'all' ? 'active' : ''}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: 'Active attributes',
                                        id: 'attributes.AttributeTitle.7716d2',
                                    })}
                                </Dropdown.Item>
                            </Dropdown.ItemCollection>
                        </Dropdown.Overlay>
                    )}
                </Dropdown>

                <Button
                    className='section-attributes collapse-all'
                    onClick={(): void => handleCollapseAll(collapseAttributeGroup['collapseAll'])}
                >
                    {collapseAttributeGroup['collapseAll']
                        ? intl.formatMessage({ defaultMessage: 'Collapse All', id: 'attributes.AttributeTitle.6f3e94' })
                        : intl.formatMessage({ defaultMessage: 'Expand All', id: 'attributes.AttributeTitle.120659' })}
                </Button>
            </SectionTitle>
        </Root>
    );
};

export default AttributeTitle;
