import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useState, useCallback } from 'react';
import {
    EraseIcon,
    SwitcherButton,
    sharedCatalogsTheme as theme,
    Dropdown,
    useBooleanState,
    Tooltip,
} from 'akeneo-design-system';

import { useAppDispatch } from 'src/tools/globalStore';
import { selectors as searchSelectors } from 'src/components/ProductGrid/store/productGrid';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import SearchBar from 'src/shared/searchbar';

const Root = styled.div<{ colorBranding: string }>`
    .searchbar {
        display: flex;
        flex: 1 1;

        .bt-clear {
            color: ${theme.color.grey100};
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }

        .switcher-value {
            color: ${({ colorBranding }): string => colorBranding};
            display: flex;
            align-items: center;
        }
    }

    .dropdown > div > button {
        align-items: center;
    }

    .switcher-display {
        margin-left: 6px;
    }

    @media (max-width: 768px) {
        margin-right: 0;
        width: auto;
        box-sizing: border-box;

        .searchbar {
            input {
                width: 100%;
            }
        }
    }
`;

const Search: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const queryString = useSelector(({ search }: { search: SearchState }) => search.currentSearch.queryString || '');

    const handleChange = useCallback(
        (search: string) => {
            dispatch({ type: 'SEARCH_QUERYSTRING', value: search });
        },
        [dispatch],
    );

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    //___ display Mode
    const [isOpen, open, close] = useBooleanState(false);

    const [displayKey, setDisplayKey] = useState(useSelector(searchSelectors.currentDisplay));

    const handleSetDisplayKey = useCallback(
        (key: 'gallery' | 'list') => {
            setDisplayKey(key);
            dispatch({ type: 'SET_GALLERY_DISPLAY', payload: key });
            close();
        },
        [close, dispatch],
    );

    // __ variation mode
    const [variationMode, setVariationMode] = useState(useSelector(searchSelectors.currentVariationMode));
    const [isOpenVariation, openVariation, closeVariation] = useBooleanState(false);

    const handleSetVariationMode = useCallback(
        (variationMode: 'grouped' | 'ungrouped') => {
            setVariationMode(variationMode);
            dispatch({ type: 'SET_VARIATION_DISPLAY', payload: variationMode });
            closeVariation();
        },
        [closeVariation, dispatch],
    );

    return (
        <Root
            colorBranding={colorBranding}
            className='product-search'
        >
            <SearchBar
                onChange={handleChange}
                inputValue={queryString}
                placeholder={intl.formatMessage({ defaultMessage: 'Search', id: 'layouts.Search.c66715' })}
                debounceWait={500}
            >
                {queryString && (
                    <div
                        className='bt-clear'
                        onClick={(): void => handleChange('')}
                    >
                        <EraseIcon />
                        {intl.formatMessage({ defaultMessage: 'Clear', id: 'layouts.Search.fc60a8' })}
                    </div>
                )}

                <Dropdown className='dropdown'>
                    <SwitcherButton
                        label={intl.formatMessage({
                            defaultMessage: 'Variations',
                            id: 'layouts.Search.e9b5e0',
                        })}
                        inline={true}
                        onClick={openVariation}
                    >
                        <div className='switcher-value'>
                            <Tooltip
                                iconSize={16}
                                direction={'bottom'}
                                width={330}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: intl.formatMessage({
                                            defaultMessage:
                                                '<b>Variations</b> are products that are based on the same model but differ in some aspects, e.g. size. They may be viewed grouped together by model or not.',
                                            id: 'layouts.Search.14c404',
                                        }),
                                    }}
                                />
                            </Tooltip>
                            {variationMode === 'grouped'
                                ? intl.formatMessage({
                                      defaultMessage: 'Grouped',
                                      id: 'layouts.Search.0915f2',
                                  })
                                : intl.formatMessage({
                                      defaultMessage: 'Ungrouped',
                                      id: 'layouts.Search.1dc613',
                                  })}
                        </div>
                    </SwitcherButton>
                    {isOpenVariation && (
                        <Dropdown.Overlay
                            verticalPosition='down'
                            onClose={closeVariation}
                            fullWidth={true}
                        >
                            <Dropdown.Header>
                                <Dropdown.Title>
                                    {intl.formatMessage({
                                        defaultMessage: 'Variations',
                                        id: 'layouts.Search.e9b5e0',
                                    })}
                                </Dropdown.Title>
                            </Dropdown.Header>
                            <Dropdown.ItemCollection>
                                <Dropdown.Item
                                    onClick={(): void => handleSetVariationMode('grouped')}
                                    isActive={variationMode === 'grouped'}
                                    className={variationMode === 'grouped' ? 'active' : ''}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: 'Grouped',
                                        id: 'layouts.Search.0915f2',
                                    })}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={(): void => handleSetVariationMode('ungrouped')}
                                    isActive={variationMode === 'ungrouped'}
                                    className={variationMode === 'ungrouped' ? 'active' : ''}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: 'Ungrouped',
                                        id: 'layouts.Search.1dc613',
                                    })}
                                </Dropdown.Item>
                            </Dropdown.ItemCollection>
                        </Dropdown.Overlay>
                    )}
                </Dropdown>

                <Dropdown className='dropdown'>
                    <SwitcherButton
                        label={intl.formatMessage({ defaultMessage: 'Display', id: 'layouts.Search.eb8340' })}
                        inline={true}
                        onClick={open}
                    >
                        <div className='switcher-value'>
                            {displayKey === 'gallery'
                                ? intl.formatMessage({ defaultMessage: 'Gallery', id: 'layouts.Search.584c55' })
                                : intl.formatMessage({ defaultMessage: 'List', id: 'layouts.Search.9ce93d' })}
                        </div>
                    </SwitcherButton>
                    {isOpen && (
                        <Dropdown.Overlay
                            verticalPosition='down'
                            onClose={close}
                            fullWidth={true}
                        >
                            <Dropdown.Header>
                                <Dropdown.Title>
                                    {intl.formatMessage({ defaultMessage: 'Display', id: 'layouts.Search.eb8340' })}
                                </Dropdown.Title>
                            </Dropdown.Header>
                            <Dropdown.ItemCollection>
                                <Dropdown.Item
                                    className={'gallery' === displayKey ? 'active' : ''}
                                    isActive={'gallery' === displayKey}
                                    onClick={(): void => handleSetDisplayKey('gallery')}
                                >
                                    <span>
                                        {intl.formatMessage({
                                            defaultMessage: 'Gallery',
                                            id: 'layouts.Search.584c55',
                                        })}
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className={'list' === displayKey ? 'active' : ''}
                                    isActive={'list' === displayKey}
                                    onClick={(): void => handleSetDisplayKey('list')}
                                >
                                    <span>
                                        {intl.formatMessage({
                                            defaultMessage: 'List',
                                            id: 'layouts.Search.9ce93d',
                                        })}
                                    </span>
                                </Dropdown.Item>
                            </Dropdown.ItemCollection>
                        </Dropdown.Overlay>
                    )}
                </Dropdown>
            </SearchBar>
        </Root>
    );
};

export default Search;
