const staticFilters: FilterType[] = [
    {
        key: 'families',
        type: 'family',
        label: 'intl-Family',
    },
    {
        key: 'associationType',
        type: 'associationType',
        label: 'intl-Type',
        isAssociationType: true,
    },
    {
        key: 'associationQuantity',
        type: 'associationQuantity',
        label: 'intl-Quantity',
        isAssociationType: true,
    },
    {
        key: 'image',
        type: 'image',
        label: 'intl-Image',
    },
    {
        key: 'label',
        type: 'label',
        label: 'intl-Label',
    },
    {
        key: 'isCurrentProduct',
        type: 'other',
        label: '',
    },
    {
        key: 'variationAxes',
        type: 'variationAxes',
        label: 'intl-Variations',
    },
    {
        key: 'variationNumber',
        type: 'variationNumber',
        label: 'intl-Variations',
    },
];

export default staticFilters;
