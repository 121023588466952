import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { NumberInput } from 'akeneo-design-system';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';

//_______
type Props = Override<InputProps<string>, object>;

const Root = styled.div``;

const InputNumber: React.FC<Props> = (props) => {
    const { value: propsValue = '', onChange = noop } = props;

    const [value, setValue] = useState(propsValue || '');

    const submitValue = useMemo(() => debounce(onChange, 350), [onChange]);

    const handleChange = useCallback(
        (value: string) => {
            setValue(value);
            submitValue(value);
        },
        [submitValue],
    );

    const handleInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event.currentTarget.value);
        },
        [handleChange],
    );

    return (
        <Root role='input-text'>
            <NumberInput
                value={value}
                onInput={handleInput}
                onChange={handleChange}
            />
        </Root>
    );
};

export default InputNumber;
