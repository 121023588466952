import React, { useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'akeneo-design-system';
import noop from 'lodash/noop';

import { useAppDispatch } from 'src/tools/globalStore';
import { Drawer } from 'src/shared';
import FiltersSelector from 'src/components/Filter/components/filtersSelector';
import { selectors as filterSelectors, actions as filterActions } from 'src/components/Filter/store';
import { useFiltersContext } from 'src/components/Filter/components/filter/FiltersContext';

//__
type Props = {
    open?: boolean;
    onOpen?: (isOpen: boolean) => void;
    handleReset: () => void;
};

const excludeKeys: BooleanMap = {
    image: true,
    label: true,
    variationAxes: true,
};

//___________
const Root = styled.div`
    .drawer-pan {
        min-width: 600px;

        .drawer-content {
            .filters-selector {
                flex: 1;
            }
        }
    }

    .bt-clear {
        margin-right: 10px;
    }
`;

const FiltersManager: React.FC<Props> = (props) => {
    const { open: propsOpen = false, onOpen = noop, handleReset } = props;

    const dispatch = useAppDispatch();
    const intl = useIntl();

    //__ display
    const [open, setOpen] = useState(propsOpen);
    const handleSetOpen = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);

    const handleToggleOpen = useCallback(() => {
        const newOpen = !open;
        setOpen(newOpen);
        onOpen(newOpen);
    }, [open, onOpen]);

    const hasBeenOpened = useRef(open);
    if (open) {
        hasBeenOpened.current = true;
    }

    //__ selected filters
    const selectedFilters: FilterType[] = useSelector(filterSelectors.getActiveFilters);
    const handleChange = useCallback(
        (filters: FilterType[]) => {
            dispatch(filterActions.setFiltersActiveAction(filters));
        },
        [dispatch],
    );

    //
    const searchValue = useSelector(filterSelectors.getSearch);

    const { getMapTypes } = useFiltersContext();

    return (
        <Root
            className='filters-manager'
            data-testid='filters-manager'
        >
            <Button
                size='small'
                level='tertiary'
                ghost
                onClick={handleReset}
                className='bt-clear'
            >
                {intl.formatMessage({ defaultMessage: 'Clear', id: 'filtersManager.FiltersManager.fc60a8' })}
            </Button>
            <Button
                size='small'
                level='tertiary'
                ghost
                onClick={handleToggleOpen}
                className='bt-manage'
                data-testid='bt-toggle-display'
            >
                {intl.formatMessage({ defaultMessage: 'Manage', id: 'filtersManager.FiltersManager.d00ce5' })}
            </Button>
            <Drawer
                open={open}
                onOpen={handleSetOpen}
                title={intl.formatMessage({
                    defaultMessage: 'Manage',
                    id: 'filtersManager.FiltersManager.d00ce5',
                })}
            >
                {hasBeenOpened.current ? (
                    <FiltersSelector
                        selectedFilters={selectedFilters}
                        onChange={handleChange}
                        searchValue={searchValue}
                        excludeKeys={excludeKeys}
                        filterAvailableTypes={getMapTypes()}
                        usePresetAttributeFilters={true}
                    />
                ) : null}
            </Drawer>
        </Root>
    );
};

export default FiltersManager;
