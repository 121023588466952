import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { Helper, SectionTitle } from 'akeneo-design-system';

import { encodeString } from 'src/tools/encodeString';
import { useInfiniteProducts } from 'src/hooks/useInfiniteProducts';

import { selectors as searchSelectors } from 'src/components/ProductGrid/store/productGrid';

import GalleryTableView from 'src/components/ProductGrid/components/gallery/GalleryTableView';
import { EmptyResult } from 'src/shared';

type Props = {
    associations: Associations;
    resetCurrentSection: () => void;
};

const Root = styled.div`
    height: calc(100vh - 325px);

    .gallery-table-view {
        height: 100%;
    }
`;

const ProductAssociations: React.FC<Props> = (props) => {
    const { associations } = props;

    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();

    const search = useSelector(searchSelectors.currentSearch);
    const columns: string[] | undefined = useSelector(
        ({ search }: { search: SearchState }) => search.selectedAssociationsColumns,
    );

    const identifiersAndParentCodes = useMemo(() => {
        const uniqueProductAssociatedIdentifiers = associations.products
            .filter((association) =>
                search.associationType?.length ?? 0 > 0
                    ? search.associationType?.includes(association.associationTypeCode)
                    : association,
            )
            .map((association) => association.identifier)
            .filter((value, index, self) => self.indexOf(value) === index);

        const uniqueProductAssociatedParentCodes = associations.product_models
            .filter((association) =>
                search.associationType?.length ?? 0 > 0
                    ? search.associationType?.includes(association.associationTypeCode)
                    : association,
            )
            .map((association) => association.identifier)
            .filter((value, index, self) => self.indexOf(value) === index);

        return {
            products: uniqueProductAssociatedIdentifiers.length
                ? uniqueProductAssociatedIdentifiers
                : ['<no_products_found>'],
            product_models: uniqueProductAssociatedParentCodes.length
                ? uniqueProductAssociatedParentCodes
                : ['<no_product_models_found>'],
        };
    }, [associations.product_models, associations.products, search.associationType]);

    // __ Get products.
    const { products, count, status, fetchNextPage } = useInfiniteProducts({
        type: 'association',
        identifiersAndParentCodes: identifiersAndParentCodes,
        columns: columns,
    });

    const emptyResult = useMemo(() => {
        return (
            <EmptyResult
                title={intl.formatMessage({
                    defaultMessage: 'Sorry, no associations found.',
                    id: 'associations.ProductAssociations.4ddaf2',
                })}
                subtitle={intl.formatMessage({
                    defaultMessage: 'Try another product or different filters.',
                    id: 'associations.ProductAssociations.34d3a7',
                })}
            />
        );
    }, [intl]);

    const handleClickRow = useCallback(
        (product: Product) => {
            props.resetCurrentSection();
            history.push(`/product/${encodeString(product.pimIdentifier)}${location.search}${location.hash}`);
        },
        [history, location.hash, location.search, props],
    );

    return (
        <Root>
            <div className='section-title'>
                <SectionTitle>
                    <SectionTitle.Title>
                        {intl.formatMessage({
                            defaultMessage: 'Associations',
                            id: 'associations.ProductAssociations.27760a',
                        })}
                    </SectionTitle.Title>
                </SectionTitle>
                <Helper>
                    {intl.formatMessage({
                        defaultMessage:
                            'Other products are associated to this one in different ways. Discover and compare them by browsing and filtering, as well as personalizing the attributes displayed.',
                        id: 'associations.ProductAssociations.91a354',
                    })}
                </Helper>
            </div>

            <GalleryTableView
                columnType='associations'
                identifiersAndParentCodes={identifiersAndParentCodes}
                associations={associations}
                columnCount={1}
                paddingRight={0}
                rowHeight={75}
                loading={status === 'pending'}
                emptyResult={emptyResult}
                itemCount={count}
                getItem={(index: number): Product | null => (products ? products[index] : null)}
                onClickRow={handleClickRow}
                loadMoreItems={fetchNextPage}
                complete={status !== 'pending'}
                minimumBatchSize={50}
            />
        </Root>
    );
};

export default ProductAssociations;
