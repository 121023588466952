import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import Loader from 'src/shared/loader/Loader';
import ProductHeader from 'src/components/ProductPage/components/ProductHeader';
import ProductAttributes from 'src/components/ProductPage/components/attributes/ProductAttributes';
import ProductSideBar from 'src/components/ProductPage/components/ProductSideBar';
import ProductMedia from 'src/components/ProductPage/components/medias/ProductMedia';
import ProductMediaGalleryModal from 'src/components/ProductPage/components/medias/ProductMediaGalleryModal';
import ProductAssociations from 'src/components/ProductPage/components/associations/ProductAssociations';
import ProductVariations from 'src/components/ProductPage/components/variations/ProductVariations';
import { useProductQuery, useCountVariationsQuery } from './hook';

const Root = styled.div`
    display: flex;
    width: 100%;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.5);
    }

    .product-content {
        height: calc(100vh - 90px);
        width: 100%;
        overflow: auto;
    }

    .section-title {
        position: sticky;
        top: 120px;
        background-color: white;
        padding-bottom: 10px;
        z-index: 50;
    }
`;

type Params = {
    tenantId: string;
    catalogCode: string;
    identifier: string;
};
const ProductPage: React.FC = () => {
    const [currentSection, setCurrentSection] = useState<ProductSections>('attributes');
    const { identifier } = useParams<Params>();
    const locale = useSelector(contextLocaleSelectors.getContextLocale);

    const { data: product, isLoading } = useProductQuery(identifier, locale);
    const { data: numberOfVariations } = useCountVariationsQuery(product?.grandparent ?? null, locale);

    const mainMedia = useMemo(() => product?.medias?.[0] || null, [product]);
    const productMedias = useMemo(() => product?.medias?.filter((media) => media.name), [product]);

    const countPerSection = useMemo(
        () => ({
            attributes: product?.attributeGroups?.reduce((acc, group) => acc + group.attributes.length, 0) || 0,
            media: productMedias?.length || 0,
            associations: product?.associations?.count || product?.associations?.products.length || 0,
            variations: numberOfVariations || 0,
        }),
        [product, productMedias, numberOfVariations],
    );

    return (
        <Root>
            {isLoading && <Loader />}
            <ProductSideBar
                currentSection={currentSection}
                countPerSection={countPerSection}
                setCurrentSection={setCurrentSection}
                lastModificationDate={product?.lastModificationDate}
            />

            {product && (
                <div className='product-content rgt-pad'>
                    <ProductHeader
                        productLabel={product.label}
                        mainMedia={mainMedia}
                    />
                    <ProductMediaGalleryModal>
                        {currentSection === 'attributes' && <ProductAttributes product={product} />}

                        {currentSection === 'media' && (
                            <ProductMedia
                                productLabel={product.label}
                                medias={productMedias || []}
                            />
                        )}

                        {currentSection === 'associations' && (
                            <ProductAssociations
                                associations={product.associations || { count: 0, products: [], product_models: [] }}
                                resetCurrentSection={(): void => setCurrentSection('attributes')}
                            />
                        )}

                        {currentSection === 'variations' && (
                            <ProductVariations
                                productIdentifier={product.pimIdentifier}
                                grandparent={product.grandparent}
                                variationAxes={product.variationAxes}
                                isModal={false}
                                resetCurrentSection={(): void => setCurrentSection('attributes')}
                            />
                        )}
                    </ProductMediaGalleryModal>
                </div>
            )}
        </Root>
    );
};

export default ProductPage;
