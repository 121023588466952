import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { TextInput } from 'akeneo-design-system';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';

//_______
type Props = InputProps<string[] | string>;

const Root = styled.div``;

const InputText: React.FC<Props> = (props) => {
    const { value: propsValue = '', onChange = noop } = props;

    const [value, setValue] = useState<string>(
        typeof propsValue === 'string' ? propsValue : propsValue.toString() || '',
    );

    const submitValue = useMemo(() => debounce(onChange, 350), [onChange]);

    const handleChange = useCallback(
        (value: string) => {
            setValue(value);
            submitValue(value);
        },
        [submitValue],
    );

    return (
        <Root role='input-text'>
            <TextInput
                value={value}
                onChange={handleChange}
            />
        </Root>
    );
};

export default InputText;
