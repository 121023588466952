const currentSearch = ({ search }: { search: SearchState }): Search => search.currentSearch;

const searchResultCount = ({ search }: { search: SearchState }): number => search.totalCount;

const searchProductModelCount = ({ search }: { search: SearchState }): number => search.productModelCount;

const searchDisplayedProductCount = ({ search }: { search: SearchState }): number => search.productDisplayedCount;

const searchSelectedCount = ({ search }: { search: SearchState }): number => search.searchSelectedCount;

const selectedMode = ({ search }: { search: SearchState }): string => search.selectedMode;

const currentDisplay = ({ search }: { search: SearchState }): 'gallery' | 'list' => search.currentDisplay;

const currentVariationMode = ({ search }: { search: SearchState }): 'grouped' | 'ungrouped' =>
    search.currentVariationMode;

const currentDisplayAttributeTypes = ({ search }: { search: SearchState }): string =>
    search.currentDisplayAttributeTypes;

export default {
    currentSearch,
    searchResultCount,
    searchProductModelCount,
    searchDisplayedProductCount,
    searchSelectedCount,
    selectedMode,
    currentDisplayAttributeTypes,
    currentDisplay,
    currentVariationMode,
};
