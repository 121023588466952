import React from 'react';

// With this hook, when you have a horizontal DOM list, it will automatically scroll on the given ref element,
// so it can always be visible.
export const useKeepVisibleX = function <T extends HTMLElement>() {
    const containerRef = React.useRef<T | null>(null);
    const elementRef = React.useCallback(
        (element: T) => {
            if (null !== containerRef.current && null !== element) {
                const style = getComputedStyle(element);
                const containerRect = containerRef.current.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();
                const elementWidth = elementRect.width + parseInt(style.marginLeft) + parseInt(style.marginRight);
                if (elementRect.left >= containerRect.right - elementWidth) {
                    containerRef.current.scrollLeft += elementRect.left - containerRect.right + elementWidth;
                } else if (elementRect.left <= containerRect.left) {
                    containerRef.current.scrollLeft -= containerRect.left - elementRect.left;
                }
            }
        },
        [containerRef],
    );

    return { containerRef, elementRef };
};
