import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button, Helper, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import { CSSTransition } from 'react-transition-group';
import React, { createContext, useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';

import { useAppDispatch } from 'src/tools/globalStore';
import { actions as productExportActions } from 'src/components/Export/store';
import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import {
    actions as selectionActions,
    selectors as selectionSelectors,
} from 'src/components/ProductGrid/store/productSelection';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import VirtualScroll from 'src/shared/virtualscroll';
import ButtonClose from 'src/shared/button/ButtonClose';
import SelectedProduct from 'src/components/ProductGrid/components/productSelection/SelectedProduct';

const sidePadding = 25;

type SelectedProductsProps = {
    visiblePanel?: boolean;
    children?: React.ReactNode;
};

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(103, 118, 138, 0.6);
    z-index: 999;
`;

const LeftConatiner = styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        display: none;
    }
`;

const Transition = styled(CSSTransition)`
    &.enter {
        opacity: 0;
    }
    &.enter-done {
        opacity: 1;
        transition-property: opacity;
        transition-duration: 0.2s;
    }
    &.exit {
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.2s;
    }
`;

const TransitionWrapper = styled(CSSTransition)`
    &.enter {
        right: -450px;
        opacity: 1;
    }
    &.enter-done {
        right: 0px;
        opacity: 1;
        transition-property: right;
        transition-duration: 0.2s;
    }
    &.exit {
        right: -450px;
        opacity: 1;
        transition-property: right;
        transition-duration: 0.2s;
    }
`;

const RightContainer = styled.div`
    background: ${theme.color.white};
    border-radius: 0px;
    padding: 25px;
    padding-left: ${sidePadding}px;
    padding-right: 0;
    display: grid;
    grid-template-rows: max-content max-content 1fr max-content;
    position: fixed;
    right: 0;
    height: 100%;
    width: 24%;
    box-sizing: border-box;
    max-width: 400px;
    min-width: 300px;

    @media (max-width: 768px) {
        max-width: none;
        width: 100%;
        grid-template-rows: max-content 1fr max-content;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 40px;
    }
`;

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

const MobileTitle = styled.span<{ colorBranding: string }>`
    display: none;
    @media (max-width: 768px) {
        color: ${({ colorBranding }): string => colorBranding};
        font-size: 18px;
        text-transform: uppercase;
        display: block;
        align-self: center;
        justify-self: center;
    }
`;

const TitleLine = styled.div<{ colorBranding: string }>`
    margin-bottom: 20px;
    margin-right: ${sidePadding}px;

    .selected-header {
        color: ${({ colorBranding }): string => colorBranding};
        font-size: 13px;
        text-transform: uppercase;
        display: block;
        border-bottom: 1px solid ${({ colorBranding }): string => colorBranding};
        padding: 0;
        padding-top: 20px;
        padding-bottom: 13px;
    }

    @media (max-width: 768px) {
        display: none;
        border-bottom: none;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding-right: ${sidePadding}px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: max-content 1fr;

        svg {
            height: 25px;
            width: 25px;
        }
    }
`;

const ButtomContainer = styled.div`
    border-top: 1px solid ${theme.color.grey60};
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        margin-top: 15px;
    }

    @media (max-width: 768px) {
        margin-bottom: 0;
        padding-top: 30px;
        margin-right: 0;
        margin-left: 0;
    }
`;

const RemoveAllButton = styled(Button)`
    && {
        margin-right: 0.5em;
        &:focus {
            outline: none;
        }

        @media (max-width: 768px) {
            display: none;
        }
    }
`;

const StyledLaunchExportButton = styled(Button)`
    && {
        display: none;

        @media (max-width: 768px) {
            display: block;
            font-size: 18px;
            width: 183px;
            height: 50px;
            border-radius: 25px;
            padding: 0;
            margin: 0;
        }
    }
`;

const StyledExportButton = styled(Button)`
    && {
        margin-left: 0.5em;

        @media (max-width: 768px) {
            display: none;
        }
    }
`;

const LaunchExportButton: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const handleShowExport = useCallback(() => {
        dispatch(productExportActions.display(true));
    }, [dispatch]);

    return (
        <StyledLaunchExportButton onClick={handleShowExport}>
            {intl.formatMessage({
                defaultMessage: 'Launch Export',
                id: 'productSelection.SelectedProductsProvider.bf96ab',
            })}
        </StyledLaunchExportButton>
    );
};

const ExportButton: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const handleShowExport = useCallback(() => {
        dispatch(productExportActions.display(true));
    }, [dispatch]);

    return (
        <StyledExportButton onClick={handleShowExport}>
            {intl.formatMessage({ defaultMessage: 'Export', id: 'productSelection.SelectedProductsProvider.495c09' })}
        </StyledExportButton>
    );
};

type SelectedProductsContextValue = {
    showSelectedProducts: (selectedProductsProps?: SelectedProductsProps) => any;
};

export const SelectedProductsContext = createContext<SelectedProductsContextValue>({
    showSelectedProducts: () => {
        return;
    },
});

export const useSelectedProducts = (): SelectedProductsContextValue => {
    return useContext(SelectedProductsContext);
};

const SelectedProductsProvider: React.FC<SelectedProductsProps> = (props) => {
    const { children, visiblePanel = false } = props;

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const unmount = useRef(false);
    useEffect(() => {
        unmount.current = false;
        return () => {
            unmount.current = true;
        };
    }, []);

    const [visible, setVisible] = useState(visiblePanel);

    const removeAllProducts = useCallback(() => {
        dispatch({ type: 'PRODUCT_SELECTION_RESET' });
        setVisible(false);
    }, [dispatch]);

    const contextValue = useMemo(
        () => ({
            showSelectedProducts: (): void => {
                setVisible(true);
            },
        }),
        [],
    );

    useEffect(() => {
        setVisible(visible);
    }, [visible]);

    const hidePanel = useCallback(() => {
        setVisible(false);
    }, []);

    //_________
    const locale = useSelector(contextLocaleSelectors.getContextLocale);
    const searchContexts = useSelector(selectionSelectors.searchContexts);
    const updates = useSelector(selectionSelectors.updates);
    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const [loading, setLoading] = useState(false);

    const pagedSelection = useMemo(() => {
        if (!locale || !searchContexts.length) {
            return;
        }

        return dispatch(
            selectionActions.pagedProductSelection({
                locale,
                searchContexts,
                onFetchPage: (page) => {
                    setLoading(true);
                },
                onPageFetched: (page, data, complete, isLoading) => {
                    if (unmount.current) {
                        return;
                    }
                    setLoading(isLoading);
                },
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locale, searchContexts, updates]);

    const totalCount = pagedSelection?.totalRecords || 0;

    const loadMoreItems = useCallback(
        async (startIndex?: number, stopIndex?: number) => {
            if (!pagedSelection || !visible) {
                return;
            }
            pagedSelection.fetchIndex(startIndex, stopIndex);
        },
        [pagedSelection, visible],
    );

    const handleRemoveItem = useCallback(
        (product: Product) => {
            dispatch(selectionActions.removeProduct(product));
        },
        [dispatch],
    );

    const renderItem = useCallback(
        (props: RenderItemProps<Product>) => {
            return (
                <SelectedProduct
                    {...props}
                    handleClickRemove={handleRemoveItem}
                />
            );
        },
        [handleRemoveItem],
    );

    return (
        <SelectedProductsContext.Provider value={contextValue}>
            {children}
            <Transition
                in={visible}
                timeout={200}
                className='container'
                unmountOnExit
            >
                <Wrapper>
                    <Container className='scrollbar-light'>
                        <LeftConatiner onClick={hidePanel} />
                        <TransitionWrapper
                            in={visible}
                            timeout={200}
                            className='selectedProducts'
                            unmountOnExit
                        >
                            <RightContainer>
                                <Header className='header'>
                                    <ButtonClose onClick={hidePanel} />
                                    <MobileTitle colorBranding={colorBranding}>
                                        {intl.formatMessage({
                                            defaultMessage: 'Selection',
                                            id: 'productSelection.SelectedProductsProvider.c4b8a0',
                                        })}
                                    </MobileTitle>
                                </Header>
                                <TitleLine colorBranding={colorBranding}>
                                    <span className='selected-header'>
                                        {intl.formatMessage(
                                            {
                                                defaultMessage:
                                                    '{count, plural, =0 {# product} one {# product} other {# products} } selected',
                                                id: 'productSelection.SelectedProductsProvider.d90342',
                                            },
                                            { count: totalCount },
                                        )}
                                    </span>
                                    <Helper>
                                        {intl.formatMessage({
                                            defaultMessage:
                                                'Review and modify your product selection for export in pdf/excel format.',
                                            id: 'productSelection.SelectedProductsProvider.cef41e',
                                        })}
                                    </Helper>
                                </TitleLine>
                                <VirtualScroll
                                    itemCount={totalCount}
                                    getItem={pagedSelection?.getRecord}
                                    renderItem={renderItem}
                                    rowHeight={68}
                                    loadMoreItems={loadMoreItems}
                                    paddingRight={0}
                                    loading={loading}
                                    complete={pagedSelection?.complete}
                                />
                                <ButtomContainer>
                                    <RemoveAllButton
                                        level='tertiary'
                                        ghost
                                        onClick={removeAllProducts}
                                        disabled={!(totalCount > 0)}
                                    >
                                        {intl.formatMessage({
                                            defaultMessage: 'Remove all',
                                            id: 'productSelection.SelectedProductsProvider.8cd6a2',
                                        })}
                                    </RemoveAllButton>
                                    <ExportButton />
                                    <LaunchExportButton />
                                </ButtomContainer>
                            </RightContainer>
                        </TransitionWrapper>
                    </Container>
                </Wrapper>
            </Transition>
        </SelectedProductsContext.Provider>
    );
};

export default SelectedProductsProvider;
