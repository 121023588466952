import { produce } from 'immer';

const initialState: AccountState = {
    user: null,
};

const AccountReducer = (state = initialState, action: AccountActions): AccountState => {
    switch (action.type) {
        case 'LOGIN':
            return produce(state, (draft) => {
                draft.user = { email: action.email };
            });
        case 'LOGOUT':
            return produce(state, (draft) => {
                draft.user = null;
            });
        default:
            return state;
    }
};

export default AccountReducer;
