import { useQuery } from '@tanstack/react-query';
import { decodeString } from '../../../tools/encodeString';
import { Product } from '../model/product';
import { apiFetch } from '../../../utils/apiFetch';

type Error = string | null;
export type Result = {
    isLoading: boolean;
    isError: boolean;
    data: Product | undefined;
    error: Error;
};

export const useProductQuery = (identifier: string, locale: string | null): Result => {
    return useQuery<Product, Error, Product>({
        queryKey: ['product', identifier, locale],
        queryFn: async () => {
            return await apiFetch<Product>(`product/${locale}`, {
                method: 'POST',
                body: JSON.stringify({ pimIdentifier: decodeString(identifier) }),
            });
        },
        enabled: locale !== null,
    });
};
