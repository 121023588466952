import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { createContext, useContext, useMemo, useState } from 'react';

import Preview from 'src/shared/preview/Preview';
import ButtonClose from 'src/shared/button/ButtonClose';
import { Key, useShortcut } from 'src/hooks/useShortcut';

const Root = styled.div`
    background-color: ${theme.color.white};
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;

    .bt-close {
        position: absolute;
        z-index: 100;
        left: 40px;
        top: 40px;
    }
`;

const ProductMediaGalleryWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
`;

type ProductMediaGalleryModalContextmediaProps = {
    medias: Media[];
    productLabel: string;
    selectedIndex: number;
    type: string;
};

export const ProductMediaGalleryModalContext = createContext<{
    show: (mediaProps: ProductMediaGalleryModalContextmediaProps) => void;
}>({
    show: () => {
        return;
    },
});

export const useProductMediaGalleryModal = (): {
    show: (mediaProps: ProductMediaGalleryModalContextmediaProps) => void;
} => {
    return useContext(ProductMediaGalleryModalContext);
};

const ProductMediaGalleryModal: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const intl = useIntl();

    const [visible, setVisible] = useState(false);
    const [mediaProps, setMediaProps] = useState<ProductMediaGalleryModalContextmediaProps>({
        medias: [] as Media[],
        productLabel: '',
        selectedIndex: 0,
        type: '',
    });

    const contextValue = useMemo(
        () => ({
            show: (mediaProps: ProductMediaGalleryModalContextmediaProps): void => {
                setVisible(true);
                setMediaProps(mediaProps);
            },
        }),
        [],
    );

    const previewMedias = mediaProps.medias.map((media) => ({
        filename: media.name,
        format: media.format,
        previewFormat: media.previewFormat,
        downloadUrl: media.download,
        previewUrl: media.preview,
        thumbnailUrl: media.thumbnail,
        sourceUrl: media.source,
        id: media.id,
        type: mediaProps.type,
    }));

    useShortcut(Key.Escape, () => setVisible(false));

    return (
        <ProductMediaGalleryModalContext.Provider value={contextValue}>
            {children}
            {visible && (
                <Root>
                    <ProductMediaGalleryWrapper>
                        <ButtonClose onClick={(): void => setVisible(false)} />
                        <Preview
                            medias={previewMedias}
                            breadcrumbs={
                                mediaProps.productLabel && mediaProps.type && mediaProps.type === 'media_file'
                                    ? intl.formatMessage({
                                          defaultMessage: 'Products',
                                          id: 'medias.ProductMediaGalleryModal.ecd15f',
                                      }) +
                                      ' / ' +
                                      mediaProps.productLabel
                                    : '' //__ if "type" is not a media file, we pass an empty string as breadcrumb (nothing to display)
                            }
                            galleryName={mediaProps.productLabel}
                            initialIndex={mediaProps.selectedIndex}
                        />
                    </ProductMediaGalleryWrapper>
                </Root>
            )}
        </ProductMediaGalleryModalContext.Provider>
    );
};

export default ProductMediaGalleryModal;
