import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import Filters from 'src/components/Filter/components/filter/Filters';
import Navigation from 'src/components/AccountMenu/components/Navigation';
import Gallery from 'src/components/ProductGrid/components/gallery/Gallery';
import ProductPage from 'src/components/ProductPage/ProductPage';
import Route from 'src/security/Route';
import ExportProduct from 'src/components/Export/components/ExportProduct';
import Error404 from 'src/Error404Page';
import NotificationItem from 'src/shared/notification/NotificationItem';
import SelectedProductsProvider from 'src/components/ProductGrid/components/productSelection/SelectedProductsProvider';
import { useAppDispatch } from 'src/tools/globalStore';
import { localesActions } from 'src/shared/locale/store/CatalogLocales';
import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import { actions as filtersActions } from 'src/components/Filter/store';
import { actions as brandingActions, selectors as brandingSelectors } from 'src/shared/branding/store';

import useMedia from 'src/hooks/useMedia';
import { useIntl } from 'react-intl';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';

const Root = styled.div<{ isMobile: boolean }>`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    > header {
        background-color: white;
        padding-top: 15px;
        padding-left: 30px;
    }

    .message-error-mobile {
        position: absolute;
        z-index: 999;
        left: 40px;
        right: 40px;
        top: 20px;

        // Erase the max-width from the DSM
        & > div > div {
            min-width: inherit;
            max-width: inherit;
        }
    }

    > .page-content {
        flex: 1;
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .sidebar {
        margin-right: 40px;
    }

    // Accessing subpanel.
    .nav-panel {
        > div {
            background: white;
        }

        &.nav-panel-open {
            > div {
                width: ${({ isMobile }): number => (isMobile ? 250 : 330)}px;

                & > div {
                    width: ${({ isMobile }): number => (isMobile ? 250 : 330)}px;

                    & > div {
                        padding-top: 12px;
                    }
                }

                & > button {
                    width: ${({ isMobile }): number => (isMobile ? 210 : 290)}px;
                }
            }
        }
    }

    .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-top: 2px;
        box-sizing: border-box;
    }

    //__ mobile
    @media (max-width: 768px) {
        > .page-content {
            padding: 0;
            padding-left: 40px;
            box-sizing: border-box;
        }

        > header {
            height: auto;
            padding-right: 40px;
            padding-left: 40px;
            box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.1);
        }

        .sidebar {
            display: none;
        }
    }
`;

// __ Overwrite dropdown style
const GlobalStyle = createGlobalStyle<{ colorBranding: string }>`
    .branding-badge {
        color: ${({ colorBranding }): string => colorBranding};
        border: 1px solid ${({ colorBranding }): string => colorBranding};
    }

    #dropdown-root {
        > :nth-child(2) {
            // Title
            > :first-child {
                border-bottom: 1px solid ${({ colorBranding }): string => colorBranding};

                div:not([role='checkbox']) {
                    color: ${({ colorBranding }): string => colorBranding};
                }
            }

            // List of items
            > :nth-child(2) .active {
                color: ${({ colorBranding }): string => colorBranding};

                &:hover {
                    color: ${theme.color.grey140};
                }
            }

            // Cancel custom dropdown and filters
            .dropdown-overlay {
                border-bottom: none;

                div:not([role='checkbox']) {
                    color: ${theme.color.grey120};

                    .dropdown-title, .welcome, .item-logout, .active {
                        color: ${({ colorBranding }): string => colorBranding} !important;
                    }
                }
            }
        }
    }

    // Overwrite our dropdown component.
    #input-overlay-root {
        > :nth-child(2) > div > div:focus {
            color: ${({ colorBranding }): string => colorBranding};
        }
        .item-locale:hover {
            color: ${({ colorBranding }): string => colorBranding};
        }
    }
`;

const Portal: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const locale = useSelector(contextLocaleSelectors.getContextLocale);
    const colorBranding = useSelector(brandingSelectors.getColorBranding);
    const isMobile = useMedia(['(max-width: 1200px)'], [true], false);

    const shouldDisplayNotification = useMedia(['(max-width: 1023px)'], [true], false);
    const [displayNotificationTablet, setDisplayNotificationTablet] = useState(true);

    useEffect(() => {
        void (async (): Promise<void> => {
            await dispatch(localesActions.fetchCatalogLocales());
        })();
    }, [dispatch]);

    useEffect(() => {
        if (!locale) {
            return;
        }
        void (async (): Promise<void> => {
            await dispatch(filtersActions.fetchUniqueIdentifierAttribute(locale));
        })();
    }, [dispatch, locale]);

    useEffect(() => {
        void (async (): Promise<void> => {
            await dispatch(brandingActions.fetchBranding());
        })();
    }, [dispatch]);

    return (
        <>
            <GlobalStyle colorBranding={colorBranding} />
            <SelectedProductsProvider>
                <Root isMobile={isMobile}>
                    {shouldDisplayNotification && displayNotificationTablet && (
                        <div className='message-error-mobile'>
                            <NotificationItem
                                notification={{
                                    title: intl.formatMessage({
                                        defaultMessage: 'Shared Catalogs is optimized for larger devices.',
                                        id: 'src.Portal.0b5294',
                                    }),
                                    description: intl.formatMessage({
                                        defaultMessage:
                                            'We noticed that you are using Shared Catalogs on a small device. For an optimized experience, we recommend using Shared Catalogs in landscape orientation on tablet, or on a larger device.',
                                        id: 'src.Portal.b7a50f',
                                    }),
                                    level: 'error',
                                    autoHide: false,
                                }}
                                onClose={(): void => setDisplayNotificationTablet(false)}
                            />
                        </div>
                    )}
                    <header className='rgt-pad'>
                        <Navigation />
                    </header>
                    <div className='page-content'>
                        <Switch>
                            <Route
                                path='/product/:identifier'
                                exact={true}
                                component={ProductPage}
                            />
                            <Route
                                path='/'
                                exact={true}
                            >
                                <div className='sidebar'>
                                    <Filters />
                                </div>
                                <div className='body'>
                                    <Gallery />
                                </div>
                            </Route>
                            <Route
                                path='*'
                                component={Error404}
                            />
                        </Switch>
                    </div>
                </Root>
            </SelectedProductsProvider>
            <ExportProduct />
        </>
    );
};

export default Portal;
