const parseLocales = () => {
    //__ special webpack util allowing tricky dir parsing frontend side :
    // https://webpack.js.org/guides/dependency-management/#context-module-api
    //___ warn require.context regExp should be literal ( no variable )
    const modules = require.context('./languages', false, /[a-z]{2,2}_[A-Z]{2,2}\.json$/).keys();

    const regExp = /([a-z]{2,2}_[A-Z]{2,2})\.json$/; //__ extract locale name without path and extension
    const files = modules.map((module) => {
        return module.match(regExp)[1];
    });

    return files;
};

export default parseLocales;
