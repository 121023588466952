import { useQuery } from '@tanstack/react-query';
import { apiFetch } from '../../../utils/apiFetch';
import { IsLoggedIn } from '../models/IsLoggedIn';

type Error = string | null;
export type Result = {
    isLoading: boolean;
    isError: boolean;
    data: IsLoggedIn | undefined;
    error: Error;
};

export const useIsLoggedIn = (): Result => {
    return useQuery<IsLoggedIn, Error, IsLoggedIn>({
        queryKey: ['is_logged_in'],
        queryFn: async () => {
            return apiFetch<IsLoggedIn>(`is_logged_in`);
        },
        staleTime: 0,
    });
};
