import noop from 'lodash/noop';
import each from 'lodash/each';
import filter from 'lodash/filter';
import styled from 'styled-components';
import { Checkbox, ArrowDownIcon, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';

import SearchBar from 'src/shared/searchbar';
import { useIntl } from 'react-intl';

//__ WARN : input checkbox is a GROUP of checkboxes ( could have only one )
type Props = Override<
    InputProps<string[]>,
    {
        choices: InputSelectChoice[] | (() => InputSelectChoice[]) | Promise<InputSelectChoice[]>;
        maxVisible?: number;
        showMoreCount?: number;
        searchBar?: boolean | ((value: string) => void);
        searchBarValue?: string;
    }
>;

const emptyArray: [] = [];

const Root = styled.div`
    .show-more {
        font-size: 11px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .icon {
            width: 10px;
            height: auto;
            margin-right: 4px;
            color: ${theme.color.grey100};
        }
    }
`;

const InputCheckbox: React.FC<Props> = (props) => {
    const { choices: propsChoices, onChange = noop, maxVisible, showMoreCount = 3, searchBar, searchBarValue } = props;

    const intl = useIntl();
    const value = useMemo(() => props.value || [], [props.value]);

    const valueByValue = useMemo<BooleanMap>(() => {
        const res: BooleanMap = {};
        value.forEach((v: string) => {
            res[v] = true;
        });
        return res;
    }, [value]);

    //__ search
    const [searchString, setSearchString] = useState(searchBarValue || '');
    const onChangeSearch = useCallback(
        (value: string) => {
            setSearchString(value);
            if (typeof searchBar === 'function') {
                searchBar(value);
            }
        },
        [searchBar],
    );

    const [choices, setChoices] = useState<InputSelectChoice[]>(emptyArray);
    const fetched = useRef(false);

    useEffect(() => {
        if (!propsChoices) {
            console.error('props.choices must be set.');
            return;
        }
        if (propsChoices instanceof Array) {
            setChoices(propsChoices);
            return;
        }
        void (async (): Promise<void> => {
            const items = (await (propsChoices as Function)()) || emptyArray;
            fetched.current = true;
            setChoices(items);
        })();
    }, [propsChoices]);

    //__ check values corresponding choices ( ie: locale changed )
    useEffect(() => {
        if (!fetched.current || !Object.keys(valueByValue).length) {
            return;
        }

        const choicesByKey: { [key: string]: InputSelectChoice } = {};
        each(choices, (choice: InputSelectChoice) => {
            choicesByKey[choice.value] = choice;
        });

        const newValue = { ...valueByValue };
        let changed = false;
        each(valueByValue, (value, key) => {
            if (!choicesByKey[key]) {
                delete newValue[key];
                changed = true;
            }
        });
        if (changed) {
            onChange(Object.keys(newValue));
        }
    }, [choices, valueByValue, onChange]);

    const filteredChoices = useMemo(() => {
        if (!searchBar || !searchString || !searchString.length) {
            return choices;
        }
        const reg = new RegExp(`${searchString}`, 'i');
        return filter(choices, (choice) => reg.test(choice.label));
    }, [searchBar, choices, searchString]);

    //__ show more
    const [visibleCount, setVisibleCount] = useState(maxVisible);
    useEffect(() => {
        setVisibleCount(maxVisible);
    }, [maxVisible, searchString]);
    const visibleChoices = useMemo(() => {
        return visibleCount ? filteredChoices.slice(0, visibleCount) : filteredChoices;
    }, [filteredChoices, visibleCount]);

    const handleClickMore = useCallback(() => {
        setVisibleCount((state) => (state || 0) + showMoreCount);
    }, [showMoreCount]);

    const showMore = useMemo(() => {
        if (!maxVisible || filteredChoices.length === visibleChoices.length) {
            return null;
        }
        return (
            <div
                className='show-more'
                onClick={handleClickMore}
            >
                <ArrowDownIcon className='icon' />
                {intl.formatMessage({ defaultMessage: 'Show more', id: 'InputCheckbox.InputCheckbox.696a41' })}
            </div>
        );
    }, [maxVisible, filteredChoices.length, visibleChoices.length, handleClickMore, intl]);

    const handleChange = useCallback(
        (value: boolean, choice: InputSelectChoice) => {
            const values = { ...valueByValue };
            if (value) {
                values[choice.value] = true;
            } else {
                delete values[choice.value];
            }
            onChange(Object.keys(values));
        },
        [onChange, valueByValue],
    );

    return (
        <Root>
            {searchBar && (
                <SearchBar
                    onChange={onChangeSearch}
                    inputValue={searchString}
                />
            )}
            {visibleChoices.map((choice, index) => (
                <ItemCheckbox
                    key={index}
                    choice={choice}
                    value={valueByValue[choice.value]}
                    onChange={handleChange}
                />
            ))}
            {showMore}
        </Root>
    );
};

export default InputCheckbox;

type ItemProps = {
    choice: InputSelectChoice;
    value?: boolean;
    index?: number;
    onChange?: (value: boolean, choice: InputSelectChoice, index: number) => void;
};

const ItemRoot = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #131415;
    margin-bottom: 14px;

    label {
        cursor: pointer;
        line-height: 18px;

        > span.label {
            display: inline-block;
        }
    }

    @media (max-width: 768px) {
        label {
            font-size: 18px;
            margin-left: 10px;
        }
    }
`;

const ItemCheckbox: React.FC<ItemProps> = (props) => {
    const { choice, index, value = false, onChange = noop } = props;

    const handleChange = useCallback(
        (value: boolean) => {
            onChange(value, choice, index);
        },
        [onChange, choice, index],
    );

    return (
        <ItemRoot className='item-checkbox'>
            <Checkbox
                checked={value}
                onChange={handleChange}
            >
                <span className='label'>{choice.label}</span>
            </Checkbox>
        </ItemRoot>
    );
};
