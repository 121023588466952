import React from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';
import { SelectInput } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

import { Flag } from 'src/shared/index';

export type Locale = {
    code: string;
    label: string;
};

//____________
type Props = {
    label?: React.ReactNode;
    choices: Locale[];
    value?: string | null;
    onChange?: (value: string) => void;
    inline?: boolean;
    clearable?: false;
    placeholder?: string;
    emptyResultLabel?: string;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;

    > label {
        margin-bottom: 8px;
    }
`;

const ItemLocale = styled(SelectInput.Option)`
    display: flex;
    align-items: center;
    cursor: pointer;

    .locale {
        display: flex;

        & span {
            margin-left: 5px;
        }
    }
`;

//__ Component
const InputLocale: React.FC<Props> = (props) => {
    const intl = useIntl();

    const {
        label,
        onChange = noop,
        choices = [],
        value = null,
        clearable,
        placeholder = intl.formatMessage({
            defaultMessage: 'Please type in a value',
            id: 'InputLocale.InputLocale.e94be1',
        }),
        emptyResultLabel = intl.formatMessage({
            defaultMessage: 'No result found',
            id: 'InputLocale.InputLocale.180037',
        }),
    } = props;

    return (
        <Root
            className='input-locale'
            data-testid='input-locale'
        >
            {label && <label>{label}</label>}
            <SelectInput
                emptyResultLabel={emptyResultLabel}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                openLabel={intl.formatMessage({
                    defaultMessage: 'Display choices',
                    id: 'InputLocale.InputLocale.deafe1',
                })}
                clearable={clearable}
            >
                {choices.map((locale: Locale) => (
                    <ItemLocale
                        key={locale.code}
                        title={locale.label}
                        value={locale.code}
                        className='item-locale'
                    >
                        <div className='locale'>
                            <Flag flagCode={locale.code.split(/[_-]/)[1].toLowerCase()} />
                            <span>{locale.label}</span>
                        </div>
                    </ItemLocale>
                ))}
            </SelectInput>
        </Root>
    );
};

export default InputLocale;
