import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Button, sharedCatalogsTheme as theme } from 'akeneo-design-system';

import useMedia from 'src/hooks/useMedia';
import { useAppDispatch } from 'src/tools/globalStore';
import { encodeString } from 'src/tools/encodeString';

import { useSelectedProducts } from '../productSelection/SelectedProductsProvider';
import { actions as productExportActions } from 'src/components/Export/store';
import { selectors as searchSelectors } from 'src/components/ProductGrid/store/productGrid';
import {
    actions as selectionActions,
    selectors as selectionSelectors,
} from 'src/components/ProductGrid/store/productSelection';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import Search from '../layouts/Search';
import SelectionMenu from '../productSelection/SelectionMenu';
import GalleryTableView from './GalleryTableView';
import MobileFilters from 'src/components/Filter/components/filter/MobileFilters';
import VirtualScroll from 'src/shared/virtualscroll';
import GalleryItem from 'src/components/ProductGrid/components/gallery/GalleryItem';
import { EmptyResult } from 'src/shared';
import VariantModal from 'src/components/ProductPage/components/variations/VariantModal';
import { useInfiniteProducts } from 'src/hooks/useInfiniteProducts';

const MAX_EXPORT_PDF = 200;

const Root = styled.div<{ paddingRight: number }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 0 !important;
    margin-right: 0 !important;

    .head {
        margin-bottom: 10px;
    }

    .content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        position: relative;
    }
`;

const SelectionToolbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    background-color: white;
    padding-bottom: 5px;
    z-index: 1;
    flex-wrap: wrap;

    label > p {
        text-transform: uppercase;
        height: 13px;
        color: ${theme.color.grey100};
        font-size: 11px;
    }

    .select-all {
        flex: 1 1;
    }

    .bt-export {
        margin-left: 10px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const MobileButtonsWrapper = styled.div<{ visible: boolean }>`
    display: ${(props): string => (props.visible ? 'block' : 'none')};
    width: 100%;
    height: 85px;
    background: rgb(255, 255, 255);
    box-shadow: 0px -2px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    position: fixed;
    bottom: 0;
`;

const MobileSelectedButton = styled.button<{ colorBranding: string }>`
    margin-top: 2em;
    height: 32px;
    background: ${theme.color.white};
    border-radius: 16px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid ${theme.color.white};
    color: ${({ colorBranding }): string => colorBranding};
    font-size: 15px;
    text-decoration: underline;
`;

const MobileSelection: React.FC<{ count: number }> = ({ count }) => {
    const intl = useIntl();
    const { showSelectedProducts } = useSelectedProducts();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    return (
        <MobileButtonsWrapper
            visible={count > 0}
            onClick={(): void => showSelectedProducts()}
            className={'mobile-selection'}
        >
            <MobileSelectedButton colorBranding={colorBranding}>
                {intl.formatMessage(
                    {
                        defaultMessage: '{count, plural, =0 {# item} one {# item} other {# items} } selected',
                        id: 'gallery.Gallery.b2d913',
                    },
                    { count: count },
                )}
            </MobileSelectedButton>
        </MobileButtonsWrapper>
    );
};

let prevSelectedCount = 0;

const Gallery: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();

    const isMobileLayout = useMedia(['(max-width: 768px)'], [true], false);

    //__ display mode
    const currentDisplay = useSelector(searchSelectors.currentDisplay);
    const isTableMode = currentDisplay === 'list';
    const variationMode = useSelector(searchSelectors.currentVariationMode);

    //_____ Selection
    const [displayNotificationCount, setDisplayNotificationCount] = useState(0);
    const selectedCount = useSelector(selectionSelectors.totalCount);
    const isSelectionEmpty = !(selectedCount > 0);

    useEffect(() => {
        if (displayNotificationCount < 5 && selectedCount > MAX_EXPORT_PDF && prevSelectedCount <= MAX_EXPORT_PDF) {
            dispatch({
                type: 'NOTIFICATION_ADD',
                payload: {
                    title: intl.formatMessage(
                        {
                            defaultMessage: 'PDF exports are limited to {max} products at once.',
                            id: 'gallery.Gallery.595510',
                        },
                        { max: MAX_EXPORT_PDF },
                    ),
                    description: intl.formatMessage({
                        defaultMessage:
                            'To export more products, prepare several export product selections or choose XLSX.',
                        id: 'gallery.Gallery.7d0d1d',
                    }),
                    level: 'info',
                },
            });
            setDisplayNotificationCount(displayNotificationCount + 1);
        }
        prevSelectedCount = selectedCount;
    }, [selectedCount, dispatch, displayNotificationCount, setDisplayNotificationCount, intl]);
    //_____ /Selection

    //_____ Search criterias
    const search = useSelector(searchSelectors.currentSearch);
    const searchContexts = useSelector(selectionSelectors.searchContexts);
    //_____ /Search criterias

    //_____ Gallery setup
    const columnCount = useMedia<number>(
        [
            '(max-width: 600px)',
            '(max-width: 776px)',
            '(max-width: 1000px)',
            '(max-width: 1200px)',
            '(max-width: 1600px)',
        ],
        [2, 3, 2, 3, 4],
        6,
    );

    const columns: string[] = useSelector(({ search }: { search: SearchState }) =>
        isTableMode ? search.selectedColumns : [],
    );

    const unmount = useRef(false);
    useEffect(() => {
        unmount.current = false;
        return () => {
            unmount.current = true;
        };
    }, []);

    // __ Get products.
    const { products, status, fetchNextPage, isFetching } = useInfiniteProducts({
        type: currentDisplay,
        columns: columns,
        search: search,
        searchContexts: searchContexts,
        variationMode: variationMode,
    });

    const handleSelectItem = useCallback(
        (isSelected: boolean, product: Product) => {
            dispatch(selectionActions.selectProduct(product, isSelected));
        },
        [dispatch],
    );

    const emptyResult = useMemo(() => {
        return (
            <EmptyResult
                title={intl.formatMessage({
                    defaultMessage: 'Sorry, there are no matching products.',
                    id: 'gallery.Gallery.4d7c9d',
                })}
                subtitle={intl.formatMessage({
                    defaultMessage: 'Try again with new search criteria or filters.',
                    id: 'gallery.Gallery.0d62d4',
                })}
            />
        );
    }, [intl]);

    const productLink = useCallback(
        (product: Product) => `/product/${encodeString(product.pimIdentifier)}${location.search}${location.hash}`,
        [location.search, location.hash],
    );

    const selectedMode = useSelector(searchSelectors.selectedMode);
    const isProductSelected = useCallback(
        (product: Product) => {
            switch (selectedMode) {
                case 'all':
                    return true;
                case 'none':
                    return false;
                default: {
                    if (product.isStacked && product.listOfVariantIds && product.listOfVariantIds.length > 0) {
                        const productSelectedCount = product.listOfVariantIds.filter(
                            (variant) => variant.isSelected,
                        ).length;
                        const productsUnselectedCount = product.listOfVariantIds.filter(
                            (variant) => !variant.isSelected,
                        ).length;

                        if (productSelectedCount === product.listOfVariantIds.length) return true;
                        if (productsUnselectedCount === product.listOfVariantIds.length) return false;
                        return 'mixed';
                    }
                    return !!product.isSelected;
                }
            }
        },
        [selectedMode],
    );

    // ___ Modal
    const [productInModal, setProductInModal] = useState<Product | undefined>(undefined);
    const [displayModal, setDisplayModal] = useState(false);
    const handleModal = useCallback((product: Product) => {
        setProductInModal(product);
        setDisplayModal(true);
    }, []);

    const handleClickRow = useCallback(
        (row: Product) => {
            if (row.variationNumber && row.variationNumber > 1) {
                handleModal(row);
            } else {
                history.push(productLink(row));
            }
        },
        [handleModal, history, productLink],
    );

    const renderItem = useCallback(
        (props: RenderItemProps<Product>) => {
            const { item } = props;

            return (
                <GalleryItem
                    {...props}
                    isSelected={isProductSelected(item)}
                    onSelect={handleSelectItem}
                    link={productLink(item)}
                    onClick={handleClickRow}
                />
            );
        },
        [isProductSelected, handleSelectItem, productLink, handleClickRow],
    );

    const rowHeight = useCallback((colWidth: number) => colWidth + 60, []);

    const numberOfProductsDisplayed = useSelector(searchSelectors.searchDisplayedProductCount);

    //_____ /Gallery setup

    const { showSelectedProducts } = useSelectedProducts();
    const handleShowSelectedProducts = useCallback(() => {
        showSelectedProducts();
    }, [showSelectedProducts]);

    const handleShowExport = useCallback(() => {
        dispatch(productExportActions.display(true));
    }, [dispatch]);

    //__ Table mode

    const isSelectedItem = useCallback((product: Product) => {
        if (product.isStacked && product.listOfVariantIds && product.listOfVariantIds.length > 0) {
            const productSelectedCount = product.listOfVariantIds.filter((variant) => variant.isSelected).length;
            const productsUnselectedCount = product.listOfVariantIds.filter((variant) => !variant.isSelected).length;

            if (productSelectedCount === product.listOfVariantIds.length) return true;
            if (productsUnselectedCount === product.listOfVariantIds.length) return false;
            return 'mixed';
        }
        return !!product.isSelected;
    }, []);

    return (
        <Root
            className={`gallery ${isTableMode ? 'table-mode' : ''}`}
            paddingRight={40}
        >
            <div className='head rgt-pad'>
                <Search />
                {isMobileLayout && <MobileFilters />}
                <SelectionToolbar className='selection-toolbar'>
                    <SelectionMenu />
                    <Button
                        className='bt-show-selection'
                        level='tertiary'
                        ghost
                        onClick={handleShowSelectedProducts}
                        disabled={isSelectionEmpty}
                    >
                        {intl.formatMessage({ defaultMessage: 'Selection', id: 'gallery.Gallery.c4b8a0' })} (
                        {selectedCount})
                    </Button>
                    <Button
                        className='bt-export'
                        level='primary'
                        onClick={handleShowExport}
                        disabled={isSelectionEmpty}
                    >
                        {intl.formatMessage({ defaultMessage: 'Export', id: 'gallery.Gallery.495c09' })}
                    </Button>
                </SelectionToolbar>
            </div>
            <div className='content'>
                {isTableMode ? (
                    <GalleryTableView
                        itemCount={numberOfProductsDisplayed}
                        getItem={(index: number): Product | null => (products ? products[index] : null)}
                        columnCount={1}
                        rowHeight={75}
                        loadMoreItems={fetchNextPage}
                        paddingRight={40}
                        onSelectRow={handleSelectItem}
                        isSelectedRow={isSelectedItem}
                        onClickRow={handleClickRow}
                        loading={isFetching}
                        complete={status !== 'pending'}
                        emptyResult={emptyResult}
                        minimumBatchSize={50}
                    />
                ) : (
                    <VirtualScroll
                        itemCount={numberOfProductsDisplayed}
                        getItem={(index: number): Product | null => (products ? products[index] : null)}
                        renderItem={renderItem}
                        columnCount={columnCount}
                        rowHeight={rowHeight}
                        loadMoreItems={fetchNextPage}
                        paddingRight={40}
                        loading={isFetching}
                        complete={status !== 'pending'}
                        emptyResult={emptyResult}
                        minimumBatchSize={50}
                    />
                )}
            </div>
            {isMobileLayout && <MobileSelection count={selectedCount} />}
            <VariantModal
                product={productInModal}
                display={displayModal}
                handleDisplayModal={setDisplayModal}
            />
        </Root>
    );
};

export default Gallery;
