import { ThunkResult } from 'src/tools/globalStore';
import { apiFetch } from '../../../utils/apiFetch';
import { ApiErrors } from '../models/ApiErrors';
import { Account } from '../models/Account';
import { AccountToCreate } from '../models/AccountToCreate';

export const login =
    (loginRequest: LoginRequest): ThunkResult<Promise<Account>> =>
    async (dispatch): Promise<Account> => {
        const loginResponse: Account = await apiFetch<Account>(`login`, {
            method: 'POST',
            body: JSON.stringify(loginRequest),
        });
        dispatch(loginAction(loginRequest.username));
        return loginResponse;
    };

export const createAnAccount =
    (accountToCreate: AccountToCreate): ThunkResult<Promise<Account>> =>
    async (dispatch): Promise<Account> => {
        const accountResponse: Account = await apiFetch<Account, ApiErrors>(`accounts`, {
            method: 'POST',
            body: JSON.stringify(accountToCreate),
        });
        dispatch(logoutAction());
        return accountResponse;
    };

export const changeAccountPassword =
    (account: ChangeAccountPassword): ThunkResult<Promise<Account>> =>
    async (): Promise<Account> => {
        return await apiFetch<Account, ApiErrors | string>(`accounts/reset`, {
            method: 'POST',
            body: JSON.stringify(account),
        });
    };

export const logout =
    (): ThunkResult<Promise<void>> =>
    async (dispatch): Promise<void> => {
        try {
            await apiFetch(`logout`, {
                method: 'POST',
            });
            dispatch(logoutAction());
        } catch (e) {
            console.error('ERROR LOGOUT', e);
        }
    };

export const loginAction = (email: string): LoginAction => ({ email, type: 'LOGIN' });

export const logoutAction = (): LogoutAction => ({ type: 'LOGOUT' });

export default {
    login,
    logout,
    createAnAccount,
    changeAccountPassword,
};
