import find from 'lodash/find';
import httpClient from 'src/tools/HttpClient';
import actions from 'src/shared/locale/store/ContextLocale/actions';
import { ThunkResult } from 'src/tools/globalStore';

const defaultLocale = (locales: CatalogLocales, locale?: string | null): string | null => {
    if (!locales.length) {
        return null;
    }

    const browserLocale: string = locale || navigator.language.split('-').join('_');

    let catalogLocale = find(locales, { code: browserLocale });
    if (!catalogLocale) {
        const browserLanguage = browserLocale.split('_')[0];
        catalogLocale = find(locales, (locale: CatalogLocale) => browserLanguage === locale.code.split('_')[0]);
    }

    if (!catalogLocale) {
        catalogLocale = locales[0];
    }

    return catalogLocale.code;
};

const addLocalesAction = (locales: CatalogLocales): AddLocalesAction => {
    return {
        locales,
        type: 'ADD_LOCALES',
    };
};

const fetchCatalogLocales =
    (): ThunkResult<Promise<void>> =>
    async (dispatch, getState): Promise<void> => {
        try {
            const { data }: { data: CatalogLocales } = await httpClient.get('/locales');
            dispatch(addLocalesAction(data));

            const state = getState();

            const locale = defaultLocale(data, state.contextLocale);
            if (null !== locale && locale !== state.contextLocale) {
                dispatch(actions.selectContextLocalesAction(locale));
            }
        } catch (e) {
            dispatch(addLocalesAction([]));
        }
    };

export default { fetchCatalogLocales, addLocalesAction };
