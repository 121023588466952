import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { TagInput } from 'akeneo-design-system';

type Props = InputProps<string[] | string>;

const Root = styled.div``;

const InputTag: React.FC<Props> = (props) => {
    const { onChange, isFocused = false } = props;

    const [value, setValue] = useState<string[]>(
        typeof props.value === 'string'
            ? props.value.trim().length === 0
                ? []
                : [props.value.trim()]
            : props.value || [],
    );

    const handleChange = useCallback(
        (value: string[]) => {
            setValue(value);
            onChange && onChange(value);
        },
        [onChange],
    );

    return (
        <Root data-testid='input-tag'>
            <TagInput
                value={value}
                onChange={handleChange}
                autoFocus={isFocused}
            />
        </Root>
    );
};

export default InputTag;
