/* eslint-disable no-console */
import React from 'react';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { Image } from 'src/shared';
import PreviewActions from 'src/shared/preview/components/PreviewActions';
import { selectors as attributeDisplaySelectors } from 'src/components/ProductGrid/store/productGrid';

type Props = {
    attribute: ReferenceEntityAttribute;
    selectedIndex: number;
};

const Root = styled.div`
    height: calc(100vh - 300px);
    width: 800px;
    margin: auto;
    overflow: auto;

    .ref-entity {
        display: grid;
        grid-template-columns: 200px 1fr;

        &:first-child {
            border-top: solid 1px ${theme.color.grey20};
        }

        .ref-entity-title {
            background-color: ${theme.color.grey20};
            border-bottom: solid 1px white;

            h3 {
                color: ${theme.color.grey140};
                font-size: 15px;
                padding: 14px 20px;
                margin: 0;
                font-weight: normal;
            }
        }

        .ref-entity-value {
            display: flex;
            align-items: center;
            border-bottom: solid 1px ${theme.color.grey20};
            font-size: 15px;
            color: ${theme.color.grey140};
            padding: 14px 20px;

            * {
                font-size: 15px;
            }

            .ref-entity-image {
                img {
                    width: 200px;
                    height: 200px;
                    object-fit: contain;
                    border: 1px solid ${theme.color.grey100};
                }
            }
        }

        .sub-reference-entity {
            display: flex;
            flex-direction: column;
            align-items: center;

            .attribute-value-reference-entity:not(:last-child) {
                margin-bottom: 20px;
            }

            .attribute-value-reference-entity {
                display: flex;
                align-items: center;
                width: 400px;

                .box-img {
                    height: 44px;
                    width: 44px;
                    border: solid 1px ${theme.color.grey100};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                }

                span {
                    margin: 0 10px;
                }
            }
        }
    }
`;

const ReferenceEntityAttribute: React.FC<Props> = (props) => {
    const { attribute, selectedIndex } = props;

    const intl = useIntl();

    const attributeDisplayType = useSelector(attributeDisplaySelectors.currentDisplayAttributeTypes);

    const referenceEntity = attribute.value ? attribute.value[selectedIndex] : null;

    if (!referenceEntity) {
        return null;
    }

    return (
        <Root>
            <div className='ref-entity'>
                <div className='ref-entity-title'>
                    <h3>
                        {intl.formatMessage({
                            defaultMessage: 'Label',
                            id: 'attributes.ReferenceEntityAttribute.ef9df2',
                        })}
                    </h3>
                </div>
                <div className='ref-entity-value'>{referenceEntity.label}</div>
            </div>

            {referenceEntity.image && (
                <div className='ref-entity'>
                    <div className='ref-entity-title'>
                        <h3>
                            {intl.formatMessage({
                                defaultMessage: 'Image',
                                id: 'attributes.ReferenceEntityAttribute.fb4cef',
                            })}
                        </h3>
                    </div>
                    <div className='ref-entity-value'>
                        <div className='ref-entity-image'>
                            <Image
                                src={referenceEntity.image.thumbnail}
                                title={referenceEntity.image.name}
                            />
                            <PreviewActions
                                media={{
                                    filename: referenceEntity.image.name,
                                    format: referenceEntity.image.format,
                                    previewFormat: referenceEntity.image.previewFormat,
                                    downloadUrl: referenceEntity.image.download,
                                    previewUrl: referenceEntity.image.preview,
                                    thumbnailUrl: referenceEntity.image.thumbnail,
                                    sourceUrl: referenceEntity.image.source,
                                    id: referenceEntity.image.id,
                                    type: attribute.type,
                                }}
                                size='small'
                            />
                        </div>
                    </div>
                </div>
            )}

            {map(referenceEntity.values, ({ label, value: attributeValue }, index) => {
                let displayedValue: string | JSX.Element = '-';

                if (typeof attributeValue === 'string' && attributeValue !== '') {
                    // Text.
                    displayedValue = <div dangerouslySetInnerHTML={{ __html: attributeValue }} />;
                } else if (!attributeValue) {
                    // NULL or undefined.
                    displayedValue = '-';
                } else if (Array.isArray(attributeValue)) {
                    // Select.
                    displayedValue = attributeValue.join(', ');
                } else if (typeof attributeValue === 'object') {
                    // Photo.
                    if ('name' in attributeValue) {
                        const media = attributeValue as Media;
                        displayedValue = (
                            <div className='ref-entity-image'>
                                <Image
                                    src={media.thumbnail}
                                    title={media.name}
                                />
                                <PreviewActions
                                    media={{
                                        filename: media.name,
                                        format: media.format,
                                        previewFormat: media.previewFormat,
                                        downloadUrl: media.download,
                                        previewUrl: media.preview,
                                        thumbnailUrl: media.thumbnail,
                                        sourceUrl: media.source,
                                        id: media.id,
                                        type: attribute.type,
                                    }}
                                    size='small'
                                />
                            </div>
                        );
                    } else {
                        // Reference entity.
                        displayedValue = (
                            <div className='sub-reference-entity'>
                                {map(attributeValue, (subReferenceEntity, index) => {
                                    if (typeof subReferenceEntity === 'object') {
                                        return (
                                            <div
                                                key={index}
                                                className='attribute-value-reference-entity'
                                            >
                                                <div className='box-img'>
                                                    <Image
                                                        src={subReferenceEntity.image?.thumbnail}
                                                        title={subReferenceEntity.image?.name ?? ''}
                                                    />
                                                </div>
                                                <span>{subReferenceEntity.label}</span>
                                            </div>
                                        );
                                    }
                                    return <></>;
                                })}
                            </div>
                        );
                    }
                }

                if (attributeDisplayType === 'all' || (attributeDisplayType === 'active' && displayedValue !== '-')) {
                    return (
                        <div
                            className='ref-entity'
                            key={`${index}-${label}`}
                        >
                            <div className='ref-entity-title'>
                                <h3>{label}</h3>
                            </div>

                            <div className='ref-entity-value'>{displayedValue}</div>
                        </div>
                    );
                }
            })}
        </Root>
    );
};

export default ReferenceEntityAttribute;
