import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useCallback, useMemo } from 'react';
import { Checkbox, sharedCatalogsTheme as theme } from 'akeneo-design-system';

import { useAppDispatch } from 'src/tools/globalStore';
import { selectors as searchSelectors } from 'src/components/ProductGrid/store/productGrid';
import { actions as selectionActions } from 'src/components/ProductGrid/store/productSelection';
import { useQueryClient } from '@tanstack/react-query';

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    label {
        cursor: pointer;
        color: ${theme.color.grey120};
    }
`;

const SelectionMenu: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const selectedCount = useSelector(searchSelectors.searchSelectedCount) || 0;
    const totalCount = useSelector(searchSelectors.searchResultCount) || 0;
    const productModelCount = useSelector(searchSelectors.searchProductModelCount) || 0;
    const selectedMode = useSelector(searchSelectors.selectedMode);
    const selectedVariation = useSelector(searchSelectors.currentVariationMode);
    const queryClient = useQueryClient();

    const handleChange = useCallback(
        (checked: boolean) => {
            dispatch(selectionActions.selectAllProducts(checked));
            void queryClient.invalidateQueries({ queryKey: ['products'], type: 'active' });
        },
        [dispatch],
    );

    const checked = useMemo(() => {
        switch (selectedMode) {
            case 'none':
                return false;
            case 'all':
                return true;
            default:
                return 'mixed';
        }
    }, [selectedMode]);

    return (
        <Root className='select-all'>
            <Checkbox
                id='selection'
                checked={checked}
                onChange={handleChange}
            >
                {intl.formatMessage(
                    {
                        defaultMessage:
                            '{selected, plural, =0 {{total} {total, plural, =0 {product} one {product} other {products} }} other {{selected} {selected, plural, one {selected product} other {selected products} } out of {total} }}',
                        id: 'productSelection.SelectionMenu.c7ea66',
                    },
                    { selected: selectedCount, total: totalCount },
                )}
                {selectedVariation === 'grouped' &&
                    intl.formatMessage(
                        {
                            defaultMessage:
                                ' ({productModel} {productModel, plural, =0 {product model} one {product model} other {product models} })',
                            id: 'productSelection.SelectionMenu.d4f093',
                        },
                        { productModel: productModelCount },
                    )}
            </Checkbox>
        </Root>
    );
};

export default SelectionMenu;
