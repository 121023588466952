import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useBooleanState, SwitcherButton, Dropdown } from 'akeneo-design-system';
import React, { useMemo, useCallback, useContext } from 'react';

import TenantCatalogContext from 'src/shared/tenantcatalog/TenantCatalogContext';
import { useCatalogQuery } from './hook/useCatalogsQuery';
import { Catalog } from './models/Catalog';
import { useIsUsingSso } from '../../components/Authentication/hooks/useIsUsingSso';
import { actions } from '../../components/Authentication/store';
import { useAppDispatch } from '../../tools/globalStore';

const Root = styled.div`
    margin-bottom: 30px;
`;

const CatalogSwitcher: React.FC = () => {
    const intl = useIntl();
    const { tenantId, catalogCode } = useContext(TenantCatalogContext);
    const { data: catalogs, isLoading } = useCatalogQuery(tenantId, catalogCode);
    const { data: isUsingSso } = useIsUsingSso();
    const dispatch = useAppDispatch();
    const [isOpen, open, close] = useBooleanState(false);

    const currentCatalog = useMemo(
        () => catalogs?.find((catalog) => catalog.code === catalogCode && catalog.tenant === tenantId) || null,
        [catalogs, catalogCode, tenantId],
    );

    const handleChangeCatalog = useCallback(
        (catalog: Catalog) => {
            if (true === isUsingSso?.isUsingSso) {
                void dispatch(actions.logout()).then(() =>
                    window.location.assign(`/${catalog.tenant}/${catalog.code}/login`),
                );
            } else {
                window.location.assign(`/${catalog.tenant}/${catalog.code}/`);
            }
        },
        [dispatch, isUsingSso],
    );

    return (
        <Root data-testid='catalog-switcher'>
            <Dropdown className='dropdown'>
                <SwitcherButton
                    onClick={open}
                    label={intl.formatMessage({
                        defaultMessage: 'Selected catalog',
                        id: 'tenantcatalog.CatalogSwitcher.c94c35',
                    })}
                    inline={false}
                >
                    {currentCatalog?.label}
                </SwitcherButton>
                {isOpen && !isLoading && (
                    <Dropdown.Overlay
                        verticalPosition='down'
                        onClose={close}
                    >
                        <Dropdown.Header>
                            <Dropdown.Title>
                                {intl.formatMessage({
                                    defaultMessage: 'Selected catalog',
                                    id: 'tenantcatalog.CatalogSwitcher.c94c35',
                                })}
                            </Dropdown.Title>
                        </Dropdown.Header>

                        <Dropdown.ItemCollection>
                            {catalogs?.map((catalog) => (
                                <Dropdown.Item
                                    onClick={(): void => handleChangeCatalog(catalog)}
                                    isActive={catalog.code === currentCatalog?.code}
                                    className={catalog.code === currentCatalog?.code ? 'active' : ''}
                                    key={catalog.code}
                                >
                                    {catalog.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.ItemCollection>
                    </Dropdown.Overlay>
                )}
            </Dropdown>
        </Root>
    );
};

export default CatalogSwitcher;
