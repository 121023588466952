import get from 'lodash/get';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import isPlainObject from 'lodash/isPlainObject';
import styled from 'styled-components';
import { Badge, FiltersIcon, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';

import { useAppDispatch } from 'src/tools/globalStore';
import { serializeSearch } from 'src/components/ProductGrid/store/productSelection';
import { selectors as searchSelectors } from 'src/components/ProductGrid/store/productGrid';
import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import { actions as filterActions, selectors as filterSelectors } from 'src/components/Filter/store';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import { Image } from 'src/shared';
import { Dropdown } from 'src/shared';
import FiltersSelector from 'src/components/Filter/components/filtersSelector';
import { VirtualTable, VirtualTableProps } from 'src/shared/virtualscroll';
import StackedImage from 'src/shared/image/StackedImage';

const excludeKeys = {};
const TABLE_HEAD_HEIGHT = 44;
const thinScrollbars = true;

const Root = styled.div<{
    productIdentifier?: string;
    paddingRight?: number;
    tableHeadHeight: number;
    columnType: 'associations' | 'variations' | 'gallery';
    colorBranding: string;
}>`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: ${((props): number | undefined => props.paddingRight) || 0}px;

    .bt-attributes {
        height: ${TABLE_HEAD_HEIGHT - 2}px;
        width: 24px;
        background-color: ${theme.color.white};
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: -30px;
            right: 100%;
            background: linear-gradient(to right, #ffffff00, #fff);
        }
    }

    .dropdown-attributes {
        position: absolute;
        z-index: 20;
        top: 0;
        right: ${thinScrollbars ? 16 : 28}px;
    }

    table {
        tr {
            td,
            th {
                width: 240px;
            }
        }

        td.col-type-identifier {
            color: ${({ colorBranding }): string => colorBranding};
            font-style: italic;
        }

        th:first-child,
        td:first-child {
            position: sticky;
            left: 0;
            background-color: white !important;
            z-index: 5;
        }

        th:nth-child(2),
        td:nth-child(2) {
            position: ${({ columnType }): string => (columnType === 'gallery' ? 'sticky' : 'initial')};
            left: 32px;
            background-color: white !important;
            z-index: 4;
        }
    }

    .variationNumber-badge {
        color: ${({ colorBranding }): string => colorBranding};
        border: 1px solid ${({ colorBranding }): string => colorBranding};
    }
`;

const DropdownBody = styled.div`
    top: 20%;
    padding: 0 20px;
    height: 650px;
    width: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .filters-selected {
        h3 {
            font-size: 11px;
            color: ${theme.color.grey100};
            border: none;
        }
    }
`;

type Props<ItemType = DefaultItem> = Override<
    VirtualTableProps<ItemType>,
    {
        identifier?: string;
        renderCellContent?: (rowItem: ItemType, column: TableColumn, rowIndex: number) => React.ReactNode | null;
        rowHeight?: number;
        columns?: void;
        columnType?: 'gallery' | 'associations' | 'variations';
        identifiersAndParentCodes?: IdentifiersAndParentCodes;
        associations?: Associations;
    }
>;

const GalleryTableView = <ItemType extends Product = Product>(props: Props<ItemType>): React.ReactElement => {
    const {
        identifier,
        paddingRight,
        rowHeight = 75,
        itemCount,
        complete,
        loading,
        associations = { products: [], product_models: [] },
        columnType = 'gallery',
        identifiersAndParentCodes,
        ...rest
    } = props;

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const locale = useSelector(contextLocaleSelectors.getContextLocale);
    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    // __ Display
    const displayMode = useSelector(searchSelectors.currentDisplay);
    const variationMode = useSelector(searchSelectors.currentVariationMode);

    const unmount = useRef(false);
    useEffect(() => {
        return (): void => {
            unmount.current = true;
        };
    }, []);

    const renderTableCell = useCallback(
        (rowItem: ItemType, column: TableColumn) => {
            if (!locale) {
                return null;
            }

            switch (column.key) {
                case 'isCurrentProduct':
                    if (identifier === rowItem.pimIdentifier) {
                        return (
                            <Badge>
                                {intl.formatMessage({
                                    defaultMessage: 'This product',
                                    id: 'gallery.GalleryTableView.f422a4',
                                })}
                            </Badge>
                        );
                    }
                    return '';
                case 'variationAxes':
                    return <span dangerouslySetInnerHTML={{ __html: get(rowItem, `attributes.variations_axes`) }} />;
                case 'variationNumber':
                    return get(rowItem, `variationNumber`) ? (
                        <Badge className='branding-badge'>
                            <span>{get(rowItem, `variationNumber`)}</span>
                        </Badge>
                    ) : (
                        ''
                    );
                case 'families':
                    return <span dangerouslySetInnerHTML={{ __html: get(rowItem, `family`) }} />;
                case 'associationType': {
                    const productsAssociationType = associations.products
                        .filter((association) => association.identifier === rowItem.pimIdentifier)
                        .map((association) => association.associationTypeLabel ?? '-');

                    const productModelsAssociationType = associations.product_models
                        .filter((association) => rowItem.parents.includes(association.identifier))
                        .map((association) => association.associationTypeLabel ?? '-');

                    return (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: productsAssociationType.concat(productModelsAssociationType).join(', '),
                            }}
                        />
                    );
                }
                case 'associationQuantity': {
                    const productsAssociationQuantity = associations.products
                        .filter((association) => association.identifier === rowItem.pimIdentifier)
                        .map((association) => association.associationQuantity ?? '-');

                    const productModelsAssociationQuantity = associations.product_models
                        .filter((association) => rowItem.parents.includes(association.identifier))
                        .map((association) => association.associationQuantity ?? '-');

                    return (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: productsAssociationQuantity.concat(productModelsAssociationQuantity).join(', '),
                            }}
                        />
                    );
                }
                default:
                    break;
            }

            switch (column.type) {
                case 'image': {
                    const variationNumber = get(rowItem, `variationNumber`) ?? 0;
                    return variationNumber > 1 ? (
                        <StackedImage
                            src={rowItem[column.key] as string}
                            title={rowItem.label}
                            variationNumber={get(rowItem, `variationNumber`)}
                        />
                    ) : (
                        <Image
                            src={rowItem[column.key] as string}
                            title={rowItem.label}
                        />
                    );
                }
                default:
                    break;
            }

            const res: any = get(rowItem, column.key);
            if (isPlainObject(res)) {
                //__ translated values
                return <span dangerouslySetInnerHTML={{ __html: get(res, locale) as string }} />;
            }
            if (res instanceof Array) {
                return <span dangerouslySetInnerHTML={{ __html: res.join(' / ') }} />;
            }
            return <span dangerouslySetInnerHTML={{ __html: res as string }} />;
        },
        [associations, locale],
    );

    //____
    const selectedColumns: string[] = useSelector(({ search }: { search: SearchState }) =>
        columnType === 'associations'
            ? search.selectedAssociationsColumns
            : columnType === 'variations'
              ? search.selectedVariationsColumns
              : search.selectedColumns,
    );
    const uniqueIdentifierAttribute: FilterType | null = useSelector(filterSelectors.getUniqueIdentifierAttribute);

    const handleChangeOfState = useCallback(() => {
        if (!uniqueIdentifierAttribute) {
            return;
        }

        const filteredSelectedColumns = selectedColumns.filter((column) => column !== 'isCurrentProduct');
        if (displayMode === 'list' && variationMode === 'grouped') {
            dispatch({
                type: 'SET_GALLERY_COLUMNS',
                payload: [...filteredSelectedColumns, 'variationNumber'],
            });
        } else {
            dispatch({
                type: 'SET_GALLERY_COLUMNS',
                payload: [...filteredSelectedColumns],
            });
        }
    }, [dispatch, displayMode, uniqueIdentifierAttribute, variationMode]);
    useEffect(() => {
        handleChangeOfState();
    }, [handleChangeOfState]);

    const handleChange = useCallback(
        (attributes: FilterType[]) => {
            switch (columnType) {
                case 'associations':
                    dispatch({
                        type: 'SET_ASSOCIATIONS_COLUMNS',
                        payload: map(attributes, (attr) => attr.code || attr.key),
                    });
                    break;

                case 'variations':
                    dispatch({
                        type: 'SET_VARIATIONS_COLUMNS',
                        payload: map(attributes, (attr) => attr.code || attr.key),
                    });
                    break;

                case 'gallery':
                default:
                    dispatch({
                        type: 'SET_GALLERY_COLUMNS',
                        payload: map(attributes, (attr) => attr.code || attr.key),
                    });
                    break;
            }
        },
        [dispatch, columnType],
    );

    const searchValue = '';
    const [selectedAttributes, setSelectedAttributes] = useState<FilterType[]>([]);
    const currentSearch = useSelector(searchSelectors.currentSearch);
    const searchContexts: SearchContext[] = useMemo(() => {
        return [
            {
                search: serializeSearch(currentSearch),
                mode: 'all',
                selectedProducts: [],
                unSelectedProducts: [],
            },
        ];
    }, [currentSearch]);

    useEffect(() => {
        if (!uniqueIdentifierAttribute) {
            return;
        }
        if (!selectedColumns.length) {
            switch (columnType) {
                case 'associations':
                    dispatch({
                        type: 'SET_ASSOCIATIONS_COLUMNS',
                        payload: [
                            'associationType',
                            'image',
                            uniqueIdentifierAttribute.code,
                            'label',
                            'associationQuantity',
                        ],
                    });
                    break;
                case 'variations':
                    dispatch({
                        type: 'SET_VARIATIONS_COLUMNS',
                        payload: [
                            'isCurrentProduct',
                            'image',
                            uniqueIdentifierAttribute.code,
                            'label',
                            'variationAxes',
                        ],
                    });
                    break;
                case 'gallery':
                default:
                    if (variationMode === 'grouped' && displayMode === 'list') {
                        dispatch({
                            type: 'SET_GALLERY_COLUMNS',
                            payload: ['image', uniqueIdentifierAttribute.code, 'label', 'variationNumber'],
                        });
                    } else {
                        dispatch({
                            type: 'SET_GALLERY_COLUMNS',
                            payload: ['image', uniqueIdentifierAttribute.code, 'label'],
                        });
                    }
                    break;
            }
        }
    }, [dispatch, columnType, selectedColumns, uniqueIdentifierAttribute, variationMode, displayMode]);

    useEffect(() => {
        void (async (): Promise<void> => {
            if (!locale || !selectedColumns.length) {
                return;
            }

            const attributes = await dispatch(
                filterActions.getSelectedAttributes({
                    locale,
                    attributeCodes: selectedColumns,
                    searchContexts,
                    columnType,
                    variationMode,
                    identifiersAndParentCodes,
                }),
            );

            if (unmount.current) {
                return;
            }

            setSelectedAttributes(attributes);
        })();
    }, [dispatch, locale, selectedColumns, searchContexts, columnType, identifiersAndParentCodes, variationMode]);

    const isEmpty = useMemo(() => complete && itemCount === 0, [complete, itemCount]);

    return (
        <Root
            colorBranding={colorBranding}
            className='gallery-table-view'
            paddingRight={paddingRight}
            tableHeadHeight={TABLE_HEAD_HEIGHT}
            columnType={columnType}
        >
            {!isEmpty && (
                <Dropdown
                    className='dropdown-attributes'
                    title={intl.formatMessage({
                        defaultMessage: 'Columns configuration',
                        id: 'gallery.GalleryTableView.7c55f6',
                    })}
                    horizontalPosition='left'
                    closeOnClickDropdown={false}
                    openTrigger={
                        <div
                            role='button'
                            className='bt-attributes'
                        >
                            <FiltersIcon
                                size={24}
                                color={theme.color.grey100}
                            />
                        </div>
                    }
                >
                    <DropdownBody>
                        <FiltersSelector
                            selectedFilters={selectedAttributes}
                            onChange={handleChange}
                            searchValue={searchValue}
                            selectedTitle={intl.formatMessage({
                                defaultMessage: 'Selected attributes',
                                id: 'gallery.GalleryTableView.9aa64d',
                            })}
                            searchLabel={intl.formatMessage({
                                defaultMessage: 'Search attributes to display',
                                id: 'gallery.GalleryTableView.0b8250',
                            })}
                            excludeKeys={excludeKeys}
                            searchContexts={searchContexts}
                            columnType={columnType}
                            variationMode={variationMode}
                            identifiersAndParentCodes={identifiersAndParentCodes}
                        />
                    </DropdownBody>
                </Dropdown>
            )}
            <VirtualTable<ItemType>
                columnCount={1}
                rowHeight={rowHeight}
                renderCellContent={renderTableCell}
                itemCount={itemCount}
                complete={complete}
                {...rest}
                loading={loading}
                columns={selectedAttributes}
                paddingRight={0}
                tableHeadHeight={TABLE_HEAD_HEIGHT}
                thinScrollbars={thinScrollbars}
            />
        </Root>
    );
};

export default GalleryTableView;
