const getActiveFilters = ({ filters }: { filters: FiltersState }): FilterType[] => filters.activeList;

const getFiltersFacets = ({ filters }: { filters: FiltersState }): Record<string, any> => filters.facets;

const getFilterFacets =
    (filterKey: string) =>
    ({ filters }: { filters: FiltersState }): any =>
        filters.facets[filterKey];

const getSearch = ({ filters }: { filters: FiltersState }): string => filters.search || '';

//__ warn: do not use useSelector with this one ( not needing store, just centralized this helper )
const getFilterLabel = (filter: FilterType, defaultLabel = ''): string => {
    if (!filter) {
        return '';
    }
    return filter.label || defaultLabel;
};

const getUniqueIdentifierAttribute = ({ filters }: { filters: FiltersState }): IdentifierAttribute | null =>
    filters.uniqueIdentifierAttribute ?? null;

export default {
    getActiveFilters,
    getSearch,
    getFilterLabel,
    getFiltersFacets,
    getFilterFacets,
    getUniqueIdentifierAttribute,
};
