/*  hook returning the presence of a string in the location querystring, it can return :
- null if flag not present
- true if flag present with no value ( ex: "?flag" )
- the string value when flag present with a value ( ex: "?flag=someValue" )
 */
const useFeatureFlag = (flagKey: string, defaultValue: string | null = null): string | boolean | null => {
    const qs = new URLSearchParams(window?.location?.search);

    let flag: string | boolean | null = defaultValue;

    const hasFlag = qs.has(flagKey);
    if (!hasFlag) {
        return flag;
    }

    flag = qs.get(flagKey) || hasFlag || flag;

    return flag;
};

export default useFeatureFlag;
