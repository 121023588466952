import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppDispatch } from 'src/tools/globalStore';
import { actions as filterActions } from 'src/components/Filter/store';
import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import Filter from 'src/shared/Filter/Filter';

//____________
type Props = {
    searchPlaceholder?: string;
};

const CategoriesFilter: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const {
        searchPlaceholder = intl.formatMessage({
            defaultMessage: 'Search categories',
            id: 'categoryFilter.CategoriesFilter.83a306',
        }),
    } = props;

    const locale = useSelector(contextLocaleSelectors.getContextLocale);
    const value = useSelector(({ filters }: { filters: FiltersState }) => filters.categories.value);
    const filterProps = useSelector(({ filters }: { filters: FiltersState }) => filters.categories.props);

    const filter: FilterType | null = useMemo(() => {
        if (!locale) {
            return null;
        }
        const getItemLabel = (item: Category): string => item.label || `[${item.code}]`;

        return {
            key: 'categories',
            type: 'tree',
            label: '',
            inputProps: {
                itemKeyProp: 'code',
                getChildren: async (parent: string | null = null, excludes: string[] | null = null) => {
                    return dispatch(filterActions.fetchCategoryChildrenAction(locale, parent));
                },
                getItemLabel,
                searchBar: async function (searchValue = '') {
                    return dispatch(filterActions.searchCategoriesAction(locale, searchValue));
                },
                searchPlaceholder,
            },
            valueToString: async (value: { [key: string]: boolean } = {}) => {
                let res = '--';
                const keys = Object.keys(value);
                if (keys.length === 1) {
                    const category: Category = await dispatch(filterActions.fetchCategoryAction(locale, keys[0]));
                    res = category.label;
                } else {
                    res = intl.formatMessage(
                        {
                            defaultMessage:
                                '{count, plural, =0 {All categories} one {# selected category} other {# selected categories} }',
                            id: 'categoryFilter.CategoriesFilter.525d2a',
                        },
                        { count: keys.length },
                    );
                }
                return res;
            },
        };
    }, [dispatch, searchPlaceholder, locale, intl]);

    if (!filter) {
        return null;
    }

    filter.value = value;
    filter.props = filterProps;

    //___ render
    return (
        <Filter
            filter={filter}
            displayMode='drawer'
            valueDisplayMode='normal'
        />
    );
};

export default CategoriesFilter;
