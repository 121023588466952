import React from 'react';
import styled from 'styled-components';
import { LoaderIcon, sharedCatalogsTheme as theme } from 'akeneo-design-system';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform-origin: 48% 50%;
    > svg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
`;

const Loader: React.FC = () => {
    return (
        <Root className='loader'>
            <LoaderIcon
                size={56}
                color={theme.color.grey100}
            />
        </Root>
    );
};

export default Loader;
