import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';
import { DateInput } from 'akeneo-design-system';

//_______
export type Props = Override<
    InputProps<string>,
    {
        onChange?: (value: Date) => void;
        readOnly?: boolean;
    }
>;

const Root = styled.div``;

const InputDate: React.FC<Props> = (props) => {
    const { value: propsValue, onChange = noop, readOnly } = props;

    const [value, setValue] = useState<string | undefined>(propsValue);

    const handleChange = useCallback(
        (value: string) => {
            setValue(value);
            onChange(new Date(value));
        },
        [onChange],
    );

    return (
        <Root className='input-date'>
            <DateInput
                data-testid='input-date'
                readOnly={readOnly}
                onChange={handleChange}
                value={value}
            />
        </Root>
    );
};

export default InputDate;
