import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FormEvent, useState, useCallback, useEffect } from 'react';

import { useAppDispatch } from 'src/tools/globalStore';
import { actions } from 'src/components/Authentication/store';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import InputPassword from 'src/shared/input/InputPassword/InputPassword';
import { useValidateNewPasswordVerificationToken } from '../hooks/useValidateNewPasswordVerificationToken';
import PasswordPolicies from './PasswordPolicies';
import { BadRequestError } from '../../../utils/apiFetch';

const Root = styled.div<{ colorBranding: string }>`
    .gen-msg {
        padding: 5px;
        color: ${theme.color.red120};

        a {
            color: ${({ colorBranding }): string => colorBranding};
        }

        ul {
            padding: 0;
            list-style-type: none;
        }
    }

    .password-form {
        width: 300px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .welcoming-message {
        margin-top: 0;
        margin-bottom: 20px;
        max-width: 300px;
        color: ${theme.color.grey120};
        font-size: 15px;

        @media (max-width: 768px) {
            font-size: 18px;
        }

        .email {
            color: ${({ colorBranding }): string => colorBranding};
            font-weight: bold;
        }
    }
`;

type Params = {
    email: string;
    verificationToken: string;
};

const NewPasswordForm: React.FC = () => {
    const [password, setPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [isPasswordVisible] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const history = useHistory();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const params = useParams<Params>();

    const { email = '', verificationToken } = params;
    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const { data: validateTokenResponse, isLoading } = useValidateNewPasswordVerificationToken(
        email,
        verificationToken,
    );

    useEffect(() => {
        if (!email) {
            history.push(`/`);
        }
    }, [email, history]);

    useEffect(() => {
        if (validateTokenResponse?.isValid === false) {
            history.push(`/reset`, { invalidToken: true });
        }
    }, [validateTokenResponse, history]);

    const onPasswordChange = useCallback((value: string) => {
        setPassword(value);
    }, []);

    const onsecondPasswordChange = useCallback((value: string) => {
        setSecondPassword(value);
    }, []);

    const onSubmit = useCallback(async () => {
        if (!email?.length) {
            return;
        }

        if (password !== secondPassword) {
            setErrorMessages([
                intl.formatMessage({
                    defaultMessage: 'The password does not match',
                    id: 'components.NewPasswordForm.28e90d',
                }),
            ]);
            return;
        }

        setErrorMessages([]);

        try {
            await dispatch(actions.changeAccountPassword({ email, password, secondPassword, verificationToken }));
            history.push(`/login/${email}`);
        } catch (e) {
            if (e instanceof BadRequestError && typeof e.data === 'object' && typeof Array.isArray(e.data.errors)) {
                setErrorMessages(Object.values(e.data.errors as string[]));
            } else {
                setErrorMessages([
                    intl.formatMessage({
                        defaultMessage: 'An error occurred while updating your password',
                        id: 'components.NewPasswordForm.194faa',
                    }),
                ]);
            }
        }
    }, [email, password, secondPassword, intl, dispatch, verificationToken, history]);

    return (
        <>
            {!isLoading && validateTokenResponse?.isValid === true && (
                <Root colorBranding={colorBranding}>
                    <p className='welcoming-message'>
                        {intl.formatMessage(
                            {
                                defaultMessage:
                                    'Hello again {email}! {breakLine} Create a new password to be able to login.',
                                id: 'components.NewPasswordForm.78a163',
                            },
                            {
                                email: <span className='email'>{email}</span>,
                                breakLine: <br />,
                            },
                        )}
                    </p>

                    <form onSubmit={(e: FormEvent<HTMLFormElement>): void => e.preventDefault()}>
                        <InputPassword
                            status={errorMessages?.length ? 'error' : undefined}
                            isPasswordVisible={isPasswordVisible}
                            makePasswordVisible
                            label={intl.formatMessage({
                                defaultMessage: 'Password',
                                id: 'components.NewPasswordForm.e6c83b',
                            })}
                            title={intl.formatMessage({
                                defaultMessage: 'Please fill out this field.',
                                id: 'components.NewPasswordForm.020cf2',
                            })}
                            onChange={onPasswordChange}
                            readOnly={false}
                        />
                        <InputPassword
                            status={errorMessages?.length ? 'error' : undefined}
                            statusMessage={errorMessages?.join('. ')}
                            isPasswordVisible={isPasswordVisible}
                            label={intl.formatMessage({
                                defaultMessage: 'Confirm password',
                                id: 'components.NewPasswordForm.f078a6',
                            })}
                            title={intl.formatMessage({
                                defaultMessage: 'Please fill out this field.',
                                id: 'components.NewPasswordForm.020cf2',
                            })}
                            onChange={onsecondPasswordChange}
                            readOnly={false}
                        />

                        <PasswordPolicies />

                        <Button
                            type='submit'
                            onClick={onSubmit}
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Create my password',
                                id: 'components.NewPasswordForm.06acae',
                            })}
                        </Button>
                    </form>
                </Root>
            )}
        </>
    );
};

export default NewPasswordForm;
