import React, { useState, useEffect } from 'react';
import { IntlProvider as IntlProviderReact } from 'react-intl';

import en_US from './languages/messages.en_US.json';

const defaultLocale = 'en_US';
const defaultMessages = en_US;

type Props = {
    locale?: string;
    children?: React.ReactNode;
};

const IntlProvider: React.FC<Props> = (props) => {
    const { children, locale: propsLocale = defaultLocale } = props;

    const [providerProps, setProviderProps] = useState({
        locale: defaultLocale.replace('_', '-'),
        messages: defaultMessages,
        onError: (err: Error) => {
            if (err && err.message) {
                const lines = err.stack?.split('\n');
                console.warn(err.message, lines ? lines[5] : 'unknown'); //__ this is the real place of the caller
            } else {
                console.error(err);
            }
        },
    });

    useEffect(() => {
        const localeCode = propsLocale.replace('-', '_');

        if (localeCode === defaultLocale) {
            setProviderProps((state) =>
                state.messages === defaultMessages
                    ? state
                    : {
                          ...state,
                          locale: propsLocale.replace('_', '-'),
                          messages: defaultMessages,
                      },
            );
            return;
        }

        void (async (): Promise<void> => {
            try {
                const messages = await import(`./languages/messages.${localeCode}`);
                setProviderProps((state) => ({
                    ...state,
                    locale: propsLocale.replace('_', '-'),
                    messages,
                }));
            } catch (err) {
                process.env.NODE_ENV !== 'test' && console.error(err);
            }
        })();
    }, [propsLocale]);

    return <IntlProviderReact {...providerProps}>{children}</IntlProviderReact>;
};

export default IntlProvider;
