import React, { useCallback, useState, useEffect, useRef, ReactElement } from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';
import filter from 'lodash/filter';

import { Tree } from 'akeneo-design-system';

import SearchBar from 'src/shared/searchbar';
import { useIntl } from 'react-intl';

export type TreeItem = {
    [key: string]: any; //__ dynamic key prop
    parent?: string;
    label?: string;
    children?: TreeItem[];
    parents?: string[];
};

//_____
type TreeProps = Override<
    InputProps<BooleanMap>,
    {
        getChildren: (parentKey: string | null) => TreeItem[] | Promise<TreeItem[]>;
        itemKeyProp?: string;
        openedItems?: { [key: string]: boolean }; //__ key paired object of opened items
        getItemLabel?: (item: TreeItem) => string;
        onOpen?: (openedKeys: BooleanMap) => void;
        disableSelectedDescendants?: boolean;
        searchBar?: boolean | ((search: string) => TreeItem[]);
        searchBarValue?: string;
        searchPlaceholder?: string;
    }
>;

type ItemsByParentKey = {
    [key: string]: TreeItem[] | 'loading';
};

//___________
const Root = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .searchbar {
        flex: none;
    }

    .tree-root {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        overflow-y: auto;
        padding-right: 15px;

        > * {
            margin: 0 0 10px 0;
        }
    }

    [role='tree'] {
        padding-left: 0;
    }

    [role='treeitem'] {
        > div:first-child {
            display: flex;
            align-items: center;
            width: auto;
            overflow: visible;
            > button:first-child {
                flex: none;
            }
            > button:last-child {
                flex: 1 0;
                overflow: visible;
                width: auto;
                max-width: none;
            }
        }
    }
`;

const defaultGetItemLabel = (item: TreeItem): string | undefined => {
    return item.label;
};

const InputTree: React.FC<TreeProps> = (props) => {
    const intl = useIntl();

    const {
        itemKeyProp = 'key',
        onChange = noop,
        getChildren,
        getItemLabel = defaultGetItemLabel,
        onOpen = noop,
        disableSelectedDescendants = true,
        searchBar,
        searchBarValue = '',
        searchPlaceholder = intl.formatMessage({ defaultMessage: 'Search', id: 'InputTree.InputTree.c66715' }),
    } = props;

    //__ open state
    const [openedItems, setOpenedItems] = useState({ ...(props.openedItems || {}) });

    const handleSetOpen = useCallback(
        (itemValue: string, isOpen: boolean) => {
            const newOpenedItems = { ...openedItems };
            if (isOpen) {
                newOpenedItems[itemValue] = true;
            } else {
                delete newOpenedItems[itemValue];
            }
            setOpenedItems(newOpenedItems);
            onOpen(newOpenedItems);
        },
        [openedItems, onOpen],
    );

    const handleOpen = useCallback(
        (itemValue: string) => {
            handleSetOpen(itemValue, true);
        },
        [handleSetOpen],
    );

    const handleClose = useCallback(
        (itemValue: string) => {
            handleSetOpen(itemValue, false);
        },
        [handleSetOpen],
    );

    //__ selection state
    const [selectedByKey, setSelectedKeys] = useState({ ...(props.value || {}) });
    useEffect(() => {
        if (!props.value) {
            return;
        }
        setSelectedKeys({ ...props.value });
    }, [props.value]);

    const childrenByParentKey = useRef<ItemsByParentKey>({});

    const itemsByKey = useRef({} as any);

    const setSelectRecursive = useCallback(
        (key: string, selected: boolean, resultKeys: any = {}, initial = true) => {
            let deleteDescKeys = false;

            if (selected) {
                let anySelectedParent = false;
                //__ if any parent already selected, delete unselect key, else select key
                let item = itemsByKey.current[key];
                while (item && item.parent) {
                    if (resultKeys[item.parent] === false) {
                        break;
                    }
                    if (resultKeys[item.parent]) {
                        anySelectedParent = true;
                        break;
                    }
                    item = itemsByKey.current[item.parent];
                }
                if (anySelectedParent) {
                    delete resultKeys[key];
                } else {
                    resultKeys[key] = selected;
                }
                deleteDescKeys = true;
            } else {
                if (key in resultKeys || !initial) {
                    delete resultKeys[key];
                    deleteDescKeys = true;
                } else {
                    resultKeys[key] = selected;
                }
            }

            if (deleteDescKeys) {
                //__ children propagation : override descendants by deleting key
                const children: TreeItem[] | 'loading' = childrenByParentKey.current[key];
                if (children instanceof Array) {
                    for (let i = 0, max = children.length; i < max; i++) {
                        setSelectRecursive(children[i][itemKeyProp], false, resultKeys, false);
                    }
                }
            }

            return resultKeys;
        },
        [itemKeyProp],
    );

    const handleSelect = useCallback(
        (itemValue: string, checked: boolean) => {
            const keys = setSelectRecursive(itemValue, checked, { ...selectedByKey });
            setSelectedKeys(keys);
            onChange(keys);
        },
        [onChange, selectedByKey, setSelectRecursive],
    );

    //__ search
    const currentSearch = useRef<string | null>(null);
    const isSearching = useRef<boolean>(false);
    const isEmptySearch = useRef<boolean>(!searchBarValue || !searchBarValue.length);
    const searchByKey = useRef<{ [key: string]: TreeItem }>({});

    useEffect(() => {
        currentSearch.current = null;
    }, [searchBar]);

    const filterItems = useCallback(
        (items: TreeItem[], openedItems: { [key: string]: boolean }) => {
            return filter(items, (item) => {
                const key = item[itemKeyProp];
                return !!(searchByKey.current[key] || openedItems[key]);
            });
        },
        [itemKeyProp],
    );

    const handleSearch = useCallback(
        async (searchString: string) => {
            isSearching.current = false;

            if (currentSearch.current === searchString) {
                return;
            }
            currentSearch.current = searchString;
            isEmptySearch.current = !searchString || !searchString.length;

            if (typeof searchBar !== 'function') {
                return;
            }

            isSearching.current = true;

            const res = (await searchBar(searchString)) || [];

            isSearching.current = false;

            childrenByParentKey.current = {};
            if (isEmptySearch.current) {
                setOpenedItems((state) => ({ ...state }));
                return;
            }

            searchByKey.current = {};
            const openedItems: { [key: string]: boolean } = {};
            res.forEach((item: TreeItem) => {
                searchByKey.current[item[itemKeyProp]] = item;
                item.parents &&
                    item.parents.forEach((parent: string) => {
                        openedItems[parent] = true;
                    });
            });
            setOpenedItems(openedItems);
        },
        [searchBar, itemKeyProp],
    );

    useEffect(() => {
        //__ initial search
        if (!searchBarValue || !searchBarValue.length) {
            return;
        }
        handleSearch(searchBarValue);
    }, [handleSearch, searchBarValue]);

    //__ lazy children expand
    const refreshKeys = useRef({} as { [key: string]: number });
    const [refreshCount, setRefreshCount] = useState(0); // eslint-disable-line @typescript-eslint/no-unused-vars
    useEffect(() => {
        childrenByParentKey.current = {};
    }, [getChildren]);

    useEffect(() => {
        if (!getChildren || isSearching.current) {
            return;
        }
        void (async (): Promise<void> => {
            for (const key of ['root', ...Object.keys(openedItems)]) {
                if (isSearching.current) {
                    continue;
                }
                if ((key === 'root' || openedItems[key]) && !childrenByParentKey.current[key]) {
                    let children = getChildren(key === 'root' ? null : key) || [];
                    if (children instanceof Promise) {
                        //__ prevent some re renders if children is not a promise ( direct return )
                        setRefreshCount((state) => ++state);
                        childrenByParentKey.current[key] = 'loading';
                        children = await children;
                    }
                    children = children || [];
                    if (!isEmptySearch.current) {
                        children = filterItems(children, openedItems);
                    }
                    children.forEach((item: TreeItem) => {
                        itemsByKey.current[item[itemKeyProp]] = item;
                    });
                    //__ trick to force grand parents ( & up ) refresh when grand children ( & down ) loaded
                    if (key !== 'root') {
                        let parent = key;
                        while (itemsByKey.current[parent]) {
                            refreshKeys.current[parent] = refreshKeys.current[parent]
                                ? refreshKeys.current[parent] + 1
                                : 1;
                            parent = itemsByKey.current[parent].parent;
                        }
                    }
                    //__ apply children
                    childrenByParentKey.current[key] = children;
                    setRefreshCount((state) => ++state);
                }
            }
        })();
    }, [openedItems, getChildren, itemKeyProp, filterItems, getItemLabel]);

    //__
    const renderChildrenRecursive = (parentKey = 'root', parentSelected = false): ReactElement[] | null => {
        const items = childrenByParentKey.current[parentKey];
        if (!items || items === 'loading') {
            return null;
        }

        return items.map((item: TreeItem) => {
            const key: string = item[itemKeyProp];
            const itemSelected = selectedByKey[key] || (selectedByKey[key] !== false && parentSelected);
            const isLoading = openedItems[key] && childrenByParentKey.current[key] === 'loading';

            return (
                <Tree
                    key={`${key}-${refreshKeys.current[key]}`}
                    value={key}
                    label={getItemLabel(item) || ''}
                    isLoading={isLoading}
                    selectable
                    selected={itemSelected}
                    onChange={handleSelect}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    _isRoot={parentKey === 'root'}
                    readOnly={disableSelectedDescendants && parentSelected}
                >
                    {openedItems[key] ? renderChildrenRecursive(key, itemSelected) : null}
                </Tree>
            );
        });
    };

    return (
        <Root className={'input-tree'}>
            {searchBar && (
                <SearchBar
                    onChange={handleSearch}
                    inputValue={searchBarValue}
                    placeholder={searchPlaceholder}
                    debounceWait={350}
                />
            )}
            <div className='tree-root'>{renderChildrenRecursive()}</div>
        </Root>
    );
};

export default InputTree;
