import { useIntl } from 'react-intl';
import styled from 'styled-components';
import React, { FormEvent, useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Field, TextInput } from 'akeneo-design-system';
import { useSelector } from 'react-redux';

import { actions } from 'src/components/Authentication/store';
import { useAppDispatch } from 'src/tools/globalStore';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import InputPassword from 'src/shared/input/InputPassword/InputPassword';
import { apiPathForTenantAndCatalog } from '../../../utils/apiFetch/apiPathForTenantAndCatalog';

const Root = styled.div<{ colorBranding: string }>`
    .redirect-notification {
        font-size: 15px;
        max-width: 300px;

        @media (max-width: 768px) {
            max-width: 350px;
            font-size: 18px;
        }

        strong {
            color: ${({ colorBranding }): string => colorBranding};
        }
    }

    .input-group {
        padding-bottom: 3em;
        width: 100%;

        > div {
            padding-bottom: 10px;
        }
    }

    .password-reset {
        width: 139px;
        color: ${({ colorBranding }): string => colorBranding};
        font-size: 13px;
        cursor: pointer;

        @media (max-width: 768px) {
            font-size: 15px;
        }
    }

    .login-form {
        min-width: 300px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
`;

type Params = {
    email?: string;
    tenantId: string;
    catalogCode: string;
};

const LoginForm: React.FC = () => {
    const params = useParams<Params>();
    const [email, setEmail] = useState(params.email || '');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const history = useHistory();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const onEmailChange = useCallback((value: string) => {
        setEmail(value);
    }, []);

    const onPasswordChange = useCallback((value: string) => {
        setPassword(value);
    }, []);

    const onSubmit = useCallback(async () => {
        try {
            await dispatch(actions.login({ username: email, password }));
            let url = '/';
            if (new URLSearchParams(location.search).has('origin')) {
                url = location.search.replace('?origin=', '');
            }
            history.push(url);
        } catch (e) {
            setErrorMessage(
                intl.formatMessage({
                    defaultMessage: 'Your e-mail or password seems to be wrong. Please, try again.',
                    id: 'components.LoginForm.cdbaa9',
                }),
            );
        }
    }, [dispatch, history, location.search, email, password, intl]);

    return (
        <Root colorBranding={colorBranding}>
            {params && params.email && (
                <p className='redirect-notification'>
                    {intl.formatMessage(
                        {
                            defaultMessage: 'Your account {email} has been created. You are now able to login.',
                            id: 'components.LoginForm.1f6382',
                        },
                        {
                            email: <strong key={email}>{params && params.email}</strong>,
                        },
                    )}
                </p>
            )}

            <form
                className='login-form'
                onSubmit={(e: FormEvent<HTMLFormElement>): void => e.preventDefault()}
            >
                <div className='input-group'>
                    <Field label={intl.formatMessage({ defaultMessage: 'Email', id: 'components.LoginForm.b32fa9' })}>
                        <TextInput
                            invalid={!!errorMessage?.length}
                            title={intl.formatMessage({
                                defaultMessage: 'Please fill out this field.',
                                id: 'components.LoginForm.020cf2',
                            })}
                            onChange={onEmailChange}
                            value={email}
                            data-testid='input-email'
                        />
                    </Field>

                    <InputPassword
                        className='input-password'
                        status={errorMessage?.length ? 'error' : undefined}
                        statusMessage={errorMessage}
                        makePasswordVisible
                        label={intl.formatMessage({ defaultMessage: 'Password', id: 'components.LoginForm.e6c83b' })}
                        title={intl.formatMessage({
                            defaultMessage: 'Please fill out this field.',
                            id: 'components.LoginForm.020cf2',
                        })}
                        onChange={onPasswordChange}
                    />

                    <a
                        className='password-reset'
                        onClick={(): void => {
                            history.push(`/reset`);
                        }}
                    >
                        {intl.formatMessage({
                            defaultMessage: 'Forgot your password?',
                            id: 'components.LoginForm.732454',
                        })}
                    </a>
                </div>

                <Button
                    type='submit'
                    disabled={email === '' || password === ''}
                    onClick={onSubmit}
                >
                    {intl.formatMessage({ defaultMessage: 'Login', id: 'components.LoginForm.03219a' })}
                </Button>
            </form>
        </Root>
    );
};

export default LoginForm;
