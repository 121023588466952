import { useQuery } from '@tanstack/react-query';
import { apiFetch } from '../../../utils/apiFetch';

type Error = string | null;
export type Result = {
    isLoading: boolean;
    isError: boolean;
    data: number | undefined;
    error: Error;
};

export const useCountVariationsQuery = (grandparent: string | null, locale: string | null): Result => {
    return useQuery<number, Error, number>({
        queryKey: ['countProductVariations', grandparent, locale],
        queryFn: async () => {
            return await apiFetch<number>(`count-product-variations/${locale}`, {
                method: 'POST',
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                body: JSON.stringify({ grandparent: grandparent }),
            });
        },
        enabled: grandparent !== null && locale !== null,
    });
};
