import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import DefaultImage from './media/default_image_product.svg';

type ImageProps = {
    src?: string;
    title: string;
};

const Root = styled.img`
    max-width: 100%;
    max-height: 100%;
    z-index: -1;
`;

declare module 'react' {
    interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
        loading?: 'eager' | 'lazy';
    }
}

const Image: React.FC<ImageProps> = ({ src, title }) => {
    return (
        <Root
            loading='lazy'
            src={src || DefaultImage}
            onError={(e: SyntheticEvent<HTMLImageElement, Event>): void => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImage;
            }}
            alt={title}
        />
    );
};

export default Image;
