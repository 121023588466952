import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { useAppDispatch } from 'src/tools/globalStore';
import { actions, selectors } from 'src/components/Authentication/store';
import { useLocaleUI } from 'src/shared/localeUI/LocaleUIContext';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import { Dropdown } from 'src/shared';
import InputLocale from 'src/shared/input/InputLocale/InputLocale';

import Avatar from 'src/media/Avatar.svg';
import { useIsUsingSso } from '../../Authentication/hooks/useIsUsingSso';

type Props = {
    isOpen?: boolean;
};

const Root = styled.div``;

const Welcome = styled.div<{ colorBranding: string }>`
    cursor: pointer;
    color: ${({ colorBranding }): string => colorBranding};
    font-size: 15px;
    font-family: Lato-Bold, sans-serif;
    display: flex;
    align-items: center;

    font-weight: bold;
    font-style: italic;
    width: 100%;
    white-space: nowrap;

    p {
        padding: 0;
        margin: 0;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 1px;
    }

    .avatar {
        height: 2em;
        padding-left: 1em;
        border-radius: 16px;
        border: none;
    }

    @media (max-width: 768px) {
        p:first-child {
            display: none;
        }
    }
`;

const DropdownBody = styled.div<{ colorBranding: string }>`
    padding: 10px 20px;

    .welcome {
        margin-bottom: 20px;
    }

    .input-locale {
        margin-bottom: 13px;
    }

    .dropdown-item {
        padding-left: 0;
        padding-right: 0;
    }

    .item-logout {
        font-weight: bold;
        font-style: italic;
        color: ${({ colorBranding }): string => colorBranding};
        text-align: right;
    }
`;

const GlobalStyle = createGlobalStyle`
  .overlay.account-menu {
    margin-top: -44px;
    margin-left: -20px;
  }
`;

const AccountMenu: React.FC<Props> = (props) => {
    const { isOpen = false } = props;

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { data: isUsingSso } = useIsUsingSso();

    const emailUser = useSelector(selectors.getUserEmail);
    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const userBlock = useMemo(() => {
        return (
            <Welcome
                colorBranding={colorBranding}
                className='welcome'
            >
                <p>
                    {intl.formatMessage({ defaultMessage: 'Welcome', id: 'components.AccountMenu.3f068d' })} {emailUser}
                </p>
                <img
                    src={Avatar}
                    alt='avatar'
                    className='avatar'
                />
            </Welcome>
        );
    }, [colorBranding, emailUser, intl]);

    const onLogout = useCallback(async () => {
        await dispatch(actions.logout()).then(() => history.push('/login'));
    }, [dispatch]);

    const [open, setOpen] = useState(isOpen);

    const { locales, locale, setLocale } = useLocaleUI();

    const handleChangeLocale = useCallback(
        async (locale: string) => {
            setOpen(false);
            await setLocale(locale);
        },
        [setLocale],
    );

    return (
        <Root>
            <GlobalStyle />
            <Dropdown
                className='dropdown account-menu'
                openTrigger={userBlock}
                verticalPosition='down'
                horizontalPosition='right'
                closeOnClickDropdown={false}
                isOpen={open}
                onOpen={setOpen}
            >
                <DropdownBody colorBranding={colorBranding}>
                    {userBlock}
                    <InputLocale
                        choices={locales}
                        value={locale}
                        onChange={handleChangeLocale}
                        clearable={false}
                        label={intl.formatMessage({
                            defaultMessage: 'Interface language',
                            id: 'components.AccountMenu.b0ced3',
                        })}
                    />
                    {true !== isUsingSso?.isUsingSso && (
                        <Dropdown.ItemCollection>
                            <Dropdown.Item
                                onClick={onLogout}
                                className='item-logout'
                            >
                                {intl.formatMessage({ defaultMessage: 'Logout', id: 'components.AccountMenu.0bcd7f' })}
                            </Dropdown.Item>
                        </Dropdown.ItemCollection>
                    )}
                </DropdownBody>
            </Dropdown>
        </Root>
    );
};

export default AccountMenu;
