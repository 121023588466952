import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button, DownloadIcon, LinkIcon } from 'akeneo-design-system';

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: initial;

    .preview-button {
        margin-top: 20px;
    }
`;

type PreviewActionsProps = {
    media: PreviewMedia;
    size?: 'default' | 'small';
};

const PreviewActions: React.FC<PreviewActionsProps> = ({ media, size = 'default' }) => {
    const intl = useIntl();

    return (
        <Actions>
            {media.downloadUrl && (
                <Button
                    size={size}
                    ghost
                    href={media.downloadUrl}
                    target='_blank'
                    level='tertiary'
                    className='preview-button'
                >
                    <DownloadIcon />
                    {intl.formatMessage({ defaultMessage: 'Download', id: 'components.PreviewActions.e6adea' })}
                </Button>
            )}
            {media.sourceUrl && (
                <Button
                    ghost
                    href={media.sourceUrl}
                    target='_blank'
                    level='tertiary'
                    className='preview-button'
                >
                    <LinkIcon />
                    {intl.formatMessage({ defaultMessage: 'Source', id: 'components.PreviewActions.687e03' })}
                </Button>
            )}
        </Actions>
    );
};

export default PreviewActions;
