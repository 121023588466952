import { useIntl } from 'react-intl';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { SelectInput, TextInput, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useState, useMemo, useCallback, useEffect } from 'react';

//_______
type InputValue = {
    value?: number | string;
    unit?: string;
};

type Props = Override<
    InputProps<InputValue>,
    {
        unitChoices: InputSelectChoice[] | (() => InputSelectChoice[]) | Promise<InputSelectChoice[]>;
        patternRegexp?: RegExp;
        visibleUnits?: boolean;
    }
>;

const Root = styled.div`
    .input-main {
        border: 1px solid ${theme.color.grey80};
        display: flex;
        flex-direction: row;
        > div {
            margin: 0 2px;
        }
    }

    .input-value {
        border: none;
    }

    .input-unit {
        input[type='text'] {
            border: none;
        }
        [value] {
            text-transform: uppercase;
            color: ${theme.color.grey100};
            justify-content: flex-end;
            text-align: right;
        }
        > div > div:first-child {
            padding-right: 42px;
            [value] {
                flex: 1;
            }
        }
    }
`;

const emptyChoices: [] = [];
const defaultPatternRegexp = /^[0-9.]*$/i;

const emptyValue: InputValue = {};

const InputUnits: React.FC<Props> = (props) => {
    const {
        onChange,
        unitChoices: propsUnitChoices,
        patternRegexp = defaultPatternRegexp,
        visibleUnits = true,
    } = props;

    const propsValue = props.value || emptyValue;

    const intl = useIntl();

    const [value, setValue] = useState((propsValue.value as string) || '');
    const [unit, setUnit] = useState(propsValue.unit || '');

    const submitValue = useMemo(() => (onChange ? debounce(onChange, 350) : null), [onChange]);

    const handleChange = useCallback(
        (value: string) => {
            setValue((state) => {
                if (!patternRegexp.test(value)) {
                    return state;
                }
                submitValue && submitValue({ value, unit });
                return value;
            });
        },
        [submitValue, unit, patternRegexp],
    );

    const handleInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event.currentTarget.value);
        },
        [handleChange],
    );

    const handleChangeUnit = useCallback(
        (unit: string) => {
            setUnit(unit);
            onChange && onChange({ value, unit });
        },
        [onChange, value],
    );

    const [unitChoices, setUnitChoices] = useState<InputSelectChoice[]>(emptyChoices);

    useEffect(() => {
        if (!visibleUnits) {
            return;
        }
        if (!propsUnitChoices) {
            console.error('props.unitChoices must be set.');
            return;
        }
        if (propsUnitChoices instanceof Array) {
            setUnitChoices(propsUnitChoices);
            return;
        }
        void (async (): Promise<void> => {
            const choices =
                ((typeof propsUnitChoices === 'function'
                    ? await propsUnitChoices()
                    : propsUnitChoices) as InputSelectChoice[]) || emptyChoices;
            setUnitChoices(choices);
            if (choices.length) {
                setUnit((state) => (state?.length ? state : (choices[0].value as string)));
            }
        })();
    }, [propsUnitChoices, visibleUnits]);

    return (
        <Root role='input-metric'>
            <div className={'input-main'}>
                <TextInput
                    value={value}
                    onInput={handleInput}
                    onChange={handleChange}
                    className='input-value'
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Define your value',
                        id: 'InputUnits.InputUnits.3ebaba',
                    })}
                />
                {visibleUnits && (
                    <SelectInput
                        value={unit}
                        onChange={handleChangeUnit}
                        emptyResultLabel={intl.formatMessage({
                            defaultMessage: 'No result found',
                            id: 'InputUnits.InputUnits.180037',
                        })}
                        clearable={false}
                        className='input-unit'
                        placeholder={intl.formatMessage({ defaultMessage: 'Unit', id: 'InputUnits.InputUnits.c6407e' })}
                        openLabel={intl.formatMessage({
                            defaultMessage: 'Display choices',
                            id: 'InputUnits.InputUnits.deafe1',
                        })}
                    >
                        {unitChoices.map((choice) => (
                            <SelectInput.Option
                                key={choice.value}
                                title={choice.label}
                                value={choice.value as string}
                            >
                                {choice.label}
                            </SelectInput.Option>
                        ))}
                    </SelectInput>
                )}
            </div>
        </Root>
    );
};

export default InputUnits;
