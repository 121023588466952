import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helper, SectionTitle } from 'akeneo-design-system';

import { useAppDispatch } from 'src/tools/globalStore';
import { encodeString } from 'src/tools/encodeString';
import { useInfiniteProducts } from 'src/hooks/useInfiniteProducts';

import { selectors as searchSelectors } from 'src/components/ProductGrid/store/productGrid';
import { selectors as filterSelectors } from 'src/components/Filter/store';
import { selectors as selectionSelectors } from 'src/components/ProductGrid/store/productSelection';

import GalleryTableView from 'src/components/ProductGrid/components/gallery/GalleryTableView';
import { EmptyResult } from 'src/shared';

type Props = {
    productIdentifier: string;
    grandparent: string | null;
    variationAxes: string[];
    isModal: boolean;
    resetCurrentSection: () => void;
};

const Root = styled.div`
    height: calc(100vh - 325px);

    .gallery-table-view {
        height: 100%;
    }

    .section-title {
        padding-bottom: 0 !important;
    }

    .variations-info {
        margin-bottom: 20px;
    }
`;

const ProductVariations: React.FC<Props> = (props) => {
    const { grandparent, productIdentifier, variationAxes, isModal = false } = props;

    const dispatch = useAppDispatch();
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();

    const uniqueIdentifierAttribute: FilterType | null = useSelector(filterSelectors.getUniqueIdentifierAttribute);
    const search = useSelector(searchSelectors.currentSearch);
    const searchContexts = useSelector(selectionSelectors.searchContexts);

    const columns: string[] | undefined = useSelector(
        ({ search }: { search: SearchState }) => search.selectedVariationsColumns,
    );

    const defaultColumnInModal = useMemo(() => {
        if (!uniqueIdentifierAttribute) {
            return [];
        }

        return ['image', uniqueIdentifierAttribute.code ?? '', 'label', 'variationAxes'];
    }, [uniqueIdentifierAttribute]);

    const handleChangeOfState = useCallback(() => {
        if (isModal) {
            dispatch({
                type: 'SET_VARIATIONS_COLUMNS',
                payload: defaultColumnInModal,
            });
        }
    }, [defaultColumnInModal, dispatch, isModal]);
    useEffect(() => {
        handleChangeOfState();
    }, [handleChangeOfState]);

    // __ Get products.
    const { products, count, status, fetchNextPage } = useInfiniteProducts(
        isModal
            ? {
                  type: 'variationModal',
                  grandparent: grandparent ?? '',
                  columns: defaultColumnInModal,
                  search: search,
                  searchContexts: searchContexts,
              }
            : {
                  type: 'variation',
                  grandparent: grandparent ?? '',
                  columns: columns,
              },
    );

    const emptyResult = useMemo(() => {
        return (
            <EmptyResult
                title={intl.formatMessage({
                    defaultMessage: 'Sorry, no variations found.',
                    id: 'variations.ProductVariations.35374c',
                })}
                subtitle={intl.formatMessage({
                    defaultMessage: 'Try another product.',
                    id: 'variations.ProductVariations.d69ab8',
                })}
            />
        );
    }, [intl]);

    const handleClickRow = useCallback(
        (product: Product) => {
            props.resetCurrentSection();
            history.push(`/product/${encodeString(product.pimIdentifier)}${location.search}${location.hash}`);
        },
        [history, location.hash, location.search, props],
    );

    return (
        <Root className='product-variant'>
            {!isModal && (
                <div className='section-title'>
                    <SectionTitle>
                        <SectionTitle.Title>
                            {intl.formatMessage({
                                defaultMessage: 'Variations',
                                id: 'variations.ProductVariations.e9b5e0',
                            })}
                        </SectionTitle.Title>
                    </SectionTitle>
                </div>
            )}

            {variationAxes.length > 0 && (
                <div className='variations-info'>
                    <Helper level='info'>
                        {`${intl.formatMessage({
                            defaultMessage: 'This product varies by',
                            id: 'variations.ProductVariations.8a0c51',
                        })} `}
                        <strong>{variationAxes.join(', ')}</strong>.
                    </Helper>
                </div>
            )}

            <GalleryTableView
                identifier={productIdentifier}
                columnType='variations'
                columnCount={1}
                paddingRight={0}
                rowHeight={75}
                loading={status === 'pending'}
                emptyResult={emptyResult}
                itemCount={count}
                getItem={(index: number): Product | null => (products ? products[index] : null)}
                onClickRow={handleClickRow}
                loadMoreItems={fetchNextPage}
                complete={status !== 'pending'}
                minimumBatchSize={50}
            />
        </Root>
    );
};

export default ProductVariations;
