import React, { FC, useCallback, useEffect } from 'react';
import { Button, SelectInput } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import { actions as productExportActions, selectors as productExportSelectors } from '../store';
import { useAppDispatch } from '../../../tools/globalStore';
import { useSelector } from 'react-redux';

export const ExportProductPdfCovers: FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const { withCover, selectedPdfCover } = useSelector(productExportSelectors.exportOptions);

    // todo remove that to do a react query hook and not put the covers in the store
    const pdfSettingsCovers = useSelector(productExportSelectors.getPdfSettingsCovers);

    useEffect(() => {
        void (async (): Promise<void> => {
            await dispatch(productExportActions.fetchPdfSettingsCovers());
        })();
    }, [dispatch]);

    const enableDefaultCover = useCallback((): void => {
        const defaultCover = pdfSettingsCovers?.find((cover) => cover.default);
        if (defaultCover) {
            dispatch(productExportActions.setExportOption('selectedPdfCover', defaultCover));
        }
    }, [dispatch, pdfSettingsCovers]);

    useEffect(() => {
        if (withCover && selectedPdfCover === null) {
            enableDefaultCover();
        }
    }, [enableDefaultCover, pdfSettingsCovers, selectedPdfCover, withCover]);

    const handleSetPdfSettingsCover = (pdfCoverId: string): void => {
        const selectedCover = pdfSettingsCovers?.find((cover) => cover['index'] === pdfCoverId);
        dispatch(productExportActions.setExportOption('selectedPdfCover', selectedCover));
    };

    const handleEnableCover = (): void => {
        dispatch(productExportActions.setExportOption('withCover', true));
        enableDefaultCover();
    };

    const handleDisableCover = (): void => {
        dispatch(productExportActions.setExportOption('withCover', false));
        dispatch(productExportActions.setExportOption('selectedPdfCover', null));
    };

    if (null === pdfSettingsCovers || undefined === pdfSettingsCovers) {
        return null;
    }

    return (
        <>
            <div className='row'>
                <Button
                    className={`bt-square ${withCover ? '' : 'select'}`}
                    ghost
                    level='tertiary'
                    onClick={handleDisableCover}
                >
                    {intl.formatMessage({
                        defaultMessage: 'Without cover',
                        id: 'components.ExportProductPdfCovers.91279e',
                    })}
                </Button>
                <Button
                    className={`bt-square ${withCover ? 'select' : ''}`}
                    ghost
                    level='tertiary'
                    onClick={handleEnableCover}
                >
                    {intl.formatMessage({
                        defaultMessage: 'With cover',
                        id: 'components.ExportProductPdfCovers.23ebf7',
                    })}
                </Button>
            </div>
            {withCover && (
                <div className='row'>
                    <SelectInput
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Please chose a PDF cover',
                            id: 'components.ExportProductPdfCovers.0e04cd',
                        })}
                        value={
                            !selectedPdfCover
                                ? pdfSettingsCovers.find((cover) => cover.default)!.index
                                : selectedPdfCover.index
                        }
                        readOnly={false}
                        onChange={(newValue: string): void => {
                            {
                                handleSetPdfSettingsCover(newValue);
                            }
                        }}
                        emptyResultLabel={intl.formatMessage({
                            defaultMessage: 'No result found',
                            id: 'components.ExportProductPdfCovers.180037',
                        })}
                        openLabel={intl.formatMessage({
                            defaultMessage: 'Display choices',
                            id: 'components.ExportProductPdfCovers.deafe1',
                        })}
                        clearable={false}
                    >
                        {pdfSettingsCovers?.map((cover) => (
                            <SelectInput.Option
                                key={cover.index}
                                title={cover.name}
                                value={cover.index}
                            >
                                {cover.name}
                            </SelectInput.Option>
                        ))}
                    </SelectInput>
                </div>
            )}
        </>
    );
};
