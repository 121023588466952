const initState: CatalogLocales = [];

const LocalesReducer = (state: CatalogLocales = initState, action: LocalesActions): CatalogLocales => {
    switch (action.type) {
        case 'ADD_LOCALES':
            return action.locales;
        default:
            return state;
    }
};

export default LocalesReducer;
