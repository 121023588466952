const initState: NotificationState = {
    list: [],
};

const NotificationReducer = (state = initState, action: NotificationActions): NotificationState => {
    switch (action.type) {
        case 'NOTIFICATION_ADD':
            return { ...state, list: [...state.list, { ...action.payload, time: new Date().getTime() }] };
        case 'NOTIFICATION_REMOVE': {
            const index = state.list.indexOf(action.payload);
            if (index === -1) {
                return state;
            } else {
                state.list.splice(index, 1);
                return { ...state, list: [...state.list] };
            }
        }
        case 'NOTIFICATIONS_CLEAR':
            return { ...state, list: [] };
        default:
            return state;
    }
};

export default NotificationReducer;
