import { DndProvider } from 'react-dnd';
import { AxiosError, AxiosResponse } from 'axios';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import React, { useContext, useEffect, useState } from 'react';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Portal from 'src/Portal';
import AuthenticationPage from 'src/components/Authentication/AuthenticationPage';
import DownloadExportFilePage from 'src/components/Export/DownloadExportFilePage';

import Route from 'src/security/Route';
import configureStore, { ConfStore } from 'src/tools/globalStore';
import httpClient, { isServerError } from 'src/tools/HttpClient';

import { logoutAction } from 'src/components/Authentication/store/actions';

import { LocaleUIProvider } from 'src/shared/localeUI/LocaleUIContext';
import TenantCatalogContext from 'src/shared/tenantcatalog/TenantCatalogContext';

import Notifications from 'src/shared/notification/Notifications';
import { actions as notifActions } from 'src/shared/notification/store';
import { UnauthorizedError } from './utils/apiFetch';
import { InternalServerError } from './utils/apiFetch/exceptions/InternalServerError';

import SsoLoginPage from './components/Sso/SsoLoginPage';
import SsoErrorPage from './components/Sso/SsoErrorPage';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: Lato, sans-serif;
    font-size: 13px;
    box-sizing: border-box;
  }

  .scrollbar-light {
    ::-webkit-scrollbar,
    *::-webkit-scrollbar {
      background-color: ${theme.color.grey40};
      width: 6px;
      height: 6px;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb,
    *::-webkit-scrollbar-thumb {
      background-color: ${theme.color.grey80};
      border-radius: 3px;
    }
  }

`;

const Application = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    ::placeholder {
        color: ${theme.color.grey100};
    }

    .rgt-pad {
        padding-right: 40px;
    }

    @media (max-width: 768px) {
        padding-left: 0;
    }
`;

const App: React.FC = () => {
    const tenantCatalogContext = useContext(TenantCatalogContext);

    const { tenantId, catalogCode } = tenantCatalogContext;

    //__
    const [confStore, setConfStore] = useState<ConfStore | null>(null);

    useEffect(() => {
        httpClient.defaults.baseURL = `${process.env.REACT_APP_API_WEB_PATH}/${tenantId}/${catalogCode}`;

        let errorInterceptor: number;

        void (async (): Promise<void> => {
            const cs = configureStore(tenantId, catalogCode);
            await cs.rehydrated;

            errorInterceptor = httpClient.interceptors.response.use(
                (response: AxiosResponse) => response,
                (reason: AxiosError): Promise<AxiosError> => {
                    const statusStr = `${reason.response?.status}`;

                    if (statusStr === '401') {
                        cs.store.dispatch(logoutAction());
                        const loginRoute = `/${tenantId}/${catalogCode}/login`;
                        if (window.location.pathname !== loginRoute) {
                            window.location.pathname = loginRoute;
                        }
                    } else if (isServerError(reason.response)) {
                        cs.store.dispatch(
                            notifActions.addAction({
                                title: 'server.error.generic',
                                level: 'error',
                            }),
                        );
                    }

                    return Promise.reject(reason);
                },
            );

            setConfStore(cs);
        })();

        return (): void => {
            httpClient.interceptors.response.eject(errorInterceptor);
        };
    }, [tenantId, catalogCode]);

    if (!confStore) {
        return null;
    }

    const handleReactQueryError = async (error: Error | UnauthorizedError | InternalServerError): Promise<void> => {
        const confStore = configureStore(tenantId, catalogCode);
        await confStore.rehydrated;
        if (error instanceof UnauthorizedError) {
            confStore.store.dispatch(logoutAction());
            const loginRoute = `/${tenantId}/${catalogCode}/login`;
            if (window.location.pathname !== loginRoute) {
                window.location.pathname = loginRoute;
            }
        } else if (error instanceof InternalServerError) {
            confStore.store.dispatch(
                notifActions.addAction({
                    title: 'server.error.generic',
                    level: 'error',
                }),
            );
        }
        setConfStore(confStore);
    };

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 10 * 1000, // 10s
                gcTime: 5 * 60 * 1000, // 5m
            },
        },
        queryCache: new QueryCache({
            onError: (error): void => {
                // cache-level queries error handler
                void handleReactQueryError(error);
            },
        }),
        mutationCache: new MutationCache({
            onError: (error): void => {
                // cache-level mutations error handler
                void handleReactQueryError(error);
            },
        }),
    });

    const isGlobalRoute = window.location.pathname.startsWith('/sso-error');

    return (
        <DndProvider backend={HTML5Backend}>
            <ReduxProvider store={confStore.store}>
                <PersistGate
                    loading={null}
                    persistor={confStore.persistor}
                >
                    <ThemeProvider theme={theme}>
                        <TenantCatalogContext.Provider value={tenantCatalogContext}>
                            <LocaleUIProvider>
                                <QueryClientProvider client={queryClient}>
                                    {isGlobalRoute ? (
                                        <BrowserRouter>
                                            <Switch>
                                                <Application>
                                                    <Route
                                                        privateRoute={false}
                                                        path='/sso-error'
                                                        component={SsoErrorPage}
                                                    />
                                                </Application>
                                            </Switch>
                                        </BrowserRouter>
                                    ) : (
                                        <BrowserRouter basename={`/${tenantId}/${catalogCode}`}>
                                            <GlobalStyle />
                                            <Application>
                                                <Switch>
                                                    <Route
                                                        privateRoute={false}
                                                        path={`/(login|signup|reset)/`}
                                                        component={AuthenticationPage}
                                                    />
                                                    <Route
                                                        path='/export/download/:identifier'
                                                        component={DownloadExportFilePage}
                                                    />
                                                    <Route
                                                        privateRoute={false}
                                                        path={`/sso-login`}
                                                        component={SsoLoginPage}
                                                    />
                                                    <Route
                                                        path='/'
                                                        component={Portal}
                                                    />
                                                </Switch>
                                                <Notifications />
                                            </Application>
                                        </BrowserRouter>
                                    )}
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </QueryClientProvider>
                            </LocaleUIProvider>
                        </TenantCatalogContext.Provider>
                    </ThemeProvider>
                </PersistGate>
            </ReduxProvider>
        </DndProvider>
    );
};

export default App;
