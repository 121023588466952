import React from 'react';
import styled from 'styled-components';
import { CloseIcon, sharedCatalogsTheme as theme } from 'akeneo-design-system';

import { Image } from 'src/shared';

type SelectedProductProps = Override<
    RenderItemProps<Product>,
    {
        handleClickRemove?: (product: Product) => void;
        style?: React.CSSProperties;
        index?: number;
    }
>;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 0;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @media (max-width: 768px) {
        padding: 0;
        &:last-child {
            border-bottom: 1px solid ${theme.color.grey60};
        }
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    border: 1px ${theme.color.grey20} solid;
    width: 55px;
    object-fit: contain;
    height: 55px;
    z-index: 1;
    box-sizing: border-box;
    img {
        max-width: 55px;
        max-height: 55px;
    }
`;

const ProductTitle = styled.span`
    color: ${theme.color.grey120};
    font-size: 13px;
    padding-left: 1.5em;
    margin: 1.3em 0;
    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

const CloseButton = styled.button`
    position: relative;
    width: auto;
    cursor: pointer;
    border: none;
    background: ${theme.color.white};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    svg {
        width: 16px;
        height: 16px;

        g {
            stroke: ${theme.color.grey100};
        }

        @media (max-width: 768px) {
            width: 30px;
            height: 30px;
        }
    }
`;

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

const SelectedProduct: React.FC<SelectedProductProps> = (props) => {
    const { item: product, data, handleClickRemove, ...rest } = props;
    return (
        <Wrapper {...rest}>
            <LeftContainer>
                <ImageWrapper>
                    <Image
                        src={product.image}
                        title={product.label}
                    />
                </ImageWrapper>
                <ProductTitle>
                    <div dangerouslySetInnerHTML={{ __html: data?.isDev ? product.pimIdentifier : product.label }} />
                </ProductTitle>
            </LeftContainer>
            {handleClickRemove && (
                <CloseButton
                    onClick={(): void => handleClickRemove(product)}
                    data-testid='product-remove'
                >
                    <CloseIcon />
                </CloseButton>
            )}
        </Wrapper>
    );
};

export default SelectedProduct;
