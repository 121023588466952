import { useQuery } from '@tanstack/react-query';
import { apiFetch } from '../../../utils/apiFetch';
import { IsUsingSso } from '../models/IsUsingSso';

type Error = string | null;
export type Result = {
    isLoading: boolean;
    isError: boolean;
    data: IsUsingSso | undefined;
    error: Error;
};

export const useIsUsingSso = (): Result => {
    return useQuery<IsUsingSso, Error, IsUsingSso>({
        queryKey: ['is_using_sso'],
        queryFn: async () => {
            return apiFetch<IsUsingSso>(`is_using_sso`);
        },
    });
};
