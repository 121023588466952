import { AssetsIllustration, Button, Checkbox, Helper, SectionTitle } from 'akeneo-design-system';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import Media from 'src/components/ProductGrid/components/layouts/Media';
import { useProductMediaGalleryModal } from 'src/components/ProductPage/components/medias/ProductMediaGalleryModal';
import { useAppDispatch } from 'src/tools/globalStore';

type Props = {
    medias: Media[];
    productLabel: string;
};

const Root = styled.div`
    .selection-media {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .product-list-media {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-row-gap: 50px;
        grid-column-gap: 20px;
        grid-auto-rows: minmax(100px, 1fr);
    }

    .product-no-medias {
        flex: 1;
        text-align: center;

        .no-medias-title {
            margin-top: 38px;

            span {
                font-size: 28px;
            }
        }

        .no-medias-sub-title {
            margin-top: 20px;

            span {
                font-size: 13px;
                color: #000000;
            }
        }
    }
`;

const ProductMedia: React.FC<Props> = (props) => {
    const { medias, productLabel } = props;

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const mediaAvailableForDownload = useMemo(() => {
        return medias.filter((media) => media.download);
    }, [medias]);

    // __ Display media
    const { show: showProductMediaGallery } = useProductMediaGalleryModal();
    const handleClickMedia = useCallback(
        (media: Media) => {
            if (!medias.length) {
                return;
            }

            showProductMediaGallery({
                type: 'media_file',
                medias: medias || [],
                productLabel: productLabel,
                selectedIndex: medias.indexOf(media),
            });
        },
        [medias, productLabel, showProductMediaGallery],
    );

    const [selectedMedias, setSelectedMedias] = useState<Media[]>([]);
    const [selectedMode, setSelectedMode] = useState('none');
    const [numberOfSelectedMedia, setNumberOfSelectedMedia] = useState(0);

    // __ Selection of media
    const onSelectMedia = useCallback(
        (isSelected: boolean, currentMedia: Media) => {
            if (isSelected) {
                setNumberOfSelectedMedia(numberOfSelectedMedia + 1);
                medias.filter((media) => media.name === currentMedia.name).map((media) => (media.isSelected = true));
                setSelectedMedias([...selectedMedias, currentMedia]);
                setSelectedMode(numberOfSelectedMedia + 1 === mediaAvailableForDownload.length ? 'all' : 'mixed');
            } else {
                setNumberOfSelectedMedia(numberOfSelectedMedia - 1);
                medias.filter((media) => media.name === currentMedia.name).map((media) => (media.isSelected = false));
                setSelectedMedias(selectedMedias.filter((media) => media !== currentMedia));
                setSelectedMode(numberOfSelectedMedia - 1 === 0 ? 'none' : 'mixed');
            }
        },
        [mediaAvailableForDownload.length, medias, numberOfSelectedMedia, selectedMedias],
    );

    const handleChange = useCallback(
        (checked: boolean) => {
            if (checked) {
                setNumberOfSelectedMedia(mediaAvailableForDownload.length);
                setSelectedMode('all');
                setSelectedMedias(medias);
                medias.filter((media) => media.download).map((media) => (media.isSelected = true));
            } else {
                setNumberOfSelectedMedia(0);
                setSelectedMode('none');
                setSelectedMedias([]);
                medias.filter((media) => media.download).map((media) => (media.isSelected = false));
            }
        },
        [mediaAvailableForDownload.length, medias],
    );

    const checked = useMemo(() => {
        switch (selectedMode) {
            case 'none':
                return false;
            case 'all':
                return true;
            default:
                return 'mixed';
        }
    }, [selectedMode]);

    // __ Download media
    const downloadMedias = useCallback(() => {
        const zip = new JSZip();

        let count = 0;

        selectedMedias.forEach((media) => {
            if (media.download) {
                const filename = `${count + 1}-${media.name.split('.')[0]}.${media.format}`;
                const fileBlob = fetch(media.download).then((response) => response.blob());
                zip.file(filename, fileBlob);
                ++count;
            }
        });

        if (count > 0) {
            zip.generateAsync({ type: 'blob' })
                .then(function (content) {
                    saveAs(
                        content,
                        `${intl.formatMessage({
                            defaultMessage: 'Media',
                            id: 'medias.ProductMedia.866677',
                        })} - ${productLabel}.zip`,
                    );
                })
                .catch(() => {
                    dispatch({
                        type: 'NOTIFICATION_ADD',
                        payload: {
                            title: intl.formatMessage({
                                defaultMessage: 'A download error has occurred.',
                                id: 'medias.ProductMedia.fcbf18',
                            }),
                            description: intl.formatMessage({
                                defaultMessage:
                                    'The selected media could not be downloaded correctly. Please try again.',
                                id: 'medias.ProductMedia.432e99',
                            }),
                            level: 'error',
                        },
                    });
                });
        }
    }, [dispatch, intl, productLabel, selectedMedias]);

    return (
        <Root>
            <div className='section-title'>
                <SectionTitle>
                    <SectionTitle.Title>
                        {intl.formatMessage({ defaultMessage: 'Media', id: 'medias.ProductMedia.866677' })}
                    </SectionTitle.Title>
                </SectionTitle>
                <Helper>
                    {intl.formatMessage({
                        defaultMessage: 'Only some media formats can be downloaded. Learn more in the ',
                        id: 'medias.ProductMedia.742ab0',
                    })}
                    <a
                        href='https://help.akeneo.com/themes-for-betty-access-and-navigate-through-shared-catalogs/using-the-product-page#the-media'
                        className='help'
                        target='_blank'
                        rel='noreferrer'
                    >
                        {intl.formatMessage({
                            defaultMessage: 'help center.',
                            id: 'medias.ProductMedia.3078ef',
                        })}
                    </a>
                </Helper>
                <div className='selection-media'>
                    <Checkbox
                        id='selection'
                        checked={checked}
                        onChange={handleChange}
                    >
                        {intl.formatMessage(
                            {
                                defaultMessage:
                                    '{selected, plural, =0 {{total} {total, plural, =0 {media file} one {media file} other {media files} }} other {{selected} {selected, plural, one {selected media files} other {selected media files} } out of {total} }} available for download',
                                id: 'medias.ProductMedia.2b254d',
                            },
                            { selected: numberOfSelectedMedia, total: mediaAvailableForDownload.length },
                        )}
                    </Checkbox>

                    <Button
                        ghost
                        level='primary'
                        disabled={numberOfSelectedMedia <= 0}
                        onClick={downloadMedias}
                    >
                        {intl.formatMessage(
                            { defaultMessage: 'Download ({count})', id: 'medias.ProductMedia.456453' },
                            { count: numberOfSelectedMedia },
                        )}
                    </Button>
                </div>
            </div>

            {medias.length ? (
                <div className='product-list-media'>
                    {medias.map((media, index) => (
                        <Media
                            key={index}
                            media={media}
                            onClick={handleClickMedia}
                            isSelected={!!media.isSelected}
                            onSelect={onSelectMedia}
                        />
                    ))}
                </div>
            ) : (
                <div className='product-no-medias'>
                    <AssetsIllustration />
                    <div className='no-medias-title'>
                        <span>
                            {intl.formatMessage({
                                defaultMessage: 'Sorry, there is no media shared.',
                                id: 'medias.ProductMedia.40de9e',
                            })}
                        </span>
                    </div>
                    <div className='no-medias-sub-title'>
                        <span>
                            {intl.formatMessage({
                                defaultMessage: 'Try with other products to display their media.',
                                id: 'medias.ProductMedia.554859',
                            })}
                        </span>
                    </div>
                </div>
            )}
        </Root>
    );
};

export default ProductMedia;
