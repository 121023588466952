import { ThunkResult } from 'src/tools/globalStore';
import pagedFetch, { PagedFetch } from 'src/hooks/pagedFetch';

type ProductSelectionResponse = {
    count: number;
    products: Product[];
};

type FetchProductSelectionOptions = {
    locale: string;
    searchContexts: SearchContext[];
    onFetchPage?: (page: number) => void;
    onPageFetched?: (page: number, data: ProductSelectionResponse, complete: boolean, isLoading: boolean) => void;
};

export type PagedProductSelection = PagedFetch<Product, ProductSelectionResponse>;

const pagedProductSelection =
    (options: FetchProductSelectionOptions): ThunkResult<PagedProductSelection> =>
    (dispatch): PagedProductSelection => {
        const { locale, searchContexts, onFetchPage, onPageFetched } = options;
        const res = pagedFetch<Product, SearchContext[], ProductSelectionResponse>(
            {
                url: `/products/selection/${locale}`,
                method: 'post',
                data: searchContexts,
            },
            {
                afterKey: (prevLoad: ProductSelectionResponse) => {
                    return prevLoad.products[prevLoad.products.length - 1]?.pimIdentifier;
                },
                lastGrandParentCode: (prevLoad: ProductSelectionResponse) => {
                    return prevLoad.products[prevLoad.products.length - 1]?.grandparent;
                },
                lastParentCode: (prevLoad: ProductSelectionResponse) => {
                    return prevLoad.products[prevLoad.products.length - 1]?.parent;
                },
                recordsKey: 'products',
                onFetchPage,
                onPageFetched: (page, data, complete, isLoading) => {
                    dispatch({ type: 'PRODUCT_SELECTION_TOTAL', total: res.totalRecords });
                    if (onPageFetched) {
                        onPageFetched(page, data, complete, isLoading);
                    }
                },
                paramsAsQueryString: true,
            },
        );
        return res;
    };

const selectProduct =
    (product: Product, select: boolean): ThunkResult<void> =>
    (dispatch, getState): void => {
        const state = getState();
        product.isSelected = select;

        if (product.isStacked && product.listOfVariantIds && product.listOfVariantIds.length > 0) {
            let numberOfProductChanged = 0;
            product.listOfVariantIds.forEach((variant) => {
                if (variant.isSelected !== select) {
                    numberOfProductChanged++;
                    variant.isSelected = select;
                }
            });
            dispatch({
                type: 'SELECT_PRODUCTS',
                productKeysAndSelection: product.listOfVariantIds,
                select,
                numberOfProductChanged,
                search: state.search.currentSearch,
            });
        } else {
            dispatch({
                type: 'SELECT_PRODUCT',
                productKey: product.pimIdentifier,
                select,
                search: state.search.currentSearch,
            });
        }
    };

const selectAllProducts =
    (select: boolean): ThunkResult<void> =>
    (dispatch, getState): void => {
        const state = getState();
        dispatch({ type: select ? 'SELECT_PRODUCTS_ALL' : 'SELECT_PRODUCTS_NONE', search: state.search.currentSearch });
    };

const removeProduct =
    (product: Product): ThunkResult<void> =>
    (dispatch, getState): void => {
        const state = getState();
        dispatch({
            type: 'PRODUCT_SELECTION_REMOVE',
            productKey: product.pimIdentifier,
            locale: state.search.currentSearch.locale,
        });
    };

//______
export default {
    pagedProductSelection,
    selectProduct,
    selectAllProducts,
    removeProduct,
};
