const display = ({ productExport }: { productExport: ProductExportState }): boolean => productExport.display;

const canExport = ({ productSelection }: { productSelection: ProductSelectionState }): boolean =>
    !!productSelection.totalCount;

const exportOptions = ({ productExport }: { productExport: ProductExportState }): ExportOptions =>
    productExport.exportOptions;

const forceSelectedAttributes = ({
    productExport,
}: {
    productExport: ProductExportState;
}): Array<{ code: string; type: string }> | null => productExport.forceSelectedAttributes;

const getPdfSettingsCovers = ({ productExport }: { productExport: ProductExportState }): PdfSettingsCover[] | null =>
    productExport.pdfSettingsCovers;
export default { display, exportOptions, canExport, forceSelectedAttributes, getPdfSettingsCovers };
