import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Checkbox, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useCallback, useState } from 'react';

import { Image } from 'src/shared';
import StackedImage from 'src/shared/image/StackedImage';

const Root = styled.div<{ colWidth: number | undefined }>`
    &.loading {
        background-color: ${theme.color.grey40};
    }

    label {
        cursor: pointer;
    }

    .product-link {
        position: relative;
        ::before {
            content: '';
            display: block;
        }
        :hover .overlay {
            opacity: 50%;
        }

        display: flex;
        height: ${(props): number => props?.colWidth || 100}px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .simple-product {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border: 1px ${theme.color.grey100} solid;
        }

        &.selected {
            .simple-product,
            .variant-product img,
            .stacked::before,
            .stacked::after {
                border: 2px ${theme.color.blue100} solid;
            }
        }
    }

    .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: ${theme.color.grey140};
        opacity: 0%;
        transition: opacity 0.3s ease-in;
    }

    .product-infos {
        margin-top: 10px;
        width: 100%;
        height: 32px;
        overflow: hidden;
        display: flex;
        flex-direction: row;

        .checkbox {
            align-items: center;
            width: 100%;

            [role='checkbox'] {
                flex: none;
            }
            label {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 13px;
                line-height: 15px;
                color: ${theme.color.grey120};
            }
        }
    }

    div.product-link {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .overlay {
            display: none;
        }
    }
`;

type Props = Override<
    RenderItemProps<Product>,
    {
        isSelected?: boolean | 'mixed';
        link: string;
        index?: number;
        style?: React.CSSProperties;
        onSelect?: (isSelected: boolean, product: Product) => void;
        onClick?: (product: Product) => void;
    }
>;

const GalleryItem: React.FC<Props> = (props: Props) => {
    const { item: product, data, isSelected: propsIsSelected = false, onSelect, link, onClick, ...rest } = props;

    const [isSelected, setSelected] = useState(propsIsSelected);

    const handleSelect = useCallback(
        (checked: boolean) => {
            if (!product || !onSelect) {
                return;
            }
            setSelected(checked);
            onSelect(checked, product);
        },
        [product, onSelect],
    );

    return (
        <Root
            colWidth={data?.colWidth}
            className={`item-product ${product ? '' : 'loading'}`}
            {...rest}
        >
            {product && (
                <>
                    <div title={data?.isDev ? product.pimIdentifier : product.label}>
                        {product.isStacked && product?.variationNumber && product.variationNumber > 1 ? (
                            <div
                                className={`product-link ${isSelected === 'mixed' || isSelected ? 'selected' : ''}`}
                                onClick={(): void => onClick && onClick(product)}
                            >
                                <div className='overlay' />
                                <div className='variant-product'>
                                    <StackedImage
                                        src={product.image}
                                        title={product.label}
                                        variationNumber={product.variationNumber}
                                    />
                                </div>
                            </div>
                        ) : (
                            <Link
                                to={link}
                                className={`product-link ${isSelected === 'mixed' || isSelected ? 'selected' : ''}`}
                            >
                                <div className='overlay' />
                                <div className='simple-product'>
                                    <Image
                                        src={product.image}
                                        title={product.label}
                                    />
                                </div>
                            </Link>
                        )}
                        <div className='product-infos'>
                            <Checkbox
                                checked={isSelected}
                                onChange={handleSelect}
                                className='checkbox'
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: data?.isDev ? product.pimIdentifier : product.label,
                                    }}
                                />
                            </Checkbox>
                        </div>
                    </div>
                </>
            )}
        </Root>
    );
};

export default GalleryItem;
