import styled from 'styled-components';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';

import { selectors as brandingSelectors } from 'src/shared/branding/store';

import { Modal } from 'src/shared';
import ProductVariations from 'src/components/ProductPage/components/variations/ProductVariations';

//____________
type Props = {
    product: Product | undefined;
    display: boolean;
    handleDisplayModal: (display: boolean) => void;
};

const Root = styled(Modal)<{ colorBranding: string }>`
    display: flex;
    justify-content: flex-start;
    padding-top: 100px;
    padding-left: 270px;
    padding-right: 270px;

    .variant-header {
        text-align: center;

        .breadcrumb {
            text-transform: uppercase;
            color: ${({ colorBranding }): string => colorBranding};
        }

        .title-label {
            font-weight: normal;
            font-size: 28px;
            color: ${theme.color.grey120};
            margin-bottom: 20px;
        }
    }

    .product-variant {
        width: 100%;
        height: calc(100vh - 375px);
    }

    .dropdown-attributes {
        display: none;
    }
`;

const VariantModal: React.FC<Props> = (props) => {
    const { product, display, handleDisplayModal } = props;

    const intl = useIntl();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    return (
        <Root
            className='variant-modal'
            colorBranding={colorBranding}
            onDisplay={handleDisplayModal}
            display={display}
        >
            <div className='variant-header'>
                <div className='breadcrumb'>
                    {intl.formatMessage({ defaultMessage: 'Products', id: 'variations.VariantModal.ecd15f' })}
                </div>
                <h1 className='title-label'>
                    {intl.formatMessage({ defaultMessage: 'Product Variations', id: 'variations.VariantModal.1ed133' })}
                </h1>
            </div>
            {product && (
                <ProductVariations
                    productIdentifier={product.pimIdentifier}
                    grandparent={product.grandparent}
                    variationAxes={product.variationAxes || []}
                    resetCurrentSection={(): void => {}}
                    isModal
                />
            )}
        </Root>
    );
};

export default VariantModal;
