import { useIntl } from 'react-intl';
import styled from 'styled-components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, sharedCatalogsTheme as theme } from 'akeneo-design-system';

import exportIllustration from 'src/media/Export-download.svg';

import TenantCatalogContext from 'src/shared/tenantcatalog/TenantCatalogContext';
import { selectors as brandingSelectors } from 'src/shared/branding/store';
import httpClient from 'src/tools/HttpClient';

const Wrapper = styled.div`
    background-color: ${theme.color.white};
    height: 100%;
    width: 100%;
    position: absolute;
`;
const ExportWrapper = styled.div`
    height: 100%;
`;

const FormWrapper = styled.div`
    display: flex;
    padding-top: 210px;
`;

const LeftWrapper = styled.div`
    padding-right: 40px;
    max-height: 220px;

    img {
        max-height: 220px;
    }
`;

const RightWrapper = styled.div<{ colorBranding: string }>`
    border-left: 1px solid ${({ colorBranding }): string => colorBranding};
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: 450px;
`;

const TitleWrapper = styled.div<{ colorBranding: string }>`
    font-size: 15px;
    color: ${({ colorBranding }): string => colorBranding};
`;

const SetupWrapper = styled.div`
    font-size: 28px;
    color: ${theme.color.grey140};
    padding-top: 10px;
`;

const DownloadInformation = styled.div`
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    max-width: 560px;
    font-size: 15px;
`;

const ExportProductWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
`;

type Params = {
    identifier: string;
    tenantId: string;
    catalogCode: string;
};

const WrapperButton = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const DownloadExportFilePage: React.FC = () => {
    const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(false);
    const { identifier } = useParams<Params>();
    const history = useHistory();
    const colorBranding = useSelector(brandingSelectors.getColorBranding);
    const linkRef = useRef<HTMLButtonElement>(null);

    if (!identifier && history.location.pathname !== '/') {
        history.push(`/`);
    }

    useEffect(() => {
        const isUserLoggedIn = async () => {
            await httpClient.get('/is_logged_in');
        };

        // Allow to redirect to the login page if the user is not logged in
        isUserLoggedIn();
    }, []);

    const { tenantId, catalogCode } = useContext(TenantCatalogContext);
    const queryParams = new URL(window.location.href).searchParams;
    const downloadRoute =
        'XLSX' === queryParams.get('format') && queryParams.get('withMedias') && queryParams.get('stream')
            ? 'stream-export-file'
            : 'export-file';
    const href = `${
        process.env.REACT_APP_API_WEB_PATH
    }/${tenantId}/${catalogCode}/${downloadRoute}/${identifier}?withMedias=${
        queryParams.get('withMedias') || '0'
    }&format=${queryParams.get('format') || 'XLSX'}`;

    const handleDownload = () => {
        if (!isDownloadButtonDisabled && linkRef.current) {
            setIsDownloadButtonDisabled(true);
            linkRef.current.click();
            setTimeout(() => {
                setIsDownloadButtonDisabled(false);
            }, 3000);
        }
    };

    const intl = useIntl();
    return (
        <Wrapper>
            <ExportProductWrapper>
                <ExportWrapper>
                    <FormWrapper>
                        <LeftWrapper>
                            <img
                                src={exportIllustration}
                                alt='Download your export file'
                            />
                        </LeftWrapper>
                        <RightWrapper colorBranding={colorBranding}>
                            <TitleWrapper colorBranding={colorBranding}>
                                {intl
                                    .formatMessage({
                                        defaultMessage: 'Export',
                                        id: 'Export.DownloadExportFilePage.495c09',
                                    })
                                    .toUpperCase()}
                            </TitleWrapper>
                            <SetupWrapper>
                                {intl.formatMessage({
                                    defaultMessage: 'Download export',
                                    id: 'Export.DownloadExportFilePage.2acaaf',
                                })}
                            </SetupWrapper>
                            <DownloadInformation>
                                {intl.formatMessage({
                                    defaultMessage:
                                        'Your catalog export is ready for download. You may close this page once the download has started.',
                                    id: 'Export.DownloadExportFilePage.ab15a7',
                                })}
                            </DownloadInformation>
                            <WrapperButton>
                                <Button
                                    href={href}
                                    ref={linkRef}
                                    download
                                    disabled={isDownloadButtonDisabled}
                                    onClick={handleDownload}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: 'Download',
                                        id: 'Export.DownloadExportFilePage.e6adea',
                                    })}
                                </Button>
                            </WrapperButton>
                        </RightWrapper>
                    </FormWrapper>
                </ExportWrapper>
            </ExportProductWrapper>
        </Wrapper>
    );
};

export default DownloadExportFilePage;
