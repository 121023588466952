import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { HelpIcon, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import { useSelector } from 'react-redux';

import { selectors as brandingSelectors } from 'src/shared/branding/store';

const Root = styled.div<{ colorBranding: string }>`
    position: absolute;
    bottom: 10px;

    .help {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: ${theme.color.grey140};
    }

    .icon {
        color: ${({ colorBranding }): string => colorBranding};
        margin-right: 8.5px;
    }
`;

const SidebarFooter: React.FC = () => {
    const intl = useIntl();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    return (
        <Root colorBranding={colorBranding}>
            <a
                href='https://help.akeneo.com/shared-catalogs/themes-for-betty.html'
                className='help'
                target='_blank'
                rel='noreferrer'
            >
                <HelpIcon className='icon' />
                {intl.formatMessage({ defaultMessage: 'Help', id: 'sidebarfooter.SidebarFooter.484351' })}
            </a>
        </Root>
    );
};

export default SidebarFooter;
