export const addAction = (notification: NotificationType): NotificationAddAction => ({
    type: 'NOTIFICATION_ADD',
    payload: notification,
});

export const removeAction = (notification: NotificationType): NotificationRemoveAction => ({
    type: 'NOTIFICATION_REMOVE',
    payload: notification,
});

export const clearAction = (): NotificationsClearAction => ({ type: 'NOTIFICATIONS_CLEAR' });

export default { addAction, removeAction, clearAction };
