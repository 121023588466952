import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import map from 'lodash/map';
import noop from 'lodash/noop';

//_____
type Props = {
    children?: React.ReactNode;
    className?: string;
    draggable?: boolean;
    onChangeOrder?: (srcIndex: number, targetIndex: number) => void;
};

const Root = styled.div`
    .dragging {
        opacity: 0;
    }

    .not-dragging {
        opacity: 1;
    }
`;

const OrderableItemList: React.FC<Props> = (props: Props) => {
    const { children, className, draggable = true, onChangeOrder = noop } = props;

    const [orderedChildren, setOrderedChildren] = useState<React.ReactNode[]>([]);

    useEffect(() => {
        if (!children) {
            return;
        }
        setOrderedChildren(React.Children.toArray(children));
    }, [children]);

    const orderChanges = useRef<{ srcIndex: number | null; targetIndex: number | null }>({
        srcIndex: null,
        targetIndex: null,
    });

    const handleMove = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragItem = orderedChildren[dragIndex];
            const sourceItem = orderedChildren[hoverIndex];
            const newItems = [...orderedChildren];
            newItems[dragIndex] = sourceItem;
            newItems[hoverIndex] = dragItem;
            setOrderedChildren(newItems);
            orderChanges.current.targetIndex = hoverIndex;
        },
        [orderedChildren],
    );

    const handleDragStart = useCallback((index: number) => {
        orderChanges.current.targetIndex = null;
        orderChanges.current.srcIndex = index;
    }, []);

    const handleDragEnd = useCallback(() => {
        onChangeOrder(orderChanges.current.srcIndex, orderChanges.current.targetIndex);
    }, [onChangeOrder]);

    return (
        <Root
            role='list'
            className={`orderable-list-item ${className}`}
        >
            {map(orderedChildren, (child: any, index) =>
                draggable && !child.props.notOrderable ? (
                    <DraggableItem
                        key={child.key}
                        index={index}
                        onMove={handleMove}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                    >
                        {child}
                    </DraggableItem>
                ) : (
                    child
                ),
            )}
        </Root>
    );
};

export default OrderableItemList;

//___ Item Filter Draggable
type DraggableItemProps = {
    index: number;
    onMove: (dragIndex: number, hoverIndex: number) => void;
    onDragStart?: (dragIndex: number) => void;
    onDragEnd?: (dragIndex: number) => void;
    children: React.ReactNode;
};

type DragItem = {
    index: number;
    type: string;
};

const ItemTypes = {
    ORDERABLE: 'orderable',
};

const DraggableItem: React.FC<DraggableItemProps> = (props) => {
    const { index, onMove, onDragStart = noop, onDragEnd = noop, children } = props;

    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.ORDERABLE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: unknown, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragItem = item as DragItem;
            const dragIndex = dragItem.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (
                (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
                (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
            ) {
                return;
            }

            // Time to actually perform the action
            onMove(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations, but it's good here for the sake of performance to avoid expensive index searches.
            dragItem.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ORDERABLE,
        item: () => {
            onDragStart(index);
            return { index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item) => {
            if (!item) {
                return;
            }
            onDragEnd(item.index);
        },
    });

    drag(drop(ref));

    return (
        <div
            className={isDragging ? 'dragging' : 'not-dragging'}
            ref={ref}
            data-handler-id={handlerId}
        >
            {children}
        </div>
    );
};
