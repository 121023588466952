import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import {
    Button,
    SwitcherButton,
    Pill,
    PlusIcon,
    CheckPartialIcon,
    ArrowDownIcon,
    sharedCatalogsTheme as theme,
} from 'akeneo-design-system';
import { useSelector } from 'react-redux';

import merge from 'src/tools/merge';
import useClickout from 'src/hooks/useClickout';
import useFeatureFlag from 'src/hooks/useFeatureFlag';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import { useFiltersContext } from 'src/components/Filter/components/filter/FiltersContext';

import { Drawer, Dropdown } from 'src/shared/index';
import debounce from 'lodash/debounce';

//_______
type DisplayMode = 'normal' | 'expand' | 'drawer';

type ValueDisplayMode = 'normal' | 'pill';

type Props = {
    filter: FilterType;
    displayMode?: DisplayMode;
    isMobileMode?: boolean;
    valueDisplayMode?: ValueDisplayMode;
};

const defaultDisplayMode = 'expand';

//_______
const Root = styled.div<{ colorBranding: string }>`
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 16px;
    padding-bottom: 10px;
    border-bottom: 1px ${theme.color.grey40} solid;

    .filter-head {
        padding-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .switcher {
            label {
                text-align: left;
            }
        }

        .filter-value-read {
            margin-left: 10px;
        }

        .pill {
            margin: 0 10px;

            div {
                background-color: ${theme.color.blue100};
                width: 8px;
                height: 8px;
                min-width: 8px;
                min-height: 8px;
            }
        }
    }

    .filter-label {
        color: ${theme.color.grey140};
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > .filter-value-read {
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${theme.color.grey140};
    }

    .filter-body {
        margin-top: 10px;

        .filter-range {
            display: flex;
            flex-direction: column;

            & + .filter-range {
                margin-top: 10px;
            }
        }

        .filter-not-range,
        .filter-empty {
            margin-top: 20px;
        }

        label {
            color: ${theme.color.grey120};
            margin-bottom: 4px;

            &.label-to {
                margin-top: 10px;
            }
        }
    }

    .categories-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        width: 100%;
        border-top: 1px solid ${theme.color.grey80};
        cursor: auto;
        .filter-value-read {
            color: ${theme.color.grey140};
            font-size: 15px;
        }
    }

    &.mode-normal {
        .filter-head {
            cursor: auto;
        }
    }

    &.mode-expand {
        padding-bottom: 0;

        .filter-head {
            height: 44px;
            padding-top: 0;
            padding-right: 10px;

            svg {
                width: 12px;
                height: 12px;

                g,
                path {
                    stroke: ${theme.color.grey100};
                }
            }
        }

        .filter-body {
            margin-top: 0;
            padding-bottom: 15px;
            position: relative;
        }
    }

    &.mode-drawer {
        .filter-head {
            .filter-value-read {
                margin-left: 0;
            }
        }

        .filter-body {
            margin-top: 0;
        }
    }

    &.mode-dropdown {
        .filter-head {
            padding-left: 0;
            padding-right: 0;
        }

        .filter-body {
            margin-top: 0;
        }
    }

    &.mode-expand,
    &.mode-drawer {
        margin-bottom: 0;
    }

    .dropdown-operators {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 4px;
        position: absolute;
        right: 0;
        top: -12px;

        .switcher {
            display: flex;
            align-items: center;
            color: ${theme.color.grey100};

            > h2 {
                font-weight: inherit;
                color: ${({ colorBranding }): string => colorBranding};
            }

            > svg {
                margin-left: 5px;
                width: 8px;
            }
        }
    }
`;

const Filter: React.FC<Props> = (props: Props) => {
    const { filter, isMobileMode = false, valueDisplayMode = 'pill' } = props;

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const filterKey = filter.key;

    const intl = useIntl();
    const unmount = useRef(false);
    useEffect(() => {
        return () => {
            unmount.current = true;
        };
    }, []);

    let displayMode: DisplayMode | undefined = props.displayMode;
    const dm = useFeatureFlag('displayMode', null) as DisplayMode;
    if (!displayMode && dm) {
        displayMode = dm;
    }
    if (!displayMode || !/^normal|expand|drawer$/.test(displayMode)) {
        displayMode = defaultDisplayMode;
    }

    const {
        getFilterType,
        getFilterOperator,
        getFilterLabel,
        catalogLocale: locale,
        onFilterChange,
    } = useFiltersContext();

    const filterType = useMemo(() => {
        return getFilterType(filter.type);
    }, [filter.type, getFilterType]);

    const [value, setValue] = useState(typeof filter.value === 'undefined' ? filterType?.defaultValue : filter.value);
    const currentValue = useRef(value);
    //___ when in range mode, it would be null while local value could have only a valid 'from' or 'to' value
    const appliedValue = useRef(value);

    const [currentOperator, setCurrentOperator] = useState<null | FilterOperator>(
        typeof filter.operator === 'string'
            ? getFilterOperator(filter.operator)
            : filterType?.operators?.length
              ? filterType.operators[0]
              : null,
    );

    const handleChange = useCallback(
        debounce((value: any, operator = currentOperator) => {
            currentValue.current = value;
            appliedValue.current = onFilterChange(filter, value, operator);
            setValue(value);
        }, 350),
        [onFilterChange, filter, currentOperator],
    );

    const handleSetOperator = useCallback(
        (operator: FilterOperator) => {
            let v = value;
            if (currentOperator && currentOperator.isRange !== operator.isRange) {
                if (currentOperator.isRange) {
                    v = value?.from || null;
                } else {
                    v = { from: value, to: null };
                }
            }

            if (value && currentOperator && currentOperator.key === 'in_list' && operator.key !== 'in_list') {
                v = (value as string).toString();
            }

            if (
                currentOperator &&
                (currentOperator.key === 'empty' || currentOperator.key === 'not_empty') &&
                operator.key !== 'empty' &&
                operator.key !== 'not_empty'
            ) {
                v = undefined;
            }

            //__ Handle empty operator by sending value as operator.key
            if ((operator && operator.key === 'empty') || operator.key === 'not_empty') {
                v = operator.key;
            }

            setCurrentOperator(operator);
            handleChange(v, operator);
        },
        [handleChange, value, currentOperator],
    );

    //___ expand
    const modeExpand = displayMode === 'expand';
    const modeDrawer = displayMode === 'drawer';

    const [expanded, setExpanded] = useState(!!filter.expanded);
    const toggleExpand = useCallback(() => {
        setFocused(!expanded);
        setExpanded(!expanded);
    }, [expanded]);

    const handleExpand = useCallback((open: boolean) => {
        setExpanded(open);
    }, []);

    //_ focus setup
    const [isFocused, setFocused] = useState(false);

    const handleClickout = useCallback(() => {
        setFocused(false);
    }, []);

    const refInput = useRef<HTMLDivElement>(null);
    useClickout(refInput, handleClickout);

    const label = useMemo(() => {
        switch (filter.label) {
            case 'intl-Family':
                return intl.formatMessage({
                    defaultMessage: 'Family',
                    id: 'Filter.Filter.af30af',
                });
            case 'intl-Type':
                return intl.formatMessage({ defaultMessage: 'Type', id: 'Filter.Filter.f94ea8' });
            case 'intl-Quantity':
                return intl.formatMessage({
                    defaultMessage: 'Quantity',
                    id: 'Filter.Filter.a95191',
                });
            case 'intl-Image':
                return intl.formatMessage({
                    defaultMessage: 'Image',
                    id: 'Filter.Filter.fb4cef',
                });
            case 'intl-Label':
                return intl.formatMessage({
                    defaultMessage: 'Label',
                    id: 'Filter.Filter.ef9df2',
                });
            case 'intl-Variations':
                return intl.formatMessage({
                    defaultMessage: 'Variations',
                    id: 'Filter.Filter.e9b5e0',
                });
            default:
                return getFilterLabel(filter);
        }
    }, [intl, getFilterLabel, filter]);

    //__ value display
    const [valueDisplay, setValueDisplay] = useState<JSX.Element | string | null>(null);

    const valueToString = filter.valueToString || filterType?.valueToString;

    useEffect(() => {
        switch (valueDisplayMode) {
            case 'pill': {
                setValueDisplay(
                    !isNil(appliedValue.current) && `${appliedValue.current}`.length ? (
                        <div className='pill'>
                            <Pill level='warning' />
                        </div>
                    ) : null,
                );
                break;
            }
            default:
                {
                    if (!valueToString) {
                        return;
                    }
                    void (async (): Promise<void> => {
                        const string = await valueToString(value, filter);
                        if (unmount.current || value !== currentValue.current) {
                            return;
                        }
                        setValueDisplay(<div className={'filter-value-read'}>{string}</div>);
                    })();
                }
                break;
        }
    }, [valueToString, valueDisplayMode, value, filter, intl]);

    //___
    const filterOperators = useMemo(() => {
        if (!filterType?.operators || (modeExpand && !expanded)) {
            return null;
        }

        return (
            <Dropdown
                className='dropdown dropdown-operators'
                horizontalPosition='left'
                overlayAutoMaxHeight={false}
                openTrigger={
                    <div
                        className='switcher'
                        data-testid='switcher-operators'
                    >
                        <h2>
                            {filterType.operators.filter((operator) => operator.key === currentOperator?.key)[0].label}
                        </h2>
                        <ArrowDownIcon />
                    </div>
                }
            >
                <Dropdown.ItemCollection>
                    {filterType.operators.map((operator: FilterOperator, index: number) => (
                        <Dropdown.Item
                            key={index}
                            onClick={(): void => handleSetOperator(operator)}
                            isActive={operator.key === currentOperator?.key}
                        >
                            {operator.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.ItemCollection>
            </Dropdown>
        );
    }, [filterType?.operators, currentOperator, handleSetOperator, modeExpand, expanded]);

    const filterBody = useMemo(() => {
        if (!filterType || !locale || (modeExpand && !expanded)) {
            return null;
        }

        let inputComp;
        const InputComponent = filterType.getComponent(isMobileMode, currentOperator);

        let inputProps: any = {};

        //___ generic filterType def inputProps
        if (filterType?.inputProps) {
            merge(
                inputProps,
                typeof filterType.inputProps === 'function' ? filterType.inputProps(filter) : filterType.inputProps,
            );
        }

        //___ specific filterType def inputProps
        if (filter.inputProps) {
            merge(inputProps, filter.inputProps);
        }

        //___ persisted filter state ( eg: tree 'categories' opened children )
        if (filter?.props && Object.keys(filter.props).length) {
            merge(inputProps, filter.props);
        }

        if (currentOperator?.isRange) {
            let inputPropsTo = inputProps;
            switch (filter.type) {
                default:
                    break;
                case 'date': {
                    inputProps = { ...inputProps };
                    inputPropsTo = { ...inputProps };
                    if (value?.to) {
                        inputProps.maxDate = new Date(value?.to);
                    }
                    if (value?.from) {
                        inputPropsTo.minDate = new Date(value?.from);
                    }
                    break;
                }
                case 'metric':
                case 'price_collection': {
                    inputPropsTo = { ...inputProps };
                    inputPropsTo.visibleUnits = false;
                    break;
                }
            }

            inputComp = (
                <>
                    <div
                        className='filter-range'
                        data-testid='filter-from'
                    >
                        <label>{intl.formatMessage({ defaultMessage: 'From', id: 'Filter.Filter.74cfa9' })}</label>
                        <InputComponent
                            {...inputProps}
                            onChange={(v: any) => {
                                handleChange({ from: v, to: value?.to });
                            }}
                            value={value?.from || null}
                            isFocused={isFocused}
                        />
                    </div>
                    <div
                        className='filter-range'
                        data-testid='filter-to'
                    >
                        <label>{intl.formatMessage({ defaultMessage: 'To', id: 'Filter.Filter.f63de1' })}</label>
                        <InputComponent
                            {...inputPropsTo}
                            onChange={(v: any) => {
                                handleChange({ from: value?.from, to: v });
                            }}
                            value={value?.to || null}
                        />
                    </div>
                </>
            );
        } else {
            {
                currentOperator?.key === 'empty' || currentOperator?.key === 'not_empty'
                    ? (inputComp = <div className='filter-empty'></div>)
                    : (inputComp = (
                          <div className='filter-not-range'>
                              <InputComponent
                                  {...inputProps}
                                  onChange={handleChange}
                                  value={value?.from || value}
                                  isFocused={isFocused}
                              />
                          </div>
                      ));
            }
        }

        let res = (
            <>
                {filterOperators}
                {inputComp}
            </>
        );

        switch (displayMode) {
            default:
                break;
            case 'drawer':
                res = (
                    <Drawer
                        open={expanded}
                        onOpen={handleExpand}
                    >
                        {res}
                    </Drawer>
                );
                break;
        }

        return (
            <div
                className='filter-body'
                data-testid='filter-body'
            >
                {res}
            </div>
        );
    }, [
        filterType,
        locale,
        modeExpand,
        expanded,
        isMobileMode,
        currentOperator,
        filter,
        filterOperators,
        displayMode,
        intl,
        value,
        isFocused,
        handleChange,
        handleExpand,
    ]);

    if (!filterType) {
        return null;
    }

    const headProps: any = {};
    if (displayMode !== 'normal') {
        headProps.onClick = toggleExpand;
    }

    return (
        <Root
            colorBranding={colorBranding}
            className={`filter filter-key-${filterKey} scrollbar-light mode-${displayMode}
                ${expanded ? 'expanded' : ''}`}
            ref={refInput}
            data-testid={`filter-key-${filterKey}`}
        >
            <div
                className='filter-head'
                {...headProps}
                data-testid='filter-head'
            >
                {filter.key === 'categories' ? (
                    <div className='categories-filter'>
                        {valueDisplay}
                        <Button
                            size='small'
                            level='tertiary'
                            ghost
                            data-testid='bt-toggle-display'
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Manage',
                                id: 'Filter.Filter.d00ce5',
                            })}
                        </Button>
                    </div>
                ) : modeDrawer ? (
                    <SwitcherButton
                        label={label}
                        inline={false}
                        className='switcher'
                    >
                        {valueDisplay}
                    </SwitcherButton>
                ) : (
                    <>
                        <div className='filter-label'>{label}</div>
                        {valueDisplay}
                    </>
                )}
                <div className='grow' />
                {modeExpand &&
                    (expanded ? <CheckPartialIcon className='icon-expand' /> : <PlusIcon className='icon-collapse' />)}
            </div>
            {filterBody}
        </Root>
    );
};

export default Filter;
