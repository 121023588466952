import map from 'lodash/map';
import each from 'lodash/each';
import noop from 'lodash/noop';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { RowIcon, SectionTitle, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { useAppDispatch } from 'src/tools/globalStore';
import { actions as filterAction, selectors as filterSelectors } from 'src/components/Filter/store';

import ButtonClose from 'src/shared/button/ButtonClose';
import OrderableItemList from 'src/shared/orderableitemlist';

//__
type Props = {
    filters?: FilterType[];
    onChange?: (filters: FilterType[]) => void;
    title?: string;
    disableRemoveKeys?: BooleanMap;
    disableOrderKeys?: BooleanMap;
    draggable?: boolean;
};

const emptyFilters: FilterType[] = [];

//___________
const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .head {
        h3 {
            flex: 1;
            font-size: 15px;
            font-weight: normal;
            text-transform: uppercase;
            color: ${theme.color.grey140};
            border-bottom: 1px solid ${theme.color.grey140};
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .list-filters {
        flex: 1;
        overflow: auto;
        margin-top: 20px;
    }
`;

const FiltersSelected: React.FC<Props> = (props) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const {
        filters: propsFilters = emptyFilters,
        onChange = noop,
        title = intl.formatMessage({ defaultMessage: 'Active filters', id: 'filtersSelector.FiltersSelected.b63ba7' }),
        disableRemoveKeys,
        disableOrderKeys,
        draggable = true,
    } = props;

    const [filters, setFilters] = useState(propsFilters);
    useEffect(() => {
        setFilters(propsFilters);
    }, [propsFilters]);

    //___ Active filters
    const handleChangeOrder = useCallback(
        (srcIndex: number, targetIndex: number | null) => {
            if (targetIndex === null || targetIndex === srcIndex) {
                return;
            }

            const srcFilter = filters[srcIndex];
            let newFilters = [...filters];
            newFilters.splice(srcIndex, 1);
            newFilters = [...newFilters.slice(0, targetIndex), srcFilter, ...newFilters.slice(targetIndex)];
            onChange(newFilters);
        },
        [onChange, filters],
    );

    //___ selection
    const selectedKeys = useMemo(() => {
        const res: FiltersByKey = {};
        each(filters, (filter) => {
            res[filter.key] = filter;
        });
        return res;
    }, [filters]);

    const handleFilterRemove = useCallback(
        (filter: FilterType) => {
            const keys: FiltersByKey = { ...selectedKeys };
            delete keys[filter.key];
            const res = map(keys, (filter) => filter);
            dispatch(filterAction.resetFilterValueAction(filter));
            setFilters(res);
            onChange(res);
        },
        [selectedKeys, dispatch, onChange],
    );

    const isNotOrderable = useCallback(
        (key: string) => {
            return !draggable || (disableOrderKeys && disableOrderKeys[key]);
        },
        [disableOrderKeys, draggable],
    );

    //___ render
    return (
        <Root
            className='filters-selected'
            data-testid='filters-selected'
        >
            <SectionTitle>
                <SectionTitle.Title>{title}</SectionTitle.Title>
            </SectionTitle>
            <OrderableItemList
                className='list-filters scrollbar-light'
                draggable={draggable}
                onChangeOrder={handleChangeOrder}
            >
                {map(filters, (filter: FilterType) => {
                    const disableRemove: boolean = disableRemoveKeys
                        ? disableRemoveKeys[filter.code || filter.key]
                        : false;

                    return (
                        <FilterItem
                            key={filter.key}
                            filter={filter}
                            onRemove={handleFilterRemove}
                            disableRemove={disableRemove}
                            notOrderable={isNotOrderable(filter.key)}
                        />
                    );
                })}
            </OrderableItemList>
        </Root>
    );
};

export default FiltersSelected;

//___ Item Filter
type ItemProps = {
    filter: FilterType;
    onRemove?: (filter: FilterType) => void;
    disableRemove?: boolean;
    notOrderable?: boolean;
};

const ItemRoot = styled.div`
    height: 34px;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: grab;

    .icon-drag {
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        > svg path {
            stroke: ${theme.color.grey100};
        }
    }

    .label {
        flex: 1 1;
        color: ${theme.color.grey120};
    }

    .bt-close {
        flex: none;
        margin-right: 10px;
        width: 14px;
        height: 14px;
    }

    &.not-orderable {
        cursor: auto;
    }
`;

const FilterItem: React.FC<ItemProps> = (props) => {
    const { filter, onRemove = noop, disableRemove, notOrderable } = props;

    const intl = useIntl();

    const filterLabel = filterSelectors.getFilterLabel(filter);
    const label = useMemo(() => {
        switch (filterLabel) {
            case 'intl-Family':
                return intl.formatMessage({
                    defaultMessage: 'Family',
                    id: 'filtersSelector.FiltersSelected.af30af',
                });
            case 'intl-Type':
                return intl.formatMessage({ defaultMessage: 'Type', id: 'filtersSelector.FiltersSelected.f94ea8' });
            case 'intl-Quantity':
                return intl.formatMessage({
                    defaultMessage: 'Quantity',
                    id: 'filtersSelector.FiltersSelected.a95191',
                });
            case 'intl-Image':
                return intl.formatMessage({
                    defaultMessage: 'Image',
                    id: 'filtersSelector.FiltersSelected.fb4cef',
                });
            case 'intl-Label':
                return intl.formatMessage({
                    defaultMessage: 'Label',
                    id: 'filtersSelector.FiltersSelected.ef9df2',
                });
            case 'intl-Variations':
                return intl.formatMessage({
                    defaultMessage: 'Variations',
                    id: 'filtersSelector.FiltersSelected.e9b5e0',
                });
            default:
                return filterLabel;
        }
    }, [filterLabel, intl]);

    const handleRemove = useCallback(() => {
        onRemove(filter);
    }, [onRemove, filter]);

    if (!filter || filter.type == 'other') {
        return null;
    }

    return (
        <ItemRoot
            className={`filter-item ${notOrderable ? ' not-orderable' : ''}`}
            role='listitem'
            aria-label={label}
        >
            {!notOrderable && (
                <div className='icon-drag'>
                    <RowIcon size={16} />
                </div>
            )}
            <span className='label'>{label}</span>
            {!disableRemove && (
                <ButtonClose
                    title='Remove filter'
                    onClick={handleRemove}
                />
            )}
        </ItemRoot>
    );
};
