import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { sharedCatalogsTheme as theme, Badge, Checkbox } from 'akeneo-design-system';
import { useSelector } from 'react-redux';

import { selectors as brandingSelectors } from 'src/shared/branding/store';

import { Image } from 'src/shared';
import { useIntl } from 'react-intl';

type MediaProps = {
    media: Media;
    onClick?: (media: Media, event: React.MouseEvent) => void;
    isSelected: boolean;
    onSelect?: (isSelected: boolean, media: Media) => void;
};

const Root = styled.div<{ colorBranding: string }>`
    display: flex;
    flex-direction: column;
    color: ${theme.color.grey120};
    overflow: hidden;
    width: 200px;
    cursor: pointer;

    .img-box {
        border: solid 1px ${theme.color.grey100};
        max-height: 200px;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge-container {
            position: absolute;
            top: 10px;
            right: 0px;

            .badge {
                margin-right: 10px;
                color: ${({ colorBranding }): string => colorBranding};
                border: 1px solid ${({ colorBranding }): string => colorBranding};
            }
        }

        &.selected {
            border: 2px solid ${theme.color.blue100};
        }
    }

    .media-infos {
        display: flex;
        align-items: center;

        .media-name {
            max-width: 170px;
            overflow: hidden;
            font-size: 13px;
            text-overflow: ellipsis;
        }
    }

    .download {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .media-infos {
            display: none;
        }
    }
`;

const Media: React.FC<MediaProps> = (props) => {
    const { media, onClick, isSelected: propsIsSelected = false, onSelect } = props;
    const { thumbnail, name, format, download } = media;

    const [isSelected, setSelected] = useState(propsIsSelected);
    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const intl = useIntl();

    const handleClick = useCallback(
        (event: React.MouseEvent) => {
            if (!onClick) {
                return;
            }
            onClick(media, event);
        },
        [onClick, media],
    );

    useEffect(() => {
        setSelected(propsIsSelected);
    }, [propsIsSelected]);

    const handleSelect = useCallback(
        (checked: boolean) => {
            if (!media || !onSelect) {
                return;
            }
            setSelected(checked);
            onSelect(checked, media);
        },
        [media, onSelect],
    );

    return (
        <Root
            colorBranding={colorBranding}
            className='product-media'
        >
            <div
                onClick={handleClick}
                className={isSelected ? 'img-box selected' : 'img-box'}
            >
                <Image
                    src={thumbnail}
                    title={name}
                />
                <div className='badge-container'>
                    {!media.download && (
                        <Badge className='badge'>
                            {intl.formatMessage({ defaultMessage: 'Link', id: 'layouts.Media.241592' })}
                        </Badge>
                    )}
                    <Badge className='badge'>{format || 'other'}</Badge>
                </div>
            </div>

            <Checkbox
                className='media-infos checkbox'
                checked={isSelected}
                onChange={handleSelect}
                readOnly={!download}
            >
                <p className={`media-name ${download ? 'download' : ''}`}>{name}</p>
            </Checkbox>
        </Root>
    );
};

export default Media;
