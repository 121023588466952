import map from 'lodash/map';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, FileXlsxIcon, Helper, Tiles, Tile, sharedCatalogsTheme as theme } from 'akeneo-design-system';

import { selectors as selectionSelectors } from 'src/components/ProductGrid/store/productSelection';
import { selectors as localesSelectors } from 'src/shared/locale/store/CatalogLocales';
import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import { actions as filterActions } from 'src/components/Filter/store';
import { actions as productExportActions, selectors as productExportSelectors } from 'src/components/Export/store';

import { useAppDispatch } from 'src/tools/globalStore';
import FiltersSelector from 'src/components/Filter/components/filtersSelector';
import arrToBoolMap from 'src/tools/arrToBoolMap';
import FilePdfIcon from 'src/media/FilePdfIcon.svg';

import ExportProductPdf from './ExportProductPdf';
import ExportProductXlsx from './ExportProductXlsx';

import { Modal } from 'src/shared';
import { InputLocale } from 'src/shared/input';

const MAX_EXPORT_PDF = 200;

const Root = styled(Modal)<{ colorBranding: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
    padding-top: 55px;
    padding-bottom: 55px;

    > .body {
        flex: 1 1;
        overflow: auto;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        > h2 {
            text-align: center;
            text-transform: uppercase;
            color: ${({ colorBranding }): string => colorBranding};
            font-size: 16px;
            font-weight: normal;
            margin: 0 0 10px 0;
        }

        > h3 {
            text-align: center;
            font-size: 38px;
            font-weight: normal;
            margin: 0 0 15px 0;
        }

        > p {
            font-size: 15px;
            text-align: center;
            margin-top: 0;
            margin-bottom: 10px;
        }

        > .desc {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            > div {
                align-items: center;
            }

            > .head-helper {
                margin-top: 27px;
            }
        }

        .inputs {
            align-self: center;
            min-width: 400px;
            .row {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                margin-bottom: 20px;

                > * {
                    flex: 1;
                }

                > .inline-helper {
                    max-width: 300px;
                    margin: auto;
                }
            }
        }

        .bt-square {
            height: 40px;
            border-radius: 0;
            text-transform: none;
            display: inline-block;

            ::first-letter {
                text-transform: uppercase;
            }
        }

        .bt-square,
        .bt-extension {
            &.select {
                color: ${theme.color.blue100};
                background-color: ${theme.color.blue20};
                border: 1px solid ${theme.color.blue100};
            }
        }

        > .foot {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 20px;
            margin-top: 20px;
        }
    }

    h4 {
        text-align: center;
        margin: 30px 0 15px 0;
    }
    .filters-selected,
    .filters-search {
        width: 300px;
        max-width: 100%;
    }

    .filters-selected {
        > .head {
            h3 {
                color: ${theme.color.grey100};
                font-size: 11px;
                border-bottom: none;
            }
        }
    }

    .filters-search {
        border-left: 1px solid ${theme.color.grey80};
    }

    /*__ dirty hack to force hide select clear button : to be moved inside input locale */
    .input-locale > div:first-child > div:first-child > div:last-child > button:first-child {
        display: none;
    }

    @media (max-width: 768px) {
        padding: 40px;
    }
`;

const StyledFilePdfIcon = styled.img`
    width: 54px;
`;

const excludeKeys: BooleanMap = {
    families: true,
    image: true,
    label: true,
    variationAxes: true,
};

const ExportProduct: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    //__ modal display
    const display = useSelector(productExportSelectors.display);
    const handleModalDisplay = useCallback(
        (display: boolean) => {
            dispatch(productExportActions.display(display));
            setDisplayAttrSelection(false);
        },
        [dispatch],
    );

    const exportOptions = useSelector(productExportSelectors.exportOptions);

    const handleSetExportOption = useCallback(
        (optionKey: string, value: string | boolean | { code: string; type: string }[]) => {
            if (optionKey === 'format' && value === 'PDF') {
                dispatch(productExportActions.setExportOption('withMedia', true));
                dispatch(productExportActions.setExportOption('withReferenceEntityMedia', true));
            }
            dispatch(productExportActions.setExportOption(optionKey, value));
        },
        [dispatch],
    );

    const locales = useSelector(localesSelectors.getCatalogLocales);
    const catalogLocale = useSelector(contextLocaleSelectors.getContextLocale);

    const [locale, setLocale] = useState(catalogLocale);

    useEffect(() => {
        setLocale(catalogLocale);
    }, [catalogLocale]);

    //___
    const forceSelectedAttributes = useSelector(productExportSelectors.forceSelectedAttributes);
    useEffect(() => {
        if (!catalogLocale || forceSelectedAttributes) {
            return;
        }

        void (async (): Promise<void> => {
            const uniqueIdentifierAttribute = await dispatch(
                filterActions.fetchUniqueIdentifierAttribute(catalogLocale),
            );
            dispatch({
                type: 'EXPORT_SET_FORCED_ATTRIBUTES',
                payload: [{ code: uniqueIdentifierAttribute.code, type: uniqueIdentifierAttribute.type }],
            });
        })();
    }, [catalogLocale, forceSelectedAttributes, dispatch]);

    //___
    const selectedCount = useSelector(selectionSelectors.totalCount);
    const bold = useCallback((string: React.ReactNode[]) => {
        return <b>{string}</b>;
    }, []);

    //__ selected filters
    const [displayAttrSelection, setDisplayAttrSelection] = useState(false);
    const handleDisplayAttributes = useCallback(() => {
        setDisplayAttrSelection(true);
    }, []);

    const [selectedAttributes, setSelectedAttributes] = useState<FilterType[]>([]);

    const unmount = useRef(false);
    useEffect(() => {
        return (): void => {
            unmount.current = true;
        };
    }, []);

    useEffect(() => {
        if (!displayAttrSelection) {
            return;
        }
        void (async (): Promise<void> => {
            if (!locale || !forceSelectedAttributes) {
                return;
            }
            const attributes: FilterType[] = await dispatch(
                productExportActions.getSelectedAttributes({
                    locale,
                    forceSelectedAttributes,
                }),
            );
            if (unmount.current) {
                return;
            }
            handleSetExportOption(
                'selectedAttributes',
                map(attributes, (attr) => {
                    return {
                        code: attr.code || attr.key,
                        type: attr.type,
                    };
                }),
            );
            setSelectedAttributes(attributes);
        })();
    }, [displayAttrSelection, dispatch, locale, forceSelectedAttributes, handleSetExportOption]);

    const handleChange = useCallback(
        (attributes: FilterType[]) => {
            handleSetExportOption(
                'selectedAttributes',
                map(attributes, (attr) => {
                    return {
                        code: attr.code || attr.key,
                        type: attr.type,
                    };
                }),
            );
            setSelectedAttributes(attributes);
        },
        [handleSetExportOption],
    );

    //__ export
    const searchContexts = useSelector(selectionSelectors.searchContexts);
    const canExport = useSelector(productExportSelectors.canExport);
    const handleExport = useCallback(async () => {
        if (!canExport || !locale) {
            return;
        }
        handleModalDisplay(false);
        const returnType = await dispatch(productExportActions.exportProducts(locale, searchContexts));
        if (returnType === 'success') {
            dispatch({
                type: 'NOTIFICATION_ADD',
                payload: {
                    title: intl.formatMessage({
                        defaultMessage: 'Your export has been launched',
                        id: 'components.ExportProduct.1a0d5c',
                    }),
                    description: intl.formatMessage({
                        defaultMessage: "You will receive an email once it's ready to be downloaded.",
                        id: 'components.ExportProduct.a07f15',
                    }),
                    level: 'success',
                },
            });
        } else if (returnType === 'exportError') {
            dispatch({
                type: 'NOTIFICATION_ADD',
                payload: {
                    title: intl.formatMessage({
                        defaultMessage: 'An error occurred during the export',
                        id: 'components.ExportProduct.ca6bc9',
                    }),
                    level: 'error',
                },
            });
        }
    }, [handleModalDisplay, dispatch, canExport, locale, searchContexts, intl]);

    return (
        <Root
            colorBranding={colorBranding}
            display={display}
            className='modal-export'
            onDisplay={handleModalDisplay}
        >
            <div className='body'>
                <h2 data-testid='products-export-title'>
                    {intl.formatMessage({ defaultMessage: 'Export', id: 'components.ExportProduct.495c09' })}
                </h2>
                <h3>
                    {intl.formatMessage({
                        defaultMessage: 'Personalize your export',
                        id: 'components.ExportProduct.ec06cf',
                    })}
                </h3>
                <div className='desc'>
                    <p>
                        {intl.formatMessage(
                            {
                                defaultMessage:
                                    'You are about to export <bold>{count, plural, one {# product} other {# products} }</bold>.',
                                id: 'components.ExportProduct.5796d9',
                            },
                            { count: selectedCount, bold },
                        )}
                    </p>
                    {selectedCount > MAX_EXPORT_PDF && exportOptions.format === 'PDF' && (
                        <Helper level='error'>
                            {intl.formatMessage(
                                {
                                    defaultMessage: 'PDF exports are limited to {max} products at once.',
                                    id: 'components.ExportProduct.595510',
                                },
                                { max: MAX_EXPORT_PDF },
                            )}{' '}
                            <br />
                            {intl.formatMessage(
                                {
                                    defaultMessage:
                                        'To export more products, prepare several export product selections or choose XLSX.',
                                    id: 'components.ExportProduct.7d0d1d',
                                },
                                { max: MAX_EXPORT_PDF },
                            )}
                        </Helper>
                    )}
                    {displayAttrSelection && (
                        <div className='head-helper'>
                            {exportOptions.format === 'PDF' ? (
                                <Helper level='warning'>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            'The order of the attributes in the PDF export will be identical to the order on the product page.',
                                        id: 'components.ExportProduct.a2f42c',
                                    })}
                                </Helper>
                            ) : (
                                <Helper level='info'>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            'The order of the attributes in the XLSX export can be defined by reordering the list of selected attributes.',
                                        id: 'components.ExportProduct.431ef9',
                                    })}
                                </Helper>
                            )}
                        </div>
                    )}
                </div>
                {displayAttrSelection ? (
                    <FiltersSelector
                        locale={locale}
                        selectedFilters={selectedAttributes}
                        onChange={handleChange}
                        selectedTitle={intl.formatMessage({
                            defaultMessage: 'Selected attributes',
                            id: 'components.ExportProduct.9aa64d',
                        })}
                        searchLabel={intl.formatMessage({
                            defaultMessage: 'Search attributes to display',
                            id: 'components.ExportProduct.0b8250',
                        })}
                        searchContexts={searchContexts}
                        excludeKeys={excludeKeys}
                        disableKeys={arrToBoolMap(forceSelectedAttributes)}
                        withTableAttributes={true}
                        draggable={exportOptions.format !== 'PDF'}
                    />
                ) : (
                    <div className='inputs'>
                        <h4>
                            {intl.formatMessage({
                                defaultMessage: 'General settings',
                                id: 'components.ExportProduct.1a3ae5',
                            })}
                        </h4>
                        <div className='row'>
                            <Tiles>
                                <Tile
                                    data-testid='bt-select-pdf'
                                    className={`bt-extension ${exportOptions.format === 'PDF' ? 'select' : ''}`}
                                    onClick={(): void => handleSetExportOption('format', 'PDF')}
                                    icon={
                                        <StyledFilePdfIcon
                                            src={FilePdfIcon}
                                            className='icon'
                                        />
                                    }
                                >
                                    PDF
                                </Tile>
                                <Tile
                                    data-testid='bt-select-xlsx'
                                    className={`bt-extension ${exportOptions.format === 'XLSX' ? 'select' : ''}`}
                                    onClick={(): void => handleSetExportOption('format', 'XLSX')}
                                    icon={
                                        <FileXlsxIcon
                                            size={54}
                                            color={theme.color.grey100}
                                            className='icon'
                                        />
                                    }
                                >
                                    XLSX
                                </Tile>
                            </Tiles>
                        </div>
                        <div className='row'>
                            <InputLocale
                                choices={locales}
                                onChange={setLocale}
                                value={locale}
                            />
                        </div>

                        <h4>
                            {intl.formatMessage({
                                defaultMessage: 'Document content',
                                id: 'components.ExportProduct.af100e',
                            })}
                        </h4>
                        <div>{exportOptions.format === 'PDF' ? <ExportProductPdf /> : <ExportProductXlsx />}</div>
                    </div>
                )}
                <div className='foot'>
                    {displayAttrSelection ? (
                        <Button
                            level='tertiary'
                            onClick={(): void => setDisplayAttrSelection(false)}
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Previous',
                                id: 'components.ExportProduct.24935c',
                            })}
                        </Button>
                    ) : (
                        <Button
                            level='tertiary'
                            onClick={(): void => handleModalDisplay(false)}
                        >
                            {intl.formatMessage({ defaultMessage: 'Cancel', id: 'components.ExportProduct.e3b158' })}
                        </Button>
                    )}
                    <Button
                        disabled={exportOptions.format === 'PDF' && selectedCount > MAX_EXPORT_PDF}
                        level='primary'
                        onClick={
                            exportOptions.allAttributes || displayAttrSelection ? handleExport : handleDisplayAttributes
                        }
                        data-testid='bt-next-action'
                    >
                        {exportOptions.allAttributes || displayAttrSelection
                            ? intl.formatMessage({ defaultMessage: 'Export', id: 'components.ExportProduct.495c09' })
                            : intl.formatMessage({
                                  defaultMessage: 'Select attributes',
                                  id: 'components.ExportProduct.a469b2',
                              })}
                    </Button>
                </div>
            </div>
        </Root>
    );
};

export default ExportProduct;
