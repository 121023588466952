import { useCallback, useEffect } from 'react';

enum Key {
    Space = ' ',
    Enter = 'Enter',
    NumpadEnter = 'NumpadEnter',
    Backspace = 'Backspace',
    Escape = 'Escape',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
}

const useShortcut = (key: Key, callback: Function): void => {
    const memoizedCallback = useCallback(
        (event: KeyboardEvent) => (key === event.code ? callback(event) : null),
        [key, callback],
    );

    useEffect(() => {
        document.addEventListener('keydown', memoizedCallback);
        return () => document.removeEventListener('keydown', memoizedCallback);
    }, [memoizedCallback]);
};

export { useShortcut, Key };
