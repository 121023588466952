import React, { ReactNode, useCallback, useState, useEffect } from 'react';
import noop from 'lodash/noop';
import styled from 'styled-components';
import { Modal as DsmModal } from 'akeneo-design-system';

type Props = {
    display?: boolean;
    onDisplay?: (isOpen: boolean) => void;
    className?: string;
    children?: ReactNode;
};

const Root = styled(DsmModal)`
    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const Modal: React.FC<Props> = (props: Props) => {
    const { display: propsDisplay = false, onDisplay = noop, className = '' } = props;

    const [display, setDisplay] = useState(propsDisplay);
    useEffect(() => {
        setDisplay(propsDisplay);
    }, [propsDisplay]);

    const handleClose = useCallback(() => {
        setDisplay(false);
        onDisplay(false);
    }, [onDisplay]);

    if (!display) {
        return null;
    }

    return (
        <Root
            illustration={undefined}
            closeTitle='close'
            onClose={handleClose}
            className={`modal ${className}`}
        >
            {props.children}
        </Root>
    );
};

export default Modal;
