import noop from 'lodash/noop';
import styled from 'styled-components';
import { Dropdown as DsmDropdown } from 'akeneo-design-system';
import React, { ReactNode, useCallback, Ref, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useViewportSize from 'src/hooks/useViewportSize';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

//____
type Props = {
    openTrigger: React.ReactChild;
    title?: ReactNode;
    children: ReactNode;
    closeOnClickDropdown?: boolean;
    onOpen?: (isOpen: boolean) => void;
    className?: string;
    verticalPosition?: 'up' | 'down';
    horizontalPosition?: 'left' | 'right';
    isOpen?: boolean;
    //__ byd default, overlay max height might be reduced to fit in viewport, set to false when in scrollable parent
    overlayAutoMaxHeight?: boolean;
};

const Root = styled.div`
    & > div {
        display: flex;
    }
`;

const DisplayTrigger = styled.div`
    cursor: pointer;
    display: flex;
    flex: 1 1;
`;

const DropdownOverlay = styled.div<{ colorBranding: string }>`
    display: flex;
    flex-direction: column;
    .dropdown-body {
        flex: 1 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    & > div {
        border-bottom: none;
    }

    .dropdown-title {
        border-bottom: 1px solid ${({ colorBranding }): string => colorBranding};
        color: ${({ colorBranding }): string => colorBranding};
    }
`;

const Dropdown = (props: Props) => {
    const {
        openTrigger,
        title,
        children,
        onOpen = noop,
        closeOnClickDropdown = true,
        className = '',
        verticalPosition,
        horizontalPosition,
        isOpen: propsIsOpen = false,
        overlayAutoMaxHeight = true,
    } = props;

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    // const [ isOpen, open, close ] = useBooleanState( propsIsOpen );
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setOpen(propsIsOpen);
    }, [propsIsOpen]);

    const handleSetOpen = useCallback(
        (isOpen: boolean) => {
            setOpen(isOpen);
            onOpen(isOpen);
        },
        [onOpen],
    );

    const handleClose = useCallback(() => {
        handleSetOpen(false);
    }, [handleSetOpen]);

    const handleClickDropdown = useCallback(() => {
        if (closeOnClickDropdown) {
            handleClose();
        }
    }, [handleClose, closeOnClickDropdown]);

    const { height: viewportHeight } = useViewportSize();
    const [overlayHeightMax, setOverlayHeightMax] = useState(0);
    const refBtTrigger = useCallback(
        (el: HTMLDivElement | null) => {
            if (!el || !viewportHeight || !overlayAutoMaxHeight) return;
            setOverlayHeightMax(viewportHeight - el.getBoundingClientRect().bottom - 30);
        },
        [viewportHeight, overlayAutoMaxHeight],
    );

    const refOverlay = useCallback(
        (el: HTMLDivElement | null) => {
            if (!el || !overlayHeightMax) {
                return;
            }
            el.style.maxHeight = `${overlayHeightMax}px`;
        },
        [overlayHeightMax],
    );

    return (
        <Root className={`dropwdown ${className}`}>
            <DisplayTrigger
                className='dropdown-trigger'
                data-testid='dropdown-trigger'
                onClick={(): void => handleSetOpen(true)}
                ref={refBtTrigger}
            >
                {openTrigger}
            </DisplayTrigger>
            <DsmDropdown>
                {isOpen && (
                    <DsmDropdown.Overlay
                        verticalPosition={verticalPosition}
                        horizontalPosition={horizontalPosition}
                        onClose={handleClose}
                        className={`overlay ${className}`}
                    >
                        <DropdownOverlay
                            colorBranding={colorBranding}
                            className='dropdown-overlay'
                            ref={refOverlay}
                        >
                            {title && (
                                <DsmDropdown.Header>
                                    <DsmDropdown.Title>
                                        <div className='dropdown-title'>{title}</div>
                                    </DsmDropdown.Title>
                                </DsmDropdown.Header>
                            )}
                            <div
                                className='dropdown-body'
                                onClick={handleClickDropdown}
                            >
                                {children}
                            </div>
                        </DropdownOverlay>
                    </DsmDropdown.Overlay>
                )}
            </DsmDropdown>
        </Root>
    );
};

export default Dropdown;

//__ ItemCollection
type ItemCollectionProps = Override<
    React.HTMLAttributes<HTMLDivElement>,
    {
        children: ReactNode;
    }
>;

const RootItemCollection = styled(DsmDropdown.ItemCollection)``;

Dropdown.ItemCollection = React.forwardRef<HTMLDivElement, ItemCollectionProps>(
    ({ children, className = '', ...rest }: ItemProps, forwardedRef: Ref<HTMLDivElement>): React.ReactElement => {
        return (
            <RootItemCollection
                className={`dropdown-list ${className}`}
                role='listbox'
                ref={forwardedRef}
                {...rest}
            >
                {children}
            </RootItemCollection>
        );
    },
);

//__ Item
type ItemProps = Override<
    React.HTMLAttributes<HTMLDivElement>,
    {
        disabled?: boolean;
        isActive?: boolean;
        children: ReactNode;
    }
>;

const RootItem = styled(DsmDropdown.Item)<{ colorBranding: string }>`
    padding: 0 20px;
    margin: 0;

    &.active {
        color: ${({ colorBranding }): string => colorBranding};
    }
`;

Dropdown.Item = React.forwardRef<HTMLDivElement, ItemProps>(
    (
        { children, isActive, className = '', ...rest }: ItemProps,
        forwardedRef: Ref<HTMLDivElement>,
    ): React.ReactElement => {
        const colorBranding = useSelector(brandingSelectors.getColorBranding);

        return (
            <RootItem
                colorBranding={colorBranding}
                className={`dropdown-item ${className} ${isActive ? 'active' : ''}`}
                isActive={isActive}
                role='listitem'
                ref={forwardedRef}
                {...rest}
            >
                {children}
            </RootItem>
        );
    },
);
