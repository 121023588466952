import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system/lib/theme/shared-catalogs';
import Branding from '../../shared/branding/BrandingLogo';
import logoAkeneo from '../../shared/branding/media/logo-akeneo.svg';

const Root = styled.div<{ coverUrl: string }>`
    color: ${theme.color.grey120};
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 5fr 5fr;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    .right-wrapper {
        display: grid;
        grid-template-rows: 1fr max-content;
        padding: 50px;

        .form-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 100px;

            .logo {
                margin-bottom: 40px;
                display: flex;
                justify-content: center;

                img {
                    max-width: 250px;
                    max-height: 60px;
                }
            }
        }

        .powered {
            display: flex;
            justify-content: center;
            padding-bottom: 0.5em;
            font-size: 13px;
            align-items: flex-end;
            color: ${theme.color.grey140};

            img {
                max-height: 20px;
                margin-left: 0.5em;
            }
        }

        .baseline {
            color: ${theme.color.purple100};
            text-align: center;
        }
    }

    .left-wrapper {
        background: ${theme.color.grey20};

        @media (max-width: 768px) {
            display: none;
        }

        .illustration-login {
            background-image: url(${({ coverUrl }): string => coverUrl});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;
        }
    }
`;

const StyledErrorDiv = styled.div`
    line-height: 22px;
`;

const SsoErrorPage: React.FC = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const errorCode = queryParameters.get('errorCode') ?? '';

    const intl = useIntl();

    const getError = (): string => {
        switch (errorCode) {
            case 'the connection is not enabled':
                return intl.formatMessage({
                    defaultMessage: 'The connection is not enabled, please contact Akeneo support.',
                    id: 'Sso.SsoErrorPage.13bdde',
                });
            case 'User is not assigned to the client application.':
                return intl.formatMessage({
                    defaultMessage:
                        'User is not assigned to the client application, please contact your Administrator.',
                    id: 'Sso.SsoErrorPage.9182fe',
                });
            case 'unknown error occurred':
            default:
                return intl.formatMessage({
                    defaultMessage:
                        "We're not able to provide you the content of this catalog, please contact Akeneo support.",
                    id: 'Sso.SsoErrorPage.3fc984',
                });
        }
    };

    return (
        <Root coverUrl={`${process.env.REACT_APP_API_WEB_PATH}/sso/error/branding/cover`}>
            <div className='right-wrapper'>
                <div className='form-wrapper'>
                    <div className='logo'>
                        <Branding />
                    </div>
                    <StyledErrorDiv>{getError()}</StyledErrorDiv>
                </div>
                <a href='https://www.akeneo.com/'>
                    <div className='powered'>
                        {intl.formatMessage({
                            defaultMessage: 'Powered by',
                            id: 'Sso.SsoErrorPage.53c401',
                        })}
                        <img
                            src={logoAkeneo}
                            alt='logo Akeneo'
                        />
                    </div>
                    <div className='baseline'>
                        {intl.formatMessage({
                            defaultMessage: 'Unlocking Growth Through Product Experiences',
                            id: 'Sso.SsoErrorPage.95a872',
                        })}
                    </div>
                </a>
            </div>
            <div className='left-wrapper'>
                <div className='illustration-login' />
            </div>
        </Root>
    );
};

export default SsoErrorPage;
