import noop from 'lodash/noop';
import find from 'lodash/find';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useCallback, useState, useEffect } from 'react';

import { useAppDispatch } from 'src/tools/globalStore';
import { selectors as localesSelectors } from 'src/shared/locale/store/CatalogLocales';
import {
    selectors as contextLocaleSelectors,
    actions as contextLocaleActions,
} from 'src/shared/locale/store/ContextLocale';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import ButtonClose from 'src/shared/button/ButtonClose';

type Props = {
    open?: boolean;
    onClose?: () => void;
};

//__ Item
type ItemProps = {
    locale: CatalogLocale;
    onSelect?: (locale: CatalogLocale) => void;
    isActive?: boolean;
};

const ItemRoot = styled.div<{ colorBranding: string }>`
    display: flex;
    align-items: center;
    line-height: 25px;
    margin-top: 50px;
    justify-content: center;
    color: ${theme.color.grey140};
    font-size: 18px;
    cursor: pointer;

    &.active {
        color: ${({ colorBranding }): string => colorBranding};
    }
`;

const ItemLocale: React.FC<ItemProps> = (props: ItemProps) => {
    const { locale, onSelect = null, isActive = false } = props;

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const handleClick = useCallback(() => {
        if (!onSelect) {
            return;
        }
        onSelect(locale);
    }, [onSelect, locale]);

    if (!locale) {
        return null;
    }

    return (
        <ItemRoot
            colorBranding={colorBranding}
            className={`item-locale ${isActive ? 'active' : ''}`}
            onClick={handleClick}
        >
            {locale.label}
        </ItemRoot>
    );
};

//__ Modal
const Root = styled.div`
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${theme.color.white};
    overflow-y: auto;
    padding: 25px;

    .bt-close {
        position: absolute;
        left: 25px;
        top: 25px;
    }

    > .head {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            margin: 0;
            color: ${theme.color.grey120};
            font-size: 18px;
        }
    }

    .list-locales {
        justify-self: center;
    }
`;

const MobileSelectContextLocale: React.FC<Props> = (props) => {
    const { open: propsOpen = false, onClose = noop } = props;

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const [open, setOpen] = useState(propsOpen);
    const handleOpen = useCallback(
        (event: React.SyntheticEvent) => {
            setOpen(true);
            onClose();
        },
        [onClose],
    );

    const handleClose = useCallback((event?: React.MouseEvent) => {
        setOpen(false);
    }, []);

    const locales: CatalogLocale[] = useSelector(localesSelectors.getCatalogLocales);
    const currentLocale = useSelector(contextLocaleSelectors.getContextLocale);
    const currentLocaleObj: CatalogLocale | undefined = currentLocale
        ? find(locales, { code: currentLocale })
        : undefined;

    const handleChangeLocale = useCallback(
        (locale: CatalogLocale) => {
            dispatch(contextLocaleActions.selectContextLocalesAction(locale.code));
            handleClose();
        },
        [dispatch, handleClose],
    );

    useEffect(() => {
        if (currentLocaleObj || !locales?.length) {
            return;
        }
        handleChangeLocale(locales[0]);
    }, [locales, currentLocaleObj, handleChangeLocale]);

    return (
        <>
            <Button
                onClick={handleOpen}
                level='tertiary'
                ghost
                className='bt-locale-open'
            >
                {currentLocaleObj?.label || '--'}
            </Button>
            {open ? (
                <Root className='modal-locale'>
                    <div className='head'>
                        <ButtonClose onClick={handleClose} />
                        <div className='title'>
                            {intl.formatMessage({
                                defaultMessage: 'Select a locale',
                                id: 'locale.MobileSelectContextLocale.c6fcfa',
                            })}
                        </div>
                    </div>
                    <div className='list-locales'>
                        {locales.map((locale) => (
                            <ItemLocale
                                key={locale.code}
                                locale={locale}
                                onSelect={handleChangeLocale}
                                isActive={locale.code === currentLocale}
                            />
                        ))}
                    </div>
                </Root>
            ) : null}
        </>
    );
};

export default MobileSelectContextLocale;
