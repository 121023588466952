import each from 'lodash/each';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';

import httpClient from 'src/tools/HttpClient';
import arrToBoolMap from 'src/tools/arrToBoolMap';
import staticFilters from './staticFilters';
import { ThunkResult } from 'src/tools/globalStore';
import pagedFetch, { PagedFetch } from 'src/hooks/pagedFetch';

const resetFilterValueAction = (filter: FilterType): ResetFilterValueAction => ({
    type: 'RESET_FILTER_VALUE',
    payload: filter,
});

const setFilterValueAction =
    (filter: FilterType, value?: any, operator?: string | null): ThunkResult<void> =>
    (dispatch): void => {
        dispatch({ type: 'SET_FILTER_VALUE', filter, value, operator });
    };

const resetFiltersValuesAction = (): ResetFiltersValuesAction => ({ type: 'RESET_FILTERS_VALUES' });

const setFilterPropsAction = (filterKey: string, props: any): SetFilterPropsAction => ({
    type: 'SET_FILTER_PROPS',
    payload: { filterKey, props },
});

//__ Filters manager
const setFiltersActiveAction = (payload: FilterType[]): SetFiltersActiveAction => ({
    type: 'SET_FILTERS_ACTIVE',
    payload,
});

const setFiltersSearchAction = (payload: string): SetFiltersSearchAction => ({
    type: 'SET_FILTERS_SEARCH',
    payload,
});

//__ Categories actions
type CategoryChildrenLoad = { [code: string]: Category[] | Promise<Category[]> };
const CACHE_CATEGORY_CHILDREN = false;
const categoryChildrenByCode: CategoryChildrenLoad = {};
let categoryLocale: string | null = null;

const fetchCategoryChildrenAction =
    (localeCode: string, parent: string | null = null): ThunkResult<Promise<Category[]>> =>
    async (): Promise<Category[]> => {
        if (!localeCode) {
            return [];
        }

        const key = parent || 'root';
        if (categoryChildrenByCode[key] && localeCode === categoryLocale) {
            //__ prevent double load
            // if ( categoryChildrenByCode[key] instanceof Promise ) {
            //   return categoryChildrenByCode[key];
            // }
            //__ return cache if set
            return categoryChildrenByCode[key];
        }

        categoryLocale = localeCode;

        const qs = new URLSearchParams();
        parent && qs.set('parent', parent);

        categoryChildrenByCode[key] = httpClient.get(`/categories/${localeCode}?${qs.toString()}`).then((response) => {
            const res = response.data || [];
            if (CACHE_CATEGORY_CHILDREN) {
                categoryChildrenByCode[key] = res;
            } else {
                delete categoryChildrenByCode[key];
            }
            return res;
        });

        return categoryChildrenByCode[key];
    };

const fetchCategoryAction =
    (localeCode: string, categoryCode: string): ThunkResult<Promise<Category>> =>
    async (): Promise<Category> => {
        const { data }: { data: Category } = await httpClient.post(`/category/${localeCode}`, {
            categoryCode: categoryCode,
        });

        return data;
    };

const searchCategoriesAction =
    (localeCode: string, searchValue = ''): ThunkResult<Promise<Category[]>> =>
    async (): Promise<Category[]> => {
        if (!searchValue.length || !localeCode) {
            return [];
        }
        const qs = new URLSearchParams();
        qs.set('search', searchValue);
        return httpClient
            .get(`/categories_search/${localeCode}?${qs.toString()}`)
            .then((response) => response.data || []);
    };

//____ get unique identifier
let promiseUniqueIdentifierAttribute: null | Promise<IdentifierAttribute> = null;
let localeUniqueIdentifierAttribute: null | string;

const fetchUniqueIdentifierAttribute =
    (localeCode: string): ThunkResult<Promise<IdentifierAttribute>> =>
    async (dispatch): Promise<IdentifierAttribute> => {
        if (promiseUniqueIdentifierAttribute && localeCode === localeUniqueIdentifierAttribute) {
            return promiseUniqueIdentifierAttribute;
        }

        localeUniqueIdentifierAttribute = localeCode;

        promiseUniqueIdentifierAttribute = (async (): Promise<IdentifierAttribute> => {
            const { data }: { data: { code: string; type: string; label: string } } = await httpClient.get(
                `/unique-identifier-attribute/${localeCode}`,
            );
            if (!data.code) {
                console.error(new Error('Invalid unique identifier attribute !'), data);
            }
            const uniqueIdentifierAttribute: IdentifierAttribute = {
                ...data,
                key: `attributes.${data.code}`,
            };

            dispatch({ type: 'SET_UNIQUE_IDENTIFIER_ATTRIBUTE', payload: uniqueIdentifierAttribute });

            return uniqueIdentifierAttribute;
        })();

        return promiseUniqueIdentifierAttribute;
    };

//____ Filters actions
export type PagedFilters = PagedFetch<FilterType, AttributeFiltersResponse>;

const pagedFilters =
    (query: GetFiltersQuery): ThunkResult<PagedFilters> =>
    (): PagedFilters => {
        const {
            locale,
            searchString,
            codes,
            excludeKeys,
            searchContexts,
            fetchAll,
            filterAvailableTypes = false,
            withTableAttributes = false,
            columnType = 'gallery',
            variationMode,
            identifiersAndParentCodes,
            onFetchPage,
            onPageFetched,
            usePresetAttributeFilters = false,
        } = query;

        //__ static filters ( provided by frontend )
        const initialRecords: FilterType[] = [];
        const codesByKeys: null | BooleanMap = codes?.length ? arrToBoolMap(codes) : null;
        const searchRegexp: null | RegExp = searchString?.length ? new RegExp(`${searchString}`, 'i') : null;
        each(staticFilters, (f) => {
            if (excludeKeys && excludeKeys[f.key]) {
                return;
            }
            if (columnType !== 'associations' && f.isAssociationType) {
                return;
            }
            if (f.key === 'variationNumber' && (columnType === 'variations' || variationMode === 'ungrouped')) {
                return;
            }
            if (columnType !== 'variations' && f.key === 'variationAxes') {
                return;
            }
            if (codesByKeys && !codesByKeys[f.code || f.key]) {
                return;
            }
            if (searchRegexp && !searchRegexp.test(f.label)) {
                return;
            }
            initialRecords.push(f);
        });

        //__ backend filters
        const payload: FetchAttributeFiltersPayload = {
            usePresetAttributeFilters: usePresetAttributeFilters,
        };
        if (searchString?.length) {
            payload.searchString = searchString;
        }
        if (codes?.length) {
            payload.attributeCodes = codes;
        }
        if (
            identifiersAndParentCodes &&
            (identifiersAndParentCodes?.products.length > 0 || identifiersAndParentCodes?.product_models.length > 0)
        ) {
            payload.identifiersAndParentCodes = identifiersAndParentCodes;
        }
        if (columnType === 'gallery') {
            if (searchContexts) {
                payload.searchContexts = searchContexts;
            }
            if (withTableAttributes) {
                payload.withTableAttributes = withTableAttributes;
            }
        }

        return pagedFetch<FilterType, FetchAttributeFiltersPayload, AttributeFiltersResponse>(
            {
                method: 'post',
                url: `/attributes/${locale}`,
                data: payload,
            },
            {
                afterKey: 'after',
                recordsKey: 'attributes',
                initialRecords,
                pageSize: 50,
                formatRecord: (record: FilterType) => {
                    if (record.code) {
                        record.key = `attributes.${record.code}`;
                    }
                    if (
                        record.type &&
                        filterAvailableTypes &&
                        (!filterAvailableTypes[record.type] || filterAvailableTypes[record.type].disabled)
                    ) {
                        record.disabled = true;
                        record.label += ' (coming soon)';
                    }

                    return record;
                },
                fetchAllPages: fetchAll,
                onFetchPage,
                onPageFetched,
            },
        );
    };

const fetchActiveFilters =
    (locale: string): ThunkResult<Promise<FilterType[]>> =>
    async (dispatch, getState): Promise<FilterType[]> => {
        const state = getState();

        const res: FilterType[] = [];
        let activeList: FilterType[] = state.filters.activeList;
        if (!activeList.length) {
            const uniqueIdentifierAttribute = await dispatch(fetchUniqueIdentifierAttribute(locale));
            //__ DEFAULT selected filters
            activeList = [uniqueIdentifierAttribute];
            dispatch(setFiltersActiveAction(activeList));
            return activeList;
        }

        const codes: string[] = [];
        each(activeList, (filter: FilterType) => {
            codes.push(filter.code || filter.key);
        });

        return new Promise((resolve) => {
            const pf = dispatch(
                pagedFilters({
                    locale,
                    codes,
                    fetchAll: true,
                    onPageFetched: (page, data, complete) => {
                        if (complete) {
                            const filters = pf.getAllRecords();
                            const recordsByKey = keyBy(filters, 'key');

                            each(activeList, (filter: FilterType) => {
                                const key = filter.code ? `attributes.${filter.code}` : filter.key;
                                const record: FilterType = recordsByKey[key];
                                if (record) {
                                    filter.label = record.label;
                                    res.push({ ...filter });
                                }
                            });

                            resolve(res);
                        }
                    },
                }),
            );
        });
    };

const getSelectedAttributes =
    (options: {
        locale: string;
        attributeCodes: string[];
        searchContexts?: SearchContext[] | null;
        withTableAttributes?: boolean;
        columnType?: 'gallery' | 'associations' | 'variations';
        variationMode: 'grouped' | 'ungrouped';
        identifiersAndParentCodes?: IdentifiersAndParentCodes;
    }): ThunkResult<Promise<FilterType[]>> =>
    async (dispatch): Promise<FilterType[]> => {
        const {
            locale,
            attributeCodes = [],
            searchContexts,
            withTableAttributes,
            columnType,
            variationMode,
            identifiersAndParentCodes,
        } = options;

        let attributes: FilterType[] = [];

        if (!attributeCodes.length) {
            return Promise.resolve(attributes);
        }

        return new Promise((resolve) => {
            const pagedRecords = dispatch(
                pagedFilters({
                    locale,
                    codes: attributeCodes,
                    searchContexts,
                    fetchAll: true,
                    columnType,
                    variationMode,
                    onPageFetched: (page, data, complete) => {
                        if (complete) {
                            attributes = sortByIndex(pagedRecords.getAllRecords(), attributeCodes);

                            resolve(attributes);
                        }
                    },
                    withTableAttributes,
                    identifiersAndParentCodes,
                }),
            );
        });
    };

function sortByIndex(attributes: FilterType[], keysArr: string[]): FilterType[] {
    const orders: { [key: string]: number } = {};
    each(keysArr, (key, index) => {
        orders[key] = index;
    });
    return attributes.sort((a, b) => {
        const ai = orders[a.code || a.key];
        const bi = orders[b.code || b.key];
        if (ai > bi) {
            return 1;
        } else if (bi > ai) {
            return -1;
        }
        return 0;
    });
}

//___ metric unit choices
type GetMetricUnitsOptions = {
    attributeCode: string;
    locale: string;
};

type FetchMetricUnitsPayload = {
    attributeCode: string;
};

type FetchMetricUnitsResponse = {
    labels: InputSelectChoice[];
};

const getMetricUnits =
    (options: GetMetricUnitsOptions): ThunkResult<Promise<InputSelectChoice[]>> =>
    async (): Promise<InputSelectChoice[]> => {
        const { locale, attributeCode } = options;

        return new Promise((resolve) => {
            const pagedRecords = pagedFetch<InputSelectChoice, FetchMetricUnitsPayload, FetchMetricUnitsResponse>(
                {
                    method: 'post',
                    url: `/metric-labels/${locale}`,
                    data: { attributeCode },
                },
                {
                    afterKey: 'after.value',
                    recordsKey: 'labels',
                    fetchAllPages: true,
                    onPageFetched: (page, data, complete) => {
                        if (complete) {
                            resolve(pagedRecords.getAllRecords());
                        }
                    },
                },
            );
        });
    };

//___ price unit choices
type GetPriceUnitsOptions = {
    attributeCode: string;
    locale: string;
};

type FetchPriceUnitsPayload = {
    attributeCode: string;
};

type FetchPriceUnitsResponse = InputSelectChoice[];

const promisePriceUnits: Promise<InputSelectChoice[]> | null = null;

const getPriceUnits =
    (options: GetPriceUnitsOptions): ThunkResult<Promise<InputSelectChoice[]>> =>
    async (): Promise<InputSelectChoice[]> => {
        const { locale } = options;

        if (promisePriceUnits) {
            return promisePriceUnits;
        }

        return new Promise((resolve) => {
            const pagedPriceUnits = pagedFetch<InputSelectChoice, FetchPriceUnitsPayload, FetchPriceUnitsResponse>(
                {
                    method: 'get',
                    url: `/currency-labels/${locale}`,
                },
                {
                    recordsKey: null,
                    fetchAllPages: true,
                    onPageFetched: (page, data, complete) => {
                        if (complete) {
                            resolve(pagedPriceUnits.getAllRecords());
                        }
                    },
                },
            );
        });
    };

//___ Select choices
type GetSelectChoicesOptions = {
    attributeCode: string;
    locale: string;
};

type FetchSelectChoicesPayload = {
    attributeCode: string;
};

type FetchSelectChoicesResponse = {
    options: (string | { code: string; label: string })[];
};

const getSelectChoices =
    (options: GetSelectChoicesOptions): ThunkResult<Promise<InputSelectChoice[]>> =>
    async (): Promise<InputSelectChoice[]> => {
        const { locale, attributeCode } = options;

        return new Promise((resolve) => {
            const pagedRecords = pagedFetch<InputSelectChoice, FetchSelectChoicesPayload, FetchSelectChoicesResponse>(
                {
                    method: 'post',
                    url: `/select-options/${locale}`,
                    data: { attributeCode },
                },
                {
                    afterKey: 'after',
                    recordsKey: 'options',
                    fetchAllPages: true,
                    onPageFetched: (page, data, complete) => {
                        if (complete) {
                            resolve(pagedRecords.getAllRecords());
                        }
                    },
                },
            );
        });
    };

//___ Families choices
const getFamiliesChoices =
    (labels: GetSelectChoicesOptions): ThunkResult<Promise<InputSelectChoice[]>> =>
    async (): Promise<InputSelectChoice[]> => {
        const { locale } = labels;
        return new Promise((resolve) => {
            const pagedRecords = pagedFetch<InputSelectChoice, FetchSelectChoicesPayload, FetchSelectChoicesResponse>(
                {
                    method: 'get',
                    url: `/families/${locale}`,
                },
                {
                    afterKey: 'after',
                    recordsKey: 'labels',
                    fetchAllPages: true,
                    onPageFetched: (page, data, complete) => {
                        if (complete) {
                            resolve(pagedRecords.getAllRecords());
                        }
                    },
                },
            );
        });
    };

//___ Association types choices
const getAssociationTypesChoices =
    (labels: GetSelectChoicesOptions): ThunkResult<Promise<InputSelectChoice[]>> =>
    async (): Promise<InputSelectChoice[]> => {
        const { locale } = labels;

        // TODO: Change it to display the attribute type options.
        return new Promise((resolve) => {
            const pagedRecords = pagedFetch<InputSelectChoice, FetchSelectChoicesPayload, FetchSelectChoicesResponse>(
                {
                    method: 'get',
                    url: `/association-types/${locale}`,
                },
                {
                    afterKey: 'after',
                    recordsKey: 'labels',
                    fetchAllPages: true,
                    onPageFetched: (page, data, complete) => {
                        if (complete) {
                            resolve(pagedRecords.getAllRecords());
                        }
                    },
                },
            );
        });
    };

type FetchReferenceEntityChoicesResponse = {
    records: { value: string; label: string }[];
    after: { value: string }; // same signature as "afterKey" path we set
};

const getReferenceEntityChoices =
    (options: GetSelectChoicesOptions): ThunkResult<Promise<InputSelectChoice[]>> =>
    async (): Promise<InputSelectChoice[]> => {
        const { locale, attributeCode } = options;

        return new Promise((resolve) => {
            const pagedRecords = pagedFetch<
                InputSelectChoice,
                FetchSelectChoicesPayload,
                FetchReferenceEntityChoicesResponse
            >(
                {
                    method: 'post',
                    url: `/reference-entity-records/${locale}`,
                    data: { attributeCode },
                },
                {
                    afterKey: 'after',
                    recordsKey: 'records',
                    fetchAllPages: true,
                    onPageFetched: (page, data, complete) => {
                        if (complete) {
                            resolve(pagedRecords.getAllRecords());
                        }
                    },
                },
            );
        });
    };

//__
export default {
    fetchCategoryChildrenAction,
    fetchCategoryAction,
    searchCategoriesAction,
    fetchUniqueIdentifierAttribute,
    setFilterValueAction,
    resetFiltersValuesAction,
    resetFilterValueAction,
    setFilterPropsAction,
    pagedFilters,
    setFiltersActiveAction,
    setFiltersSearchAction,
    fetchActiveFilters,
    getSelectedAttributes,
    getMetricUnits,
    getPriceUnits,
    getSelectChoices,
    getReferenceEntityChoices,
    getFamiliesChoices,
    getAssociationTypesChoices,
};
