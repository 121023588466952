import React from 'react';
import styled from 'styled-components';

type Props = {
    attribute: Attribute | PriceAttribute;
};

const Root = styled.div`
    * {
        word-break: break-word;
    }
`;

const TextareaStyledDiv = styled.div`
    * {
        font-size: revert !important;
    }
`;

const Attribute: React.FC<Props> = (props) => {
    const { value, type } = props.attribute;

    return (
        <Root>
            {Array.isArray(value) ? (
                value.map((value, index) => <div key={index}>{value}</div>)
            ) : type === 'textarea' ? (
                <TextareaStyledDiv dangerouslySetInnerHTML={{ __html: value === null ? '-' : value }} />
            ) : (
                <div dangerouslySetInnerHTML={{ __html: value === null ? '-' : value }} />
            )}
        </Root>
    );
};

export default Attribute;
