import { BadRequestError } from './exceptions/BadRequestError';
import { ForbiddenError } from './exceptions/ForbiddenError';
import { UnauthorizedError } from './exceptions/UnauthorizedError';
import { apiPathForTenantAndCatalog } from './apiPathForTenantAndCatalog';
import { InternalServerError } from './exceptions/InternalServerError';

export const apiFetch = async <T, E = string>(input: string, init?: RequestInit): Promise<T> => {
    const response = await fetch(`${apiPathForTenantAndCatalog}/${input}`, {
        ...init,
        headers: {
            ...init?.headers,
            'X-Requested-With': 'XMLHttpRequest',
        },
    });

    if (!response.ok) {
        switch (response.status) {
            case 400:
            case 422:
                throw new BadRequestError<E>((await response.json()) as E);
            case 401:
                throw new UnauthorizedError();
            case 403:
                throw new ForbiddenError();
            default: {
                if (isServerError(response.status)) {
                    throw new InternalServerError();
                }

                throw new Error(`${response.status} ${response.statusText}`);
            }
        }
    }

    if ('application/json' === response.headers.get('content-type')) {
        return (await response.json()) as T;
    }

    return (await response.text()) as T;
};

export function isServerError(status: number): boolean {
    const regErr5xx = /^5[0-9]{2}$/;
    return regErr5xx.test(String(status));
}
