import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';

import PreviewActions from './PreviewActions';
import DefaultImage from 'src/shared/image/media/default_image_product.svg';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    box-sizing: border-box;
    height: 100%;
`;

const Border = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid ${theme.color.grey80};
    max-height: calc(100vh - 470px);
    min-height: 100px;
    box-sizing: border-box;
`;

const Image = styled.img`
    max-height: calc(100vh - 250px);
    max-width: 100%;
    min-height: 100%;
    object-fit: contain;
    width: auto;
`;

type EmptyPreviewProps = {
    media: PreviewMedia;
};

const EmptyPreview: React.FC<EmptyPreviewProps> = ({ media }) => {
    const intl = useIntl();

    return (
        <Container>
            <Border>
                <Image
                    src={DefaultImage}
                    aria-label={intl.formatMessage({ defaultMessage: 'Preview', id: 'components.EmptyPreview.4c9a39' })}
                />
                <PreviewActions media={media} />
            </Border>
        </Container>
    );
};

export default EmptyPreview;
