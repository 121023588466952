import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, Helper } from 'akeneo-design-system';
import { useAppDispatch } from 'src/tools/globalStore';
import { actions as productExportActions, selectors as productExportSelectors } from 'src/components/Export/store';
import { ExportProductPdfCovers } from './ExportProductPdfCovers';

const ExportProductPdf: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const exportOptions = useSelector(productExportSelectors.exportOptions);

    const handleSetExportOption = useCallback(
        (optionKey: string, value: string | boolean | any[] | null | PdfSettingsCover) => {
            dispatch(productExportActions.setExportOption(optionKey, value));
        },
        [dispatch],
    );

    return (
        <div>
            <div className='row'>
                <Button
                    className={`bt-square ${exportOptions.allAttributes ? 'select' : ''}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('allAttributes', true)}
                >
                    {intl.formatMessage({
                        defaultMessage: 'All attributes',
                        id: 'components.ExportProductPdf.0e55e1',
                    })}
                </Button>
                <Button
                    className={`bt-square ${exportOptions.allAttributes ? '' : 'select'}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('allAttributes', false)}
                    data-testid='bt-select-attributes'
                >
                    {intl.formatMessage({
                        defaultMessage: 'Select attributes',
                        id: 'components.ExportProductPdf.a469b2',
                    })}
                </Button>
            </div>

            {exportOptions.allAttributes && (
                <div className='row'>
                    <div className='inline-helper'>
                        <Helper
                            inline
                            level='info'
                        >
                            {intl.formatMessage({
                                defaultMessage:
                                    'The order of the attributes in the export will be identical to the order on the product page.',
                                id: 'components.ExportProductPdf.00a6d5',
                            })}
                        </Helper>
                    </div>
                </div>
            )}

            <h4>
                {intl.formatMessage({
                    defaultMessage: 'Style',
                    id: 'components.ExportProductPdf.ee62fd',
                })}
            </h4>

            <ExportProductPdfCovers />

            <div className='row'>
                <Button
                    className={`bt-square ${exportOptions.withTableOfContent ? '' : 'select'}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('withTableOfContent', false)}
                >
                    {intl.formatMessage({
                        defaultMessage: 'Without Table of Content',
                        id: 'components.ExportProductPdf.4b4d12',
                    })}
                </Button>
                <Button
                    className={`bt-square ${exportOptions.withTableOfContent ? 'select' : ''}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('withTableOfContent', true)}
                >
                    {intl.formatMessage({
                        defaultMessage: 'With Table of Content',
                        id: 'components.ExportProductPdf.1c3055',
                    })}
                </Button>
            </div>

            <div className='row'>
                <Button
                    className={`bt-square ${exportOptions.withGlossary ? '' : 'select'}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('withGlossary', false)}
                >
                    {intl.formatMessage({
                        defaultMessage: 'Without Glossary',
                        id: 'components.ExportProductPdf.ea1088',
                    })}
                </Button>
                <Button
                    className={`bt-square ${exportOptions.withGlossary ? 'select' : ''}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('withGlossary', true)}
                >
                    {intl.formatMessage({
                        defaultMessage: 'With Glossary',
                        id: 'components.ExportProductPdf.7da435',
                    })}
                </Button>
            </div>
        </div>
    );
};

export default ExportProductPdf;
