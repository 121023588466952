import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, Helper } from 'akeneo-design-system';
import map from 'lodash/map';
import { useAppDispatch } from 'src/tools/globalStore';
import { selectors as productExportSelectors } from 'src/components/Export/store';
import { actions as productExportActions } from 'src/components/Export/store';
import styled from 'styled-components';

const StyledHelper = styled(Helper)`
    margin-top: 10px;
`;

const ExportProductXlsx: React.FC = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const exportOptions = useSelector(productExportSelectors.exportOptions);

    const handleSetExportOption = useCallback(
        (optionKey: string, value: string | boolean | any[]) => {
            dispatch(productExportActions.setExportOption(optionKey, value));
        },
        [dispatch],
    );

    return (
        <div>
            <div className='row'>
                <Button
                    className={`bt-square ${exportOptions.withMedia ? '' : 'select'}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('withMedia', false)}
                    disabled={exportOptions.format === 'PDF'}
                >
                    {intl.formatMessage({
                        defaultMessage: 'Without media',
                        id: 'components.ExportProductXlsx.4abefd',
                    })}
                </Button>
                <Button
                    className={`bt-square ${exportOptions.withMedia ? 'select' : ''}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('withMedia', true)}
                >
                    {intl.formatMessage({
                        defaultMessage: 'With media',
                        id: 'components.ExportProductXlsx.02abca',
                    })}
                </Button>
            </div>

            {exportOptions.withMedia && (
                <div className='row'>
                    <div className='inline-helper'>
                        <Helper
                            inline
                            level='info'
                        >
                            {intl.formatMessage({
                                defaultMessage:
                                    'Please note that exporting media will increase export duration. \n You will receive an e-mail notification when the export is complete.',
                                id: 'components.ExportProductXlsx.5303cc',
                            })}
                        </Helper>
                        {'XLSX' === exportOptions.format && (
                            <StyledHelper
                                inline
                                level='info'
                            >
                                {intl.formatMessage({
                                    defaultMessage: 'Any media over 8MB will be skipped during the XLSX export.',
                                    id: 'components.ExportProductXlsx.5b5879',
                                })}
                            </StyledHelper>
                        )}
                    </div>
                </div>
            )}

            <div className='row'>
                <Button
                    className={`bt-square ${exportOptions.withReferenceEntityMedia ? '' : 'select'}`}
                    ghost
                    level='tertiary'
                    onClick={() => handleSetExportOption('withReferenceEntityMedia', false)}
                    disabled={exportOptions.format === 'PDF'}
                >
                    {intl.formatMessage({
                        defaultMessage: 'Without reference entity media',
                        id: 'components.ExportProductXlsx.2bd021',
                    })}
                </Button>
                <Button
                    className={`bt-square ${exportOptions.withReferenceEntityMedia ? 'select' : ''}`}
                    ghost
                    level='tertiary'
                    onClick={() => handleSetExportOption('withReferenceEntityMedia', true)}
                >
                    {intl.formatMessage({
                        defaultMessage: 'With reference entity media',
                        id: 'components.ExportProductXlsx.370bdf',
                    })}
                </Button>
            </div>

            {exportOptions.withReferenceEntityMedia && (
                <div className='row'>
                    <div className='inline-helper'>
                        <Helper
                            inline
                            level='info'
                        >
                            {intl.formatMessage({
                                defaultMessage:
                                    'Please note that exporting reference entity media will increase export duration.\n You will receive an e-mail notification when the export is complete.',
                                id: 'components.ExportProductXlsx.690a18',
                            })}
                        </Helper>
                        {'XLSX' === exportOptions.format && (
                            <StyledHelper
                                inline
                                level='info'
                            >
                                {intl.formatMessage({
                                    defaultMessage: 'Any media over 8MB will be skipped during the XLSX export.',
                                    id: 'components.ExportProductXlsx.5b5879',
                                })}
                            </StyledHelper>
                        )}
                    </div>
                </div>
            )}
            <div className='row'>
                <Button
                    className={`bt-square ${exportOptions.allAttributes ? 'select' : ''}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('allAttributes', true)}
                >
                    {intl.formatMessage({
                        defaultMessage: 'All attributes',
                        id: 'components.ExportProductXlsx.0e55e1',
                    })}
                </Button>
                <Button
                    className={`bt-square ${exportOptions.allAttributes ? '' : 'select'}`}
                    ghost
                    level='tertiary'
                    onClick={(): void => handleSetExportOption('allAttributes', false)}
                    data-testid='bt-select-attributes'
                >
                    {intl.formatMessage({
                        defaultMessage: 'Select attributes',
                        id: 'components.ExportProductXlsx.a469b2',
                    })}
                </Button>
            </div>

            {exportOptions.allAttributes && (
                <div className='row'>
                    <div className='inline-helper'>
                        <Helper
                            inline
                            level='info'
                        >
                            {intl.formatMessage({
                                defaultMessage:
                                    'The order of the attributes in the export will be identical to the order on the product page.',
                                id: 'components.ExportProductXlsx.00a6d5',
                            })}
                        </Helper>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExportProductXlsx;
