import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import each from 'lodash/each';
import noop from 'lodash/noop';
import styled from 'styled-components';
import { Checkbox, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

import { useAppDispatch } from 'src/tools/globalStore';
import { selectors as filterSelectors, actions as filterActions, PagedFilters } from 'src/components/Filter/store';
import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import VirtualScroll from 'src/shared/virtualscroll';
import SearchBar from 'src/shared/searchbar';

//__
type Props = {
    locale?: string | null;
    selectedFilters?: FilterType[];
    onChange?: (filters: FilterType[]) => void;
    searchLabel?: string;
    searchValue?: string;
    searchContexts?: SearchContext[];
    excludeKeys?: BooleanMap;
    disableKeys?: BooleanMap;
    filterAvailableTypes?: false | Record<string, FilterTypeDef>;
    withTableAttributes?: boolean;
    columnType?: 'gallery' | 'associations' | 'variations';
    variationMode?: 'grouped' | 'ungrouped';
    identifiersAndParentCodes?: IdentifiersAndParentCodes;
    usePresetAttributeFilters: boolean;
};

//___________
const Root = styled.div`
    display: flex;
    flex-direction: column;

    .head {
        //flex: none;
        position: relative;
        margin-bottom: 10px;

        > div:first-child {
            height: auto;
        }

        input::placeholder {
            color: ${theme.color.grey120};
        }
    }
`;

const FiltersSearch: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const defaultLocale = useSelector(contextLocaleSelectors.getContextLocale);

    const {
        locale = defaultLocale,
        selectedFilters,
        onChange = noop,
        searchValue: propsSearchValue = '',
        searchLabel = intl.formatMessage({
            defaultMessage: 'Search filters',
            id: 'filtersSelector.FiltersSearch.d7e79e',
        }),
        searchContexts,
        excludeKeys,
        disableKeys,
        filterAvailableTypes,
        withTableAttributes,
        columnType = 'gallery',
        variationMode = 'ungrouped',
        identifiersAndParentCodes = { products: [], product_models: [] },
        usePresetAttributeFilters,
    } = props;

    const unmount = useRef(false);

    useEffect(() => {
        unmount.current = false;
        setSearchValue('');
        return () => {
            unmount.current = true;
        };
    }, []);

    //___ selection
    const [checkedKeys, setCheckedKeys] = useState<FiltersByKey>({});

    useEffect(() => {
        const res: FiltersByKey = {};
        each(selectedFilters, (filter) => {
            res[filter.key] = filter;
        });
        setCheckedKeys(res);
    }, [selectedFilters]);

    const handleCheckFilter = useCallback(
        (value: boolean, filter: FilterType) => {
            const res: FiltersByKey = { ...checkedKeys };
            if (value) {
                res[filter.key] = filter;
            } else {
                dispatch(filterActions.resetFilterValueAction(filter));
                delete res[filter.key];
            }
            setCheckedKeys(res);
            onChange(map(res, (filter) => filter));
        },
        [checkedKeys, dispatch, onChange],
    );

    //___ search
    const [searchValue, setSearchValue] = useState(propsSearchValue);

    const handleChangeSearch = useCallback(
        (searchInput: string) => {
            setSearchValue(searchInput);
            dispatch(filterActions.setFiltersSearchAction(searchInput));
        },
        [dispatch],
    );

    //____ All Filters
    const [itemCount, setItemCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const pagedRecords: PagedFilters | null = useMemo(() => {
        if (!locale) {
            return null;
        }
        setItemCount(0);
        const res = dispatch(
            filterActions.pagedFilters({
                locale,
                searchString: searchValue,
                excludeKeys: { ...excludeKeys, isCurrentProduct: true },
                searchContexts,
                filterAvailableTypes,
                identifiersAndParentCodes,
                onFetchPage: (page) => {
                    setLoading(true);
                },
                onPageFetched: (page, data, complete, isLoading) => {
                    if (unmount.current) {
                        return;
                    }
                    setLoading(isLoading);
                    //__ backend does not provide total count, so trick it by adding 1 to already loaded ones
                    setItemCount(complete ? res.totalRecords : res.totalRecords + 1);
                },
                withTableAttributes,
                columnType,
                variationMode,
                usePresetAttributeFilters,
            }),
        );
        return res;
    }, [
        locale,
        dispatch,
        searchValue,
        excludeKeys,
        searchContexts,
        filterAvailableTypes,
        withTableAttributes,
        columnType,
        variationMode,
        usePresetAttributeFilters,
    ]);

    const loadMoreItems = useCallback(
        async (indexStart?: number, indexEnd?: number) => {
            if (!pagedRecords) {
                return;
            }
            pagedRecords.fetchIndex(indexStart, indexEnd);
        },
        [pagedRecords],
    );

    //____
    const renderItem = useCallback(
        (props: RenderItemProps<FilterType>) => {
            const { index, item, style } = props;

            const readOnly: boolean = item.disabled || (disableKeys ? disableKeys[item.code || item.key] : false);

            return (
                <FilterItem
                    filter={item}
                    onCheck={handleCheckFilter}
                    selected={!!checkedKeys[item.key]}
                    readOnly={readOnly}
                    style={style}
                />
            );
        },
        [disableKeys, handleCheckFilter, checkedKeys],
    );

    const emptyResult = useMemo(() => {
        return (
            <>{intl.formatMessage({ defaultMessage: 'No result found', id: 'filtersSelector.FiltersSearch.180037' })}</>
        );
    }, [intl]);

    return (
        <Root
            className='filters-search'
            data-testid='filters-search'
        >
            <div
                className='head'
                role='search'
            >
                <SearchBar
                    onChange={handleChangeSearch}
                    inputValue={searchValue}
                    placeholder={searchLabel}
                    tooltip={intl.formatMessage({
                        defaultMessage: 'Search filters',
                        id: 'filtersSelector.FiltersSearch.d7e79e',
                    })}
                    debounceWait={250}
                />
            </div>
            <VirtualScroll<FilterType>
                itemCount={itemCount}
                getItem={pagedRecords?.getRecord}
                columnCount={1}
                renderItem={renderItem}
                rowHeight={34}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={pagedRecords?.options.pageSize}
                loading={loading}
                complete={pagedRecords?.complete}
                emptyResult={emptyResult}
            />
        </Root>
    );
};

export default FiltersSearch;

//___ Item Filter
type ItemProps = {
    filter: FilterType;
    selected?: boolean;
    onCheck?: (value: boolean, filter: FilterType) => void;
    readOnly?: boolean;
    style: React.CSSProperties;
};

const ItemRoot = styled.div`
    height: 34px;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: none;
    cursor: pointer;

    [role='checkbox'] {
        display: flex;
    }

    > * {
        flex: 1 1;
        overflow: hidden;

        &:first-child {
            align-items: center;
        }
    }

    label {
        flex: 1 1;
        cursor: pointer;
        font-size: ${theme.fontSize.default};
        flex-direction: row;
        align-items: center;
        line-height: normal;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
    }

    &.readonly,
    &.readonly label,
    &.readonly [role='checkbox'] {
        //cursor: not-allowed;
        pointer-events: none;
    }
`;

const FilterItem = (props: ItemProps) => {
    const { filter, selected = false, onCheck = noop, readOnly, ...rest } = props;

    const intl = useIntl();

    const handleCheck = useCallback(
        (value: boolean) => {
            onCheck(value, filter);
        },
        [onCheck, filter],
    );

    const filterLabel = filterSelectors.getFilterLabel(filter);
    const label = useMemo(() => {
        switch (filterLabel) {
            case 'intl-Family':
                return intl.formatMessage({
                    defaultMessage: 'Family',
                    id: 'filtersSelector.FiltersSearch.af30af',
                });
            case 'intl-Type':
                return intl.formatMessage({ defaultMessage: 'Type', id: 'filtersSelector.FiltersSearch.f94ea8' });
            case 'intl-Quantity':
                return intl.formatMessage({
                    defaultMessage: 'Quantity',
                    id: 'filtersSelector.FiltersSearch.a95191',
                });
            case 'intl-Image':
                return intl.formatMessage({
                    defaultMessage: 'Image',
                    id: 'filtersSelector.FiltersSearch.fb4cef',
                });
            case 'intl-Label':
                return intl.formatMessage({
                    defaultMessage: 'Label',
                    id: 'filtersSelector.FiltersSearch.ef9df2',
                });
            case 'intl-Variations':
                return intl.formatMessage({
                    defaultMessage: 'Variations',
                    id: 'filtersSelector.FiltersSearch.e9b5e0',
                });
            default:
                return filterLabel;
        }
    }, [filterLabel, intl]);

    if (!filter) {
        return null;
    }

    return (
        <ItemRoot
            className={`filter-item ${readOnly ? ' readonly' : ''}`}
            role='listitem'
            aria-label={label}
            {...rest}
        >
            <Checkbox
                checked={selected}
                onChange={handleCheck}
                readOnly={readOnly}
            >
                {label}
            </Checkbox>
        </ItemRoot>
    );
};
