import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Carousel from './components/Carousel';
import PdfPreview from './components/PdfPreview';
import ImagePreview from './components/ImagePreview';
import EmbedPreview from './components/EmbedPreview';
import EmptyPreview from './components/EmptyPreview';

import { selectors as brandingSelectors } from 'src/shared/branding/store';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
`;

const MediaContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto hidden;
`;

const Title = styled.div<{ colorBranding: string }>`
    color: ${({ colorBranding }): string => colorBranding};
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
    word-break: break-all;
`;

const SubTitle = styled.div`
    color: ${theme.color.grey120};
    font-size: 13px;
    margin: 48px 0 7px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
`;

const PreviewAdaptor: React.FC<{
    media: PreviewMedia;
}> = ({ media }) => {
    const [loading, setLoading] = useState<boolean>(true);

    // this is a dirty trick, this force react to clear the preview DOM when the media change
    // Otherwise, if the image does not load fast enough, the previous one is still rendered
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 0);
    }, [media]);

    if (loading) {
        return null;
    }

    switch (media.previewFormat) {
        case 'pdf':
            return <PdfPreview media={media} />;
        case 'youtube':
            return (
                <EmbedPreview
                    media={media}
                    url={`https://youtube.com/embed/${media.id}`}
                />
            );
        case 'vimeo':
            return (
                <EmbedPreview
                    media={media}
                    url={`https://player.vimeo.com/video/${media.id}`}
                />
            );
        case 'other':
            return <EmptyPreview media={media} />;
    }

    return <ImagePreview media={media} />;
};

type ProductMediaGalleryModalProps = {
    medias: PreviewMedia[];
    breadcrumbs: string;
    galleryName: string;
    initialIndex: number;
};

const Preview: React.FC<ProductMediaGalleryModalProps> = ({ medias, breadcrumbs, galleryName, initialIndex = 0 }) => {
    const [index, setIndex] = useState(initialIndex);
    const currentMediaPreview: PreviewMedia = useMemo(() => medias[index], [medias, index]);

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    if (medias.length === 0 || !currentMediaPreview) {
        return null;
    }

    return (
        <Container data-testid='Preview'>
            <MediaContainer>
                <div className='preview-header'>
                    <SubTitle dangerouslySetInnerHTML={{ __html: breadcrumbs }} />
                    <Title colorBranding={colorBranding}>{currentMediaPreview.filename}</Title>
                </div>
                <PreviewAdaptor media={currentMediaPreview} />
                <Carousel
                    title={galleryName}
                    medias={medias}
                    selectedIndex={index}
                    onSelection={setIndex}
                    initialIndex={initialIndex}
                />
            </MediaContainer>
        </Container>
    );
};

export default Preview;
