import React, { useCallback, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import noop from 'lodash/noop';
import { MessageBar } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export type NotificationProps = {
    notification: NotificationType;
    onClose: (notification: NotificationType) => void;
};

const ANIMATION_DURATION = 350;

const MessageBarDisplayAnimation = keyframes`
  0% {
    transform: translateX(calc(100% + 50px));
  }
  100% {
    transform: translateX(0);
  }
`;

const MessageBarHideAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(calc(100% + 50px));
    max-height: 150px;
    opacity: 0;
  }
  100% {
    transform: translateX(calc(100% + 50px));
    max-height: 0;
    opacity: 0;
  }
`;

const Root = styled.div<{ hide: boolean }>`
    animation: ${({ hide }) => (hide ? MessageBarHideAnimation : MessageBarDisplayAnimation)} ${ANIMATION_DURATION}ms
        forwards;
    max-height: 150px;
`;

const NotificationItem: React.FC<NotificationProps> = (props) => {
    const { notification, onClose = noop } = props;

    const intl = useIntl();
    const { autoHide = true } = notification;
    const [hide, setHide] = useState(false);
    const hidden = useRef(false);

    const handleClose = useCallback(
        (...args: any) => {
            const isManualClose = !!args.length;
            if (isManualClose || autoHide) {
                if (hidden.current) {
                    return;
                } //__ prevent multiple onClose call
                hidden.current = true;
                setTimeout(() => {
                    //__ prevent race cond warn with MessageBar
                    setHide(true); //__ refresh
                    setTimeout(() => {
                        onClose(notification);
                    }, ANIMATION_DURATION);
                });
            }
        },
        [onClose, autoHide, notification],
    );

    const res = (
        <Root
            className='item-notification'
            hide={hide}
            role='list-item'
            aria-label={notification.title}
        >
            <MessageBar
                title={
                    notification.title === 'server.error.generic'
                        ? intl.formatMessage({
                              defaultMessage: 'Internal Server Error',
                              id: 'notification.NotificationItem.7130f5',
                          })
                        : notification.title
                }
                level={notification.level}
                onClose={handleClose}
                dismissTitle={intl.formatMessage({
                    defaultMessage: 'Close',
                    id: 'notification.NotificationItem.adbada',
                })}
            >
                {notification.description}
            </MessageBar>
        </Root>
    );

    return res;
};

export default NotificationItem;
