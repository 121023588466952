import { produce } from 'immer';

const initialState: BrandingState = {
    color: '#956C25',
};

const BrandingReducer = (state = initialState, action: BrandingActions): BrandingState => {
    switch (action.type) {
        case 'SET_COLOR':
            return produce(state, (draft) => {
                draft.color = action.color;
            });

        case 'RESET_COLOR':
            return produce(state, (draft) => {
                draft.color = initialState.color;
            });

        case 'RESET_BRANDING':
            return initialState;

        default:
            return state;
    }
};

export default BrandingReducer;
