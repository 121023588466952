import { Breadcrumb } from 'akeneo-design-system';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Image } from 'src/shared';
import MobileSelectContextLocale from 'src/shared/locale/MobileSelectContextLocale';
import SelectContextLocale from 'src/shared/locale/SelectContextLocale';
import useMedia from 'src/hooks/useMedia';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

type Props = {
    productLabel: string;
    mainMedia: Media | null;
};

const Root = styled.div<{ colorBranding: string }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    top: 0;
    background-color: white;
    height: 120px;
    z-index: 50;

    .breadcrumb {
        margin-bottom: 10px;

        a:first-child:hover {
            color: ${({ colorBranding }): string => colorBranding};
        }
    }

    .head {
        width: 100%;
        display: flex;
        align-items: center;

        .product-head-thumb {
            min-width: 75px;
            height: 75px;
            margin-right: 15px;
        }

        .product-head-title {
            min-width: 0;
        }

        .product-title {
            font-size: 28px;
            font-weight: normal;
            color: ${({ colorBranding }): string => colorBranding};
            margin: 0 0 8px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .input-locale {
            margin-bottom: 15px;
        }
    }
`;

const ProductHeader: React.FC<Props> = (props) => {
    const { productLabel, mainMedia } = props;

    const history = useHistory();
    const intl = useIntl();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const isMobile = useMedia(['(max-width: 640px)'], [true], false);

    const onBreadcrumbClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            const target = event.target as HTMLElement;
            const href = target.getAttribute('href');
            href?.length && history.push(`${href}${location.search}${location.hash}`);
        },
        [history],
    );

    return (
        <Root colorBranding={colorBranding}>
            <div className='breadcrumb'>
                <Breadcrumb>
                    <Breadcrumb.Step
                        href={`/`}
                        onClick={onBreadcrumbClick}
                    >
                        {intl.formatMessage({ defaultMessage: 'Catalog', id: 'components.ProductHeader.18e76a' })}
                    </Breadcrumb.Step>
                    <Breadcrumb.Step>
                        <span dangerouslySetInnerHTML={{ __html: productLabel }} />
                    </Breadcrumb.Step>
                </Breadcrumb>
            </div>

            <div className='head'>
                <div className='product-head-thumb'>
                    <Image
                        src={mainMedia?.thumbnail}
                        title={productLabel}
                    />
                </div>
                <div className='product-head-title'>
                    <div>
                        <h1
                            className='product-title'
                            dangerouslySetInnerHTML={{ __html: productLabel }}
                        ></h1>
                    </div>
                    {isMobile ? <MobileSelectContextLocale /> : <SelectContextLocale inline />}
                </div>
            </div>
        </Root>
    );
};

export default ProductHeader;
