import { map } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ProductAttributeGroup from './ProductAttributeGroup';
import { selectors as attributeDisplaySelectors } from 'src/components/ProductGrid/store/productGrid';
import AttributeTitle from './AttributeTitle';
import { Product } from '../../model/product';

type Props = {
    product: Product;
};

const Root = styled.div``;

const ProductAttributes: React.FC<Props> = (props) => {
    const { product } = props;

    const [search, setSearch] = useState('');
    const [numberOfResult, setNumberOfResult] = useState(0);

    //__ attribute display
    const [attributeDisplayType, setAttributeDisplayType] = useState(
        useSelector(attributeDisplaySelectors.currentDisplayAttributeTypes),
    );

    //__ attribute group collapse
    const [collapseAttributeGroup, setCollapseAttributeGroup] = useState<{ [key: string]: boolean }>({
        collapseAll: true,
    });
    const handleCollapseAll = useCallback(
        (isCollapsed: boolean) => {
            map(collapseAttributeGroup, (_value, key) => {
                collapseAttributeGroup[key] = !isCollapsed;
            });
            setCollapseAttributeGroup({ ...collapseAttributeGroup });
        },
        [collapseAttributeGroup],
    );

    const handleCollapseAttributeGroup = useCallback(
        (attributeCode: string) => {
            let collapseAll = false;
            collapseAttributeGroup[attributeCode] = !collapseAttributeGroup[attributeCode];
            map(collapseAttributeGroup, (value, key) => {
                if (value && key !== 'collapseAll') {
                    collapseAll = true;
                    return false;
                }
            });
            setCollapseAttributeGroup({ ...collapseAttributeGroup, collapseAll });
        },
        [collapseAttributeGroup],
    );

    // __ search
    const handleChangeSearch = useCallback((searchInput: string) => {
        setSearch(searchInput);
    }, []);

    const findValueInReferenceEntity = (attribute: ReferenceEntityAttribute, search: string): boolean => {
        let findValueInReferenceEntity = false;

        if (new RegExp(`.*${search}.*`).test(attribute.label.toLowerCase())) {
            findValueInReferenceEntity = true;
        } else if (Array.isArray(attribute.value)) {
            attribute.value.map((refEntityValues) => {
                if (new RegExp(`.*${search}.*`).test(refEntityValues.label.toLowerCase())) {
                    findValueInReferenceEntity = true;
                }

                if (!findValueInReferenceEntity) {
                    map(refEntityValues.values, (referenceEntityAttribute) => {
                        if (new RegExp(`.*${search}.*`).test(referenceEntityAttribute.label.toLowerCase())) {
                            findValueInReferenceEntity = true;
                        }

                        if (!findValueInReferenceEntity && referenceEntityAttribute.value) {
                            if (Array.isArray(referenceEntityAttribute.value)) {
                                findValueInReferenceEntity = referenceEntityAttribute.value.some((refValue) => {
                                    return new RegExp(`.*${search}.*`).test(refValue.toString().toLowerCase());
                                });
                            } else if (typeof referenceEntityAttribute.value === 'object') {
                                map(referenceEntityAttribute.value, (subReferenceEntity) => {
                                    if (subReferenceEntity && typeof subReferenceEntity === 'object') {
                                        findValueInReferenceEntity = new RegExp(`.*${search}.*`).test(
                                            subReferenceEntity.label.toLowerCase(),
                                        );
                                    }
                                });
                            } else {
                                findValueInReferenceEntity = new RegExp(`.*${search}.*`).test(
                                    referenceEntityAttribute.value.toString().toLowerCase(),
                                );
                            }
                        }
                    });
                }
            });
        }

        return findValueInReferenceEntity;
    };

    const filteredProduct = useMemo(() => {
        let numberOfResultTmp = 0;
        let productTmp = product;

        if (attributeDisplayType === 'active') {
            const filteredAttributeGroups = product.attributeGroups
                ?.map((attributeGroup: AttributeGroup) => {
                    return {
                        ...attributeGroup,
                        attributes: attributeGroup.attributes?.filter(
                            (attribute: AttributeType) =>
                                (Array.isArray(attribute.value) && attribute.value.length > 0) ||
                                (!Array.isArray(attribute.value) && attribute.value !== null),
                        ),
                    };
                })
                .filter((attributeGroup: AttributeGroup) => attributeGroup.attributes?.length > 0);
            productTmp = {
                ...product,
                attributeGroups: filteredAttributeGroups,
            };
        }

        // __ initialize collapse state
        const newCollapseAttributeGroup: { [key: string]: boolean } = collapseAttributeGroup;
        productTmp.attributeGroups?.map((attributeGroup: AttributeGroup) => {
            if (!(attributeGroup.code in newCollapseAttributeGroup)) {
                newCollapseAttributeGroup[attributeGroup.code] = true;
            }
        });
        setCollapseAttributeGroup(newCollapseAttributeGroup);

        if (search !== '') {
            const escapedSearchInLowerCase = search.replace(/[<>*()?]/g, '\\$&').toLowerCase();

            const filteredAttributeGroups = productTmp.attributeGroups
                ?.map((attributeGroup: AttributeGroup) => {
                    return {
                        ...attributeGroup,
                        attributes: attributeGroup.attributes?.filter((attribute: AttributeType) => {
                            switch (attribute.type) {
                                case 'reference_entity': {
                                    return findValueInReferenceEntity(attribute, escapedSearchInLowerCase);
                                }
                                case 'table': {
                                    let findValueInTable = false;

                                    map(attribute.columnLabels, (label: string | number) => {
                                        if (
                                            new RegExp(`.*${escapedSearchInLowerCase}.*`).test(
                                                label.toString().toLowerCase(),
                                            )
                                        ) {
                                            findValueInTable = true;
                                        }
                                    });

                                    if (
                                        new RegExp(`.*${escapedSearchInLowerCase}.*`).test(
                                            attribute.label.toLowerCase(),
                                        )
                                    ) {
                                        findValueInTable = true;
                                    } else if (Array.isArray(attribute.value)) {
                                        map(attribute.value, (item) => {
                                            map(item, (value: string | number) => {
                                                if (
                                                    value &&
                                                    new RegExp(`.*${escapedSearchInLowerCase}.*`).test(
                                                        value.toString().toLowerCase(),
                                                    )
                                                ) {
                                                    findValueInTable = true;
                                                    return false;
                                                }
                                            });
                                        });
                                    }
                                    return findValueInTable;
                                }
                                default:
                                    return (
                                        new RegExp(`.*${escapedSearchInLowerCase}.*`).test(
                                            attribute.label.toLowerCase(),
                                        ) ||
                                        (attribute.value &&
                                            new RegExp(`.*${escapedSearchInLowerCase}.*`).test(
                                                attribute.value.toString().toLowerCase(),
                                            ))
                                    );
                            }
                        }),
                    };
                })
                .filter((attributeGroup: AttributeGroup) => attributeGroup.attributes?.length > 0);
            productTmp = {
                ...product,
                attributeGroups: filteredAttributeGroups,
            };
        }

        productTmp = {
            ...productTmp,
        };

        productTmp.attributeGroups?.map((attributeGroup: AttributeGroup) => {
            numberOfResultTmp += attributeGroup.attributes.length;
        });

        setNumberOfResult(numberOfResultTmp);

        return productTmp;
    }, [attributeDisplayType, collapseAttributeGroup, product, search]);

    return (
        <Root>
            <AttributeTitle
                search={search}
                numberOfResult={numberOfResult}
                collapseAttributeGroup={collapseAttributeGroup}
                handleChangeSearch={handleChangeSearch}
                attributeDisplayType={attributeDisplayType}
                setAttributeDisplayType={setAttributeDisplayType}
                handleCollapseAll={handleCollapseAll}
            />

            <ProductAttributeGroup
                productLabel={product.label}
                attributeGroups={filteredProduct.attributeGroups ?? []}
                collapseAttributeGroup={collapseAttributeGroup}
                handleCollapseAttributeGroup={handleCollapseAttributeGroup}
            />
        </Root>
    );
};

export default ProductAttributes;
