import React, { useContext } from 'react';

import TenantCatalogContext from 'src/shared/tenantcatalog/TenantCatalogContext';

const BrandingLogo: React.FC = () => {
    const { tenantId, catalogCode } = useContext(TenantCatalogContext);

    return (
        <img
            src={`${process.env.REACT_APP_API_WEB_PATH}/${tenantId}/${catalogCode}/branding`}
            alt='Shared Catalogs branding logo'
        />
    );
};

export default BrandingLogo;
