//___
const initState: ProductExportState = {
    display: false,
    forceSelectedAttributes: null,
    exportOptions: {
        withMedia: false,
        withReferenceEntityMedia: false,
        allAttributes: true,
        selectedAttributes: [],
        format: 'XLSX',
        withCover: true,
        withTableOfContent: true,
        withGlossary: true,
        selectedPdfCover: null,
    },
    pdfSettingsCovers: null,
};

export const persistWhitelist = ['exportOptions'];

//___
const ExportReducer = (state = initState, action: ExportActions | RehydrateAction): ProductExportState => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            if (action.payload?.productExport) {
                const res = initState;
                if (action.payload?.productExport?.exportOptions) {
                    Object.assign(res, action.payload.productExport.exportOptions);
                }
                return res;
            }
            return state;

        case 'EXPORT_DISPLAY':
            return { ...state, display: action.payload };

        case 'EXPORT_SET_OPTION':
            return { ...state, exportOptions: { ...state.exportOptions, [action.key]: action.value } };

        case 'EXPORT_SET_FORCED_ATTRIBUTES':
            return { ...state, forceSelectedAttributes: action.payload };

        case 'SET_CATALOG_PDF_SETTINGS_COVERS':
            return { ...state, pdfSettingsCovers: action.payload };

        default:
            return state;
    }
};

export default ExportReducer;
