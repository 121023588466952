import findIndex from 'lodash/findIndex';
import each from 'lodash/each';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

//___ Filters' states ( will be persisted )
const initState: FiltersState = {
    activeList: [],
    categories: {},
    facets: {},
    uniqueIdentifierAttribute: null,
    //__ filtersManager
    search: '', //__ filters input search value
};

export const persistWhitelist = ['activeList', 'categories'];

const setFilterProp = (state: FiltersState, filterKeySrc: string, prop: string, _value?: any): FiltersState => {
    let filter: FilterType;
    const activeList = state.activeList;
    const categories = state.categories;

    if (filterKeySrc === 'categories') {
        filter = categories;
    } else if (filterKeySrc === 'associationType') {
        filter = {
            key: 'associationType',
            type: 'associationType',
            label: 'intl-Type',
        };
    } else {
        const index = findIndex(activeList, { key: filterKeySrc });
        if (index === -1) {
            console.error(new Error(`Unknown filter from key ${filterKeySrc}.`));
            return state;
        }
        filter = activeList[index];
    }

    let value = _value;
    if (isNil(value)) {
        delete filter[prop];
    } else {
        if (prop === 'props') {
            filter.props = { ...(filter.props || {}), ...value };
        } else {
            if (isArray(value)) {
                value = [...value];
            } else if (!(value instanceof Date) && isObject(value)) {
                value = { ...value };
            }
            filter[prop] = value;
        }
    }

    return { ...state, activeList, categories };
};

const FilterReducer = (state = initState, action: FilterActions | RehydrateAction) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            if (action.payload?.filters) {
                if (!action.payload.filters.activeList) {
                    return { ...initState };
                }

                return { ...state, ...action.payload.filters };
            }
            return state;

        case 'SET_FILTER_VALUE': {
            const { filter, value, operator } = action;
            const s = operator ? setFilterProp(state, filter.key, 'operator', operator) : state;
            return setFilterProp(s, filter.key, 'value', value);
        }

        case 'RESET_FILTER_VALUE':
            return setFilterProp(state, action.payload.key, 'value');

        case 'RESET_FILTERS_VALUES': {
            const cleanedFilters = state.activeList.map((filter) => {
                const cleanedFilter = { ...filter };
                delete cleanedFilter.value; // Remove the `value` property
                return cleanedFilter;
            });

            return {
                ...state,
                activeList: cleanedFilters,
            };
        }

        case 'SET_FILTER_PROPS':
            return setFilterProp(state, action.payload.filterKey, 'props', action.payload.props);

        case 'SET_FILTERS_ACTIVE':
            return { ...state, activeList: action.payload };

        case 'SET_FILTERS_SEARCH':
            return { ...state, search: action.payload };

        case 'SET_UNIQUE_IDENTIFIER_ATTRIBUTE':
            return { ...state, uniqueIdentifierAttribute: action.payload };

        default:
            return state;
    }
};

export default FilterReducer;
