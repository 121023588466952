const initState: ContextLocaleState = null;

const ContextLocalesReducer = (state = initState, action: ContextLocaleActions): string | null => {
    switch (action.type) {
        case 'SELECT_CONTEXT_LOCALE':
            return action.contextLocale;
        default:
            return state;
    }
};

export default ContextLocalesReducer;
