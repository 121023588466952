import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Badge, sharedCatalogsTheme as theme } from 'akeneo-design-system';

import DefaultImage from './media/default_image_product.svg';

type ImageProps = {
    src?: string;
    title: string;
    variationNumber: number | undefined;
};

const Root = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1;

    .stacked::before,
    .stacked::after {
        content: ' ';
        position: absolute;
        top: 0px;
        left: 0px;
        box-sizing: border-box;
        border: 1px solid ${theme.color.grey100};
        background-color: rgb(255, 255, 255);
    }

    .stacked::before {
        width: 96%;
        height: 96%;
        transform: translate(8px, 8px);
    }

    .stacked::after {
        width: 96%;
        height: 96%;
        transform: translate(4px, 4px);
    }

    .stacked-badge {
        position: absolute;
        z-index: 1;
        top: 5%;
        right: 10%;
    }
`;

const StyledImg = styled.img`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 96%;
    height: 96%;
    box-sizing: border-box;
    border: 1px solid ${theme.color.grey100};
    background-color: white;
    object-fit: contain;
`;

const StackedImage: React.FC<ImageProps> = ({ src, title, variationNumber = 0 }) => {
    return (
        <Root className='stacked-image'>
            <Badge className='stacked-badge branding-badge'>{variationNumber}</Badge>
            <div className='stacked'></div>
            <StyledImg
                loading='lazy'
                src={src || DefaultImage}
                onError={(e: SyntheticEvent<HTMLImageElement, Event>): void => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = DefaultImage;
                }}
                alt={title}
            />
        </Root>
    );
};

export default StackedImage;
