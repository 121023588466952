import { useIntl } from 'react-intl';
import styled from 'styled-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, sharedCatalogsTheme as theme } from 'akeneo-design-system';

import errorImage from 'src/media/Error404.svg';

const Root = styled.div`
    text-align: center;
    flex: 1;
    padding-right: 100px;

    .error-image {
        margin-top: 150px;
    }

    .error-title {
        font-size: 31px;
        font-weight: 700;
        margin-top: 32px;
        color: ${theme.color.grey120};
    }

    .error-sub-title {
        font-size: 13px;
        margin-top: 10px;
    }

    button {
        margin-top: 30px;
    }
`;

const Error404: React.FC = () => {
    const intl = useIntl();
    const history = useHistory();

    return (
        <Root>
            <img
                className='error-image'
                src={errorImage}
                alt='Image error'
            />
            <div className='error-title'>
                {intl.formatMessage({ defaultMessage: 'A 404 error occurred...', id: 'src.Error404Page.a8fb8d' })}
            </div>
            <div className='error-sub-title'>
                {intl.formatMessage({ defaultMessage: 'No page has been found.', id: 'src.Error404Page.f5f495' })}
            </div>
            <Button
                size='default'
                level='tertiary'
                ghost
                onClick={(): void => {
                    history.push(`/`);
                }}
                className='bt-manage'
                data-testid='bt-toggle-display'
            >
                {intl.formatMessage({ defaultMessage: 'Go to Catalog', id: 'src.Error404Page.5527fe' })}
            </Button>
        </Root>
    );
};

export default Error404;
