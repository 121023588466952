import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import find from 'lodash/find';
import { SwitcherButton, sharedCatalogsTheme as theme, Dropdown, useBooleanState } from 'akeneo-design-system';

import { useAppDispatch } from 'src/tools/globalStore';
import { Flag } from 'src/shared/index';
import { selectors as localesSelectors } from 'src/shared/locale/store/CatalogLocales';
import {
    selectors as contextLocaleSelectors,
    actions as contextLocaleActions,
} from 'src/shared/locale/store/ContextLocale';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

type Props = {
    inline?: boolean;
};

const Root = styled.div<{ colorBranding: string }>`
    margin-bottom: 30px;

    .switcher label {
        margin-bottom: 0;
    }

    .inline {
        .current-locale {
            color: ${({ colorBranding }): string => colorBranding};
        }

        label {
            text-transform: uppercase;
            color: ${theme.color.grey100};
            font-size: 11px;
        }
    }

    .current-locale {
        display: flex;

        & span {
            margin-left: 5px;
        }
    }
`;

const SelectContextLocale: React.FC<Props> = (props) => {
    const { inline = false } = props;

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const locales: CatalogLocales = useSelector(localesSelectors.getCatalogLocales);
    const locale = useSelector(contextLocaleSelectors.getContextLocale);
    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const [currentLocale, setCurrentLocale] = useState<CatalogLocale | null>(null);
    const [isOpen, open, close] = useBooleanState(false);

    useEffect(() => {
        if (!locales || !locales.length) {
            return;
        }
        let choice: CatalogLocale | undefined = locale ? find(locales, { code: locale }) : undefined;
        if (!choice) {
            choice = locales[0];
        }
        setCurrentLocale(choice);
    }, [locale, locales]);

    const handleChangeLocale = useCallback(
        (locale: CatalogLocale) => {
            dispatch(contextLocaleActions.selectContextLocalesAction(locale.code));
            close();
        },
        [close, dispatch],
    );

    return (
        <Root
            colorBranding={colorBranding}
            className='input-locale'
            data-testid='select-locales'
        >
            <Dropdown className='dropdown'>
                <SwitcherButton
                    onClick={open}
                    label={intl.formatMessage({
                        defaultMessage: 'Catalog language',
                        id: 'locale.SelectContextLocale.3088b7',
                    })}
                    className={inline ? 'inline switcher' : 'switcher'}
                    data-testid='switcher-locales'
                    inline={inline}
                >
                    <div
                        className='current-locale'
                        data-testid='dropdown-trigger'
                    >
                        <Flag flagCode={currentLocale?.code.split('_')[1].toLowerCase() || ''} />
                        <span>{currentLocale?.label}</span>
                    </div>
                </SwitcherButton>
                {isOpen && (
                    <Dropdown.Overlay
                        verticalPosition='down'
                        onClose={close}
                    >
                        <Dropdown.Header>
                            <Dropdown.Title>
                                {intl.formatMessage({
                                    defaultMessage: 'Catalog language',
                                    id: 'locale.SelectContextLocale.3088b7',
                                })}
                            </Dropdown.Title>
                        </Dropdown.Header>

                        <Dropdown.ItemCollection>
                            {locales.map((locale: CatalogLocale) => (
                                <Dropdown.Item
                                    onClick={(): void => handleChangeLocale(locale)}
                                    isActive={locale.code === currentLocale?.code}
                                    key={locale.code}
                                    className={locale.code === currentLocale?.code ? 'active' : ''}
                                >
                                    <Flag flagCode={locale.code.split('_')[1].toLowerCase()} />
                                    <span>{locale.label}</span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.ItemCollection>
                    </Dropdown.Overlay>
                )}
            </Dropdown>
        </Root>
    );
};

export default SelectContextLocale;
