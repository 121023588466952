import difference from 'lodash/difference';

import httpClient, { isServerError } from 'src/tools/HttpClient';
import { ThunkResult } from 'src/tools/globalStore';
import { actions as filterActions } from 'src/components/Filter/store';

export const display = (display: boolean): ExportDisplayAction => ({
    type: 'EXPORT_DISPLAY',
    payload: display,
});

const setExportOption = <T>(key: string, value: T): ExportSetOptionAction => ({
    type: 'EXPORT_SET_OPTION',
    key,
    value,
});

const exportProducts =
    (locale: string, searchContexts: SearchContext[]): ThunkResult<Promise<string>> =>
    async (dispatch, getState): Promise<string> => {
        const state = getState();

        const {
            withMedia,
            withReferenceEntityMedia,
            selectedAttributes,
            allAttributes,
            format,
            withCover,
            withTableOfContent,
            withGlossary,
            selectedPdfCover,
        } = state.productExport.exportOptions;
        const { totalCount } = state.productSelection;

        const payload: ExportProductsPayload = {
            searchContexts,
            withMedia,
            withReferenceEntityMedia,
            format,
            selectedCount: totalCount,
            withCover,
            withTableOfContent,
            withGlossary,
            selectedPdfCover: selectedPdfCover,
        };

        if (!allAttributes) {
            payload.selectedAttributes = selectedAttributes;
        }

        try {
            await httpClient.post(`/products/export/${locale}`, payload);
            return 'success';
        } catch (err: any) {
            if (!isServerError(err.response)) {
                return 'exportError';
            }
            return 'error';
        }
    };

const getSelectedAttributes =
    (options: {
        locale: string;
        forceSelectedAttributes: Array<{ code: string; type: string }>;
    }): ThunkResult<Promise<FilterType[]>> =>
    async (dispatch, getState): Promise<FilterType[]> => {
        const { locale, forceSelectedAttributes } = options;

        const state = getState();
        const selectedAttributes = [...state.productExport.exportOptions.selectedAttributes];

        let attributeCodes = selectedAttributes.map((attribute) => attribute.code);
        const forceSelectedAttributeCodes = forceSelectedAttributes.map((attribute) => attribute.code);

        const dif: string[] = difference(forceSelectedAttributeCodes, attributeCodes);
        let newCodes: null | string[] = null;
        if (dif.length) {
            attributeCodes = [...dif, ...attributeCodes].filter((el) => el);
            newCodes = attributeCodes;
        }

        const attributes = await dispatch(
            filterActions.getSelectedAttributes({
                locale,
                attributeCodes,
                withTableAttributes: true,
                variationMode: 'ungrouped',
            }),
        );

        if (newCodes) {
            dispatch(setExportOption('selectedAttributes', newCodes));
        }

        return Promise.resolve(attributes);
    };

const fetchPdfSettingsCovers =
    (): ThunkResult<Promise<void>> =>
    async (dispatch): Promise<void> => {
        const { data }: { data: PdfSettingsCover[] } = await httpClient.get(`/pdfSettings/covers`).catch(() => {
            const defaultPdfSettings: PdfSettingsCover[] = [
                {
                    index: '0',
                    cover: null,
                    default: true,
                    name: 'Akeneo',
                    status: 'Enabled',
                    type: 'default',
                },
            ];

            return { data: defaultPdfSettings };
        });
        dispatch({ type: 'SET_CATALOG_PDF_SETTINGS_COVERS', payload: data });
    };

export default { display, setExportOption, exportProducts, getSelectedAttributes, fetchPdfSettingsCovers };
