import React, { useState, useEffect, useCallback, useMemo } from 'react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import parseLocales from 'src/translations/parseLocales.js';
import httpClient from 'src/tools/HttpClient';
import IntlProvider from 'src/translations/IntlProvider';
import { selectors } from 'src/components/Authentication/store';

export type LocaleUI = {
    code: string;
    label: string;
};

export type ContextValue = {
    locale: string;
    locales: LocaleUI[];
    setLocale: (localeCode: string) => Promise<void>;
};

type Props = { children: React.ReactNode };

const defaultLocaleUI = 'en_US';

const Context = React.createContext<ContextValue | undefined>(undefined);

function LocaleUIProvider({ children }: Props) {
    const saveUserLocale = useCallback(async (locale: string) => {
        try {
            await httpClient.post('/accounts/ui_locale', { userInterfaceLocale: locale });
        } catch (err) {
            console.error(err);
        }
    }, []);

    const setLocale = useCallback(
        async (locale: string, save = true) => {
            setContextValue((state) => ({ ...state, locale }));
            save && saveUserLocale(locale);
        },
        [saveUserLocale],
    );

    const [contextValue, setContextValue] = useState<ContextValue>({
        locale: defaultLocaleUI,
        locales: [],
        setLocale,
    });

    const isAuthenticated = useSelector(selectors.isUserAuthenticated);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        void (async (): Promise<void> => {
            const localesString = parseLocales() as string[];
            const locales: LocaleUI[] = map(localesString, (locale: string) => {
                const intLabel = new Intl.DisplayNames([locale.split('_')[0]], { type: 'language' });
                let label = intLabel.of(locale.replace('_', '-'));
                label = label ? `${label[0].toUpperCase()}${label.substring(1)}` : locale; //__ force cap
                return { code: locale, label };
            });

            //__ fetch/set user locale
            const defaultLocale: string = navigator?.language || defaultLocaleUI;

            let userLocale: string = defaultLocale.replace('-', '_');
            let save = false;

            try {
                const response = await httpClient.get('/accounts/ui_locale');

                if (response.data.userInterfaceLocale?.length) {
                    userLocale = response.data.userInterfaceLocale as string;
                } else {
                    if (!locales.find((locale) => locale.code === userLocale)) {
                        const languageCode = userLocale.split(/[-_]/)[0];
                        userLocale =
                            locales.find((locale) => locale.code.startsWith(languageCode))?.code || defaultLocaleUI;
                    }

                    save = true;
                }
            } catch (err) {
                console.error(err);
            }

            setContextValue((state) => ({ ...state, locales, locale: userLocale }));
            save && saveUserLocale(userLocale);
        })();
    }, [saveUserLocale, isAuthenticated]);

    const intlProvider = useMemo(() => {
        return <IntlProvider locale={contextValue.locale}>{children}</IntlProvider>;
    }, [children, contextValue.locale]);

    return <Context.Provider value={contextValue}>{intlProvider}</Context.Provider>;
}

function useLocaleUI(): ContextValue {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error('useLocaleUI must be used within a LocaleUIProvider');
    }
    return context;
}

export { LocaleUIProvider, useLocaleUI };
