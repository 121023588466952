import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { FixedSizeGrid as Grid, GridOnScrollProps } from 'react-window';

import Loader from 'src/shared/loader/Loader';

const Root = styled.div<{ paddingRight: number }>`
    flex: 1;
    overflow: hidden; //__ IMPORTANT : prevents flickering while resizing ( autosizer width race condition )

    .grid {
        &.scrollbar-thin {
            ::-webkit-scrollbar,
            *::-webkit-scrollbar {
                background-color: ${theme.color.grey40};
                width: 6px;
                height: 6px;
                border-radius: 3px;
            }

            ::-webkit-scrollbar-thumb,
            *::-webkit-scrollbar-thumb {
                background-color: ${theme.color.grey80};
                border-radius: 3px;
            }
        }
    }

    .virtual-scroll-item {
        overflow: hidden;
    }

    > .loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 200px;
        z-index: 10;
        background-color: transparent;
        padding-right: ${(props): number => props.paddingRight}px;

        .loader {
            transform: scale(0.5);
        }
    }

    > .box-empty-result {
        padding-right: ${(props): number => props.paddingRight}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const defaultRowHeight = (colWidth: number): number => colWidth + 60;

// From react-virtualized-auto-sizer
export type Size = {
    height?: number;
    width?: number;
};

export type Props<ItemType = unknown> = {
    itemCount?: number;
    getItem?: (index: number) => ItemType | null;
    renderItem: (props: RenderItemProps<ItemType>) => React.ReactElement | null;
    rowHeight: number | ((colWidth: number) => number);
    loadMoreItems?: (startIndex?: number, stopIndex?: number) => Promise<void> | void;
    gutterWidth?: number;
    paddingRight?: number;
    columnCount?: number;
    overscanRowCount?: number;
    overscanColumnCount?: number;
    scroll?: {
        top?: number;
        left?: number;
    };
    minimumBatchSize?: number; // important : fetch paging size
    innerElementType?: React.ElementType;
    outerElementType?: React.ElementType;
    thinScrollbars?: boolean;
    loading?: boolean;
    complete?: boolean;
    emptyResult?: React.ReactElement;
};

const VirtualScroll = <ItemType extends DefaultItem>(props: Props<ItemType>): React.ReactElement => {
    const {
        getItem: propsGetItem,
        itemCount = 0,
        loadMoreItems,
        gutterWidth = 24,
        paddingRight = 0,
        columnCount = 1,
        overscanRowCount = 1,
        overscanColumnCount = 1,
        rowHeight: propsRowHeight = defaultRowHeight,
        renderItem: propsRenderItem,
        scroll,
        minimumBatchSize = 50,
        innerElementType,
        outerElementType,
        thinScrollbars = true,
        loading = false,
        complete = false,
        emptyResult,
    } = props;

    const getRowHeight = useCallback(
        (colWidth: number) => {
            return typeof propsRowHeight === 'function' ? propsRowHeight(colWidth) : propsRowHeight;
        },
        [propsRowHeight],
    );

    const getItem = useCallback(
        (index: number) => {
            return propsGetItem ? propsGetItem(index) : null;
        },
        [propsGetItem],
    );

    const isItemLoaded = useCallback((index: number) => !!getItem(index), [getItem]);

    const infiniteLoaderRef = useRef<InfiniteLoader>(null);
    const hasMountedRef = useRef(false);
    useEffect(() => {
        if (!propsGetItem) {
            return;
        }
        if (hasMountedRef.current) {
            if (infiniteLoaderRef.current) {
                infiniteLoaderRef.current.resetloadMoreItemsCache(true);
            }
        }
        hasMountedRef.current = true;
    }, [propsGetItem]);

    const rowCount = Math.ceil(itemCount / columnCount);

    const itemDataRef = useRef<ItemData>({ colWidth: 100, rowHeight: 100 });

    const renderItem = useCallback(
        (props: { data: ItemData; columnIndex: number; rowIndex: number; style: React.CSSProperties }) => {
            const { data, columnIndex, rowIndex, style: _style } = props;

            const index = rowIndex * columnCount + columnIndex;

            const item = getItem(index);

            if (!item) {
                return null;
            }

            const style = { ..._style, marginLeft: gutterWidth * columnIndex };

            return propsRenderItem({ item, index, style, data });
        },
        [getItem, columnCount, gutterWidth, propsRenderItem],
    );

    const gridRef = useRef<Grid | null>(null);

    const handleLoadMoreItems = useCallback(
        async (indexStart: number, indexEnd: number) => {
            if (loading) {
                return;
            }
            loadMoreItems && (await loadMoreItems(indexStart, indexEnd));
        },
        [loadMoreItems],
    );

    const unmount = useRef(false);
    useEffect(() => {
        unmount.current = false;
        return () => {
            unmount.current = true;
        };
    }, []);

    const currentScroll = useRef({ scrollLeft: scroll?.left || 0, scrollTop: scroll?.top || 0 });
    const handleScroll = useCallback(({ scrollUpdateWasRequested, scrollLeft, scrollTop }: GridOnScrollProps) => {
        if (scrollUpdateWasRequested || (!scrollTop && !scrollLeft)) {
            return;
        }
        currentScroll.current = { scrollLeft, scrollTop };
    }, []);

    const displayedItemState = useRef({ index: 0, loaded: false });

    useEffect(() => {
        if (loading || !gridRef.current) {
            return;
        }
        const loaded = isItemLoaded(displayedItemState.current.index);
        if (loaded && !displayedItemState.current.loaded) {
            gridRef.current.scrollTo({
                ...currentScroll.current,
                scrollTop: currentScroll.current.scrollTop - 0.00001,
            });
        }
        displayedItemState.current.loaded = loaded;
    }, [loading, isItemLoaded]);

    const renderGrid = useCallback(
        (size: Size) => {
            const { width = 0, height = 0 } = size;
            const availableWidth = width - Math.max(thinScrollbars ? 6 : 18, paddingRight); //__ vertical scrollbar compensation
            itemDataRef.current.colWidth = (availableWidth - (columnCount - 1) * gutterWidth) / columnCount;
            itemDataRef.current.rowHeight = getRowHeight(itemDataRef.current.colWidth);

            return (
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={handleLoadMoreItems}
                    minimumBatchSize={minimumBatchSize}
                    ref={infiniteLoaderRef}
                >
                    {({ onItemsRendered, ref }): ReactElement => {
                        return (
                            <Grid
                                itemData={itemDataRef.current}
                                className={`grid ${thinScrollbars ? 'scrollbar-thin' : ''}`}
                                ref={(grid): void => {
                                    ref(grid);
                                    gridRef.current = grid;
                                }}
                                innerElementType={innerElementType}
                                outerElementType={outerElementType}
                                width={width}
                                height={height - 1}
                                rowHeight={itemDataRef.current.rowHeight}
                                rowCount={rowCount}
                                columnCount={columnCount}
                                columnWidth={itemDataRef.current.colWidth}
                                overscanColumnCount={overscanColumnCount}
                                overscanRowCount={overscanRowCount}
                                onScroll={handleScroll}
                                onItemsRendered={(renderedProps): void => {
                                    const {
                                        visibleRowStartIndex,
                                        visibleRowStopIndex,
                                        visibleColumnStartIndex,
                                        visibleColumnStopIndex,
                                        overscanRowStartIndex,
                                        overscanRowStopIndex,
                                        overscanColumnStartIndex,
                                        overscanColumnStopIndex,
                                    } = renderedProps;
                                    //__ transform 2D indexes to 1D indexes to allow infinite loader to trigger loadMoreItems
                                    const res = {
                                        visibleStartIndex: visibleRowStartIndex * columnCount + visibleColumnStartIndex,
                                        visibleStopIndex: visibleRowStopIndex * columnCount + visibleColumnStopIndex,
                                        overscanStartIndex:
                                            overscanRowStartIndex * columnCount + overscanColumnStartIndex,
                                        overscanStopIndex: overscanRowStopIndex * columnCount + overscanColumnStopIndex,
                                    };
                                    displayedItemState.current.loaded = false;
                                    displayedItemState.current.index = Math.min(itemCount - 1, res.visibleStopIndex);
                                    onItemsRendered(res);
                                }}
                            >
                                {renderItem}
                            </Grid>
                        );
                    }}
                </InfiniteLoader>
            );
        },
        [
            columnCount,
            getRowHeight,
            gutterWidth,
            handleLoadMoreItems,
            innerElementType,
            isItemLoaded,
            minimumBatchSize,
            overscanColumnCount,
            overscanRowCount,
            paddingRight,
            renderItem,
            rowCount,
            itemCount,
            thinScrollbars,
            handleScroll,
            outerElementType,
        ],
    );

    return (
        <Root
            className='virtual-scroll'
            role='list'
            paddingRight={paddingRight}
        >
            {complete && itemCount === 0 ? (
                <div className='box-empty-result'>{emptyResult}</div>
            ) : (
                <AutoSizer>{renderGrid}</AutoSizer>
            )}
            {loading && (
                <div className='loading'>
                    <Loader />
                </div>
            )}
        </Root>
    );
};

export default VirtualScroll;
