import map from 'lodash/map';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { sharedCatalogsTheme as theme, SubNavigationPanel, useBooleanState } from 'akeneo-design-system';
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useAppDispatch } from 'src/tools/globalStore';
import CatalogSwitcher from 'src/shared/tenantcatalog/CatalogSwitcher';
import SelectContextLocale from 'src/shared/locale/SelectContextLocale';

import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import { actions as filterActions, selectors as filterSelectors } from 'src/components/Filter/store';

import { Filter } from 'src/shared';
import FiltersManager from '../filtersManager';
import CategoriesFilter from '../categoryFilter/CategoriesFilter';
import { FiltersProvider } from './FiltersContext';
import SidebarFooter from 'src/shared/sidebarfooter/SidebarFooter';

import useMedia from 'src/hooks/useMedia';

//___________
const Root = styled.div<{ isMobile: boolean }>`
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .switcher {
        label {
            margin-bottom: 0;
        }
    }

    .filter-key-categories {
        padding-bottom: 0;
        border-bottom: none;

        .filter-head {
            padding-top: 0;
        }
    }

    .subheader {
        height: 60px;
        border-top: 1px ${theme.color.grey80} solid;
        border-bottom: 1px ${theme.color.grey40} solid;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h3 {
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 15px;
            font-weight: normal;
            color: ${theme.color.grey140};
        }

        .bt-manage {
            margin-right: 2px;
        }
    }

    .drawer {
        left: ${({ isMobile }): number => (isMobile ? 250 : 330) - 1}px;
        border-left: 1px solid transparent;
        transition: border-left 0.5s;

        &.open {
            border-left: 1px solid #ddd;
        }
    }

    .filters-common {
        flex: 1;
        overflow-y: auto;
        padding-left: 2px;
        padding-bottom: 20px;
    }
`;

const Filters: React.FC = () => {
    const unmount = useRef(false);
    useEffect(() => {
        return (): void => {
            unmount.current = true;
        };
    }, []);

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const [isPanelOpen, panelOpen, panelClose] = useBooleanState(true);
    const isMobile = useMedia(['(max-width: 1200px)'], [true], false);

    //__ locale
    const locale = useSelector(contextLocaleSelectors.getContextLocale);

    //__ filters
    const selectedFilters: FilterType[] = useSelector(filterSelectors.getActiveFilters);
    const [filters, setFilters] = useState<FilterType[]>(selectedFilters);

    useEffect(() => {
        setFilters(selectedFilters);
    }, [selectedFilters]);

    useEffect(() => {
        if (!locale) {
            return;
        }
        void (async (): Promise<void> => {
            const f = await dispatch(filterActions.fetchActiveFilters(locale));
            if (unmount.current) {
                return;
            }
            setFilters(f);
        })();
    }, [dispatch, locale]);

    const handleClearFilterValues = useCallback(() => {
        dispatch(filterActions.resetFiltersValuesAction());
        setFilters([]);
    }, [dispatch, filters]);

    return (
        <Root
            isMobile={isMobile}
            className={`filters scrollbar-light nav-panel ${isPanelOpen ? 'nav-panel-open' : ''}`}
        >
            <SubNavigationPanel
                open={panelOpen}
                close={panelClose}
                isOpen={isPanelOpen}
            >
                <FiltersProvider>
                    <div className='main'>
                        <CatalogSwitcher />
                        <SelectContextLocale />
                        <CategoriesFilter />

                        <div className='subheader'>
                            <h3>{intl.formatMessage({ defaultMessage: 'Filters', id: 'filter.Filters.cd23af' })}</h3>
                            <FiltersManager handleReset={handleClearFilterValues} />
                        </div>
                    </div>

                    <div className='filters-common'>
                        {map(filters, (filter) => (
                            <Filter
                                key={filter.key}
                                filter={filter}
                                displayMode={filter.type === 'tree' ? 'drawer' : undefined}
                            />
                        ))}
                    </div>
                </FiltersProvider>

                <SidebarFooter />
            </SubNavigationPanel>
        </Root>
    );
};

export default Filters;
