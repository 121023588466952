import {
    ArrowDownIcon,
    ArrowUpIcon,
    Badge,
    Button,
    Collapse,
    IconButton,
    SectionTitle,
    sharedCatalogsTheme as theme,
} from 'akeneo-design-system';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors as brandingSelectors } from 'src/shared/branding/store';

import Attribute from 'src/components/ProductPage/components/attributes/Attribute';
import { EmptyResult, Image } from 'src/shared';
import AttributeModal from 'src/components/ProductPage/components/attributes/AttributeModal';

type Props = {
    productLabel: string;
    attributeGroups: AttributeGroup[];
    collapseAttributeGroup: { [key: string]: boolean };
    handleCollapseAttributeGroup: (attributeGroupCode: string) => void;
};

const Root = styled.div<{ colorBranding: string }>`
    color: ${theme.color.grey140};

    .attribute-group-badge {
        margin-left: 5px;
    }

    .product-attribute {
        display: grid;
        grid-template-columns: 340px 1fr;
    }

    .attribute-title {
        background-color: ${theme.color.grey20};
        border-bottom: solid 1px white;

        h3 {
            font-weight: normal;
            font-size: 15px;
            padding: 14px 20px;
            margin: 0;
        }
    }

    .attribute-value {
        display: flex;
        align-items: center;
        border-bottom: solid 1px ${theme.color.grey20};
        font-size: 15px;
        padding: 14px 20px;

        * {
            font-size: 15px;
        }
    }

    .collapse {
        & > div {
            border: none;

            & > div:first-child {
                position: sticky;
                top: 174px;
                z-index: 5;
                background-color: white;
            }
        }

        button[title='Collapse'] {
            display: none;
        }
    }

    .reference-entity-attribute {
        display: flex;
        align-items: center;

        &.space-beside:not(:last-child) {
            margin-bottom: 20px;
        }

        .attribute-value-reference-entity {
            display: flex;
            align-items: center;
            width: 400px;

            .box-img {
                height: 44px;
                width: 44px;
                border: solid 1px ${theme.color.grey100};
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
            }

            span {
                margin: 0 10px;
            }
        }
    }
`;

const ProductAttributeGroup: React.FC<Props> = (props) => {
    const { attributeGroups, collapseAttributeGroup, handleCollapseAttributeGroup, productLabel } = props;

    const intl = useIntl();
    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    // ___ Modal.
    const [attributeInModal, setAttributeInModal] = useState<TableAttribute | ReferenceEntityAttribute | undefined>(
        undefined,
    );
    const [index, setIndex] = useState(0);
    const [displayModal, setDisplayModal] = useState(false);
    const handleModal = useCallback((attribute: TableAttribute | ReferenceEntityAttribute, index = 0) => {
        setIndex(index);
        setAttributeInModal(attribute);
        setDisplayModal(true);
    }, []);

    return (
        <Root colorBranding={colorBranding}>
            {attributeGroups && attributeGroups.length == 0 ? (
                <EmptyResult
                    title={intl.formatMessage({
                        defaultMessage: 'Sorry, there are no attributes matching your search.',
                        id: 'attributes.ProductAttributeGroup.f081ab',
                    })}
                    subtitle={intl.formatMessage({
                        defaultMessage: 'Try again with new search criteria.',
                        id: 'attributes.ProductAttributeGroup.badd58',
                    })}
                />
            ) : (
                attributeGroups.map((attributeGroup: AttributeGroup) => (
                    <div
                        className='collapse'
                        key={attributeGroup.code}
                    >
                        <Collapse
                            collapseButtonLabel='Collapse'
                            isOpen={collapseAttributeGroup[attributeGroup.code]}
                            onCollapse={(): void => handleCollapseAttributeGroup(attributeGroup.code)}
                            label={
                                <SectionTitle className='collapse-title'>
                                    <SectionTitle.Title>{attributeGroup.label}</SectionTitle.Title>
                                    <Badge className='sections-title-badge branding-badge'>
                                        {attributeGroup.attributes.length}
                                    </Badge>
                                    <SectionTitle.Spacer />
                                    <IconButton
                                        title='arrow-collapse'
                                        icon={
                                            collapseAttributeGroup[attributeGroup.code] ? (
                                                <ArrowUpIcon />
                                            ) : (
                                                <ArrowDownIcon />
                                            )
                                        }
                                    />
                                </SectionTitle>
                            }
                            key={attributeGroup.code}
                        >
                            {attributeGroup.attributes.map((attribute: AttributeType) => (
                                <div
                                    className='product-attribute'
                                    key={attribute.code}
                                >
                                    <div className='attribute-title'>
                                        <h3>{attribute.label}</h3>
                                    </div>

                                    <div className='attribute-value'>
                                        {attribute.type === 'reference_entity' ? (
                                            attribute.value.length ? (
                                                <div>
                                                    {attribute.value.map((refEntityValue, index: number) => (
                                                        <div
                                                            className={`reference-entity-attribute ${
                                                                attribute.value.length > 1 ? 'space-beside' : ''
                                                            }`}
                                                            key={index}
                                                        >
                                                            <div className='attribute-value-reference-entity'>
                                                                <div className='box-img'>
                                                                    <Image
                                                                        src={refEntityValue.image?.thumbnail}
                                                                        title={refEntityValue.label || ''}
                                                                    />
                                                                </div>
                                                                <span>{refEntityValue.label}</span>
                                                            </div>
                                                            <Button
                                                                ghost
                                                                level='tertiary'
                                                                onClick={(): void => handleModal(attribute, index)}
                                                            >
                                                                {intl.formatMessage({
                                                                    defaultMessage: 'View details',
                                                                    id: 'attributes.ProductAttributeGroup.327a54',
                                                                })}
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                '-'
                                            )
                                        ) : attribute.type === 'table' ? (
                                            attribute.columnLabels && attribute.value.length > 0 ? (
                                                <Button
                                                    ghost
                                                    level='tertiary'
                                                    onClick={(): void => handleModal(attribute)}
                                                >
                                                    {intl.formatMessage({
                                                        defaultMessage: 'View table',
                                                        id: 'attributes.ProductAttributeGroup.f5d23f',
                                                    })}
                                                </Button>
                                            ) : (
                                                '-'
                                            )
                                        ) : (
                                            <Attribute attribute={attribute} />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Collapse>
                    </div>
                ))
            )}
            <AttributeModal
                productLabel={productLabel}
                attribute={attributeInModal}
                initialIndex={index}
                display={displayModal}
                handleDisplayModal={setDisplayModal}
            />
        </Root>
    );
};

export default ProductAttributeGroup;
