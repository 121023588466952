import { useEffect, useRef } from 'react';

type Options = { eventType?: string };

const useClickout = (
    refElement: React.RefObject<HTMLElement>,
    handler: (event: Event) => void,
    options: Options = {},
): void => {
    const { eventType = 'click' } = options;

    const elementClicked = useRef(false);

    const element = refElement.current;

    useEffect(() => {
        const elementListener = (): void => {
            elementClicked.current = true;
        };

        if (element) {
            element.addEventListener(eventType, elementListener);
            element.addEventListener('touchend', elementListener);
        }

        const documentListener = (event: Event): void => {
            if (elementClicked.current) {
                elementClicked.current = false;
                return;
            }
            handler(event);
        };

        document.addEventListener(eventType, documentListener);
        document.addEventListener('touchend', documentListener);

        return (): void => {
            if (element) {
                element.removeEventListener(eventType, elementListener);
            }
            document.removeEventListener(eventType, documentListener);
            document.removeEventListener('touchend', documentListener);
        };
    }, [element, handler, eventType]);
};

export default useClickout;
