import map from 'lodash/map';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ArrowLeftIcon, Button, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useEffect, useState, useCallback, useRef } from 'react';

import { useAppDispatch } from 'src/tools/globalStore';
import MobileSelectContextLocale from 'src/shared/locale/MobileSelectContextLocale';
import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import { actions as filterActions, selectors as filterSelectors } from 'src/components/Filter/store';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

import { Filter } from 'src/shared';
import CategoriesFilter from '../categoryFilter/CategoriesFilter';
import { FiltersProvider } from './FiltersContext';

type Props = {
    open?: boolean;
};

const Root = styled.div<{ colorBranding: string }>`
    .bt-open {
        font-size: 15px;
        text-transform: uppercase;
        color: ${({ colorBranding }): string => colorBranding};
        border: 1px solid ${theme.color.white};
        background-color: ${theme.color.white};
        padding-right: 0;
        margin: 0;
        margin-top: 25px;
        margin-bottom: 15px;
        align-self: flex-end;
        cursor: pointer;
    }

    > .modal {
        position: fixed;
        z-index: 99;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: ${theme.color.white};
        padding: 30px;
        padding-top: 50px;
        padding-bottom: 35px;
        box-sizing: border-box;
        display: grid;
        grid-template-rows: min-content 1fr min-content;

        .bt-locale-open {
            margin-top: 25px;
            margin-bottom: 15px;
        }
    }

    .bt-close {
        display: flex;
        cursor: pointer;
        svg {
            color: ${theme.color.grey100};
        }
    }
`;

const Informations = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Header = styled.div<{ colorBranding: string }>`
    align-items: center;
    display: grid;
    grid-template-columns: max-content 1fr;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ colorBranding }): string => colorBranding};
`;

const Title = styled.p<{ colorBranding: string }>`
    color: ${({ colorBranding }): string => colorBranding};
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
`;

const Reset = styled.p<{ colorBranding: string }>`
    color: ${({ colorBranding }): string => colorBranding};
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
`;

const Filters = styled.div`
    overflow: auto;
`;

const ApplyButtonWrapper = styled.div`
    border-top: 1px solid ${theme.color.grey80};
    padding-top: 30px;
    display: flex;
    button {
        width: auto;
        margin: auto;
    }
`;

const FilterCommon = styled.div`
    margin: 0;
    color: ${theme.color.grey140};
    font-size: 18px;
`;

const MobileFilters: React.FC<Props> = (props) => {
    const { open: propsOpen = false } = props;

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const unmount = useRef(false);
    useEffect(() => {
        return (): void => {
            unmount.current = true;
        };
    }, []);

    const [open, setOpen] = useState(propsOpen);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    //__ locale
    const locale = useSelector(contextLocaleSelectors.getContextLocale);

    //__ filters
    const selectedFilters: FilterType[] = useSelector(filterSelectors.getActiveFilters);
    const [filters, setFilters] = useState<FilterType[]>(selectedFilters);

    useEffect(() => {
        setFilters(selectedFilters);
    }, [selectedFilters]);

    useEffect(() => {
        if (!locale) {
            return;
        }
        void (async (): Promise<void> => {
            const f = await dispatch(filterActions.fetchActiveFilters(locale));
            if (unmount.current) {
                return;
            }
            setFilters(f);
        })();
    }, [dispatch, locale]);

    const handleClickResetFilters = (): void => {
        dispatch(filterActions.resetFiltersValuesAction());
    };

    return (
        <Root
            colorBranding={colorBranding}
            className='mobile-filters'
        >
            <FiltersProvider>
                <button
                    onClick={handleOpen}
                    className='bt-open'
                >
                    {intl.formatMessage({ defaultMessage: 'Filters', id: 'filter.MobileFilters.cd23af' })}
                </button>
                {open && (
                    <div className='modal'>
                        <Header colorBranding={colorBranding}>
                            <div
                                className='bt-close'
                                onClick={handleClose}
                            >
                                <ArrowLeftIcon />
                            </div>
                            <Informations>
                                <Title colorBranding={colorBranding}>
                                    {intl.formatMessage({
                                        defaultMessage: 'Filters',
                                        id: 'filter.MobileFilters.cd23af',
                                    })}
                                </Title>
                                <Reset
                                    colorBranding={colorBranding}
                                    onClick={handleClickResetFilters}
                                >
                                    {intl.formatMessage({ defaultMessage: 'Reset', id: 'filter.MobileFilters.8e6fec' })}
                                </Reset>
                            </Informations>
                        </Header>

                        <Filters>
                            <MobileSelectContextLocale />
                            <CategoriesFilter />

                            <FilterCommon>
                                {map(filters, (filter) => (
                                    <Filter
                                        key={filter.key}
                                        filter={filter}
                                        displayMode={filter.type === 'tree' ? 'drawer' : undefined}
                                    />
                                ))}
                            </FilterCommon>
                        </Filters>

                        <ApplyButtonWrapper>
                            <Button onClick={handleClose}>
                                {intl.formatMessage({ defaultMessage: 'Apply', id: 'filter.MobileFilters.116c3f' })}
                            </Button>
                        </ApplyButtonWrapper>
                    </div>
                )}
            </FiltersProvider>
        </Root>
    );
};

export default MobileFilters;
