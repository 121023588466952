import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Route from 'src/security/Route';
import Branding from 'src/shared/branding/BrandingLogo';
import AccountMenu from 'src/components/AccountMenu/components/AccountMenu';
import useMedia from 'src/hooks/useMedia';

const Root = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        a {
            width: ${({ isMobile }): number => (isMobile ? 250 : 330) - 60}px;
            height: 100%;
            display: flex;
            justify-content: center;
        }

        img {
            object-fit: contain;
            max-width: 100%;
        }
    }

    > nav {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    @media (max-width: 768px) {
        .logo {
            display: none;
        }
    }
`;

const Navigation: React.FC = () => {
    const isMobile = useMedia(['(max-width: 1200px)'], [true], false);

    return (
        <Root isMobile={isMobile}>
            <div className='logo'>
                <Route
                    path='/'
                    privateRoute={true}
                >
                    <Link to={`/`}>
                        <Branding />
                    </Link>
                </Route>
            </div>
            <nav>
                <AccountMenu />
            </nav>
        </Root>
    );
};

export default Navigation;
