import { map } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import { serializeAttributes } from 'src/components/ProductGrid/store/productGrid/actions';

const selectionState: ProductSelectionState = {
    totalCount: 0,
    searchContexts: [],
    updates: 0,
};

export const persistWhitelist = ['searchContexts'];

let currentSearch: null | Search = null;
const SelectionReducer = (
    state = selectionState,
    action: ProductSelectionActions | RehydrateAction,
): ProductSelectionState => {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            //__ reassign search reference after reload rehydratation from localStorage
            if (action.payload?.search?.currentSearch) {
                currentSearch = action.payload.search.currentSearch;
            }

            if (!action.payload?.productSelection) {
                return state;
            }

            //__ fix previous search.locale null
            const catalogLocale: string = action.payload?.search?.currentSearch?.locale || 'en_US';
            each(action.payload.productSelection.searchContexts, (searchContext: SearchContext) => {
                if (!searchContext.search.locale) {
                    searchContext.search.locale = catalogLocale;
                }
            });

            return { ...state, ...action.payload.productSelection };
        }

        case 'PRODUCT_SELECTION_TOTAL':
            return { ...state, totalCount: action.total };

        case 'SELECT_PRODUCT': {
            const { select, productKey, search } = action;
            const newState = { ...state, totalCount: select ? ++state.totalCount : Math.max(0, state.totalCount - 1) };
            let searchContext: SearchContext = state.searchContexts[state.searchContexts.length - 1];

            //__ search contexts
            if (!searchContext || currentSearch !== search || searchContext.global || searchContext.mode !== 'manual') {
                searchContext = {
                    mode: 'manual',
                    selectedProducts: [],
                    partiallySelectedProducts: [],
                    unSelectedProducts: [],
                    search: serializeSearch(search),
                };
                newState.searchContexts.push(searchContext);
                currentSearch = search;
            }

            searchContext.mode = 'manual';

            //___ selection
            if (select) {
                searchContext.selectedProducts.push(productKey);
                searchContext.unSelectedProducts = searchContext.unSelectedProducts.filter((pid) => productKey !== pid);
            } else {
                searchContext.unSelectedProducts.push(productKey);
                searchContext.selectedProducts = searchContext.selectedProducts.filter((pid) => productKey !== pid);
            }

            ++newState.updates; //__ force refetch selection

            return newState;
        }

        case 'SELECT_PRODUCTS': {
            const { select, productKeysAndSelection, search } = action;
            const newState = { ...state };

            let searchContext: SearchContext = state.searchContexts[state.searchContexts.length - 1];

            //__ search contexts
            if (!searchContext || currentSearch !== search || searchContext.global || searchContext.mode !== 'manual') {
                searchContext = {
                    mode: 'manual',
                    selectedProducts: [],
                    unSelectedProducts: [],
                    search: serializeSearch(search),
                };
                newState.searchContexts.push(searchContext);
                currentSearch = search;
            }

            searchContext.mode = 'manual';

            let totalCount = 0;
            //___ selection
            if (select) {
                map(productKeysAndSelection, (productKeyAndIdentifier) => {
                    searchContext.selectedProducts.push(productKeyAndIdentifier.identifier);
                    searchContext.unSelectedProducts = searchContext.unSelectedProducts.filter(
                        (pid) => productKeyAndIdentifier.identifier !== pid,
                    );
                    if (productKeyAndIdentifier.isSelected === false) {
                        totalCount++;
                    }
                });
            } else {
                map(productKeysAndSelection, (productKeyAndIdentifier) => {
                    searchContext.unSelectedProducts.push(productKeyAndIdentifier.identifier);
                    searchContext.selectedProducts = searchContext.selectedProducts.filter(
                        (pid) => productKeyAndIdentifier.identifier !== pid,
                    );
                    if (productKeyAndIdentifier.isSelected === false) {
                        totalCount++;
                    }
                });
            }

            newState.totalCount = select ? state.totalCount + totalCount : Math.max(0, state.totalCount - totalCount);
            ++newState.updates; //__ force refetch selection

            return newState;
        }

        case 'SELECT_PRODUCTS_ALL':
        case 'SELECT_PRODUCTS_NONE': {
            const { search } = action;

            const newState = { ...state };

            const all = action.type === 'SELECT_PRODUCTS_ALL';

            let searchContext: SearchContext = state.searchContexts[state.searchContexts.length - 1];

            if (!all && state.searchContexts.length === 1 && currentSearch === search) {
                //__ special reset case when only search context would be 'none' which would produce a wrong non empty selection
                return { ...state, totalCount: 0, searchContexts: [] };
            }

            if (!searchContext || currentSearch !== search || searchContext.global) {
                searchContext = {
                    mode: 'all',
                    selectedProducts: [],
                    partiallySelectedProducts: [],
                    unSelectedProducts: [],
                    search: serializeSearch(search),
                };
                newState.searchContexts.push(searchContext);
                currentSearch = search;
            }

            searchContext.mode = all ? 'all' : 'none';
            searchContext.selectedProducts = [];
            searchContext.unSelectedProducts = [];
            newState.searchContexts = [...state.searchContexts]; //__ force refetch search + selection

            return newState;
        }

        case 'PRODUCT_SELECTION_REMOVE': {
            const { productKey, locale } = action;

            const newState = { ...state };

            let searchContext: SearchContext = state.searchContexts[state.searchContexts.length - 1];
            if (!searchContext.global) {
                searchContext = {
                    mode: 'manual',
                    selectedProducts: [],
                    unSelectedProducts: [],
                    partiallySelectedProducts: [],
                    search: {
                        queryString: null,
                        locale,
                        families: [],
                        attributes: [],
                        categories: [],
                        excluded_categories: [],
                    },
                    global: true,
                };
                newState.searchContexts.push(searchContext);
            }

            searchContext.unSelectedProducts.push(productKey);
            searchContext.selectedProducts = searchContext.selectedProducts.filter((pid) => productKey !== pid);
            newState.totalCount = Math.max(0, newState.totalCount - 1);
            newState.searchContexts = [...state.searchContexts]; //__ force refetch search + selection

            return newState;
        }

        case 'PRODUCT_SELECTION_RESET': {
            return { ...state, totalCount: 0, searchContexts: [] };
        }

        default:
            return state;
    }
};

export const serializeSearch = (search: Search): SearchSerialized => {
    const attributes = search.attributes;
    const res = cloneDeep(search as unknown) as SearchSerialized;
    if (attributes) {
        res.attributes = serializeAttributes(attributes);
    }
    return res;
};

export default SelectionReducer;
