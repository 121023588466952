import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';

import PreviewActions from './PreviewActions';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 423px);
    flex-direction: column;
    min-height: 100px;
    box-sizing: border-box;
    height: 100%;
`;

const Border = styled.div`
    border: 1px solid ${theme.color.grey80};
    padding: 20px;
    overflow: hidden auto;
`;

const EmbedPlayer = styled.iframe`
    width: 640px;
    height: 360px;
    border: none;
    max-height: calc(100vh - 445px);
`;

type EmbedPreviewProps = {
    media: PreviewMedia;
    url: string;
};

const EmbedPreview: React.FC<EmbedPreviewProps> = ({ media, url }) => {
    const intl = useIntl();

    return (
        <Container>
            <Border
                aria-label={intl.formatMessage({ defaultMessage: 'Preview', id: 'components.EmbedPreview.4c9a39' })}
            >
                <EmbedPlayer
                    src={url}
                    allowFullScreen
                    referrerPolicy='no-referrer-when-downgrade'
                />
                <PreviewActions media={media} />
            </Border>
        </Container>
    );
};

export default EmbedPreview;
