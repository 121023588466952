import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TableInput } from 'akeneo-design-system';
import { map } from 'lodash';
import { useIntl } from 'react-intl';

//____________
type Props = {
    attribute: TableAttribute;
};

const Root = styled.div`
    .table-attribute-input {
        overflow: auto;
        max-height: calc(100vh - 200px);
        max-width: calc(100vw - 200px);
        margin: auto;
    }

    thead tr:first-child {
        position: sticky;
        top: 0;
        z-index: 5;
    }

    td:first-child {
        font-weight: bold;
    }

    tr:nth-child(even) td {
        background-color: white;
    }

    tr td,
    tr th {
        word-wrap: break-word;
        overflow-wrap: break-word;
        text-overflow: initial;
        vertical-align: top;
        overflow: inherit;
        white-space: break-spaces;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    table tr td div {
        line-height: 17px;
        text-overflow: inherit;
        height: initial;
        overflow: initial;
        white-space: break-spaces;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .number-rows {
        text-align: right;
        margin-bottom: 15px;
    }
`;

//__ Component
const TableAttribute: React.FC<Props> = (props) => {
    const { columnLabels, value } = props.attribute;

    const intl = useIntl();

    // __ Number of rows
    const numberOfRows = useMemo(() => {
        return (
            <div className='number-rows'>
                {intl.formatMessage(
                    {
                        defaultMessage: '{count, plural, =0 {# row} one {# row} other {# rows}}',
                        id: 'attributes.TableAttribute.fa7968',
                    },
                    { count: value.length },
                )}
            </div>
        );
    }, [value.length, intl]);

    return (
        <Root
            className='table-attribute'
            data-testid='table-attribute'
        >
            {numberOfRows}
            <TableInput className='table-attribute-input'>
                <TableInput.Header>
                    {map(columnLabels, (item, index) => (
                        <TableInput.HeaderCell key={`${index}--${item}`}>{item}</TableInput.HeaderCell>
                    ))}
                </TableInput.Header>
                <TableInput.Body>
                    {map(value, (row: [], indexRow: number) => (
                        <TableInput.Row key={indexRow}>
                            {map(row, (item, index) => (
                                <TableInput.Cell key={`${indexRow}--${index}`}>
                                    <TableInput.CellContent key={`${indexRow}--${index}`}>
                                        {item}
                                    </TableInput.CellContent>
                                </TableInput.Cell>
                            ))}
                        </TableInput.Row>
                    ))}
                </TableInput.Body>
            </TableInput>
        </Root>
    );
};

export default TableAttribute;
