import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import FiltersSelected from './FiltersSelected';
import FiltersSearch from './FiltersSearch';

//__
type Props = {
    locale?: string | null;
    selectedFilters?: FilterType[];
    onChange?: (filters: FilterType[]) => void;
    selectedTitle?: string;
    searchValue?: string;
    searchLabel?: string;
    searchContexts?: SearchContext[];
    excludeKeys?: BooleanMap;
    disableKeys?: BooleanMap;
    filterAvailableTypes?: false | Record<string, FilterTypeDef>;
    withTableAttributes?: boolean;
    columnType?: 'gallery' | 'associations' | 'variations';
    variationMode?: 'grouped' | 'ungrouped';
    identifiersAndParentCodes?: IdentifiersAndParentCodes;
    draggable?: boolean;
    usePresetAttributeFilters?: boolean;
};

//___________
const Root = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    overflow: auto;

    > * {
        flex: 1 1;

        padding-right: 15px;

        &:last-child {
            padding-left: 15px;
            padding-right: 0;
        }
    }

    .head {
        height: 44px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        margin: 0 0 20px 0;
    }
`;

const FiltersSelector: React.FC<Props> = (props: Props) => {
    const defaultLocale = useSelector(contextLocaleSelectors.getContextLocale);

    const {
        locale = defaultLocale,
        selectedFilters,
        onChange,
        selectedTitle,
        searchValue,
        searchLabel,
        searchContexts,
        excludeKeys,
        disableKeys,
        filterAvailableTypes,
        withTableAttributes,
        columnType,
        variationMode,
        identifiersAndParentCodes,
        draggable = true,
        usePresetAttributeFilters = false,
    } = props;

    return (
        <Root
            className='filters-selector'
            data-testid='filters-selector'
        >
            <FiltersSelected
                filters={selectedFilters}
                onChange={onChange}
                title={selectedTitle}
                disableRemoveKeys={disableKeys}
                draggable={draggable}
            />
            <FiltersSearch
                locale={locale}
                selectedFilters={selectedFilters}
                onChange={onChange}
                searchValue={searchValue}
                searchLabel={searchLabel}
                searchContexts={searchContexts}
                excludeKeys={excludeKeys}
                disableKeys={disableKeys}
                filterAvailableTypes={filterAvailableTypes}
                withTableAttributes={withTableAttributes}
                columnType={columnType}
                variationMode={variationMode}
                identifiersAndParentCodes={identifiersAndParentCodes}
                usePresetAttributeFilters={usePresetAttributeFilters}
            />
        </Root>
    );
};

export default FiltersSelector;
