import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectors as brandingSelectors } from 'src/shared/branding/store';
import { Modal } from 'src/shared';
import TableAttribute from 'src/components/ProductPage/components/attributes/TableAttribute';
import ReferenceEntityAttribute from 'src/components/ProductPage/components/attributes/ReferenceEntityAttribute';
import Carousel from 'src/shared/preview/components/Carousel';

//____________
type Props = {
    productLabel: string;
    attribute: TableAttribute | ReferenceEntityAttribute | undefined;
    initialIndex: number;
    display: boolean;
    handleDisplayModal: (display: boolean) => void;
};

const Root = styled(Modal)<{ colorBranding: string }>`
    display: flex;
    justify-content: flex-start;
    padding-left: 100px;
    padding-right: 100px;

    .attribute-header {
        text-align: center;

        .breadcrumb {
            text-transform: uppercase;
        }

        .attribute-label {
            font-weight: normal;
            font-size: 28px;
            color: ${({ colorBranding }): string => colorBranding};
        }
    }

    .attribute-value {
        margin-top: 30px;
    }

    .carousel {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

const AttributeModal: React.FC<Props> = (props) => {
    const { attribute, initialIndex, display, handleDisplayModal, productLabel } = props;

    const [index, setIndex] = useState(initialIndex);

    useEffect(() => {
        setIndex(initialIndex);
    }, [initialIndex]);

    const previewMedias: PreviewMedia[] =
        attribute && attribute.type === 'reference_entity'
            ? attribute.images.map((media) => ({
                  filename: media.name,
                  format: media.format,
                  previewFormat: media.previewFormat,
                  downloadUrl: media.download,
                  previewUrl: media.preview,
                  thumbnailUrl: media.thumbnail,
                  sourceUrl: media.source,
                  id: media.id,
                  type: attribute.type,
              }))
            : [];

    const intl = useIntl();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    return (
        <Root
            className='attribute-modal'
            colorBranding={colorBranding}
            onDisplay={handleDisplayModal}
            display={display}
        >
            <div className='attribute-header'>
                <div className='breadcrumb'>
                    {intl.formatMessage({ defaultMessage: 'Product', id: 'attributes.AttributeModal.c7f655' })} /{' '}
                    <span dangerouslySetInnerHTML={{ __html: productLabel }} />
                </div>
                <h1 className='attribute-label'>{attribute?.label}</h1>
            </div>

            <div className='attribute-value'>
                {attribute?.type === 'table' && attribute.columnLabels && attribute.value.length > 0 && (
                    <TableAttribute attribute={attribute} />
                )}

                {attribute?.type === 'reference_entity' && (
                    <ReferenceEntityAttribute
                        attribute={attribute}
                        selectedIndex={index}
                    />
                )}
            </div>

            {attribute?.type === 'reference_entity' && (
                <div className='carousel'>
                    <Carousel
                        title={attribute.label}
                        medias={previewMedias}
                        selectedIndex={index}
                        onSelection={setIndex}
                        initialIndex={initialIndex}
                    />
                </div>
            )}
        </Root>
    );
};

export default AttributeModal;
