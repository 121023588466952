import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';

import { useAppDispatch } from 'src/tools/globalStore';
import { actions as filterAction, selectors as filterSelectors } from 'src/components/Filter/store';
import { selectors as contextLocaleSelectors } from 'src/shared/locale/store/ContextLocale';
import {
    InputCheckbox,
    InputTag,
    InputTree,
    InputText,
    InputTextarea,
    InputSimpleSelect,
    InputNumber,
    InputUnits,
    InputDate,
} from 'src/shared/input';

export type ContextValue = {
    getMapTypes: () => Record<string, FilterTypeDef>;
    getFilterType: (type: string) => null | FilterTypeDef;
    getFilterOperator: (operatorKey: string) => null | FilterOperator;
    getFilterLabel: (filter: FilterType, defaultLabel?: string) => string;
    catalogLocale: string | null;
    onFilterChange: (filter: FilterType, value: any, operator?: FilterOperator | null) => any;
    onSetFilterProps: (filter: FilterType, props: Record<string, any>) => void;
};

type Props = { children: React.ReactNode };

const Context = React.createContext<ContextValue | undefined>(undefined);

function isValidValue(value?: any, operator?: FilterOperator | null): boolean {
    const values = operator?.isRange ? [value?.from, value?.to] : [value];

    for (let i = 0, max = values.length; i < max; i++) {
        const v = values[i];
        if (isNil(v) || !`${v}`?.length) {
            return false;
        }
    }

    return true;
}

const isValidInputUnits = (value: any, operator?: FilterOperator | null): boolean => {
    if (operator?.isRange) {
        return (
            isValidValue(value?.from?.value) &&
            !isNaN((value?.from?.value as number) * 1) &&
            isValidValue(value?.from?.unit) &&
            isValidValue(value?.to?.value) &&
            !isNaN((value?.to?.value as number) * 1)
        );
    } else {
        return isValidValue(value?.value) && !isNaN((value?.value as number) * 1) && isValidValue(value?.unit);
    }
};

const castValueInputUnits = (value: any, operator?: FilterOperator | null): void => {
    if (operator?.isRange) {
        value.from.value *= 1;
        value.to.value *= 1;
        value.to.unit = value.from.unit;
    } else {
        value.value *= 1;
    }
};

function FiltersProvider({ children }: Props) {
    const intl = useIntl();

    const dispatch = useAppDispatch();

    const filtersFacets = useSelector(filterSelectors.getFiltersFacets);
    const catalogLocale = useSelector(contextLocaleSelectors.getContextLocale);

    const valueToString = useCallback(
        (value: any, filter: FilterType, operator = 'in list'): React.ReactNode => {
            return value && value.length > 0
                ? `${operator} "${value && value.join ? value.join(', ') : value}"`
                : intl.formatMessage({ defaultMessage: 'All', id: 'filter.FiltersContext.cd0bd5' });
        },
        [intl],
    );

    const operators: Record<string, FilterOperator> = useMemo(() => {
        return {
            between: {
                key: 'between',
                label: intl.formatMessage({ defaultMessage: 'Between', id: 'filter.FiltersContext.e401ae' }),
                isRange: true,
            },
            not_between: {
                key: 'not_between',
                label: intl.formatMessage({ defaultMessage: 'Not between', id: 'filter.FiltersContext.4a687a' }),
                isRange: true,
            },
            equal: {
                key: 'equal',
                label: intl.formatMessage({ defaultMessage: 'Equals', id: 'filter.FiltersContext.09f8e7' }),
                isRange: false,
            },
            lower_than: {
                key: 'lower_than',
                label: intl.formatMessage({ defaultMessage: 'Lower than', id: 'filter.FiltersContext.9ff66b' }),
                isRange: false,
            },
            greater_than: {
                key: 'greater_than',
                label: intl.formatMessage({ defaultMessage: 'Greater than', id: 'filter.FiltersContext.670198' }),
                isRange: false,
            },
            greater_than_or_equal_to: {
                key: 'greater_than_or_equal_to',
                label: intl.formatMessage({
                    defaultMessage: 'Greater than or equal to',
                    id: 'filter.FiltersContext.9b4971',
                }),
                isRange: false,
            },
            lower_than_or_equal_to: {
                key: 'lower_than_or_equal_to',
                label: intl.formatMessage({
                    defaultMessage: 'Lower than or equal to',
                    id: 'filter.FiltersContext.eef434',
                }),
                isRange: false,
            },
            in_list: {
                key: 'in_list',
                label: intl.formatMessage({ defaultMessage: 'In list', id: 'filter.FiltersContext.c7a2bc' }),
                isRange: false,
            },
            equal_to: {
                key: 'equal_to',
                label: intl.formatMessage({ defaultMessage: 'Equal to', id: 'filter.FiltersContext.333939' }),
                isRange: false,
            },
            starts_with: {
                key: 'starts_with',
                label: intl.formatMessage({ defaultMessage: 'Starts with', id: 'filter.FiltersContext.a1a717' }),
                isRange: false,
            },
            contains: {
                key: 'contains',
                label: intl.formatMessage({ defaultMessage: 'Contains', id: 'filter.FiltersContext.dad396' }),
                isRange: false,
            },
            not_contain: {
                key: 'not_contain',
                label: intl.formatMessage({ defaultMessage: 'Does not contain', id: 'filter.FiltersContext.1d5424' }),
                isRange: false,
            },
            empty: {
                key: 'empty',
                label: intl.formatMessage({ defaultMessage: 'Is empty', id: 'filter.FiltersContext.86a7b8' }),
                isRange: false,
            },
            not_empty: {
                key: 'not_empty',
                label: intl.formatMessage({ defaultMessage: 'Is not empty', id: 'filter.FiltersContext.0e7d4b' }),
                isRange: false,
            },
        };
    }, [intl]);

    const getFilterOperator = useCallback(
        (operatorKey: string): null | FilterOperator => {
            return operators[operatorKey] || null;
        },
        [operators],
    );

    const getSelectChoices = useCallback(
        async (filter: FilterType) => {
            const filterFacets = filtersFacets[filter.key];
            if (filterFacets) {
                return filterFacets;
            } else if (filter.code) {
                if (filter.type === 'reference_entity') {
                    return dispatch(
                        filterAction.getReferenceEntityChoices({
                            locale: catalogLocale as string,
                            attributeCode: filter.referenceEntityCode ?? '',
                        }),
                    );
                } else {
                    return dispatch(
                        filterAction.getSelectChoices({
                            locale: catalogLocale as string,
                            attributeCode: filter.code,
                        }),
                    );
                }
            } else if (filter.key === 'families') {
                return dispatch(
                    filterAction.getFamiliesChoices({
                        locale: catalogLocale as string,
                        attributeCode: 'families',
                    }),
                );
            } else if (filter.key === 'associationType') {
                return dispatch(
                    filterAction.getAssociationTypesChoices({
                        locale: catalogLocale as string,
                        attributeCode: 'attributeType',
                    }),
                );
            }
        },
        [filtersFacets, dispatch, catalogLocale],
    );

    const MAP_TYPES: Record<string, FilterTypeDef> = useMemo(() => {
        const filterCheckbox = {
            getComponent: () => InputCheckbox,
            valueToString: (value: string[] = [], filter: FilterType, operator?: string) => {
                if (!value) {
                    return null;
                }
                //__ index values by values.value
                const itemsByValue: Record<string, boolean> = {};
                value.forEach((v: string) => {
                    itemsByValue[v] = true;
                });
                //__ localize labels from values.value && choices.label
                const labels: string[] = [];
                filter.inputProps.choices.forEach((item: any) => {
                    itemsByValue[item.value] && labels.push(item.label);
                });
                return valueToString(labels, filter, operator);
            },
            inputProps: (filter: FilterType) => ({
                maxVisible: 5,
                showMoreCount: 5,
                searchBar: true,
                choices: async () => getSelectChoices(filter),
            }),
            operators: [operators.in_list, operators.empty, operators.not_empty],
        };

        const res = {
            multiselect: { ...filterCheckbox },
            family: { ...filterCheckbox, operators: [operators.in_list] },
            associationType: { ...filterCheckbox, operators: [operators.in_list] },
            reference_entity: { ...filterCheckbox },
            identifier: {
                getComponent: (isMobileMode?: boolean, operator?: FilterOperator) =>
                    'in_list' === operator?.key ? InputTag : InputText,
                valueToString,
                operators: [operators.in_list, operators.contains, operators.not_contain, operators.starts_with],
            },
            tree: {
                getComponent: (isMobileMode?: boolean) => InputTree,
                valueToString: (value: { [key: string]: boolean } = {}, filter: FilterType, operator?: string) => {
                    const labels = Object.keys(value).map((key) => key);
                    return valueToString(labels, filter, operator);
                },
                inputProps: (filter: FilterType) => ({
                    onOpen: (openedItems: { [itemKey: string]: boolean }) => {
                        dispatch(filterAction.setFilterPropsAction(filter.key, { openedItems }));
                    },
                    searchBar: true,
                }),
            },
            boolean: {
                getComponent: () => InputSimpleSelect,
                valueToString,
                inputProps: {
                    choices: [
                        {
                            label: intl.formatMessage({ defaultMessage: 'Yes', id: 'filter.FiltersContext.6b99ac' }),
                            value: true,
                        },
                        {
                            label: intl.formatMessage({ defaultMessage: 'No', id: 'filter.FiltersContext.a14580' }),
                            value: false,
                        },
                    ],
                },
                operators: [operators.equal, operators.empty, operators.not_empty],
            },
            number: {
                getComponent: () => InputNumber,
                valueToString,
                castValue: (value: number | string) => {
                    (value as any) *= 1;
                },
                operators: [
                    operators.equal,
                    operators.between,
                    operators.not_between,
                    operators.lower_than,
                    operators.lower_than_or_equal_to,
                    operators.greater_than,
                    operators.greater_than_or_equal_to,
                    operators.empty,
                    operators.not_empty,
                ],
            },
            simpleselect: { ...filterCheckbox },
            text: {
                getComponent: (isMobileMode?: boolean, operator?: FilterOperator) =>
                    'in_list' === operator?.key ? InputTag : InputText,
                valueToString,
                operators: [
                    operators.contains,
                    operators.not_contain,
                    operators.starts_with,
                    operators.equal_to,
                    operators.empty,
                    operators.not_empty,
                    operators.in_list,
                ],
            },
            textarea: {
                getComponent: (isMobileMode?: boolean) => InputTextarea,
                valueToString,
                operators: [
                    operators.contains,
                    operators.not_contain,
                    operators.starts_with,
                    operators.equal_to,
                    operators.empty,
                    operators.not_empty,
                ],
            },
            metric: {
                getComponent: () => InputUnits,
                valueToString,
                inputProps: (filter: FilterType) => {
                    const res: any = {};
                    if (filter.code && !filter.inputProps?.unitChoices) {
                        res.unitChoices = async (): Promise<InputSelectChoice[]> => {
                            return dispatch(
                                filterAction.getMetricUnits({
                                    locale: catalogLocale as string,
                                    attributeCode: filter.code as string,
                                }),
                            );
                        };
                    }

                    return res;
                },
                isValid: isValidInputUnits,
                castValue: castValueInputUnits,
                operators: [
                    operators.equal,
                    operators.between,
                    operators.not_between,
                    operators.lower_than,
                    operators.lower_than_or_equal_to,
                    operators.greater_than,
                    operators.greater_than_or_equal_to,
                    operators.empty,
                    operators.not_empty,
                ],
            },
            date: {
                getComponent: () => InputDate,
                valueToString,
                operators: [
                    operators.between,
                    operators.not_between,
                    operators.equal,
                    operators.lower_than,
                    operators.lower_than_or_equal_to,
                    operators.greater_than,
                    operators.greater_than_or_equal_to,
                    operators.empty,
                    operators.not_empty,
                ],
            },
            price_collection: {
                getComponent: () => InputUnits,
                valueToString,
                operators: [
                    operators.equal,
                    operators.between,
                    operators.not_between,
                    operators.lower_than,
                    operators.lower_than_or_equal_to,
                    operators.greater_than,
                    operators.greater_than_or_equal_to,
                    operators.empty,
                    operators.not_empty,
                ],
                inputProps: (filter: FilterType) => {
                    const res: any = {};
                    if (filter.code && !filter.inputProps?.unitChoices) {
                        res.unitChoices = async (): Promise<InputSelectChoice[]> => {
                            return dispatch(
                                filterAction.getPriceUnits({
                                    locale: catalogLocale as string,
                                    attributeCode: filter.code as string,
                                }),
                            );
                        };
                    }
                    return res;
                },
                isValid: isValidInputUnits,
                castValue: castValueInputUnits,
            },
        };

        return res;
    }, [operators, dispatch, intl, catalogLocale, valueToString, getSelectChoices]);

    const getMapTypes = useCallback(() => MAP_TYPES, [MAP_TYPES]);

    const getFilterType = useCallback(
        (type: string) => {
            const res = MAP_TYPES[type];
            if (!res || res.disabled) {
                console.error(new Error(`Unknown filter component type "${type}"`));
                return null;
            }
            return res;
        },
        [MAP_TYPES],
    );

    const [contextValue, setContextValue] = useState<ContextValue>({
        getMapTypes,
        getFilterType,
        getFilterOperator,
        getFilterLabel: filterSelectors.getFilterLabel,
        catalogLocale,
        onFilterChange: (filter, value, operator): any => {
            const filterType = MAP_TYPES[filter.type];
            if (!filterType) {
                console.error(new Error(`Unknown filter type "${filter.type}" for filter ${filter.key}`));
                return;
            }

            const isValid = filterType.isValid || isValidValue;
            if (operator?.key !== 'empty' && operator?.key !== 'not_empty') {
                if (!isValid(value, operator)) {
                    dispatch(filterAction.resetFilterValueAction(filter));
                    return null;
                }

                if (filterType.castValue) {
                    filterType.castValue(value, operator);
                }
            }

            dispatch(filterAction.setFilterValueAction(filter, value, operator?.key));

            return value;
        },
        onSetFilterProps: (filter: FilterType, props: Record<string, any>) => {
            dispatch(filterAction.setFilterPropsAction(filter.key, props));
        },
    });

    useEffect(() => {
        setContextValue((state) =>
            getFilterType === state.getFilterType && getFilterType === state.getFilterType
                ? state
                : {
                      ...state,
                      getMapTypes,
                      getFilterType,
                  },
        );
    }, [getMapTypes, getFilterType]);

    useEffect(() => {
        setContextValue((state) =>
            catalogLocale === state.catalogLocale
                ? state
                : {
                      ...state,
                      catalogLocale,
                  },
        );
    }, [catalogLocale]);

    useEffect(() => {
        setContextValue((state) =>
            getFilterOperator === state.getFilterOperator
                ? state
                : {
                      ...state,
                      getFilterOperator,
                  },
        );
    }, [getFilterOperator]);

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

function useFiltersContext(): ContextValue {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error('useLocaleUI must be used within a LocaleUIProvider');
    }
    return context;
}

export { FiltersProvider, useFiltersContext };
