import get from 'lodash/get';
import map from 'lodash/map';
import each from 'lodash/each';
import find from 'lodash/find';
import { AxiosResponse, Method, AxiosRequestConfig } from 'axios';

import httpClient from 'src/tools/HttpClient';
import arrPut from 'src/tools/arrPut';

export type PagedFetchOptions<Entity, Response> = {
    recordsKey?: string | null;
    totalKey?: string;
    pageSize?: number;
    afterKey?: string | ((data: Response) => string | string[] | null);
    lastParentCode?: string | ((data: Response) => string | string[] | null);
    lastGrandParentCode?: string | ((data: Response) => string | string[] | null);
    fetchFirstPage?: boolean;
    fetchAllPages?: boolean;
    withTableAttributes?: boolean;
    onFetchPage?: (page: number) => void;
    onPageFetched?: (page: number, data: Response, complete: boolean, isLoading: boolean) => void;
    initialRecords?: Entity[];
    formatRecord?: (record: Entity) => Entity;
    paramsAsQueryString?: boolean;
};

export type PagedFetch<Entity, Response> = {
    id: number;
    options: Override<PagedFetchOptions<Entity, Response>, { pageSize: number }>;
    totalRecords: number;
    complete: boolean;
    isLoading: boolean;
    loadingCount: number;
    fetchPage: (page: number) => Promise<Response | void>;
    fetchIndex: (indexStart?: number, indexEnd?: number) => void;
    getRecord: (index: number) => Entity | null;
    findRecord: (predicate: Record<keyof Entity, any> | ((r: Entity) => boolean)) => null | Entity;
    eachRecord: (callback: (record: Entity) => void) => void;
    getAllRecords: () => Entity[];
};

type RequestParams = {
    page?: number;
    after?: string | string[];
};

type RequestConfig<Payload> = Override<
    AxiosRequestConfig,
    {
        url: string;
        method: Method;
        data?: Payload;
    }
>;

let idAuto = 0;

export default function pagedFetch<
    Entity,
    Payload,
    Response extends Override<Record<string, unknown>, { pageSize?: number }> | Entity[],
>(requestConfig: RequestConfig<Payload>, options: PagedFetchOptions<Entity, Response>): PagedFetch<Entity, Response> {
    const {
        recordsKey = 'records',
        totalKey = 'count',
        pageSize = 50,
        afterKey, //__ ES mode if defined
        fetchFirstPage = true,
        fetchAllPages,
        onFetchPage,
        onPageFetched,
        initialRecords = [],
        formatRecord,
        paramsAsQueryString,
    } = options;

    const records: {
        [page: string | number]: Entity[];
    } = {};

    let allRecords: Entity[] = [...initialRecords];

    const loadings: {
        [page: string | number]: Promise<Response | void>;
    } = {};

    const afterKeys: {
        [page: string | number]: string;
    } = {};

    const indexToPage = (index: number): number => {
        return Math.floor(index / pagedRecords.options.pageSize) + 1;
    };

    const getAfterKey = async (page: number): Promise<string | null | string[]> => {
        let res = null;
        if (!afterKey) {
            return res;
        }
        const key = `${page - 1}`;
        if (afterKeys[key]) {
            return afterKeys[key];
        }

        const prevLoad = await pagedRecords.fetchPage(page - 1);
        if (prevLoad) {
            if (typeof afterKey === 'string') {
                res = get(prevLoad, afterKey) as string;
            } else if (typeof afterKey === 'function') {
                res = afterKey(prevLoad);
            }
        }

        return res;
    };

    const getRecord = (_index: number): Entity | null => {
        return allRecords[_index] ? allRecords[_index] : null;
    };

    const id = ++idAuto;

    const pagedRecords: PagedFetch<Entity, Response> = {
        options: {
            recordsKey,
            totalKey,
            pageSize,
            afterKey,
            fetchFirstPage,
            fetchAllPages,
            onPageFetched,
            initialRecords,
            paramsAsQueryString,
        },
        id,
        complete: false,
        isLoading: false,
        loadingCount: 0,
        totalRecords: initialRecords.length,
        fetchIndex: (indexStart = 0, indexEnd?): void => {
            const pageStart = indexStart ? indexToPage(indexStart) : 1;
            const pageEnd = indexEnd ? Math.ceil((indexEnd + 1) / pagedRecords.options.pageSize) : pageStart;

            let page = pageStart;
            let fetchCount = 0;
            void (async (): Promise<void> => {
                while (page <= pageEnd && !pagedRecords.complete && ++fetchCount < 1000) {
                    await pagedRecords.fetchPage(page);
                    ++page;
                }
            })();
        },
        fetchPage: async (page: number): Promise<Response | void> => {
            const key = `${page}`;
            if (key in loadings) {
                return loadings[key];
            }

            pagedRecords.complete = false;
            ++pagedRecords.loadingCount;
            pagedRecords.isLoading = true;

            if (onFetchPage) {
                onFetchPage(page);
            }

            loadings[key] = (async (): Promise<Response | void> => {
                const requestParams: { pageSize: number; after?: string | string[]; page?: number } = {
                    pageSize: pagedRecords.options.pageSize,
                };
                if (afterKey) {
                    let after: string[] | null | string = null;
                    if (page > 1) {
                        after = await getAfterKey(page);
                        if (!after) {
                            console.error(new Error(`Unable retrieving "after" property from page ${page}`));
                            return;
                        }
                    }
                    if (after) {
                        requestParams.after = after;
                    }
                } else {
                    requestParams.page = page;
                }

                const _requestConfig: RequestConfig<Payload | RequestParams> = { ...requestConfig };
                if (paramsAsQueryString || /^get$/i.test(_requestConfig.method)) {
                    _requestConfig.params = requestParams;
                } else {
                    _requestConfig.data = _requestConfig.data
                        ? { ..._requestConfig.data, ...requestParams }
                        : requestParams;
                }

                const response: AxiosResponse<Response> = await httpClient.request<Response>(_requestConfig);

                const { data } = response;
                const _pageSize = (data as Record<string, unknown>).pageSize;
                if (_pageSize) {
                    pagedRecords.options.pageSize = _pageSize as number;
                }
                records[key] = (recordsKey === null ? data : get(data, recordsKey)) as Entity[];
                pagedRecords.complete = true;
                --pagedRecords.loadingCount;
                pagedRecords.isLoading = pagedRecords.loadingCount > 0;

                if (records[key]) {
                    if (records[key].length >= pagedRecords.options.pageSize) {
                        pagedRecords.complete = false;
                    }
                    const total = get(data, totalKey) as number;
                    if (isNaN(total)) {
                        pagedRecords.totalRecords += records[key].length;
                    } else {
                        pagedRecords.totalRecords = total;
                    }

                    if (formatRecord) {
                        records[key] = map<any, Entity>(records[key], formatRecord);
                    }

                    allRecords = arrPut<Entity>(
                        allRecords,
                        records[key],
                        initialRecords.length + page * pagedRecords.options.pageSize,
                    );

                    if (onPageFetched) {
                        onPageFetched(page, data, pagedRecords.complete, pagedRecords.isLoading);
                    }
                } else {
                    console.error(new Error(`fetchPage '${recordsKey}' not found in response.data`), data);
                }

                return data;
            })();

            return loadings[key];
        },
        getRecord,
        findRecord: (predicate: Record<keyof Entity, any> | ((r: Entity) => boolean)): null | Entity => {
            let result: null | Entity = null;
            const r = find(allRecords, predicate) as Entity;
            if (r) {
                result = r;
            }
            return result;
        },
        eachRecord: (callback: (record: Entity) => void) => {
            each(allRecords, callback);
        },
        getAllRecords: (): Entity[] => allRecords,
    };

    if (fetchFirstPage) {
        void (async (): Promise<void> => {
            await pagedRecords.fetchPage(1);
        })();
    }

    if (fetchAllPages) {
        void (async (): Promise<void> => {
            let fetchCount = 0;
            while (!pagedRecords.complete && ++fetchCount < 1000) {
                await pagedRecords.fetchPage(fetchCount);
            }
        })();
    }

    return pagedRecords;
}
