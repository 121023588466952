import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React from 'react';

const PasswordPoliciesWrapper = styled.div`
    margin-bottom: 20px;

    > span {
        color: ${theme.color.grey140};
        font-size: 13px;

        @media (max-width: 768px) {
            font-size: 15px;
        }
    }

    > p {
        height: 13px;
        color: ${theme.color.grey120};
        font-size: 11px;
        margin-bottom: 0;
        @media (max-width: 768px) {
            font-size: 15px;
            height: 15px;
        }
    }
`;

const PasswordPolicies: React.FC = () => {
    const intl = useIntl();

    return (
        <PasswordPoliciesWrapper>
            <span>
                {intl.formatMessage({
                    defaultMessage: 'Your password must contains:',
                    id: 'components.PasswordPolicies.e114d0',
                })}
            </span>
            <p>
                {intl.formatMessage({
                    defaultMessage: 'At least 10 characters',
                    id: 'components.PasswordPolicies.f3474b',
                })}
            </p>
            <p>
                {intl.formatMessage({
                    defaultMessage: 'One uppercase letter',
                    id: 'components.PasswordPolicies.b78168',
                })}
            </p>
            <p>
                {intl.formatMessage({
                    defaultMessage: 'One lowercase letter',
                    id: 'components.PasswordPolicies.35f4c2',
                })}
            </p>
            <p>{intl.formatMessage({ defaultMessage: 'One number', id: 'components.PasswordPolicies.57b6e8' })}</p>
        </PasswordPoliciesWrapper>
    );
};

export default PasswordPolicies;
