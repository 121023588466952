import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../tools/globalStore';
import { useIsLoggedIn } from '../Authentication/hooks/useIsLoggedIn';
import { loginAction } from '../Authentication/store/actions';

const SsoLoginPage: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { data: isUsingSso, isError } = useIsLoggedIn();

    if (isUsingSso && isUsingSso.isLoggedIn) {
        dispatch(loginAction(isUsingSso.email));
        history.push('/');
    }
    if (isError) {
        location.replace('/sso-error');
    }
    return null;
};

export default SsoLoginPage;
