import noop from 'lodash/noop';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Search, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { ReactNode, useState, useCallback, useMemo, useEffect } from 'react';

import { selectors as brandingSelectors } from 'src/shared/branding/store';

type Props = {
    inputValue?: string;
    onChange?: (search: string) => void;
    placeholder?: string;
    tooltip?: string;
    debounceWait?: number;
    children?: ReactNode;
};

const Root = styled.div<{ colorBranding: string }>`
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 7px;
    color: ${theme.color.grey100};

    .dsm-searchbar {
        flex: 1 1;

        &:focus-within {
            border-bottom: 1px solid ${({ colorBranding }): string => colorBranding};
        }
    }
`;

const SearchBar: React.FC<Props> = (props: Props) => {
    const intl = useIntl();

    const {
        inputValue: propsInputValue = '',
        onChange = noop,
        placeholder = intl.formatMessage({ defaultMessage: 'Search', id: 'searchbar.SearchBar.c66715' }),
        tooltip = intl.formatMessage({ defaultMessage: 'Search', id: 'searchbar.SearchBar.c66715' }),
        debounceWait = 0,
        children,
    } = props;

    const [inputValue, setInputValue] = useState<string>(propsInputValue || '');

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const submitValue = useMemo(() => debounce(onChange, debounceWait), [onChange, debounceWait]);

    useEffect(() => {
        setInputValue(propsInputValue || '');
    }, [propsInputValue]);

    const handleChange = useCallback(
        (value: string) => {
            if (value === inputValue) {
                return;
            }
            setInputValue(value);
            submitValue(value);
        },
        [inputValue, submitValue],
    );

    return (
        <Root
            colorBranding={colorBranding}
            className='searchbar'
        >
            <Search
                className='dsm-searchbar'
                onSearchChange={handleChange}
                searchValue={inputValue}
                placeholder={placeholder}
                title={tooltip}
            >
                {children}
            </Search>
        </Root>
    );
};

export default SearchBar;
