import axios, { AxiosResponse } from 'axios';

export const baseUrl = process.env.REACT_APP_API_WEB_PATH;

//__ http client
const httpClient = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
});

export default httpClient;

//__ interceptors
export const regErr5xx = /^5[0-9]{2}$/;

export function isServerError(response?: AxiosResponse): boolean {
    return regErr5xx.test(`${response?.status}`);
}
