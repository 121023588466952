import { useQuery } from '@tanstack/react-query';
import { Catalog } from '../models/Catalog';
import { apiFetch } from '../../../utils/apiFetch';

type Error = string | null;
export type Result = {
    isLoading: boolean;
    isError: boolean;
    data: Catalog[] | undefined;
    error: Error;
};

export const useCatalogQuery = (tenantId: string, catalogCode: string): Result => {
    return useQuery<Catalog[], Error, Catalog[]>({
        queryKey: ['catalogs', tenantId, catalogCode],
        queryFn: async () => {
            return await apiFetch<Catalog[]>(`catalogs`, {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
            });
        },
    });
};
