import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import PreviewActions from './PreviewActions';
import Image from 'src/shared/image/Image';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    box-sizing: border-box;
    height: 100%;

    img {
        max-height: calc(100vh - 250px);
        max-width: 100%;
        min-height: 100%;
        object-fit: contain;
        width: auto;
    }
`;

const Border = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: calc(100vh - 470px);
    min-height: 100px;
    box-sizing: border-box;
`;

type ImagePreviewProps = {
    media: PreviewMedia;
};

const ImagePreview: React.FC<ImagePreviewProps> = ({ media }) => {
    const intl = useIntl();

    return (
        <Container>
            <Border>
                <Image
                    src={media.previewUrl}
                    title={intl.formatMessage({ defaultMessage: 'Preview', id: 'components.ImagePreview.4c9a39' })}
                    aria-label={intl.formatMessage({ defaultMessage: 'Preview', id: 'components.ImagePreview.4c9a39' })}
                />
                <PreviewActions media={media} />
            </Border>
        </Container>
    );
};

export default ImagePreview;
