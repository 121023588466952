import React from 'react';
import styled from 'styled-components';

import noAttributesImage from 'src/media/Attribute.svg';

type Props = {
    title: string;
    subtitle?: string;
};

const Root = styled.div`
    flex: 1;
    text-align: center;

    .empty-result-title {
        margin-top: 38px;

        span {
            font-size: 28px;
        }
    }

    .empty-result-sub-title {
        margin-top: 20px;

        span {
            font-size: 13px;
            color: #000000;
        }
    }
`;

const EmptyResult: React.FC<Props> = (props) => {
    const { title, subtitle } = props;

    return (
        <Root>
            <img
                src={noAttributesImage}
                alt='No Attributes'
            />
            <div className='empty-result-title'>
                <span>{title}</span>
            </div>
            {subtitle && (
                <div className='empty-result-sub-title'>
                    <span>{subtitle}</span>
                </div>
            )}
        </Root>
    );
};

export default EmptyResult;
