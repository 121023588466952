import map from 'lodash/map';
import styled from 'styled-components';
import { Checkbox, Table, sharedCatalogsTheme as theme } from 'akeneo-design-system';
import React, { useCallback, Ref, useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import VirtualScroll, { Props as VirtualScrollProps } from 'src/shared/virtualscroll/index';

export type VirtualTableProps<ItemType = unknown> = Omit<
    Override<
        VirtualScrollProps<ItemType>,
        {
            columns: TableColumn[];
            renderCellContent: (rowItem: ItemType, column: TableColumn, rowIndex: number) => React.ReactNode | null;
            onSelectRow?: (isSelected: boolean, rowItem: ItemType) => void;
            isSelectedRow?: (rowItem: ItemType) => boolean | 'mixed';
            onClickRow?: (rowItem: ItemType) => void;
            tableHeadHeight?: number;
            children?: React.ReactNode;
        }
    >,
    'renderItem'
>;

//_______
type InnerElementProps = {
    children: React.ReactNode;
    style: React.CSSProperties;
};

const Root = styled.div<{ tableHeadHeight?: number }>`
    table {
        display: block;
        table-layout: fixed;
        height: 100%;
        width: auto;

        tbody,
        thead {
            height: ${(props): number => props.tableHeadHeight || 44}px;
            position: relative;
            display: block;
            min-width: 100%;
        }

        thead {
            position: sticky;
            top: 0;
            z-index: 10;
            tr {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
            }
        }

        tr {
            display: flex;
            flex-direction: row;
            min-width: 100%;

            td,
            th {
                flex: none;
                height: 100%;
                max-width: none;
                display: flex;
                align-items: center;
                text-align: left;
                color: ${theme.color.grey140};

                > div {
                    display: inline-block;
                    justify-content: left;
                    align-items: center;
                    overflow: hidden;
                    width: 100%;
                    min-height: auto;
                    height: auto;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-align: left;
                }

                &:last-child {
                    flex: 1 0 auto;
                }

                img {
                    display: inline-block;
                    z-index: 1;
                }

                &.col-type-image {
                    width: 64px;
                }

                &.col-isCurrentProduct {
                    width: 140px;
                }
            }

            .col-type-image > div {
                overflow: visible;

                .stacked-image {
                    position: relative;
                }

                img {
                    border: 1px solid ${theme.color.grey100};
                }
            }

            td {
                &.col-type-image {
                    > div {
                        text-align: center;
                        height: 100%;
                    }
                }
            }

            th {
                background: linear-gradient(to top, #67768a 1px, #ffffff 0px);
                height: 100%;
                font-weight: normal;
                padding: 0 10px;
            }
        }

        &.selectable {
            th:first-child,
            td:first-child {
                width: 32px;
                text-align: left;

                > div {
                    line-height: 100%;
                    justify-content: center;
                    text-align: center;
                    width: auto;
                }

                [role='checkbox'] {
                    display: inline-block;
                }
            }
        }
    }
`;

const defaultIsSelectedRow = <ItemType extends Product>(item: ItemType): boolean | 'mixed' => {
    if (item.isStacked && item.listOfVariantIds && item.listOfVariantIds.length > 0) {
        const productSelectedCount = item.listOfVariantIds.filter((variant) => variant.isSelected).length;
        const productsUnselectedCount = item.listOfVariantIds.filter((variant) => !variant.isSelected).length;

        if (productSelectedCount === item.listOfVariantIds.length) return true;
        if (productsUnselectedCount === item.listOfVariantIds.length) return false;
        return 'mixed';
    }
    return !!item.isSelected;
};
//_______
const VirtualTable = <ItemType extends Product>(props: VirtualTableProps<ItemType>): React.ReactElement => {
    const intl = useIntl();

    const {
        columns,
        renderCellContent,
        onSelectRow,
        isSelectedRow = defaultIsSelectedRow,
        onClickRow,
        tableHeadHeight,
        children: childrenRoot,
        ...rest
    } = props;

    const isSelectable = !!onSelectRow;

    const InnerElement = useMemo(() => {
        const formatLabel = (label: string): string => {
            label = label.replace('intl-', '');
            switch (label) {
                case 'Family':
                    return intl.formatMessage({ defaultMessage: 'Family', id: 'virtualscroll.VirtualTable.af30af' });
                case 'Type':
                    return intl.formatMessage({ defaultMessage: 'Type', id: 'virtualscroll.VirtualTable.f94ea8' });
                case 'Quantity':
                    return intl.formatMessage({ defaultMessage: 'Quantity', id: 'virtualscroll.VirtualTable.a95191' });
                case 'Image':
                    return intl.formatMessage({ defaultMessage: 'Image', id: 'virtualscroll.VirtualTable.fb4cef' });
                case 'Label':
                    return intl.formatMessage({ defaultMessage: 'Label', id: 'virtualscroll.VirtualTable.ef9df2' });
            }
            return label;
        };

        return React.forwardRef<HTMLDivElement, InnerElementProps>((props, forwardedRef: Ref<HTMLDivElement>) => {
            const { children, style } = props;

            return (
                <>
                    {childrenRoot}
                    <Root
                        style={style}
                        ref={forwardedRef}
                        tableHeadHeight={tableHeadHeight}
                    >
                        <Table
                            displayCheckbox={isSelectable}
                            isSelectable={isSelectable}
                            className={`${isSelectable ? 'selectable' : ''}`}
                        >
                            <Table.Header sticky={0}>
                                {map(columns, (column, index) => {
                                    const { key, type, label } = column;
                                    const headerLabel = label.includes('intl') ? formatLabel(label) : label;

                                    return (
                                        <th
                                            key={index}
                                            className={`${type ? 'col-type-' + type + ' ' : ''}col-${key}`}
                                        >
                                            <div className='cell-value'>{headerLabel}</div>
                                        </th>
                                    );
                                })}
                            </Table.Header>
                            <Table.Body>
                                <>{children}</>
                            </Table.Body>
                        </Table>
                    </Root>
                </>
            );
        });
    }, [intl, childrenRoot, tableHeadHeight, isSelectable, columns]);

    const renderItem = useCallback(
        (props: RenderItemProps<ItemType>) => {
            const { item } = props;
            return (
                <VirtualTableRow<ItemType>
                    {...props}
                    columns={columns}
                    renderCellContent={renderCellContent}
                    isSelected={isSelectedRow(item)}
                    onSelectToggle={onSelectRow}
                    onClick={onClickRow}
                />
            );
        },
        [columns, renderCellContent, isSelectedRow, onSelectRow, onClickRow],
    );

    return (
        <VirtualScroll
            {...rest}
            innerElementType={InnerElement}
            renderItem={renderItem}
        />
    );
};

export default VirtualTable;

type RowProps<ItemType = DefaultItem> = Override<
    RenderItemProps<ItemType>,
    {
        columns: TableColumn[];
        renderCellContent: (rowItem: ItemType, column: TableColumn, rowIndex: number) => React.ReactNode;
        isSelected?: boolean | 'mixed';
        onSelectToggle?: (isSelected: boolean, item: ItemType) => void;
        onClick?: (rowItem: ItemType) => void;
    }
>;

const VirtualTableRow = <ItemType extends Product>(props: RowProps<ItemType>): React.ReactElement => {
    const {
        columns,
        renderCellContent,
        onSelectToggle,
        item: rowItem,
        index: rowIndex,
        style,
        isSelected: propsIsSelected = false,
        onClick,
    } = props;

    const rowItemClean = useMemo(() => {
        if (rowItem.attributes) {
            map(rowItem.attributes, (attribute: string, key) => {
                if (null !== attribute) {
                    rowItem.attributes[key] = attribute.toString().replaceAll(/(<([^>]+)>)/gi, '');
                }
            });
        }
        return rowItem;
    }, [rowItem]);

    const [isSelected, setSelected] = useState(propsIsSelected);

    useEffect(() => {
        setSelected(propsIsSelected);
    }, [propsIsSelected]);

    const handleSelectToggle = useCallback(
        (isSelected: boolean) => {
            if (!onSelectToggle) {
                return;
            }
            setSelected(isSelected);
            onSelectToggle(isSelected, rowItemClean);
        },
        [onSelectToggle, rowItemClean],
    );

    const handleClick = useMemo(() => {
        return onClick
            ? (): void => {
                  onClick(rowItemClean);
              }
            : undefined;
    }, [onClick, rowItemClean]);

    return (
        <Table.Row
            style={style}
            onSelectToggle={handleSelectToggle}
            isSelected={isSelected}
            onClick={handleClick}
            data-testid={`row-${rowIndex}`}
        >
            {map(columns, (column, index) => {
                const { key, type } = column;
                return (
                    <Table.Cell
                        key={index}
                        className={`${type ? 'col-type-' + type + ' ' : ''}col-${key}`}
                        data-testid={`col-${column.key}`}
                    >
                        {renderCellContent(rowItemClean, column, rowIndex)}
                    </Table.Cell>
                );
            })}
        </Table.Row>
    );
};
