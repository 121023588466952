import React, { useCallback, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import { useAppDispatch } from 'src/tools/globalStore';
import NotificationItem from './NotificationItem';
import { actions, selectors } from 'src/shared/notification/store';

const Root = styled.div`
    position: fixed;
    z-index: 9999;
    bottom: 25px;
    right: 45px;
    display: flex;
    flex-direction: column-reverse;

    .item-notification {
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        right: 10px;
    }
`;

const Notifications: React.FC = () => {
    const dispatch = useAppDispatch();
    const notifications = useSelector(selectors.getList);

    const handleClose = useCallback(
        (notification: NotificationType) => {
            dispatch(actions.removeAction(notification));
        },
        [dispatch],
    );

    return (
        <Root
            className='notifications'
            role='list'
        >
            {notifications.map((notification) => {
                return (
                    <NotificationItem
                        key={notification.time}
                        notification={notification}
                        onClose={handleClose}
                    />
                );
            })}
        </Root>
    );
};

export default Notifications;
