import { applyMiddleware, combineReducers, compose, createStore, Store, AnyAction } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Persistor } from 'redux-persist/es/types';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createFilter } from 'redux-persist-transform-filter';
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk';

import AccountReducer from 'src/components/Authentication/store';
import BrandingReducer from 'src/shared/branding/store/reducers';
import ContextLocalesReducer from 'src/shared/locale/store/ContextLocale/reducers';
import LocalesReducer from 'src/shared/locale/store/CatalogLocales/reducers';
import NotificationReducer from 'src/shared/notification/store';

import filterReducers, { persistWhitelist as persistWhitelistFilter } from 'src/components/Filter/store/reducers';
import searchReducers, {
    persistWhitelist as persistWhitelistSearch,
} from 'src/components/ProductGrid/store/productGrid/reducers';
import ProductExportReducer, { persistWhitelist as persistWhitelistExport } from 'src/components/Export/store/reducers';
import ProductSelectionReducer, {
    persistWhitelist as persistWhitelistSelection,
} from 'src/components/ProductGrid/store/productSelection/reducers';

export type RootState = {
    account: AccountState;
    locales: CatalogLocales;
    contextLocale: ContextLocaleState;
    branding: BrandingState;
    filters: FiltersState;
    search: SearchState;
    notification: NotificationState;
    productExport: ProductExportState;
    productSelection: ProductSelectionState;
};

type AppStore = Store<RootState>;

const composeEnhancers =
    // typeof window === 'object' &&
    process.env.NODE_ENV === 'development' &&
    // ( window as any ).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) as typeof compose)
        : compose;

const appReducer = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage: storage,
            whitelist: ['user'],
        },
        AccountReducer,
    ),
    locales: LocalesReducer,
    contextLocale: ContextLocalesReducer,
    branding: BrandingReducer,
    filters: filterReducers,
    search: searchReducers,
    notification: NotificationReducer,
    productExport: ProductExportReducer,
    productSelection: ProductSelectionReducer,
});

const rootReducer = (state: any, action: any) => {
    return appReducer(state, action);
};

export type ConfStore = {
    store: AppStore;
    persistor: Persistor;
    rehydrated: Promise<unknown>;
};

const configureStore = (tenantId = 'tenant0', catalogCode = 'shared_catalog_demo'): ConfStore => {
    const persistedReducer = persistReducer(
        {
            key: `${tenantId}-${catalogCode}`,
            storage,
            whitelist: ['contextLocale', 'branding', 'search', 'productExport', 'filters', 'productSelection'],
            transforms: [
                //__ nested whitelist
                createFilter('search', persistWhitelistSearch),
                createFilter('productExport', persistWhitelistExport),
                createFilter('filters', persistWhitelistFilter),
                createFilter('productSelection', persistWhitelistSelection),
            ],
        },
        rootReducer,
    );

    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

    let rehydratedResolve: (value?: unknown) => void;
    const rehydrated = new Promise((resolve) => {
        rehydratedResolve = resolve;
    });

    const persistor = persistStore(store, null, () => {
        rehydratedResolve();
    });

    return { store, persistor, rehydrated };
};

export default configureStore;

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
export type ThunkResult<R = void> = ThunkAction<R, RootState, unknown, AnyAction>;
export const useAppDispatch = (): AppThunkDispatch => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
