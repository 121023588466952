import each from 'lodash/each';

/*___ convert array of string to an object of keyed booleans : ['a','b','c'] => {a:true,b:true,c:true} */
const arrToBoolMap = (arr: any[] | null, keyPredicate: (value: any) => string = (value) => value): BooleanMap => {
    const res: { [key: string]: boolean } = {};
    each(arr, (value) => {
        res[keyPredicate(value)] = true;
    });
    return res;
};

export default arrToBoolMap;
