import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Route as ReactRoute, RouteProps as ReactRouteProps, Redirect } from 'react-router';
import { selectors } from 'src/components/Authentication/store';

type RouteProps = {
    privateRoute?: boolean;
};

const loginPath = '/login';

const Route: React.FC<RouteProps & ReactRouteProps> = ({ privateRoute = true, ...props }) => {
    const location = useLocation();
    const isAuthenticated = useSelector(selectors.isUserAuthenticated);

    if (privateRoute && !isAuthenticated && loginPath !== location.pathname) {
        return <Redirect to={`${loginPath}?origin=${location.pathname}${location.search}${location.hash}`} />;
    }
    return <ReactRoute {...props} />;
};

export default Route;
