/*___ default merge which replace arrays ( instead lodash default to fusion arrays ) */

import { MergeWithCustomizer } from 'lodash';
import mergeWith from 'lodash/mergeWith';

const replArray: MergeWithCustomizer = (dest: [] | object, src: [] | object) =>
    src instanceof Array ? src : undefined;

const merge = (dest: any, ...src: any[]): any => mergeWith(dest, ...src, replArray);

export default merge;
