import { useCallback, useEffect, useState } from 'react';

// More informations here : https://usehooks.com/useMedia/
const useMedia = <T, >(queries: string[], values: T[], defaultValue: T): T => {
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));

    const getValue = useCallback(() => {
        const index = mediaQueryLists.findIndex((mql) => mql.matches);
        return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
    }, [mediaQueryLists, values, defaultValue]);

    const [value, setValue] = useState(getValue);

    useEffect(() => {
        const handler = (): void => setValue(getValue);
        mediaQueryLists.forEach((mql) => mql.addListener(handler));
        return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    }, [getValue, mediaQueryLists]);

    return value;
};

export default useMedia;
