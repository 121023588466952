import { useQuery } from '@tanstack/react-query';
import { apiFetch } from '../../../utils/apiFetch';
import { SignupTokenAndExistingAccountValidation } from '../models/SignupTokenAndExistingAccountValidation';
import { ApiErrors } from '../models/ApiErrors';

type Error = string | null;
export type Result = {
    isLoading: boolean;
    isError: boolean;
    data: SignupTokenAndExistingAccountValidation | undefined;
    error: Error;
};

export const useSignupTokenAndExistingAccountValidation = (email: string, verificationToken: string): Result => {
    return useQuery<SignupTokenAndExistingAccountValidation, Error, SignupTokenAndExistingAccountValidation>({
        queryKey: ['validate_signup_token_and_existing_account', email, verificationToken],
        queryFn: async () => {
            return apiFetch<SignupTokenAndExistingAccountValidation, ApiErrors>(
                `accounts/${email}/email/${verificationToken}`,
            );
        },
        staleTime: 0,
    });
};
