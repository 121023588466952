const totalCount = ({ productSelection }: { productSelection: ProductSelectionState }): number =>
    productSelection.totalCount;

const searchContexts = ({ productSelection }: { productSelection: ProductSelectionState }): SearchContext[] =>
    productSelection.searchContexts;

const isSelectedProduct =
    (product?: Product | null) =>
    ({ search }: { search: SearchState }): boolean => {
        if (!product) {
            return false;
        }

        switch (search.selectedMode) {
            case 'all':
                return true;
            case 'none':
                return false;
            default:
                return !!product.isSelected;
        }
    };

const updates = ({ productSelection }: { productSelection: ProductSelectionState }): number => productSelection.updates;

export default {
    totalCount,
    searchContexts,
    isSelectedProduct,
    updates,
};
