import { useQuery } from '@tanstack/react-query';
import { ValidateVerificationToken } from '../models/ValidateVerificationToken';
import { apiFetch } from '../../../utils/apiFetch';

type Error = string | null;
export type Result = {
    isLoading: boolean;
    isError: boolean;
    data: ValidateVerificationToken | undefined;
    error: Error;
};

export const useValidateNewPasswordVerificationToken = (email: string, verificationToken: string): Result => {
    return useQuery<ValidateVerificationToken, Error, ValidateVerificationToken>({
        queryKey: ['validate_newpassword_verification_token', email, verificationToken],
        queryFn: async () => {
            return apiFetch<ValidateVerificationToken>(`accounts/${email}/validate/${verificationToken}`);
        },
        staleTime: 0,
    });
};
