import { useEffect, useState } from 'react';

type ViewportSize = { width: number; height: number };

function useViewportSize(): ViewportSize {
    const [windowDimensions, setWindowDimensions] = useState<ViewportSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        function handleResize(): void {
            setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export default useViewportSize;
