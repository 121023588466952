import set from 'lodash/set';
import get from 'lodash/get';
import unset from 'lodash/unset';
import isNil from 'lodash/isNil';

export const emptyFilters = {
    families: [],
    categories: [],
    excluded_categories: [],
    attributes: {},
};

export const initialSearchState: SearchState = {
    currentSearch: {
        queryString: null,
        locale: 'en_US',
        ...emptyFilters,
        attributes: {},
        identifiersAndParentCodes: {
            products: [],
            product_models: [],
        },
    },
    searchSelectedCount: 0,
    selectedMode: 'none',
    totalCount: 0,
    productModelCount: 0,
    productDisplayedCount: 0,
    compatVersion: 7, //__ augment this when you want force reset state coming from persisted localStorage ( estimate incompat )
    currentDisplay: 'gallery',
    currentVariationMode: 'ungrouped',
    currentDisplayAttributeTypes: 'all',
    selectedColumns: [],
    selectedAssociationsColumns: [],
    selectedVariationsColumns: [],
};

export const persistWhitelist = [
    'currentSearch',
    'compatVersion',
    'currentDisplay',
    'currentVariationMode',
    'currentDisplayAttributeTypes',
    'selectedColumns',
    'selectedAssociationsColumns',
    'selectedVariationsColumns',
];

function filterToSearch(search: Search, filter: FilterType, value: any, operator?: string | null): Search {
    const res = { ...search };

    const filterKey = filter.key;
    let filterValue = value;
    switch (filterKey) {
        case 'categories':
            filterValue = [];
            res.excluded_categories = [];
            Object.keys(value).forEach((key: string) => {
                (value[key] ? filterValue : res.excluded_categories).push(key);
            });
            break;
        default:
            if (filter.code) {
                filterValue = {
                    code: filter.code,
                    type: filter.type,
                    value: 'boolean' === filter.type && 'empty' === value ? null : value,
                };
                if (typeof operator !== 'undefined') {
                    filterValue.operator = operator;
                }
            }
            break;
    }

    set(res, filterKey, filterValue);

    return res;
}

const calcSelectedMode = (selectedCount: number, total: number) => {
    return selectedCount === 0 ? 'none' : selectedCount === total ? 'all' : 'partial';
};

const SearchReducer = (state = initialSearchState, action: ProductsActions | RehydrateAction) => {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            const ps = action.payload?.search;
            if (!ps) {
                return state;
            }

            const newState = { ...state };

            //__ do not use rehydrate state if not compat
            if (!ps.compatVersion || ps.compatVersion < state.compatVersion) {
                if (action.payload.search.currentSearch) {
                    newState.currentSearch = action.payload.search.currentSearch;
                }
                //___ insure current locale wont be set null
                if (!newState.currentSearch.locale) {
                    newState.currentSearch.locale = 'en_US';
                }
            } else {
                Object.assign(newState, ps);
            }
            return newState;
        }

        case 'SET_GALLERY_DISPLAY': {
            return { ...state, currentDisplay: action.payload };
        }

        case 'SET_VARIATION_DISPLAY': {
            return { ...state, currentVariationMode: action.payload };
        }

        case 'SET_GALLERY_COLUMNS': {
            return { ...state, selectedColumns: action.payload };
        }

        case 'SET_ASSOCIATIONS_COLUMNS': {
            return { ...state, selectedAssociationsColumns: action.payload };
        }

        case 'SET_VARIATIONS_COLUMNS': {
            return { ...state, selectedVariationsColumns: action.payload };
        }

        case 'SET_ATTRIBUTE_DISPLAY': {
            return { ...state, currentDisplayAttributeTypes: action.payload };
        }

        //___ Search
        case 'SEARCH_QUERYSTRING':
            return { ...state, currentSearch: { ...state.currentSearch, queryString: action.value } };

        case 'SELECT_CONTEXT_LOCALE':
            return { ...state, currentSearch: { ...state.currentSearch, locale: action.contextLocale } };

        case 'SET_FILTER_VALUE': {
            const { filter, value, operator }: { filter: FilterType; value: any; operator?: string | null } = action;
            return { ...state, currentSearch: filterToSearch(state.currentSearch, filter, value, operator) };
        }

        case 'RESET_FILTER_VALUE': {
            if (isNil(get(state.currentSearch, action.payload.key))) {
                return state;
            }
            const currentSearch = { ...state.currentSearch };
            unset(currentSearch, action.payload.key);
            return { ...state, currentSearch };
        }

        case 'RESET_FILTERS_VALUES': {
            const categories = state.currentSearch.categories;
            return {
                ...state,
                currentSearch: { ...state.currentSearch, ...emptyFilters, categories: categories, attributes: {} },
            };
        }

        case 'SEARCH_RESULT_INFOS': {
            return {
                ...state,
                searchSelectedCount: action.selectedCount,
                totalCount: action.productsTotal,
                productModelCount: action.productModelsTotal,
                productDisplayedCount: action.productDisplayedTotal,
                selectedMode: calcSelectedMode(action.selectedCount, action.productsTotal),
            };
        }

        //___ Selection
        case 'SELECT_PRODUCT': {
            const newState = { ...state };
            const { select } = action;
            if (select) {
                newState.searchSelectedCount = Math.min(state.totalCount, state.searchSelectedCount + 1);
            } else {
                newState.searchSelectedCount = Math.max(0, state.searchSelectedCount - 1);
            }
            newState.selectedMode = calcSelectedMode(newState.searchSelectedCount, state.totalCount);

            return newState;
        }

        case 'SELECT_PRODUCTS': {
            const newState = { ...state };
            const { select, numberOfProductChanged } = action;
            if (select) {
                newState.searchSelectedCount = Math.min(
                    state.totalCount,
                    state.searchSelectedCount + numberOfProductChanged,
                );
            } else {
                newState.searchSelectedCount = Math.max(0, state.searchSelectedCount - numberOfProductChanged);
            }
            newState.selectedMode = calcSelectedMode(newState.searchSelectedCount, state.totalCount);

            return newState;
        }

        case 'SELECT_PRODUCTS_ALL':
        case 'SELECT_PRODUCTS_NONE': {
            const newState = { ...state };

            if (action.type === 'SELECT_PRODUCTS_ALL') {
                newState.selectedMode = 'all';
                newState.searchSelectedCount = state.totalCount;
            } else {
                newState.selectedMode = 'none';
                newState.searchSelectedCount = 0;
            }

            return newState;
        }

        case 'PRODUCT_SELECTION_RESET': {
            return { ...state, searchSelectedCount: 0, selectedMode: 'none' };
        }

        default:
            return state;
    }
};

export default SearchReducer;
