import {
    ArrowSimpleUpIcon,
    Badge,
    Dropdown,
    MoreVerticalIcon,
    sharedCatalogsTheme as theme,
    SubNavigationPanel,
    useBooleanState,
} from 'akeneo-design-system';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Filter } from 'src/shared';
import SidebarFooter from 'src/shared/sidebarfooter/SidebarFooter';

import { FiltersProvider } from 'src/components/Filter/components/filter/FiltersContext';
import { staticFilters } from 'src/components/Filter/store';
import Route from 'src/security/Route';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

type Props = {
    currentSection: ProductSections;
    countPerSection: ProductSectionsCount;
    setCurrentSection: (sectionDisplay: ProductSections) => void;
    lastModificationDate: Date | undefined;
};

const Root = styled.div<{ colorBranding: string }>`
    a:hover {
        color: ${({ colorBranding }): string => colorBranding};
    }

    .main {
        flex: 1 1 0;

        .back {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: ${theme.color.grey140};

            .arrow {
                color: ${({ colorBranding }): string => colorBranding};
                transform: rotate(-90deg);
                margin-right: 8px;
            }
        }

        .product-title {
            color: ${theme.color.grey100};
            font-size: 11px;
            text-transform: uppercase;
        }

        .product-navigation {
            margin-top: 47px;
            padding-bottom: 30px;
            border-bottom: 1px ${theme.color.grey40} solid;

            .sections-title {
                color: ${theme.color.grey140};
                font-size: 15px;

                &.active,
                &:hover {
                    color: ${({ colorBranding }): string => colorBranding};
                    cursor: pointer;
                }

                .sections-title-badge {
                    margin-left: 15px;
                }
            }
        }

        .subheader {
            height: 60px;
            border-bottom: 1px ${theme.color.grey40} solid;
            display: flex;
            align-items: center;

            h3 {
                font-size: 15px;
                font-weight: normal;
                color: ${theme.color.grey140};
            }
        }

        .product-information {
            padding-top: 30px;

            .product-lastModificationDate {
                margin-top: 30px;

                p {
                    margin: 2px;
                }

                &:nth-child(2) {
                    color: ${theme.color.grey140};
                }
            }
        }
    }

    .filters-common {
        flex: 1;
        overflow-y: auto;
        padding-left: 2px;
        padding-bottom: 20px;
    }
`;

const ProductSideBar: React.FC<Props> = (props) => {
    const { currentSection, countPerSection, setCurrentSection, lastModificationDate } = props;

    const intl = useIntl();

    const [isPanelOpen, panelOpen, panelClose] = useBooleanState(true);
    const [isDropdownOpen, dropdownOpen, dropdownClose] = useBooleanState(false);

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const filterAssociationType = staticFilters.filter((filter) => filter.key === 'associationType')[0];

    const onClickSection = useCallback(
        (sectionTitle: ProductSections) => {
            window.scrollTo(0, 0);
            setCurrentSection(sectionTitle);
        },
        [setCurrentSection],
    );

    return (
        <Root
            colorBranding={colorBranding}
            className={`sidebar nav-panel ${isPanelOpen ? 'nav-panel-open' : ''}`}
        >
            <SubNavigationPanel
                open={panelOpen}
                close={panelClose}
                isOpen={isPanelOpen}
            >
                <SubNavigationPanel.Collapsed>
                    <Dropdown className='dropdown'>
                        <MoreVerticalIcon onClick={dropdownOpen} />
                        {isDropdownOpen && (
                            <Dropdown.Overlay
                                verticalPosition='down'
                                onClose={dropdownClose}
                            >
                                <Dropdown.ItemCollection>
                                    <Dropdown.Item>
                                        <Route
                                            path='/'
                                            privateRoute={true}
                                        >
                                            <Link to={`/`}>
                                                {intl.formatMessage({
                                                    defaultMessage: 'Back to catalog',
                                                    id: 'components.ProductSideBar.5f3407',
                                                })}
                                            </Link>
                                        </Route>
                                    </Dropdown.Item>

                                    <Dropdown.Section>
                                        {intl.formatMessage({
                                            defaultMessage: 'Product navigation',
                                            id: 'components.ProductSideBar.38a341',
                                        })}
                                    </Dropdown.Section>
                                    <Dropdown.Item
                                        onClick={(): void => onClickSection('attributes')}
                                        isActive={currentSection === 'attributes'}
                                    >
                                        {intl.formatMessage({
                                            defaultMessage: 'Attributes',
                                            id: 'components.ProductSideBar.fb14e9',
                                        })}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(): void => onClickSection('media')}
                                        isActive={currentSection === 'media'}
                                    >
                                        {intl.formatMessage({
                                            defaultMessage: 'Media',
                                            id: 'components.ProductSideBar.866677',
                                        })}
                                    </Dropdown.Item>
                                    {countPerSection.variations > 0 && (
                                        <Dropdown.Item
                                            onClick={(): void => onClickSection('variations')}
                                            isActive={currentSection === 'variations'}
                                        >
                                            {intl.formatMessage({
                                                defaultMessage: 'Variations',
                                                id: 'components.ProductSideBar.e9b5e0',
                                            })}
                                        </Dropdown.Item>
                                    )}
                                    {countPerSection.associations > 0 && (
                                        <Dropdown.Item
                                            onClick={(): void => onClickSection('associations')}
                                            isActive={currentSection === 'associations'}
                                        >
                                            {intl.formatMessage({
                                                defaultMessage: 'Associations',
                                                id: 'components.ProductSideBar.27760a',
                                            })}
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.ItemCollection>
                            </Dropdown.Overlay>
                        )}
                    </Dropdown>
                </SubNavigationPanel.Collapsed>

                <div className='product-sidebar'>
                    <FiltersProvider>
                        <div className='main'>
                            <Route
                                path='/'
                                privateRoute={true}
                            >
                                <Link
                                    className='back'
                                    to={`/`}
                                >
                                    <ArrowSimpleUpIcon className='arrow' />
                                    {intl.formatMessage({
                                        defaultMessage: 'Catalog',
                                        id: 'components.ProductSideBar.18e76a',
                                    })}
                                </Link>
                            </Route>

                            <div className='product-navigation'>
                                <p className='product-title'>
                                    {intl.formatMessage({
                                        defaultMessage: 'Product navigation',
                                        id: 'components.ProductSideBar.38a341',
                                    })}
                                </p>

                                <div>
                                    <p
                                        className={`sections-title ${currentSection === 'attributes' ? 'active' : ''}`}
                                        onClick={(): void => onClickSection('attributes')}
                                    >
                                        {intl.formatMessage({
                                            defaultMessage: 'Attributes',
                                            id: 'components.ProductSideBar.fb14e9',
                                        })}
                                        <Badge className='sections-title-badge branding-badge'>
                                            {countPerSection.attributes}
                                        </Badge>
                                    </p>
                                    <p
                                        className={`sections-title ${currentSection === 'media' ? 'active' : ''}`}
                                        onClick={(): void => onClickSection('media')}
                                    >
                                        {intl.formatMessage({
                                            defaultMessage: 'Media',
                                            id: 'components.ProductSideBar.866677',
                                        })}
                                        <Badge className='sections-title-badge branding-badge'>
                                            {countPerSection.media}
                                        </Badge>
                                    </p>
                                    {countPerSection.variations > 0 && (
                                        <p
                                            className={`sections-title ${
                                                currentSection === 'variations' ? 'active' : ''
                                            }`}
                                            onClick={(): void => onClickSection('variations')}
                                        >
                                            {intl.formatMessage({
                                                defaultMessage: 'Variations',
                                                id: 'components.ProductSideBar.e9b5e0',
                                            })}
                                            <Badge className='sections-title-badge branding-badge'>
                                                {countPerSection.variations}
                                            </Badge>
                                        </p>
                                    )}
                                    {countPerSection.associations > 0 && (
                                        <p
                                            className={`sections-title ${
                                                currentSection === 'associations' ? 'active' : ''
                                            }`}
                                            onClick={(): void => onClickSection('associations')}
                                        >
                                            {intl.formatMessage({
                                                defaultMessage: 'Associations',
                                                id: 'components.ProductSideBar.27760a',
                                            })}
                                            <Badge className='sections-title-badge branding-badge'>
                                                {countPerSection.associations}
                                            </Badge>
                                        </p>
                                    )}
                                </div>
                            </div>

                            {currentSection === 'associations' ? (
                                <div className='subheader'>
                                    <h3>
                                        {intl.formatMessage({
                                            defaultMessage: 'Filters',
                                            id: 'components.ProductSideBar.cd23af',
                                        })}
                                    </h3>
                                </div>
                            ) : (
                                <div className='product-information'>
                                    <p className='product-title'>
                                        {intl.formatMessage({
                                            defaultMessage: 'Product information',
                                            id: 'components.ProductSideBar.d996fa',
                                        })}
                                    </p>

                                    {lastModificationDate && (
                                        <div className='product-lastModificationDate'>
                                            <p className='product-title'>
                                                {intl.formatMessage({
                                                    defaultMessage: 'Last update',
                                                    id: 'components.ProductSideBar.a151e7',
                                                })}
                                            </p>
                                            <p>
                                                {intl.formatDate(lastModificationDate, {
                                                    dateStyle: 'short',
                                                    timeStyle: 'short',
                                                })}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {currentSection === 'associations' && (
                            <div className='filters-common'>
                                <Filter
                                    key={filterAssociationType.key}
                                    filter={filterAssociationType}
                                />
                            </div>
                        )}
                    </FiltersProvider>

                    <SidebarFooter />
                </div>
            </SubNavigationPanel>
        </Root>
    );
};

export default ProductSideBar;
