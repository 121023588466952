import React from 'react';
import styled from 'styled-components';
import { CloseIcon, sharedCatalogsTheme as theme } from 'akeneo-design-system';

type Props = any;

const Root = styled.button`
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    width: 24px;
    height: 24px;

    .icon-delete {
        width: 100%;
        height: 100%;
        pointer-events: none; /*__ fix icon title tooltip overriding parent title */
        g {
            stroke: ${theme.color.grey100};
        }
    }
`;

const ButtonClose: React.FC<Props> = (props) => {
    return (
        <Root
            className='bt-close'
            {...props}
        >
            <CloseIcon className='icon-delete' />
        </Root>
    );
};

export default ButtonClose;
