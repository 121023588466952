import get from 'lodash/get';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import React, { FormEvent, useEffect, useState, useCallback } from 'react';
import { Button, Field, TextInput, Helper, sharedCatalogsTheme as theme, useBooleanState } from 'akeneo-design-system';
import { useSelector } from 'react-redux';

import httpClient from 'src/tools/HttpClient';
import { selectors as brandingSelectors } from 'src/shared/branding/store';

const Root = styled.div<{ colorBranding: string }>`
    .label {
        margin-top: 0;
        margin-bottom: 20px;
        color: ${theme.color.grey120};
        font-size: 15px;
        max-width: 300px;

        @media (max-width: 768px) {
            font-size: 18px;
        }
    }

    form {
        width: 300px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .button-go-back {
        display: block;
        width: 95px;
        color: ${({ colorBranding }): string => colorBranding};
        font-size: 13px;
        cursor: pointer;
        margin-top: 30px;

        @media (max-width: 768px) {
            font-size: 15px;
        }
    }

    .message-token {
        &.error {
            color: ${theme.color.red120};
            font-size: 15px;
        }
    }

    .message-email {
        &.error {
            margin-top: 5px;
        }

        &.valid {
            width: 300px;
            margin-top: 10px;
        }
    }

    .input-group {
        padding-bottom: 20px;
    }
`;

const ResetForm: React.FC = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState<string>('');
    const [messageIsShown, showMessage] = useBooleanState(false);
    const [isShowEmailErrorMessage, showErrorMessage, hideErrorMessage] = useBooleanState(false);

    const intl = useIntl();
    const history = useHistory();

    const colorBranding = useSelector(brandingSelectors.getColorBranding);

    const onEmailChange = useCallback((value: string) => {
        setEmail(value);
    }, []);

    const [invalidToken, setInvalidToken] = useState<boolean>(
        get(history.location?.state, 'invalidToken', false) as boolean,
    );

    const validateEmail = (email: string): boolean => {
        return new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$').test(email);
    };

    const onSubmit = useCallback(async () => {
        if (validateEmail(email)) {
            hideErrorMessage();
            setInvalidToken(false);
            await httpClient.post(`/accounts/${email}/reset`);
            setMessage(
                intl.formatMessage({
                    defaultMessage:
                        'If an account is linked to this address, you will receive an email with a link to reset your password.',
                    id: 'components.ResetForm.39726f',
                }),
            );
            showMessage();
        } else {
            showErrorMessage();
        }
    }, [email, hideErrorMessage, intl, showMessage, showErrorMessage]);

    useEffect(() => {
        if (!invalidToken) {
            return;
        }
        return (): void => {
            if (invalidToken && history?.replace) {
                history.replace(history.location.pathname);
            }
        };
    }, [invalidToken, history]);

    return (
        <Root colorBranding={colorBranding}>
            {invalidToken && (
                <p className='message-token error'>
                    {intl.formatMessage(
                        {
                            defaultMessage:
                                'Your verification token is invalid or has expired. {breakLine} Please provide your email address again.',
                            id: 'components.ResetForm.dcee8b',
                        },
                        {
                            breakLine: <br key={Math.random()} />,
                        },
                    )}
                </p>
            )}
            {!messageIsShown ? (
                <>
                    <p className='label'>
                        {intl.formatMessage({
                            defaultMessage: 'Please enter your email address to generate a new password.',
                            id: 'components.ResetForm.e274a1',
                        })}
                    </p>
                    <form onSubmit={(e: FormEvent<HTMLFormElement>): void => e.preventDefault()}>
                        <div className='input-group'>
                            <div>
                                <Field
                                    label={intl.formatMessage({
                                        defaultMessage: 'Email',
                                        id: 'components.ResetForm.b32fa9',
                                    })}
                                >
                                    <TextInput
                                        title={intl.formatMessage({
                                            defaultMessage: 'Please fill out this field.',
                                            id: 'components.ResetForm.020cf2',
                                        })}
                                        onChange={onEmailChange}
                                        value={email}
                                        data-testid='input-email'
                                    />
                                </Field>
                            </div>
                            {isShowEmailErrorMessage && (
                                <div className='message-email error'>
                                    <Helper
                                        level='error'
                                        inline={true}
                                    >
                                        {intl.formatMessage({
                                            defaultMessage: 'Please enter a valid email address.',
                                            id: 'components.ResetForm.c6cfcc',
                                        })}
                                    </Helper>
                                </div>
                            )}
                        </div>
                        <Button
                            type='submit'
                            onClick={onSubmit}
                            disabled={email.length === 0}
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Reset my password',
                                id: 'components.ResetForm.3972cb',
                            })}
                        </Button>
                    </form>
                </>
            ) : (
                <div className='message-email valid'>
                    <Helper level='success'>{message}</Helper>
                </div>
            )}
            <div
                className='button-go-back'
                onClick={(): void => {
                    history.push(`/`);
                }}
            >
                {intl.formatMessage({ defaultMessage: 'Go to homepage', id: 'components.ResetForm.924d51' })}
            </div>
        </Root>
    );
};

export default ResetForm;
