import styled from 'styled-components';
import React, { useState, useCallback, useEffect } from 'react';
import {
    ViewIcon,
    Field,
    TextInput,
    TextInputProps,
    Helper,
    HelperProps,
    sharedCatalogsTheme as theme,
} from 'akeneo-design-system';

type PasswordProps = TextInputProps & {
    label: string;
    fullWidth?: boolean;
    status?: HelperProps['level'];
    statusMessage?: string;
    isPasswordVisible?: boolean;
    makePasswordVisible?: boolean | ((isPasswordVisible: boolean) => void);
};

const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 10px;

    .bt-icon {
        position: absolute;
        top: 31px;
        right: 3px;
        padding: 5px;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        svg {
            height: 16px;
            width: 16px;

            &:focus {
                outline: none;
            }
        }

        &.active {
            svg path {
                stroke: ${theme.color.blue100};
            }
        }
    }
`;

const InputPassword: React.FC<PasswordProps> = (props) => {
    const { label, fullWidth, status, statusMessage, makePasswordVisible, ...inputProps } = props;

    const [isPasswordVisible, setPasswordVisible] = useState(props.isPasswordVisible);

    useEffect(() => {
        setPasswordVisible(props.isPasswordVisible);
    }, [props.isPasswordVisible]);

    const handleMakePasswordVisible = useCallback(
        (visible: boolean) => {
            setPasswordVisible(visible);
            if (typeof makePasswordVisible === 'function') {
                makePasswordVisible(visible);
            }
        },
        [makePasswordVisible],
    );

    return (
        <Root>
            <Field
                label={label}
                fullWidth={fullWidth}
            >
                <TextInput
                    {...inputProps}
                    type={isPasswordVisible ? 'text' : 'password'}
                    invalid={status === 'error'}
                    data-testid='input-password'
                />
                {statusMessage?.length ? <Helper level={status}>{statusMessage}</Helper> : null}
            </Field>
            {makePasswordVisible && (
                <div
                    data-testid='make-password-visible'
                    onClick={(): void => handleMakePasswordVisible(!isPasswordVisible)}
                    className={`bt-icon ${isPasswordVisible ? 'active' : ''}`}
                >
                    <ViewIcon />
                </div>
            )}
        </Root>
    );
};

export default InputPassword;
