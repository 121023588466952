import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SelectInput } from 'akeneo-design-system';
import noop from 'lodash/noop';
import { useIntl } from 'react-intl';

//_______
type Props = Override<
    InputProps<string>,
    {
        choices: InputSelectChoice[] | (() => InputSelectChoice[]) | Promise<InputSelectChoice[]>;
    }
>;

const emptyArray: [] = [];

const Root = styled.div``;

const emptyChoices: [] = [];

const InputSimpleSelect: React.FC<Props> = (props) => {
    const { value = '', onChange = noop, choices: propsChoices } = props;

    const intl = useIntl();

    const [choices, setChoices] = useState<InputSelectChoice[]>(emptyChoices);

    useEffect(() => {
        if (!propsChoices) {
            console.error('props.choices must be set.');
            return;
        }
        if (propsChoices instanceof Array) {
            setChoices(propsChoices);
            return;
        }
        void (async (): Promise<void> => {
            const items = (await (propsChoices as Function)()) || emptyArray;
            setChoices(items);
        })();
    }, [propsChoices]);

    return (
        <Root role='input-text'>
            <SelectInput
                value={value}
                onChange={onChange}
                emptyResultLabel={intl.formatMessage({
                    defaultMessage: 'No result found',
                    id: 'InputSimpleSelect.InputSimpleSelect.180037',
                })}
                openLabel={intl.formatMessage({
                    defaultMessage: 'Display choices',
                    id: 'InputSimpleSelect.InputSimpleSelect.deafe1',
                })}
            >
                {choices.map((choice) => (
                    <SelectInput.Option
                        key={choice.value}
                        title={choice.label}
                        value={choice.value as string}
                    >
                        {choice.label}
                    </SelectInput.Option>
                ))}
            </SelectInput>
        </Root>
    );
};

export default InputSimpleSelect;
