import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { sharedCatalogsTheme as theme } from 'akeneo-design-system';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import PreviewActions from './PreviewActions';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100px;
    box-sizing: border-box;
    height: 100%;
`;

const Border = styled.div`
    border: 1px solid ${theme.color.grey80};
    overflow: hidden auto;
`;

type PdfPreviewProps = {
    media: PreviewMedia;
};

const PdfPreview: React.FC<PdfPreviewProps> = ({ media }) => {
    const intl = useIntl();
    const [numPages, setNumPages] = useState(1);

    // The PDF preview should have 905px width (or lower, if mobile)
    const width = Math.min(window.innerWidth - 286, 905);

    const onDocumentLoadSuccess = ({ numPages }: any): void => {
        setNumPages(numPages as number);
    };

    return (
        <Container>
            <Border aria-label={intl.formatMessage({ defaultMessage: 'Preview', id: 'components.PdfPreview.4c9a39' })}>
                <Document
                    file={media.downloadUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from(new Array(numPages), (_el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={width}
                        />
                    ))}
                </Document>
            </Border>
            <PreviewActions media={media} />
        </Container>
    );
};

export default PdfPreview;
