import map from 'lodash/map';

import { ThunkResult } from 'src/tools/globalStore';
import pagedFetch, { PagedFetch } from 'src/hooks/pagedFetch';

export type PagedProducts = PagedFetch<Product, ServerProductsResponse>;

const pagedProducts =
    (options: FetchProductsOptions): ThunkResult<PagedProducts> =>
    (dispatch): PagedProducts => {
        const {
            type = null,
            locale,
            search,
            searchContexts,
            columns,
            onFetchPage,
            onPageFetched,
            variationGrouped = false,
        } = options;

        const {
            queryString = null,
            families = [],
            categories = [],
            excluded_categories = [],
            attributes: searchAttributes,
            identifiersAndParentCodes = {
                products: [],
                product_models: [],
            },
        } = search;

        const payload: SearchPayload = {
            searchContexts,
            searchString: queryString,
            families,
            categories,
            excluded_categories,
            attributes: serializeAttributes(searchAttributes),
            columns,
            identifiersAndParentCodes,
            variationGrouped,
        };

        return pagedFetch<Product, SearchPayload, ServerProductsResponse>(
            {
                method: 'post',
                url: `/products/${locale}`,
                data: payload,
            },
            {
                afterKey: (prevLoad: ServerProductsResponse) => {
                    return [
                        prevLoad.products[prevLoad.products.length - 1]?.grandparent,
                        prevLoad.products[prevLoad.products.length - 1]?.parent,
                        prevLoad.products[prevLoad.products.length - 1]?.pimIdentifier,
                    ];
                },
                recordsKey: 'products',
                fetchFirstPage: true,
                onFetchPage,
                onPageFetched: (page, data: ServerProductsResponse, complete, isLoading) => {
                    if (type === null) {
                        dispatch({
                            type: 'SEARCH_RESULT_INFOS',
                            productsTotal: data.count,
                            selectedCount: data.selected.count,
                            productModelsTotal: data.product_models,
                            productDisplayedTotal: data.product_displayed,
                        });
                    }
                    if (onPageFetched) {
                        onPageFetched(page, data, complete, isLoading);
                    }
                },
            },
        );
    };

//___ helpers
export const serializeAttributes = (
    attributes?: Record<string, FilterAttributeSerialized>,
): FilterAttributeSerialized[] | null => {
    return attributes ? map(attributes, (filter) => filter) : null;
};

export default {
    pagedProducts,
};
